import commonUtils from "../utils/CommonUtils";
import moment from "moment";
import produce from 'immer';
import history from "../history";
import {unsetToken} from "./initReducer";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
const REQUESTS_INIT_LOAD_START = 'REQUESTS_INIT_LOAD_START';
const REQUESTS_INIT_LOAD_SUCCESS = 'REQUESTS_INIT_LOAD_SUCCESS';
const REQUESTS_INIT_LOAD_FAIL = 'REQUESTS_INIT_LOAD_FAIL';
const GET_REQUESTS_LIST_START = 'GET_REQUESTS_LIST_START';
const GET_REQUESTS_LIST_SUCCESS = 'GET_REQUESTS_LIST_SUCCESS';
const GET_REQUESTS_LIST_FAIL = 'GET_REQUESTS_LIST_FAIL';
const RESET_FILTER = 'RESET_FILTER';
const HANDLE_DATE_ON_CHANGE = 'HANDLE_DATE_ON_CHANGE';
//=================== ACTION CREATORS ======================
export function notAuthorized() {
    return {
        type: NOT_AUTHORIZED
    }
}

export function requestsInitLoadStart() {
    return {
        type: REQUESTS_INIT_LOAD_START,
    }
}
export function requestsInitLoadSuccess(payload) {
    return {
        type: REQUESTS_INIT_LOAD_SUCCESS,
        payload,
    }
}
export function requestsInitLoadFail(payload) {
    return {
        type: REQUESTS_INIT_LOAD_FAIL,
        payload,
    }
}
export function getRequestsListStart() {
    return {
        type: GET_REQUESTS_LIST_START,
    }
}
export function getRequestsListSuccess(payload) {
    return {
        type: GET_REQUESTS_LIST_SUCCESS,
        payload,
    }
}
export function getRequestsListFail(payload) {
    return {
        type: GET_REQUESTS_LIST_FAIL,
        payload,
    }
}
export function resetFilter() {
    return {
        type: RESET_FILTER,
    }
}

export function handleDateOnChange(payload) {
    return {
        type: HANDLE_DATE_ON_CHANGE,
        payload,
    }
}
//=================== thunks ======================
export function initRequestsListLoad() {
    return (dispatch, getState) => {
        dispatch(requestsInitLoadStart());

        let get_request_list_param = {filter: getState().requestReducer.filter};

        let api_calls = [
            commonUtils.apiCall("get", "Info/GetRequestQuality", {}),
            commonUtils.apiCall("post", "RequestData/GetRequestList", get_request_list_param),
            commonUtils.apiCall("post", "Info/GetUsersList", {})
        ]

        let payload = {};
        Promise.all(api_calls)
            .then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    if (responses[i].data.resultCode === 1) {
                        let call_method = responses[i].config.url.split('/').slice(-1)[0];
                        switch (call_method) {
                            case "GetRequestQuality":
                                payload.requests_quality = responses[i].data.result;
                                break;
                            case "GetRequestList":
                                payload.requests_list = responses[i].data.result;
                                break;
                            case "GetUsersList":
                                payload.users_list = responses[i].data.result;
                                break;
                        }
                    }
                    if (responses[i].data.resultCode === 3) {
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    }
                    if (responses[i].data.resultCode === 4) {
                        dispatch(requestsInitLoadFail(responses[i].data.result[0].validationMessage));
                        break;
                    }
                }
                dispatch(requestsInitLoadSuccess(payload));
            })
            .catch((error) => {
                dispatch(requestsInitLoadFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
            })
    }
}

export function getRequestsList(filter_params) {
    return (dispatch, getState) => {
        dispatch(getRequestsListStart());
        let user_id = getState().initReducer.user_id;

        let startDate = getState().requestReducer.filter.startDate;
        let endDate = getState().requestReducer.filter.endDate;

        let param = {
            "filter": {
                "startDate": filter_params.startDate !== undefined ? filter_params.startDate : startDate,
                "endDate": filter_params.endDate !== undefined ? filter_params.endDate : endDate,
                "userID": filter_params.userID !== undefined ? filter_params.userID : [],
                "qualityGroup": filter_params.qualityGroup,
                "quality": filter_params.quality,
                "pageNumber": filter_params.pageNumber,
            }
        };

        commonUtils.apiCall('post', 'RequestData/GetRequestList', param)
            .then((response) => {
                if (response.data.resultCode === 1) {
                  dispatch(getRequestsListSuccess({requests_list: response.data.result, filter: param.filter}));
                }
                if (response.data.resultCode === 3) {
                  history.push('/signin');
                  dispatch(unsetToken());
                  dispatch(notAuthorized());
                }
                if (response.data.resultCode === 4) {
                  let error_message = response.data.result[0].validationMessage;
                  dispatch(getRequestsListFail({error_message: error_message}));
                }
            })
            .catch((error) => {
                dispatch(getRequestsListFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
            })
    }
}

//=================== State ======================
const initial_state = {
    loading: {
        type: "",
        loading_state: false,
    },
    error_message: {
        type: '',
        message: '',
    },
    requests_list: {},
    filter: {
        "startDate": moment().format("YYYY-MM-DD"),
        "endDate": moment().format("YYYY-MM-DD"),
        "qualityGroup": 5,
        "quality": [0],
        "userID": [],
        "pageNumber": 1,
    },
    requests_quality: {},
    users_list: [],
}

export default function requestReducer(state=initial_state, action){
    switch (action.type){
        case NOT_AUTHORIZED:
            return produce(state, draft => {
                draft.loading.type = "";
                draft.loading.loading_state = false;
                draft.error_message.type = "NotAuthorized";
                draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
            })
        case REQUESTS_INIT_LOAD_START:
            return produce(state, draft => {
                draft.loading.type = "requests_init_load";
                draft.loading.loading_state = true;
            })
        case REQUESTS_INIT_LOAD_SUCCESS:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                action.payload.hasOwnProperty("requests_list") && (draft.requests_list = action.payload.requests_list);
                action.payload.hasOwnProperty("requests_quality") && (draft.requests_quality = action.payload.requests_quality);
                action.payload.hasOwnProperty("users_list") && (draft.users_list = action.payload.users_list);
            })
        case REQUESTS_INIT_LOAD_FAIL:
            return produce(state, draft  => {
                draft.loading.loading_state = false;
                draft.error_message.type = "request_init_fail";
                draft.error_message.message = action.payload;
            })
        case GET_REQUESTS_LIST_START:
            return produce(state, draft => {
                draft.loading.type = "get_requests_list";
                draft.loading.loading_state = true;
            })
        case GET_REQUESTS_LIST_SUCCESS:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.requests_list = action.payload.requests_list;
                draft.filter = action.payload.filter;
            })
        case GET_REQUESTS_LIST_FAIL:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.error_message.type = "get_request_list_error";
                draft.error_message.message = action.payload.error_message;
            })
        case RESET_FILTER:
            return produce(state, draft => {
                draft.filter = {
                    "startDate": moment().format("YYYY-MM-DD"),
                    "endDate": moment().format("YYYY-MM-DD"),
                    "qualityGroup": 5,
                    "quality": [0],
                    "userID": [],
                    "pageNumber": 1,
                }
            })
        case HANDLE_DATE_ON_CHANGE:
            return produce(state, draft => {
                draft.filter[action.payload.dateType] = action.payload.dateValue;
            })
        default:
        return state;
    }
  };
