import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NotificationsPopoverContent.scss';
import Title from "../../components/Title/Title";
import {Link} from "react-router-dom";
import Notice from "../Notice/Notice";

import {markAsRead} from "../../reducers/notificationsReducer";

class NotificationsPopoverContent extends Component {
  setNotificationIconByStatus = (customerStatus, guarantorStatus) => {
    if (guarantorStatus == 0) {
      switch (customerStatus) {
        case 1:
          return ["confirm", "#00A789"];
          break;
        case 2:
          return ["product", "#00A789"];
          break;
        case 3:
          return ["time"];
          break;
        case 4:
          return ["info", "rgb(226, 175, 41)"]
          break;
        case 5:
        case 6:
        case 7:
          return ["confirm", "#00A789"]
          break;
        case 8:
        case 9:
          return ["question", "#828282"];
          break;
        case 10:
        case 99:
        case 100:
          return ["cancel", "#D60000"];
          break;
        default:
      }
    }
    else {
      switch (guarantorStatus) {
        case 2:
          return ["confirm", "#00A789"]
          break;
        case 3:
          return ["cancel", "#D60000"];
          break;
        default:
      }
    }
  }

  setNotificationTitleByStatus = (status) => {
    switch (status) {
      case 1:
        return "Նոր";
        break;
      case 2:
        return "Ուղարկված";
        break;
      case 3:
        return "Բանկում դիտարկվող";
        break;
      case 4:
        return "Թերի";
        break;
      case 5:
        return "Դրական";
        break;
      case 6:
        return "Հաստատված";
        break;
      case 7:
        return "Տրամադրված";
        break;
      case 8:
        return "Ենթակա է Բանկ ուղարկման";
        break;
      case 9:
        return "Սահմանաչափի առաջարկ";
        break;
      case 10:
        return "Չտրամադրված";
        break;
      case 99:
        return "Հրաժարված";
        break;
      case 100:
        return "Մերժված";
        break;
      default:
    }
  }

  getNotificationMessage = (userInfo) => {
    const {
      requestID,
      quality,
      guarantorStatus,
      customerFullName
    } = userInfo;
    let notificationMessage = "";

    if (guarantorStatus == 0) {
      notificationMessage = `Հայտի պատասխան (${this.setNotificationTitleByStatus(quality)})`;
    }
    else {
      switch (guarantorStatus) {
        case 2:
          notificationMessage =
            <>
              {customerFullName}ի (հայտի համար՝ <a onClick={() => this.markAsRead(requestID)} href={`/application/${requestID}`} target="_blank">{requestID}</a>) հայտի երաշխավորը հաստատվել է․ խնդրում ենք դիտարկել
            </>;
          break;
        case 3:
          notificationMessage =
            <>
              {customerFullName}ի (հայտի համար՝ <a onClick={() => this.markAsRead(requestID)} href={`/application/${requestID}`} target="_blank">{requestID}</a>) հայտի երաշխավորը մերժվել է․ խնդրում ենք դիտարկել
            </>;
          break;
        default:
      }
    }

    return notificationMessage;
  }

  markAsRead = (request_id) => {
    this.props.dispatch(markAsRead(request_id));
  }

  render() {
    const {notifications_list} = this.props;

    return (
      <>
      <div className="flex-container row-wrap popover_header">
        <div className="column small-12">
          <Title text="ԾԱՆՈՒՑՈՒՄՆԵՐ" size="xs" weight="extrabold" className="color-white" />
        </div>
      </div>
      {/*<div className="popover_main">
        <Notice
          status="unread"
          icon="confirm"
          iconColor="#00A789"
          message="Հայտի պատասխան"
          desc={["Պողողյան Պողոս (հայտի համար՝", <a href="">00025231023</a>,  ")"]}
          key="n-1"
        />
        <Notice
          status="unread"
          icon="cancel"
          iconColor="#D60000"
          message="Հայտի պատասխան"
          desc={["Պողողյան Պողոս (հայտի համար՝", <a href="">00025231023</a>,  ")"]}
          key="n-2"
        />
        <Notice
          status=""
          icon="question"
          iconColor="#828282"
          message="գործողություն պահանջող ԾԱՆՈՒՑՄԱՆ վերնագիր"
          desc="Ծանուցման տեքստ"
          buttons={[
            <button typeof="button" className="base_btn base_btn-green">
              ԸՆԴՈՒՆԵԼ
            </button>,
            <button typeof="button" className="base_btn base_btn-red">
              ՄԵՐԺԵԼ
            </button>
          ]}
          key="n-3"
        />
        <Notice
          status=""
          icon="file"
          iconColor="#00A789"
          message="Կցված ֆայլով ԾԱՆՈՒՑՄԱՆ վերնագիր"
          messageColor="#00A789"
          desc="Paymaagir.docx"
          buttons={
            <a href="" download="" className="base_btn">
              <Icon type="download" size={17} />
              ՆԵՐԲԵՌՆԵԼ
            </a>
          }
          key="n-4"
        />
        <Notice
          status=""
          icon="product"
          iconColor="#00A789"
          message="ՆՈՐ ՊՐՈԴՈՒԿՏ"
          messageColor="#00A789"
          desc="Ծանուցման տեքստ"
          key="n-5"
        />
        <Notice
          status=""
          icon="info"
          iconColor="#00A789"
          message="ՆՈՐ ՏԵՂԵԿԱՏՎԱԿԱՆ ՆՅՈՒԹ"
          messageColor="#00A789"
          desc="Ծանուցման տեքստ"
          key="n-6"
        />
        <Notice
          status=""
          icon="flag"
          iconColor="#00A789"
          message="ՆՈՐ արշավ"
          messageColor="#00A789"
          desc="Ծանուցման տեքստ"
          key="n-7"
        />
        <Notice
          status=""
          icon="power"
          iconColor="#D60000"
          message="ծրագրի խափանում / անջատում"
          desc="Ծանուցման տեքստ"
          key="n-8"
        />
      </div>*/}
      <div className="popover_main">
        {notifications_list.map((element, index) => {
         if (index < 5) {
           // waste of resource
           return (
             <Notice
               status={element.read ? "read" : "unread"}
               icon={this.setNotificationIconByStatus(element.quality, element.guarantorStatus)[0]}
               iconColor={this.setNotificationIconByStatus(element.quality, element.guarantorStatus)[1]}
               message={this.getNotificationMessage(element)}
               desc={
                element.guarantorStatus == 0 ?
                  [`${element.customerFullName} (հայտի համար: `,
                     <a
                       onClick={() => this.markAsRead(element.requestID)}
                       href={`/application/${element.requestID}`}
                       target='_blank'
                     >
                      {element.requestID}
                     </a>,
                  ")"]
                : null
               }
               key={index}
             />
           )
         }
        })}
      </div>
      <div className="flex-container row-wrap text-center popover_footer">
        <div className="column small-12">
          <Link to="/notifications" className="base-text color-green">
            տեսնել բոլորը
          </Link>
        </div>
      </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    notifications_list: state.notificationsReducer.notifications_list,
  }
}

export default connect(mapStateToProps)(NotificationsPopoverContent);