import React, { Component } from 'react';
import './Notice.scss';
import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";

export default class Notice extends Component {
  render() {
    return (
      <div className={`flex-container row-wrap align-middle notice notice-${this.props.status} ${this.props.className}`} style={this.props.style}>
        <div className="notice_icon">
          <Icon type={this.props.icon} size={20} style={{color: this.props.iconColor}} />
        </div>
        <div className="notice_caption">
          <div className="notice_message base-text" style={{color: this.props.messageColor}}>
            {this.props.message}
          </div>
          <div className="notice_desc">
            {this.props.desc}
          </div>
        </div>
        <div className="notice_btn_group">
          {this.props.buttons}
        </div>
      </div>
    )
  }
}
