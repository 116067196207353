import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Spin} from "antd";
import moment from "moment";

import {getNews} from "../../reducers/newsReducer";
import NewsContainer from "../../components/NewsContainer/NewsContainer";

import "./_news-materials.scss";

const NewMaterials = (props) => {
  const {news, loading} = props;

  const formatDate = (dateString) => {
    const dateFormat = "DD/MM/YYYY";
    return moment(dateString).format(dateFormat);
  };

  useEffect(() => {
    props.dispatch(getNews());
  }, [0]);

  return (
    <Spin spinning={loading.loading_state && loading.type === "GetNews"} >
      {
        news.map((element, index) => (
          <div className="news-block-wrapper" >
            <div className="date-wrapper" >
              <b>{formatDate(element.openningDate)}</b>
            </div>
            <NewsContainer text={element.newsDescription} />
          </div>
        ))
      }
    </Spin>
  )
};

const mapStateToProps = (state) => ({
  loading: state.newsReducer.loading,
  news: state.newsReducer.news
});

export default connect(mapStateToProps)(NewMaterials);