import React, { Component } from 'react';
import './Menu.scss';
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {newIdentification} from "../../reducers/applicationReducer";
import { logout } from '../../reducers/initReducer';
import {message} from "antd";

class Menu extends Component {
  constructor(props){
    super(props);

    this.state = {
      burgerActive: ''
    };

    this.handleBurger = this.handleBurger.bind(this);
  }

  handleBurger() {
    this.state.burgerActive ? this.setState({burgerActive: ''}) : this.setState({burgerActive: 'active'});
  }

  logout = () => {
    this.props.dispatch(logout());
    // localStorage.clear();
    // window.location.reload();
  }

  handleNewIdentification = () => {
    this.props.dispatch(newIdentification())
  }

  isNewRequestButtonActive = () => {
    return this.props.new_request_permission;
  }

  handleNoAccessForNewRequest = () => {
    message.error("Այս պահին նոր հայտ հնարավոր չէ մուտքագրել", 5);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { error_message } = this.props;
    const errorMessageType = error_message.type;
    const errorMessageText = error_message.message;

    if (prevProps.error_message.text !== errorMessageText && errorMessageText && errorMessageType === 'LogOut') {
      message.error(errorMessageText);
    }
  }

  render() {
    return (
      <>
      <div className="small-12 relative mt_30 mb_30 text-center burger_panel">
        <div className={`burger ${this.state.burgerActive}`} onClick={this.handleBurger}>
        </div>

        <div>
          <img src={require("../../assets/img/acba.svg")} style={{width: 300}} alt="" />
        </div>
      </div>

      <div className={`menu ${this.state.burgerActive}`}>
        <a href={this.props.user_type == 0 ? "/materials" : "/"} className="brand_logo">
          <img src={require("../../assets/img/acba.svg")} alt="" />
        </a>

        {(this.props.user_type == 1 || this.props.user_type == 2) ?
            <div
              onClick={this.handleNewIdentification}
              className={this.isNewRequestButtonActive() ? "new_app" : "new_app_disabled"}
            >
              <span style={{cursor: "pointer"}} className="btn_proto btn_link">
                <span>+</span>
                ՆՈՐ ՀԱՅՏ
              </span>
            </div>
            :
            null
        }


        <div>
          <nav className="nav">
            {(this.props.user_type == 1 || this.props.user_type == 2) ?
              <NavLink to="/applications" className="nav__link">
                Հայտեր
              </NavLink>
              :
              null
            }
            {(this.props.user_type == 2 || this.props.user_type == 3 || this.props.user_type == 4) ?
                <NavLink to="/users" className="nav__link">
                  Օգտատերեր
                </NavLink>
                :
                null
            }
            {(this.props.user_type != 4) ?
                <NavLink to="/reports" className="nav__link">
                  Հաշվետվություններ
                </NavLink>
                :
                null
            }
            {/*<NavLink to="/materials" className="nav__link">
              Տեղեկատվական նյութեր
            </NavLink>*/}
            <NavLink to="/materials" className="nav__link">
              Տեղեկատվական նյութեր
            </NavLink>

            <NavLink to="/video-tutorials" className="nav__link" style={{textAlign: "center"}}>
              Ծրագրի օգտագործման ուղեցույց
            </NavLink>

            <NavLink to={this.props.user_type == 4 ? '/settings' : '/passwordreset'} className="nav__link">
              Կարգավորումներ
            </NavLink>

            <NavLink to="/calculator" className="nav__link">
              Հաշվիչ
            </NavLink>
          </nav>

          {
            this.props.logged_in != null
              ? (
                <nav onClick={this.logout} className="nav nav__link cust_btn">
                  Դուրս գալ
                </nav>
              )
              : null
          }
        </div>
      </div>
      </>
    )
  }
}

export function mapStateToProps(state) {
  return {
      logged_in: state.initReducer.logged_in,
      error_message: state.initReducer.error_message,
      user_type: state.initReducer.user_type,
      new_request_permission: state.initReducer.new_request_permission,
  };
}

export default connect(mapStateToProps)(Menu);
