import React from "react";
import {connect} from "react-redux";
import { NavLink, Redirect } from 'react-router-dom';

import {changePassword, changePasswordRequirementState} from "../../reducers/initReducer";

import Menu from "../../layout/Menu/Menu";
import Content from "../../layout/Content/Content";
import {Button, Form, Input, message} from "antd";
import Icon from "../../components/Icon/Icon";
import Title from "../../components/Title/Title";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";


class PasswordReset extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    const {error_message} = this.props;

    if (error_message.type === "ChangePass" && (error_message.message !== prevProps.error_message.message) && (error_message.message !== "")) {
      message.error(<ErrorMessageContent text={error_message.message} />, 5);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(changePassword(values));
      }
    })
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="medium-large-flex-container row-wrap fluid-y" >
        <Menu/>
        <Content>
          <div className="row">
            {!this.props.logged_in || this.props.logged_in === '0' ?
              <Redirect to="/" />
              :
              <div className="column small-12 medium-large-6" >
                <Title text="Փոխել գաղտնաբառը" size="md" weight="black" className="mb_35"/>

                {/*<button
                  onClick={() => {
                    if (this.props.password_change_requirement === true) {
                      this.props.dispatch(changePasswordRequirementState(false));
                    } else {
                      this.props.dispatch(changePasswordRequirementState(true));
                    }
                  }}
                >
                  change local storage
                </button>*/}

                <Form onSubmit={this.onSubmit} >
                  <Form.Item label="Հին գաղտնաբառ">
                    {getFieldDecorator('password', {
                      rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                    })(
                        <Input type="password" />
                    )}
                  </Form.Item>

                  <Form.Item label="Նոր գաղտնաբառ">
                    {getFieldDecorator('newPassword', {
                      rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                    })(
                        <Input type="password" />
                    )}
                  </Form.Item>

                  <Form.Item label="Կրկնել գաղտնաբառը">
                    {getFieldDecorator('retypePassword', {
                      rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                    })(
                        <Input type="password" />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                        className="btn btn-green"
                        htmlType="submit"
                        loading={this.props.loading}
                    >
                      <span>ՓՈԽԵԼ</span>
                      <span className="btn__icon">
                      <Icon type="ident" size={22}/>
                    </span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            }
          </div>
        </Content>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    session_id: state.initReducer.session_id,
    logged_in: state.initReducer.logged_in,
    loading: state.initReducer.loading,
    error_message: state.initReducer.error_message,
    password_change_requirement: state.initReducer.password_change_requirement,
  }
}

const PasswordResetContainer = Form.create()(PasswordReset);

export default connect(mapStateToProps)(PasswordResetContainer);