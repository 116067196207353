import React, { Component } from 'react';
import "./ActionInfo.scss";
import Icon from "../Icon/Icon";

export default class ActionInfo extends Component {
  render() {
    //identificationStatus can be {success, partly, fail}
    const {message1, message2, identificationStatus} = this.props.message;

    return (
      <div className="flex-container align-middle action_info">
        <div className="action_info__icon">
          <Icon
            size={36}
            type={
              identificationStatus === "success" ? "confirm" : "info"
            }
            style={
              {color: identificationStatus === "success" ? "#00A789" : "rgb(226, 175, 41)"}
            }
          />
        </div>
        <div className="action_info__desc">
          <b>{message1}</b> {message2}
        </div>
      </div>
    )
  }
}