import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Form, Input, Select } from 'antd';
import Icon from '../../components/Icon/Icon';
import NumericInput from '../../components/NumericInput/NumericInput';
import { initLoad, setMaxLoan } from '../../reducers/applicationReducer';
import Title from '../../components/Title/Title';

import './Calculator.scss';

const PMT = (monthlyPercent, months, initialAmount) => {
  let value;

  if (monthlyPercent === 0) {
    value = initialAmount / months;
  } else {
    monthlyPercent /= 100;
    value = monthlyPercent * initialAmount * Math.pow((1 + monthlyPercent), months) / (1 - Math.pow((1 + monthlyPercent), months));
  }
  return Math.abs(value);
};

const ceilValue = (value) => {
  return parseFloat(value.toFixed(0));
};

const generateCreditClosureScheme = (percent, period, sum, monthlyPayment) => {
  if (sum == 0) {
    return '';
  }

  // period = parseInt(period + "");

  var output = '',
    stepPercent,
    stepCapital,
    sumPercent = 0,
    sumCapital = 0,
    counter = 0;

  while (counter < 1) {
    // stepPercent = parseInt(percent) === 0 ? 0 : sum * percent / 100; // for guaranty we are checking if percent is 0 inside the function too
    stepPercent =  sum * percent / 100;
    sumPercent += stepPercent;

    stepCapital = monthlyPayment - stepPercent;
    sumCapital += stepCapital;

    output += '<tr>'
      + '<td>' + ceilValue(sum) + '</td>'
      + '<td>' + ceilValue(stepPercent) + '</td>'
      + '<td>' + ceilValue(stepCapital) + '</td>'
      + '<td>' + ceilValue(monthlyPayment) + '</td>'
      + '</tr>';

    sum -= stepCapital;
    return {
      stepPercent: ceilValue(stepPercent),
      stepCapital: ceilValue(stepCapital),
    };
  }
};

class Calculator extends Component {
  state = {
    monthlyPayment: null,
    brokenResult: null,
    commissionAmount: null,
  };

  countCreditMonthlyPayment = (loanAmount, loanPeriod, loanPercent, loanMonthPercent) => {
    let monthlyPayment = PMT(loanPercent / 12, loanPeriod, loanAmount);
    let monthlyTaxedPercent = parseFloat(loanMonthPercent);
    const monthlyTaxSum = loanAmount * monthlyTaxedPercent / 100;
    // monthlyPayment += monthlyTaxSum;
    const brokenResult = generateCreditClosureScheme(parseInt(loanPercent) === 0 ? 0 : (loanPercent / 12), loanPeriod, loanAmount, monthlyPayment);
    const commissionAmount = loanAmount * loanMonthPercent / 100;

    this.setState({
      monthlyPayment: ceilValue(monthlyPayment),
      brokenResult,
      commissionAmount,
    })
  };

  clearMonthlyPayment = () => {
    if (this.state.monthlyPayment) {
      this.setState({
        monthlyPayment: null,
        brokenResult: null,
        commissionAmount: null,
      })
    }
  };

  validateLoanAmount = (value) => {
    let loanAmountInput;
    if (typeof value === 'number') {
      loanAmountInput = value;
    }
    else {
      loanAmountInput = parseInt(value.replace(/\$\s?|(,*)/g, ''));
    }

    const { setFields } = this.props.form;
    if (loanAmountInput !== '') {
      if (loanAmountInput < 26000) {
        setFields({
          "loanAmount": {
            value: loanAmountInput,
            errors: [new Error("Վարկի գումարը պետք է լինի առնվազն 26,000")]
          }
        });
        return false;
      }
      /*else if (loanAmountInput > 2000000) {
        setFields({
          "loanAmount": {
            value: loanAmountInput,
            errors: [new Error('Վարկի գումարը պետք է լինի առավելագույնը 2,000,000')]
          }
        });
        return false;
      }*/
    }
    return true;
  };

  validateLoanPeriod = (value) => {
    const loanPeriodInput = value !== '' ? parseInt(value) : '';
    const { form, max_loan } = this.props;
    const { setFields } = form;
    if (loanPeriodInput !== '') {
      if (loanPeriodInput < 3) {
        setFields({
          'loanPeriod': {
            value: loanPeriodInput,
            errors: [new Error('Վարկի ժամկետը պետք է լինի առնվազն 3 ամիս')]
          }
        });
        return false;
      }
      if (parseInt(max_loan) !== 0) {
        if (loanPeriodInput > parseInt(max_loan)) {
          setFields({
            'loanPeriod': {
              value: loanPeriodInput,
              errors: [new Error(`Վարկի ժամկետը պետք է լինի առավելագույնը ${max_loan} ամիս`)]
            }
          });
          return false;
        }
      }
    }
    return true;
  };

  validateLoanPercent = (value) => {
    const loanPercentInput = value ? parseInt(value) : '';
    const { setFields } = this.props.form;
    if (loanPercentInput < 0 || loanPercentInput === '') {
      setFields({
        'loanPercent': {
          value: loanPercentInput,
          errors: [new Error('Վարկի տոկոսադրույքը պետք է լինի առնվազն 0%')],
        }
      });
      return false;
    }
    else if (loanPercentInput > 21) {
      setFields({
        'loanPercent': {
          value: loanPercentInput,
          errors: [new Error('Վարկի տոկոսադրույքը պետք է լինի առավելագույնը 21%')]
        }
      });
      return false;
    }
    return true;
  };

  validateLoanMonthPercent = (value) => {
    const loanMonthPercentInput = value ? parseInt(value) : '';
    const { setFields } = this.props.form;
    if (loanMonthPercentInput !== '') {
      if (loanMonthPercentInput < 0) {
        setFields({
          'loanMonthPercent': {
            value: loanMonthPercentInput,
            errors: [new Error('Վարկի ամսական վճարը պետք է լինի առնվազն 0%')]
          }
        });
        return false;
      }
      else if (loanMonthPercentInput > 100) {
        setFields({
          'loanMonthPercent': {
            value: loanMonthPercentInput,
            errors: [new Error('Վարկի ամսական վճարը պետք է լինի առավելագույնը 100%')]
          }
        });
        return false;
      }
    }
    return true;
  };

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  handleActionOnChange = (value) => {
    const { setMaxLoan, form } = this.props;
    this.clearMonthlyPayment();
    if (parseInt(value) === 28) {
      form.setFieldsValue({
        loanPercent: 9.56,
        loanMonthPercent: 0,
      });
    }
    else if (parseInt(value) === 30) {
      form.setFieldsValue({
        loanPercent: 8,
        loanMonthPercent: 0,
      });
    }
    else {
      form.setFieldsValue({
        loanPercent: '',
        loanMonthPercent: '',
      });
    }
    setMaxLoan(value);
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { loanAmount, loanPeriod, loanPercent, loanMonthPercent } = values;
        if (this.validateLoanAmount(loanAmount) && this.validateLoanPeriod(loanPeriod) && this.validateLoanPercent(loanPercent) && this.validateLoanMonthPercent(loanMonthPercent)) {
          this.countCreditMonthlyPayment(loanAmount, loanPeriod, loanPercent, loanMonthPercent);
        }
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { max_loan, form } = this.props;
    if (parseInt(max_loan) !== prevProps.max_loan) {
      form.setFieldsValue({
        loanPeriod: max_loan,
      });
    }
  }

  componentDidMount() {
    this.props.initLoad();
  }

  render() {
    const { monthlyPayment, brokenResult, commissionAmount } = this.state;
    let { form, max_loan, actions } = this.props;
    const { getFieldDecorator } = form;
    if (!Array.isArray(actions)) {
      actions = [];
    }
    const isActionsFilled = actions && actions.length;

    return (
      <div className='row'>
        <div className='column small-12 medium-large-9' >
          <Title className="mb_30 mt_30" text="Հաշվիչ" size="lg" weight="bold" icon="calc"/>
          <Form className='row align-middle' onSubmit={this.onSubmit} >
            {
              isActionsFilled ?
                <Form.Item label='Արշավ' className='column small-12 large-3' style={{ paddingLeft: 0, paddingRight: 24 }}>
                  {getFieldDecorator('action', {
                    initialValue: '',
                    rules: [{required: false, message: ''}],
                  })(
                    <Select onChange={this.handleActionOnChange} >
                      <Select.Option style={{ paddingBottom: 25 }} value='0' />

                      {
                        actions.map((key) => (
                          <Select.Option key={key} value={key.value}>
                            {key.label}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  )}
                </Form.Item>
                : null
            }
            <div className='row' >
              <Form.Item label='Վարկի գումար' className='column small-12 large-3'>
                {getFieldDecorator('loanAmount', {
                  initialValue: '',
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                  <NumericInput
                    suffix={<Icon type="dram" size={13}/>}
                    formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={this.clearMonthlyPayment}
                    onBlur={(event) => this.validateLoanAmount(event.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item label='Վարկի ժամկետ' className='column small-12 large-3'>
                {getFieldDecorator('loanPeriod', {
                  initialValue: max_loan || 3, // if max_loan is 0, set initial value to 3
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                  <Input
                    type="number"
                    suffix="ամիս"
                    onChange={this.clearMonthlyPayment}
                    onBlur={(event) => this.validateLoanPeriod(event.target.value)}
                  />
                )}
              </Form.Item>

              <Form.Item label='Վարկի տոկոսադրույք' className='column small-12 large-3'>
                {getFieldDecorator('loanPercent', {
                  initialValue: '',
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                  <Input
                    type="number"
                    step="any"
                    suffix="%"
                    onChange={this.clearMonthlyPayment}
                    onBlur={(event) => this.validateLoanPercent(event.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item label='Ամսական սպասարկման վճար' className='column small-12 large-3'>
                {getFieldDecorator('loanMonthPercent', {
                  initialValue: '',
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                  <Input
                    autoComplete="off"
                    type="number"
                    step="any"
                    suffix="%"
                    onChange={this.clearMonthlyPayment}
                    onBlur={(event) => this.validateLoanMonthPercent(event.target.value)}
                  />
                )}
              </Form.Item>

              <div className={`fluid-x flex-container align-middle align-right mb_30`} >
                <Button
                  className="btn column small-12 large-3"
                  htmlType="submit"
                >
                  <span>ՀԱՇՎԵԼ</span>
                  <span className="btn__icon">
                  <Icon type="calc" size={22} />
                </span>
                </Button>
              </div>
            </div>
          </Form>
          <div className='row align-middle' >
            {
              monthlyPayment ?
                <div className="flex-container column small-12">
                  <div className="form_field monthly-payment-wrapper">
                    <label>
                      Ամսական մարում
                    </label>
                    <div className="fluid-x small-order-2 text-black text-lg">
                      {this.formatNumber(monthlyPayment + commissionAmount)} <Icon type="dram" size={28}/>
                    </div>
                  </div>
                </div>
                : null
            }
            <div className="flex-container" >
              {
                monthlyPayment ?
                  <>
                    <div className={`column ${commissionAmount ? 'small-4' : 'small-12'}`} >
                      <div className="form_field">
                        <label>
                          Մայր գումար և տոկոսագումար
                        </label>
                        <div className="fluid-x small-order-2 text-black text-lg">
                          {this.formatNumber(monthlyPayment)} <Icon type="dram" size={28}/>
                        </div>
                      </div>
                    </div>
                    {/* <div className="column small-4" >
                      <div className="form_field">
                        <label>
                          Ամսական տոկոսագումար
                        </label>
                        <div className="fluid-x small-order-2 text-black text-lg">
                          {this.formatNumber(brokenResult.stepPercent)} <Icon type="dram" size={28}/>
                        </div>
                      </div>
                    </div> */}
                  </>
                  : null
              }
              {
                commissionAmount ?
                  <div className="column small-4" >
                    <div className="form_field">
                      <label>
                        Սպասարկման միջնորդավճար
                      </label>
                      <div className="fluid-x small-order-2 text-black text-lg">
                        {this.formatNumber(commissionAmount)} <Icon type="dram" size={28}/>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>

            {
              monthlyPayment ?
                <div className="flex-container column small-12" >
                  <i>Ամսական մարման չափը կարող է տարբերվել` կախված տվյալ ամսվա օրերի քանակից</i>
                </div>
              : null
            }
          </div>
          {/*{
            brokenResult ?
              <div className="column small-12">
                <div className="form_field">
                  <label>
                    Վարկի ամսական տոկոսագումարը կկազմի
                  </label>
                  <div className="fluid-x small-order-2 text-black text-lg">
                    {this.formatNumber(brokenResult.stepPercent)} <Icon type="dram" size={28}/>
                  </div>
                </div>
                <div className="form_field">
                  <label>
                    Վարկի ամսական գումարի մարումը կկազմի
                  </label>
                  <div className="fluid-x small-order-2 text-black text-lg">
                    {this.formatNumber(brokenResult.stepCapital)} <Icon type="dram" size={28}/>
                  </div>
                </div>
              </div>
            : null
          }
          {
            commissionAmount ?
              <div className="column small-12" >
                <div className="form_field">
                  <label>
                    Վարկի ամսական սպասարկման միջնորդավճարը կկազմի
                  </label>
                  <div className="fluid-x small-order-2 text-black text-lg">
                    {this.formatNumber(commissionAmount)} <Icon type="dram" size={28}/>
                  </div>
                </div>
              </div>
            : null
          }*/}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  max_loan: state.applicationReducer.max_loan,
  actions: state.applicationReducer.dictionary.actions,
  loan_percent: state.applicationReducer.loan_percent,
});

const mapDispatchToProps = (dispatch) => ({
  initLoad: () => dispatch(initLoad()),
  setMaxLoan: (data) => dispatch(setMaxLoan(data)),
});

const CalculatorScreen = Form.create()(Calculator);

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorScreen);
