import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';

import Menu from '../../layout/Menu/Menu';
import Content from '../../layout/Content/Content';
import Title from '../../components/Title/Title';
import CustomButton from '../../components/Button/Button';
import { resetEmailSentStatus, userRequestActionRestore } from '../../reducers/initReducer';
import './ForgotPassword.scss';

class ForgotPassword extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(userRequestActionRestore(values.userName));
      }
    });
  };

  componentWillUnmount() {
    this.props.dispatch(resetEmailSentStatus());
  }

  render() {
    const { isEmailSent } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className='medium-large-flex-container row-wrap fluid-y' >
        <Menu/>
        <Content>
          <div className='row'>
            <div className='column small-12 medium-large-6' >
              {
                isEmailSent
                  ? (
                    <Title
                      size='xs'
                      weight='medium'
                      className='mb_35 color-green capitalize-first-letter'
                      text='Գաղտնաբառի փոփոխությունը կատարելու համար հարկավոր է անցնել էլ.հասցեին ուղարկված հղմամբ'
                    />
                  ) : (
                    <>
                      <Title text='Մոռացել եմ գաղտնաբառը' size='md' weight='black' className='mb_35 capitalize-first-letter' />
                      <Form onSubmit={this.onSubmit} >
                        <Form.Item label='Մուտքանուն' >
                          {getFieldDecorator('userName', {
                            rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                          })(
                            <Input type='text' />
                          )}
                        </Form.Item>
  
                        <div className='actions-container flex-container align-justify' >
                          <Button
                            loading={this.props.loading}
                            htmlType="submit"
                            className="btn btn-green"
                          >
                            <span>Փոխել գաղտնաբառը</span>
                          </Button>
  
                          <CustomButton
                            text='ՉԵՂԱՐԿԵԼ'
                            linkTo='/singin'
                            className="btn btn-green"
                          />
                        </div>
                      </Form>
                    </>
                  )
              }
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.initReducer.loading,
    isEmailSent: state.initReducer.isEmailSent,
    error_message: state.initReducer.error_message,
  };
}

const ForgotPasswordContainer = Form.create()(ForgotPassword);

export default connect(mapStateToProps)(ForgotPasswordContainer);
