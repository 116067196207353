import React from "react";
import {Button, DatePicker, Form, Input, Select} from "antd";
import moment from "moment";
import FormHint from "../../components/FormHint/FormHint";
import Icon from "../../components/Icon/Icon";
import {connect} from "react-redux";
import {getRequestsList, handleDateOnChange} from "../../reducers/requestReducer";

class Filter extends React.Component {

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filter_params = {};
        filter_params.startDate = values.startDate.format("YYYY-MM-DD");
        filter_params.endDate = values.endDate.format("YYYY-MM-DD");
        filter_params.quality = values.quality !== undefined && values.quality != null ? values.quality : [0];
        filter_params.userID = values.userID !== undefined && values.userID != null ? values.userID : [];
        switch (this.props.category) {
          case "tab-all":
            filter_params.qualityGroup = 5;
            break;
          case "tab-editing":
            filter_params.qualityGroup = 1;
            break;
          case "tab-sent":
            filter_params.qualityGroup = 2;
            break;
          case "tab-received":
            filter_params.qualityGroup = 3;
            break;
          case "tab-finished":
            filter_params.qualityGroup = 4;
            break;
          default:
            // useless
            break;
        }
        filter_params.pageNumber = 1;
        this.props.dispatch(getRequestsList(filter_params));
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {category, users_list, user_type} = this.props;
    const dateFormat = 'DD/MM/YYYY';

    let option_group_list = [];
    Object.keys(this.props.requests_quality).map((index) => {
      switch (category) {
        case "tab-all":
          option_group_list.push(<Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>)
          break;
        case "tab-editing":
          if (index == 1 || index == 4 || index == 8) {
            option_group_list.push(<Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>)
          }
          break;
        case "tab-sent":
          if (index == 2 || index == 3) {
            option_group_list.push(<Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>)
          }
          break;
        case "tab-received":
          if (index == 5 || index == 9) {
            option_group_list.push(<Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>)
          }
          break;
        case "tab-finished":
          if (index == 6 || index == 7 || index == 100 || index == 99 || index == 10) {
            option_group_list.push(<Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>)
          }
          break;
      }
    })

    let users_option_list = [];
    users_list.map((element, index) => {
      users_option_list.push(<Select.Option key={element.id} > {`${element.firstName} ${element.lastName}`} </Select.Option>)
    })

    return (
        <Form
          onSubmit={this.onSubmit}
        >
          <div className="small-12 medium-large-9">
            <div className="row">
              <div className="column small-12 large-6 flex-container">
                <div>
                  <Form.Item label="Ամսաթիվ">
                    {getFieldDecorator('startDate', {
                      initialValue: moment(new Date(this.props.requests_list_filter.startDate)),
                      rules: [{ required: false, message: '' }],
                    })(
                      <>
                        <DatePicker
                          value={moment(new Date(this.props.requests_list_filter.startDate))}
                          allowClear={false}
                          format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]}
                          className="date-sm"
                          onChange={(momentValue) => {
                            this.props.dispatch(handleDateOnChange({
                              dateValue: momentValue.format("YYYY-MM-DD"),
                              dateType: "startDate",
                            }));
                            // reset necessary for changing datepicker value
                            this.props.form.resetFields("startDate");
                          }}
                        />
                        <FormHint>Սկիզբ</FormHint>
                      </>
                    )}
                  </Form.Item>
                </div>
                <div style={{paddingLeft: 20, paddingRight: 20}} className="text-left dates_separator">-</div>
                <div>
                  <Form.Item style={{float: "right"}} label="." className="hide-label">
                    {getFieldDecorator('endDate', {
                      initialValue: moment(new Date(this.props.requests_list_filter.endDate)),
                      rules: [{ required: false, message: '' }],
                    })(
                      <>
                        <DatePicker
                          value={moment(new Date(this.props.requests_list_filter.endDate))}
                          allowClear={false}
                          format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]}
                          className="date-sm"
                          onChange={(momentValue) => {
                            this.props.dispatch(handleDateOnChange({
                              dateValue: momentValue.format("YYYY-MM-DD"),
                              dateType: "endDate",
                            }));
                            // reset necessary for changing datepicker value
                            this.props.form.resetFields("endDate");
                          }}
                        />
                        <FormHint>Ավարտ</FormHint>
                      </>
                    )}
                  </Form.Item>
                </div>
              </div>

              <Form.Item label="Կարգավիճակ" className="column small-12 large-3">
                {getFieldDecorator('quality', {
                  rules: [{ required: false, message: '' }],
                })(
                    <Select
                      mode="multiple"
                      placeholder="Բոլորը"
                      dropdownStyle={{ minWidth: "30%" }}
                    >
                      {option_group_list}
                    </Select>
                )}
              </Form.Item>

              <Form.Item label="Օգտատերեր" className="column small-12 large-3">
                {getFieldDecorator("userID", {
                  rules: [{required: false, message: ''}],
                })(
                  <Select
                    mode="multiple"
                    placeholder="Բոլորը"
                    dropdownStyle={{ minWidth: "30%" }}
                  >
                    {users_option_list}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="row" style={{justifyContent: "flex-end"}}>
              <Form.Item
                className="column small-12 large-2 align-self-middle"
                style={{marginBottom: 8, marginRight: 150}}
              >
                <Button
                  className="btn btn_group"
                  htmlType="submit"
                >
                  <span>ՑՈՒՑԱԴՐԵԼ</span>
                  <span className="btn__icon">
                          <Icon type="next" size={20} />
                        </span>
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.requestReducer.loading,
    error_message: state.requestReducer.error_message,
    refuse_reason_types: state.applicationReducer.refuse_reason_types,
    requests_list: state.requestReducer.requests_list,
    requests_list_filter: state.requestReducer.filter,
    requests_quality: state.requestReducer.requests_quality,
    users_list: state.requestReducer.users_list,
    user_type: state.initReducer.user_type,
  };
}

const FilterContainer = Form.create()(Filter);

export default connect(mapStateToProps)(FilterContainer);