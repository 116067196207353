import React, { Component } from 'react';
import Icon from "../../components/Icon/Icon";
import Title from "../../components/Title/Title";
import Collapsible from 'react-collapsible';
import ActionInfo from "../../components/ActionInfo/ActionInfo";
import PersonAvatar from "../../components/PersonAvatar/PersonAvatar";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {
  newIdentification,
  fetchStreet,
  searchSelect,
  saveCustomerInfo,
  calculateRequestOffer,
  getClearSalary,
  changeCustomerSumNeed,
  customerPeriodOnChange,
  setMaxLoan,
  changeCustomerLoanAction,
  loadAcraAgreement,
  sendRequestToBank,
  changeEditStatus,
  confirmCustomerInfo,
  clearResidenceAddress,
  getArmenianPlacesByRegionId,
  getAllStreets,
  getRegions
} from "../../reducers/applicationReducer";
import { Form, Input, Button, Checkbox, Select, DatePicker, Spin, message, Modal } from 'antd';
import moment from 'moment';
import commonUtils from "../../utils/CommonUtils";
import {element} from "prop-types";
import UserIncomes from "../UserIncomes/UserIncomes";
import CancelRequest from "../CancelRequest/CancelRequest";
import UserIncomes_new from "../UserIncomes/UserIncomes";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";
import {userInputLanguageValidation} from "../../utils/userInputLanguageValidation";
import MaskedInput from "antd-mask-input";

class IdentificationResults extends Component {
  constructor(props) {
    super(props);
    const identifiedAddressList = this.props.user_info.addressList !== undefined && this.props.user_info.addressList != null ? this.props.user_info.addressList : [];
    const isIdentifiedAddressListEmpty = identifiedAddressList.length === 0;

    let registrationCountry, residenceCountry;
    if (!isIdentifiedAddressListEmpty) {
      const {addressList} = this.props.user_info;
      registrationCountry = addressList[0] ? addressList[0].country : 51; // by default Armenia is needed
      residenceCountry = addressList[1] ? addressList[1].country : 51;
    }

    this.state = {
      sameAddresses: this.props.user_info.addressList !== null ? this.isTheSameAddress(this.props.user_info.addressList): false,
      additionalInfo: "",
      currentTownVillage: '',
      currentRegion: '',
      acra_checked: false,
      apartment_required: !isIdentifiedAddressListEmpty ? identifiedAddressList[0].building == 36 : true,
      residence_apartment_required: !isIdentifiedAddressListEmpty && identifiedAddressList[1] !== undefined ? identifiedAddressList[1].building == 36 : true,
      user_info: {},
      sending_data: {},
      identification_result_message: {message1:'',message2:''},
      selectedDocumentType: null,
      isCustomerInfoEdited: false,
      isRegistrationCountrySelectedArmenia: true,
      isResidenceCountrySelectedArmenia: true,
      isRegistrationAddressStreetInputTag: false,
      isResidenceAddressStreetInputTag: false
  };
  }

  componentDidUpdate(previousProps, previousState) {
    let update_state = false;
    let incomes = this.state.incomes;

    if (this.props.error_message.type === "SaveCustomerAndRequestInfo" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(this.props.error_message.message);
    }

    if (this.props.error_message.type === "CalculateRequestOffer" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(this.props.error_message.message);
    }

    if (this.props.error_message.type === "AuthenticationError" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(this.props.error_message.message);
    }

    if (this.props.error_message.type === "LoadAcraAgreement" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      this.setState({acra_checked: false});
    }

    if(update_state){
      this.setState({
        incomes
      });
      update_state = false;
    }
  }

  printIdentificationResultMessage = () => {
    const input_values = this.props.form.getFieldsValue();
    delete input_values["addInf"];
    delete input_values["sameAddresses"];
    delete input_values["country"];
    if (input_values.residenceCountry !== undefined) {
      delete input_values['residenceCountry'];
    }
    if (input_values.buildingType != 36) {
      delete input_values['apartment'];
    }

    const input_values_keys = Object.keys(input_values);

    let emptyInputsCount = 0;
    for (let i = 0; i < input_values_keys.length; i++) {
      const currentInputValue = input_values[input_values_keys[i]];
      if (currentInputValue == null || currentInputValue === "") {
        emptyInputsCount++;
      }
    }

    switch (emptyInputsCount) {
      case 0:
        this.setState({
          identification_result_message: {
            message1: "Հաջող նույնականացում.",
            message2: "խնդրում ենք լրացնել հաճախորդի բնակության հասցեն, եթե այն տարբեր է գրանցման հասցեից",
            identificationStatus: "success",
          }
        });
        // message.success("Հաջող նույնականացում. խնդրում ենք լրացնել հաճախորդի բնակության հասցեն");
        // this.identification_result_message.message1 = "Հաջող նույնականացում.";
        // this.identification_result_message.message2 = "խնդրում ենք լրացնել հաճախորդի բնակության հասցեն";
        break;
      case input_values_keys.length:
        this.setState({
          identification_result_message: {
            message1: "Նույնականացումը հնարավոր չէ․",
            message2: "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը",
            identificationStatus: "fail",
          }
        });
        // message.error("Նույնականացումը հնարավոր չէ․ խնդրում ենք լրացնել դաշտերը")
        /*this.identification_result_message.message1 = "Նույնականացումը հնարավոր չէ․";
        this.identification_result_message.message2 = "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը";*/
        break;
      default:
        this.props.form.validateFields();
        this.setState({
          identification_result_message: {
            message1: "Հաճախորդի անձնական տվյալներն ամբողջությամբ լրացված չեն.",
            message2: "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը",
            identificationStatus: "partly",
          }
        });
        // message.warning("Հաճախորդի անձնական տվյալներն ամբողջությամբ լրացված չեն. խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը");
        // this.identification_result_message.message1 = "Հաճախորդի անձնական տվյալներն ամբողջությամբ լրացված չեն.";
        // this.identification_result_message.message2 = "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը";
        break;
    }
  };

  componentDidMount() {
    this.printIdentificationResultMessage();
  }

  isTheSameAddress = (address_list) => {
    let address1 = address_list[0];
    let address2 = address_list[1];

    if (address2 !== undefined) {
      if (address1 instanceof Object && address2 instanceof Object) {
        delete address1["addressType"];
        delete address2["addressType"];
        delete address1["priority"];
        delete address2["priority"];
        return JSON.stringify(address1) === JSON.stringify(address2);
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  };

  searchStreet = (value, id, addressType) => {
    const cityID = this.props.form.getFieldValue(id === 0 ? 'city' : 'residenceCity').key;
    this.props.dispatch(fetchStreet(value, cityID, addressType))
  };

  handleStreetChange = (value, id, type, addressType) => {
    const {addressList} = this.props.user_info;
    const resetNames = id === '0' ? ['city', 'region'] : ['residenceCity', 'residenceRegion'];
    let found;

    if (addressType === "registrationAddress") {
      found = this.props.searching_streets.find(element => element.street == value.key);
    } else {
      found = this.props.residence_searching_streets.find(element => element.street == value.key);
    }

    if (!found["country"]) {
      found["country"] = "51";
    }

    if (!found["building"] && addressList[id]) {
      found["building"] = addressList[id].building
    }

    if (!found["appartment"] && addressList[id]) {
      found["appartment"] = addressList[id].appartment
    }

    if (!found["house"] && addressList[id]) {
      found["house"] = addressList[id].house
    }

    this.props.dispatch(searchSelect(found, id, type));

    this.props.form.resetFields(resetNames);
  };

  handleBuildingTypeChange = (e) =>{
    const apartmentValue = this.props.form.getFieldValue("apartment") ? this.props.form.getFieldValue("apartment") : "";

    if (e == 36 && !apartmentValue) {
      this.props.form.setFields({
        "apartment": {
          value: apartmentValue,
          errors: [new Error("դաշտը լրացված չէ")]
        }
      });
    }
    else {
      this.props.form.setFields({
        "apartment": {
          value: apartmentValue
        }
      });
    }
    this.setState({
      apartment_required: e == 36
    });
  };

  handleResidenceBuildingTypeChange = (e) => {
    const residenceApartment = this.props.form.getFieldValue("residenceApartment") ? this.props.form.getFieldValue("residenceApartment") : "";

    if (e == 36 && !residenceApartment) {
      this.props.form.setFields({
        "residenceApartment": {
          value: residenceApartment,
          errors: [new Error("դաշտը լրացված չէ")]
        }
      });
    }
    else {
      this.props.form.setFields({
        "residenceApartment": {
          value: residenceApartment
        }
      });
    }
    this.setState({
      residence_apartment_required: e == 36,
    })
  };

  changeSameAddress = (e) => {
    if (e.target.checked && this.props.user_info.addressList[1] !== undefined) {
      this.props.dispatch(clearResidenceAddress());
    }
    this.setState({
      sameAddresses: !this.state.sameAddresses,
    })
  };

  handleAcraChecked = (event)  => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (event.target.checked === true) {
          const userFullName = {
            name: values.name,
            lastName: values.lastName,
            fatherName: values.fatherName
          };

          this.props.dispatch(loadAcraAgreement("person", values.documentNumber, userFullName))
        }
        this.setState({
          acra_checked: event.target.checked,
          isCustomerInfoEdited: false
        })
      }
    });
  };

  getUserAddressInfo = (street, region, city) => {
    let returningObject = {
      street: {
        key: null,
        label: ""
      },
      region: {
        key: null,
        label: ""
      },
      city: {
        key: null,
        label: ""
      }
    };

    if (street) {
      if (typeof street === "string") {
        returningObject.street = {
          key: 0,
          label: street
        }
      }
      else {
        returningObject.street = {
          key: street.key,
          label: street.label.split(' - ')[0]
        }
      }
    }

    if (region) {
      if (typeof region === "string") {
        returningObject.region = {
          key: 0,
          label: region
        }
      }
      else {
        returningObject.region = {
          key: region.key,
          label: region.label
        }
      }
    }

    if (city) {
      if (typeof city === "string") {
        returningObject.city = {
          key: 0,
          label: city
        }
      }
      else {
        returningObject.city = {
          key: city.key,
          label: city.label
        }
      }
    }

    return returningObject;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const dateFormat = "YYYY-MM-DDTHH:mm:ss";

      if (!err) {
        let user_info = this.props.user_info;

        let user_address_list = [];
        const registrationAddressInfo = this.getUserAddressInfo(values.street1, values.region, values.city);
        user_address_list.push({
          "addressType": 2,
          "appartment": values.apartment !== undefined && values.apartment,
          "building": values.buildingType !== undefined && values.buildingType,
          "country": values.country !== undefined && values.country,
          "house": values.house !== undefined && values.house,
          "postCode": user_info.addressList[0] !== undefined && user_info.addressList[0].postCode,
          "priority": 1,
          "region": registrationAddressInfo.region.key,
          "regionDescription": registrationAddressInfo.region.label,
          "street": registrationAddressInfo.street.key ? registrationAddressInfo.street.key : 0,
          "streetDescription": registrationAddressInfo.street.label,
          "townVillage": registrationAddressInfo.city.key,
          "townVillageDescription": registrationAddressInfo.city.label,
        });

        if (this.state.sameAddresses === false) {
          const residenceAddressInfo = this.getUserAddressInfo(values.street2, values.residenceRegion, values.residenceCity);
          user_address_list.push({
            "addressType": 1,
            "appartment": values.residenceApartment !== undefined && values.residenceApartment,
            "building": values.residenceBuildingType !== undefined && values.residenceBuildingType,
            "country": values.residenceCountry !== undefined && values.residenceCountry,
            "house": values.residenceHouse !== undefined && values.residenceHouse,
            "postCode": user_info.addressList[1] !== undefined && user_info.addressList[1].postCode !== undefined ? user_info.addressList[1].postCode : "",
            "priority": 2,
            "region": residenceAddressInfo.region.key,
            "regionDescription": residenceAddressInfo.region.label,
            "street": residenceAddressInfo.street.key ? residenceAddressInfo.street.key : 0,
            "streetDescription": residenceAddressInfo.street.label,
            "townVillage": residenceAddressInfo.city.key,
            "townVillageDescription": residenceAddressInfo.city.label,
          });
        }
        let customerDetails = {
          addInf: values.addInf,
          customerDetailsInfo: {
            "address": user_info.address,
            "addressList": user_address_list,
            "applicationID": user_info.applicationID,
            "applicationProcessingStatus": user_info.applicationProcessingStatus,
            "automatScoreRejectionReason": user_info.automatScoreRejectionReason,
            "automatScoreStep": user_info.automatScoreStep,
            "citizenship": user_info.citizenship,
            "customerIdentificationGroup": user_info.customerIdentificationGroup,
            "customerNumber": user_info.customerNumber,
            "customerPhoto": user_info.customerPhoto,
            "customerQuality": 1,
            "dateOfBirth": moment(values.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            "documentDate": moment(values.documentDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            "documentInf": values.documentInf,
            "documentNumber": values.documentNumber,
            "documentType": values.documentType,
            "documentValidDate": moment(values.documentValidDate, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            "familyCount": user_info.familyCount,
            "familyWorkingCount": user_info.familyWorkingCount,
            "fatherName": values.fatherName,
            "filialCode": user_info.filialCode,
            "guarantStatusQuality": user_info.guarantStatusQuality,
            "homePhone": user_info.homePhone,
            "lastName": values.lastName,
            "maritalStatus": user_info.maritalStatus,
            "marketPhone": user_info.marketPhone,
            "mobilePhone": user_info.mobilePhone,
            "name": values.name,
            "notDocSalary": user_info.notDocSalary,
            "notDocSalarySource": user_info.notDocSalarySource,
            "offeredCustomerNumber": user_info.offeredCustomerNumber,
            "otherDocSalary": user_info.otherDocSalary,
            "otherPhone": user_info.otherPhone,
            "quality": user_info.quality,
            "realAddress": user_info.realAddress,
            "requestQualityDescr": user_info.requestQualityDescr,
            "resident": user_info.resident,
            "scoreResult": user_info.scoreResult,
            "socialNumber": values.socialNumber !== undefined ? values.socialNumber : user_info.socialNumber
          }
        };
        this.props.dispatch(confirmCustomerInfo({ customerDetails }));
      }
    })
  };

  isAddressListEmpty = (addressListIndex, addressListValue) => {
    const user_address_list = this.props.user_info.addressList;
    if (user_address_list == null || user_address_list[addressListIndex] === undefined || user_address_list[addressListIndex][addressListValue] == null || user_address_list[addressListIndex][addressListValue] === "") {
      return true;
    }
    else {
      return false;
    }
  };

  personAvatarText = () => {
    const {user_info} = this.props;
    const lastName = user_info.lastName != null && user_info.lastName !== "" ? user_info.lastName : "";
    const name = user_info.name != null && user_info.name !== "" ? user_info.name : "";
    const fatherName = user_info.fatherName != null && user_info.fatherName !== "" ? user_info.fatherName : "";
    return lastName + " " + name + " " + fatherName;
  };

  handleRegionOnChange = (region, type) => {
    this.props.dispatch(getArmenianPlacesByRegionId(region.key, type));
    if (type === "region") {
      this.props.form.setFieldsValue({
        city: "",
        street1: "",
      });
    } else {
      this.props.form.setFieldsValue({
        residenceCity: "",
        street2: ""
      });
    }
  };

  handleCityOnChange = (e, id) => {
    const arm_place_id = e.key;
    const region = this.props.form.getFieldValue(id === 1 ? 'region' : 'residenceRegion');

    this.props.form.setFieldsValue({
      [id === 1 ? 'street1' : 'street2']: '',
    });
    this.props.dispatch(getAllStreets(arm_place_id, id, 'user', region));
  };

  handleDocumentTypeOnChange = (value) => {
    this.setState({
      selectedDocumentType: value
    })
  };

  handleCustomerInfoOnChange = (event) => {
    const {value} = event.target;
    const {agreementPDF} = this.props;

    if (value && agreementPDF) {
      this.setState({
        isCustomerInfoEdited: true,
        acra_checked: false
      })
    }
  };

  handleCountryOnChange = (value, id) => {
    if (value != 51 && value != 999) { // 51 = ARMENIA, 999 = KARABAKH
      this.setState({
        [id === 1 ? "isRegistrationCountrySelectedArmenia" : "isResidenceCountrySelectedArmenia"]: false,
        [id === 1 ? "isRegistrationAddressStreetInputTag" : "isResidenceAddressStreetInputTag"]: true
      })
    }
    else {
      let objectForSettingState = {
        [id === 1 ? "isRegistrationCountrySelectedArmenia" : "isResidenceCountrySelectedArmenia"]: true
      };

      if (value == 51) {
        objectForSettingState[id === 1 ? "isRegistrationAddressStreetInputTag" : "isResidenceAddressStreetInputTag"] = false
      } else {
        objectForSettingState[id === 1 ? "isRegistrationAddressStreetInputTag" : "isResidenceAddressStreetInputTag"] = true
      }

      this.setState(objectForSettingState)
    }

    this.props.dispatch(getRegions(value, id));

    this.props.form.setFieldsValue({
      [id == 1 ? "street1" : "street2"]: "",
      [id == 1 ? "city" : "residenceCity"]: "",
      [id == 1 ? "region" : "residenceRegion"]: "",
    })
  };

  isPersonalInfoDisabled = () => {
    const {request_status} = this.props;
    if (request_status === "edit_user_info") {
      return true;
    }
    return false;
  };

  maskedInputOnBlur = (e, inputFieldName, isValidationInput) => {
    const dateFormat = 'DD/MM/YYYY';
    const {value} = e.target;
    const isDateValid = moment(value, dateFormat).isValid();
    const isDateBefore = moment(value, "DD/MM/YYYY").isBefore();

    if (value && (!isDateValid || value.includes("_"))) {
      this.props.form.setFields({
        [inputFieldName]: {
          value,
          errors: [new Error("լրացված է սխալ")]
        }
      });
    }
    if (isValidationInput && value && isDateBefore) {
      this.props.form.setFields({
        documentValidDate: {
          value,
          errors: [new Error("Մուտքագրված անձը հաստատող փաստաթուղթը վավեր չէ")]
        }
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const dateFormat = 'DD/MM/YYYY';

    const user_address_list = this.props.user_info.addressList;
    const default_country = this.props.dictionaries.countries.find((element) => element.label.toUpperCase() === "ARMENIA" );
    return (
        <Form onSubmit={this.onSubmit}>
          <div className="row">
            <Spin spinning={this.props.request_status === "save_start"}>
              <div className="column small-12 medium-large-9">

                {
                  this.props.request_status === "identified" &&
                    <ActionInfo message={this.state.identification_result_message}/>
                }

                <div className="flex-container row-wrap align-justify align-middle mb_30">
                  <PersonAvatar
                      name={this.personAvatarText()}
                      avatar={this.props.user_info.customerPhoto ? `data:image/jpeg;base64,${this.props.user_info.customerPhoto}` : require("../../assets/img/no-user.gif")}
                  />
                  <Button disabled={this.props.request_status !== 'identified' ? true : false} type="link" className="link_btn" onClick={() => {this.props.dispatch(newIdentification())}}>
                    <Icon type="proccess" size={13} />
                    Նոր նույնականացում
                  </Button>
                </div>

                {/* IDENTIFIED USER INFO START */}
                <>
                  {/* USER PASSPORT INFO*/}
                  <div className="row mb_30 person_main">
                    <Form.Item label="Ազգանուն" className="column small-12 large-4">
                      {getFieldDecorator('lastName', {
                        initialValue: this.props.user_info.lastName != null ? this.props.user_info.lastName : "",
                        rules: [
                          {required: true, message: 'դաշտը լրացված չէ'},
                          {
                            validator: (rule, value) => userInputLanguageValidation(value),
                            message: `Խնդրում ենք գրել հայատառ`,
                          }
                        ],
                      })(
                          <Input type="text"
                                 name="lastName"
                                 onChange={this.handleCustomerInfoOnChange}
                                 disabled={this.isPersonalInfoDisabled()}
                                 autoComplete="off"
                          />
                      )}
                    </Form.Item>

                    <Form.Item label="Անուն" className="column small-12 large-4">
                      {getFieldDecorator('name', {
                        initialValue: this.props.user_info.name != null ? this.props.user_info.name : "",
                        rules: [
                          {required: true, message: 'դաշտը լրացված չէ'},
                          {
                            validator: (rule, value) => userInputLanguageValidation(value),
                            message: `Խնդրում ենք գրել հայատառ`,
                          }
                        ],
                      })(
                          <Input type="text"
                                 name="name"
                                 onChange={this.handleCustomerInfoOnChange}
                                 disabled={this.isPersonalInfoDisabled()}
                                 autoComplete="off"
                          />
                      )}
                    </Form.Item>

                    <Form.Item label="Հայրանուն" className="column small-12 large-4">
                      {getFieldDecorator('fatherName', {
                        initialValue: this.props.user_info.fatherName != null ? this.props.user_info.fatherName : "",
                        rules: [
                          {required: true, message: 'դաշտը լրացված չէ'},
                          {
                            validator: (rule, value) => userInputLanguageValidation(value),
                            message: `Խնդրում ենք գրել հայատառ`,
                          }
                        ],
                      })(
                          <Input type="text"
                                 name="fatherName"
                                 onChange={this.handleCustomerInfoOnChange}
                                 disabled={this.isPersonalInfoDisabled()}
                                 autoComplete="off"
                          />
                      )}
                    </Form.Item>

                    <Form.Item label="Ծննդյան ամսաթիվ" className="column small-12 large-4">
                      {getFieldDecorator('dateOfBirth', {
                        initialValue: this.props.user_info.dateOfBirth != null && this.props.user_info.dateOfBirth !== "" ? moment(this.props.user_info.dateOfBirth).format(dateFormat) : null,
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                        <MaskedInput
                          mask="11/11/1111"
                          placeholder={dateFormat}
                          autoComplete="off"
                          disabled={this.isPersonalInfoDisabled()}
                          onBlur={e => this.maskedInputOnBlur(e, "dateOfBirth")}
                        />
                      )}
                    </Form.Item>

                    <Form.Item label="Փաստաթղթի տեսակ" className="column small-12 large-4">
                      {getFieldDecorator('documentType', {
                        initialValue: this.props.user_info.documentType != null ? this.props.user_info.documentType : "",
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                          <Select
                            disabled={this.isPersonalInfoDisabled() || this.props.requestID || this.props.documentType}
                            onChange={this.handleDocumentTypeOnChange}
                          >
                            <Select.Option style={{paddingBottom: 25}} value="">

                            </Select.Option>
                            {
                              this.props.dictionaries.document_types.length
                                  ?
                                  this.props.dictionaries.document_types.map((key)=>{
                                    return (
                                        <Select.Option key={key} value={key.value}>
                                          {key.label}
                                        </Select.Option>
                                    )
                                  })
                                  :
                                  null
                            }
                          </Select>
                      )}
                    </Form.Item>

                    <Form.Item label="Փաստաթղթի համար" className="column small-12 large-4">
                      {getFieldDecorator('documentNumber', {
                        initialValue: this.props.user_info.documentNumber != null ? this.props.user_info.documentNumber : "",
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                          <Input
                            type="text"
                            name="documentNumber"
                            disabled={this.props.requestID || this.props.documentNumber}
                            autoComplete="off"
                          />
                      )}
                    </Form.Item>

                    <Form.Item label="Տրվել է" className="column small-12 large-4">
                      {getFieldDecorator('documentDate', {
                        initialValue: this.props.user_info.documentDate != null && this.props.user_info.documentDate !== "" ?  moment(this.props.user_info.documentDate).format(dateFormat) : null,
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                        <MaskedInput
                          mask="11/11/1111"
                          placeholder={dateFormat}
                          autoComplete="off"
                          disabled={this.isPersonalInfoDisabled()}
                          onBlur={e => this.maskedInputOnBlur(e, "documentDate")}
                        />
                      )}
                    </Form.Item>

                    <Form.Item label="Ում կողմից" className="column small-12 large-4">
                      {getFieldDecorator('documentInf', {
                        initialValue: this.props.user_info.documentInf != null ? this.props.user_info.documentInf : "",
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                          <Input type="text"
                                 name="documentInf"
                                 disabled={this.isPersonalInfoDisabled()}
                                 autoComplete="off"
                          />
                      )}
                    </Form.Item>

                    <Form.Item label="Վավերական է" className="column small-12 large-4">
                      {getFieldDecorator('documentValidDate', {
                        initialValue: this.props.user_info.documentValidDate != null && this.props.user_info.documentValidDate !== "" ? moment(this.props.user_info.documentValidDate).format(dateFormat) : null,
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                        <MaskedInput
                          mask="11/11/1111"
                          placeholder={dateFormat}
                          autoComplete="off"
                          disabled={this.isPersonalInfoDisabled()}
                          onBlur={e => this.maskedInputOnBlur(e, "documentValidDate", true)}
                        />
                      )}
                    </Form.Item>

                    {(this.state.selectedDocumentType == 2 || this.props.user_info.documentType == 2 || this.state.identification_result_message.identificationStatus === "fail") ?
                      <Form.Item label="Հանրային ծառայությունների համարանիշ" className="column small-12 large-4">
                        {getFieldDecorator('socialNumber', {
                          initialValue: this.props.user_info.socialNumber != null ? this.props.user_info.socialNumber : "",
                          rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                        })(
                          <Input type="number"
                                 name="socialNumber"
                                 disabled={this.isPersonalInfoDisabled() || this.props.user_info.documentNumber}
                                 autoComplete="off"
                            // disabled={this.props.requestID || this.props.user_info.socialNumber}
                          />
                        )}
                      </Form.Item>
                      :null
                    }

                  </div>

                  {/* USER ADDRESS INFO*/}
                  <div className="mb_30 person_addresses">
                    <div id="register_address">
                      <Title text="ԳՐԱՆՑՄԱՆ ՀԱՍՑԵ" size="lg" weight="bold" icon="location" className="mb_30" />
                      <div className="row">
                        <Form.Item label="Երկիր" className="column small-12 large-4">
                          {getFieldDecorator('country', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].country !== null) ? this.props.user_info.addressList[0].country : default_country.value,
                            rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                          })(
                            <Select
                              showSearch
                              filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onChange={value => this.handleCountryOnChange(value, 1)}
                            >
                              <Select.Option style={{paddingBottom: 25}} value="">

                              </Select.Option>
                              {
                                this.props.dictionaries.countries.length
                                  ?
                                  this.props.dictionaries.countries.map((key)=>{
                                    return (
                                      <Select.Option key={key} value={key.value}>
                                        {key.label}
                                      </Select.Option>
                                    )
                                  })
                                  :
                                  null
                              }
                            </Select>
                          )}
                        </Form.Item>

                        {
                          this.state.isRegistrationCountrySelectedArmenia ?
                            <Form.Item label="Մարզ" className="column small-12 large-4">
                              {getFieldDecorator('region', {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: !this.isAddressListEmpty(0, "region") && !this.isAddressListEmpty(0, "regionDescription") ?
                                  {
                                    key: user_address_list[0].region,
                                    label: user_address_list[0].regionDescription
                                  } : "",
                              })(
                                <Select
                                  labelInValue
                                  showSearch
                                  onChange={(e) => this.handleRegionOnChange(e, "region")}
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                  <Select.Option style={{paddingBottom: 25}} value="">

                                  </Select.Option>
                                  {
                                    this.props.dictionaries.regions.length
                                      ?
                                      this.props.dictionaries.regions.map((key)=>{
                                        return (
                                          <Select.Option key={key} value={key.value}>
                                            {key.label}
                                          </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                  }
                                </Select>
                              )}
                            </Form.Item>
                            :
                            <Form.Item label="Մարզ" className="column small-12 large-4">
                              {getFieldDecorator("region", {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: user_address_list[0] && user_address_list[0].regionDescription ? user_address_list[0].regionDescription : null,
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        {
                          this.state.isRegistrationCountrySelectedArmenia ?
                            <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                              {getFieldDecorator('city', {
                                initialValue: !this.isAddressListEmpty(0, "townVillage") && !this.isAddressListEmpty(0, "townVillageDescription") ?
                                  {
                                    key: user_address_list[0].townVillage,
                                    label: user_address_list[0].townVillageDescription
                                  } : "",
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                              })(
                                <Select
                                  labelInValue
                                  showSearch
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onChange={(e) => this.handleCityOnChange(e, 1)}
                                >
                                  <Select.Option style={{paddingBottom: 25}} value="">

                                  </Select.Option>
                                  {
                                    this.props.dictionaries.cities.length
                                      ?
                                      this.props.dictionaries.cities.map((key)=>{
                                        return (
                                          <Select.Option key={key.number} value={key.number}>
                                            {key.description}
                                          </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                  }
                                </Select>
                              )}
                            </Form.Item>
                            :
                            <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                              {getFieldDecorator("city", {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: user_address_list[0] && user_address_list[0].townVillageDescription ?  user_address_list[0].townVillageDescription : null,
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        {
                          !this.state.isRegistrationAddressStreetInputTag ?
                            <Form.Item label="Փողոց" className="column small-12 large-4">
                              {getFieldDecorator('street1', {
                                rules: [{ required: this.props.searching_streets.length ? true : false, message: 'դաշտը լրացված չէ' }],
                                initialValue: !this.isAddressListEmpty(0, "street") && !this.isAddressListEmpty(0, "streetDescription") ?
                                  {
                                    key: user_address_list[0].street,
                                    label: user_address_list[0].streetDescription
                                  } : ""
                                /*initialValue: {
                                  key: (this.props.user_info.addressList !== null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].street !== null ? this.props.user_info.addressList[0].street : null),
                                  label: (this.props.user_info.addressList !== null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].streetDescription !== null ? this.props.user_info.addressList[0].streetDescription : null)
                                },*/
                                // initialValue: "",
                              })(
                                <Select
                                  showSearch
                                  mode="default"
                                  labelInValue
                                  // setFieldsValue={this.state.value}
                                  placeholder="Մուտքագրեք փողոցը"
                                  filterOption={false}
                                  notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                  onSearch={(e) => this.searchStreet(e, 0, 'registrationAddress')}
                                  onChange={(e) => this.handleStreetChange(e,'0', 'person', 'registrationAddress')}
                                  style={{ width: '100%' }}
                                  dropdownStyle={{ minWidth: "35%" }}
                                >
                                  {this.props.searching_streets.length !== 0 ?
                                    this.props.searching_streets.map(element => {
                                      if (element.streetDescription !== undefined && element.streetDescription !== "" && element.streetDescription != null &&
                                        element.townVillageDescription !== undefined && element.townVillageDescription !== "" && element.townVillageDescription != null) {
                                        return (
                                          <Select.Option key={element.street} value={element.street}>{`${element.streetDescription} - ${element.townVillageDescription}`}</Select.Option>
                                        )
                                      }
                                    }) : null}
                                </Select>
                              )}
                            </Form.Item>
                          :
                            <Form.Item label="Փողոց" className="column small-12 large-4">
                              {getFieldDecorator("street1", {
                                initialValue: user_address_list[0] && user_address_list[0].streetDescription ? user_address_list[0].streetDescription : null,
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        <Form.Item label="Շինության տեսակ" className="column small-12 large-4">
                          {getFieldDecorator('buildingType', {
                            initialValue: (this.props.user_info.addressList != null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].building != null) ? this.props.user_info.addressList[0].building.toString() : null,
                            rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                          })(
                              <Select
                                  showSearch
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onChange={this.handleBuildingTypeChange}
                              >
                                <Select.Option style={{paddingBottom: 25}} value="">

                                </Select.Option>
                                {
                                  this.props.dictionaries.building_types.length
                                      ?
                                      this.props.dictionaries.building_types.map((key)=>{
                                        return (
                                            <Select.Option key={key} value={key.value}>
                                              {key.label}
                                            </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                }
                              </Select>
                          )}
                        </Form.Item>

                        <Form.Item label="Տուն / Շենք" className="column small-12 large-2">
                          {getFieldDecorator('house', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].house !== null) ? this.props.user_info.addressList[0].house : null,
                            rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                          })(
                              <Input type="text"
                                     name="house"
                                     autoComplete="off"
                              />
                          )}
                        </Form.Item>

                        <Form.Item label="Բնակարան" className="column small-12 large-2">
                          {getFieldDecorator('apartment', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[0] !== undefined && this.props.user_info.addressList[0].appartment !== null) ? this.props.user_info.addressList[0].appartment : null,
                            rules: [{ required: this.state.apartment_required, message: 'դաշտը լրացված չէ' }],
                          })(
                              <Input type="text"
                                     name="apartment"
                                     autoComplete="off"
                              />
                          )}
                        </Form.Item>

                        <Form.Item className="column small-12 m_0">
                          {getFieldDecorator('sameAddresses', {
                            valuePropName: 'checked',
                            initialValue: !this.state.sameAddresses,
                            rules: [{ required: false, message: '' }],
                          })(
                              <Checkbox
                                  onChange={(e) => this.changeSameAddress(e)}
                              >
                                Բնակության հասցեն տարբերվում է գրանցման հասցեից
                              </Checkbox>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="mb_30">
                    <div className="mb_60" />
                    { this.state.sameAddresses === false &&
                    <div id="residense_address">
                      <Title text="ԲՆԱԿՈՒԹՅԱՆ ՀԱՍՑԵ" size="lg" weight="bold" icon="home" className="mb_30"/>
                      <div className="row">
                        <Form.Item label="Երկիր" className="column small-12 large-4">
                          {getFieldDecorator('residenceCountry', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[1] !== undefined && this.props.user_info.addressList[1].country !== undefined) ? this.props.user_info.addressList[1].country : default_country.value,
                            rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                          })(
                            <Select
                              showSearch
                              filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onChange={value => this.handleCountryOnChange(value, 2)}
                            >
                              <Select.Option style={{paddingBottom: 25}} value="">

                              </Select.Option>
                              {
                                this.props.dictionaries.countries.length
                                  ?
                                  this.props.dictionaries.countries.map((key) => {
                                    return (
                                      <Select.Option key={key} value={key.value}>
                                        {key.label}
                                      </Select.Option>
                                    )
                                  })
                                  :
                                  null
                              }
                            </Select>
                          )}
                        </Form.Item>

                        {
                          this.state.isResidenceCountrySelectedArmenia ?
                            <Form.Item label="Մարզ" className="column small-12 large-4">
                              {getFieldDecorator('residenceRegion', {
                                initialValue: !this.isAddressListEmpty(1, "region") && !this.isAddressListEmpty(1, "regionDescription") ?
                                  {
                                    key: user_address_list[1].region,
                                    label: user_address_list[1].regionDescription
                                  } : "",
                                rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                              })(
                                <Select
                                  labelInValue
                                  showSearch
                                  onChange={(e) => this.handleRegionOnChange(e, "residenceRegion")}
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                  <Select.Option style={{paddingBottom: 25}} value="">

                                  </Select.Option>
                                  {
                                    this.props.dictionaries.residence_regions.length
                                      ?
                                      this.props.dictionaries.residence_regions.map((key) => {
                                        return (
                                          <Select.Option key={key} value={key.value}>
                                            {key.label}
                                          </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                  }
                                </Select>
                              )}
                            </Form.Item>
                            :
                            <Form.Item label="Մարզ" className="column small-12 large-4">
                              {getFieldDecorator("residenceRegion", {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: user_address_list[1] && user_address_list[1].regionDescription ? user_address_list[1].regionDescription : null,
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        {
                          this.state.isResidenceCountrySelectedArmenia ?
                            <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                              {getFieldDecorator('residenceCity', {
                                initialValue: !this.isAddressListEmpty(1, "townVillage") && !this.isAddressListEmpty(1, "townVillageDescription") ?
                                  {
                                    key: user_address_list[1].townVillage,
                                    label: user_address_list[1].townVillageDescription
                                  } : "",
                                rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                              })(
                                <Select
                                  labelInValue
                                  showSearch
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onChange={(e) => this.handleCityOnChange(e, 2)}
                                >
                                  <Select.Option style={{paddingBottom: 25}} value="">

                                  </Select.Option>
                                  {
                                    this.props.dictionaries.residence_cities.length
                                      ?
                                      this.props.dictionaries.residence_cities.map((key) => {
                                        return (
                                          <Select.Option key={key.number} value={key.number}>
                                            {key.description}
                                          </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                  }
                                </Select>
                              )}
                            </Form.Item>
                            :
                            <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                              {getFieldDecorator("residenceCity", {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: user_address_list[1] && user_address_list[1].townVillageDescription ? user_address_list[1].townVillageDescription : null,
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        {
                          !this.state.isResidenceAddressStreetInputTag ?
                            <Form.Item label="Փողոց" className="column small-12 large-4">
                              {getFieldDecorator('street2', {
                                rules: [{required: this.props.residence_searching_streets.length ? true : false, message: 'դաշտը լրացված չէ'}],
                                initialValue: !this.isAddressListEmpty(1, "street") && !this.isAddressListEmpty(1, "streetDescription") ?
                                  {
                                    key: user_address_list[1].street,
                                    label: user_address_list[1].streetDescription
                                  } : "",
                              })(
                                <Select
                                  showSearch
                                  mode="default"
                                  labelInValue
                                  // setFieldsValue={this.state.value}
                                  placeholder="Մուտքագրեք փողոցը"
                                  notFoundContent={this.state.fetching ? <Spin size="small"/> : null}
                                  filterOption={false}
                                  onSearch={(e) => this.searchStreet(e, 1, "residenceAddress")}
                                  onChange={(e) => this.handleStreetChange(e, '1', 'person', 'residenceAddress')}
                                  style={{width: '100%'}}
                                  dropdownStyle={{ minWidth: "35%" }}
                                >
                                  {this.props.residence_searching_streets.length !== 0 ?
                                    this.props.residence_searching_streets.map(element => (
                                      <Select.Option key={element.street} value={element.street}>{element.streetDescription !== undefined && `${element.streetDescription} - ${element.townVillageDescription}`}</Select.Option>
                                    )) : null}
                                </Select>
                              )}
                            </Form.Item>
                          :
                            <Form.Item label="Փողոց" className="column small-12 large-4">
                              {getFieldDecorator("street2", {
                                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                                initialValue: user_address_list[1] && user_address_list[1].streetDescription ? user_address_list[1].streetDescription : null,
                              })(
                                <Input type="text" autoComplete="off" />
                              )}
                            </Form.Item>
                        }

                        <Form.Item label="Շինության տեսակ" className="column small-12 large-4">
                          {getFieldDecorator('residenceBuildingType', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[1] !== undefined && this.props.user_info.addressList[1].building !== null) ? this.props.user_info.addressList[1].building.toString() : null,
                            rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                          })(
                              <Select
                                  showSearch
                                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onChange={this.handleResidenceBuildingTypeChange}
                              >
                                <Select.Option style={{paddingBottom: 25}} value="">

                                </Select.Option>
                                {
                                  this.props.dictionaries.building_types.length
                                      ?
                                      this.props.dictionaries.building_types.map((key) => {
                                        return (
                                            <Select.Option key={key} value={key.value}>
                                              {key.label}
                                            </Select.Option>
                                        )
                                      })
                                      :
                                      null
                                }
                              </Select>
                          )}
                        </Form.Item>

                        <Form.Item label="Տուն / Շենք" className="column small-12 large-2">
                          {getFieldDecorator('residenceHouse', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[1] !== undefined && this.props.user_info.addressList[1].house !== null) ? this.props.user_info.addressList[1].house : null,
                            rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                          })(
                              <Input type="text"
                                     name="residenceHouse"
                                     autoComplete="off"
                              />
                          )}
                        </Form.Item>

                        <Form.Item label="Բնակարան" className="column small-12 large-2">
                          {getFieldDecorator('residenceApartment', {
                            initialValue: (this.props.user_info.addressList !== null && this.props.user_info.addressList[1] !== undefined && this.props.user_info.addressList[1].appartment !== null) ? this.props.user_info.addressList[1].appartment : null,
                            rules: [{required: this.state.residence_apartment_required, message: 'դաշտը լրացված չէ'}],
                          })(
                              <Input type="text"
                                     name="residenceApartment"
                                     autoComplete="off"
                              />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    }
                  </div>
                </>
                {/* IDENTIFIED USER INFO END */}

                {/* Additional info */}
                <Collapsible
                  trigger={<Title text="ԼՐԱՑՈՒՑԻՉ ՏՎՅԱԼՆԵՐ" icon="info" size="lg" weight="bold" />}
                  triggerTagName="div"
                  open={this.props.addInf}
                  disabled={this.isPersonalInfoDisabled()}
                >
                  <div className="panel_inner">
                    <Form.Item>
                      {getFieldDecorator('addInf', {
                        rules: [{ required: false, message: '' }],
                        initialValue: this.props.addInf,
                      })(
                          <Input.TextArea style={{minHeight: "60px"}} />
                      )}
                    </Form.Item>
                  </div>
                </Collapsible>

              </div>
            </Spin>

            <div className="column small-12">
              <div>
                <Spin size="small" spinning={this.props.loading.type === "acra_loading" && this.props.loading.loading_state} >
                  <>
                    {(this.props.agreementPDF === "") || this.state.isCustomerInfoEdited ?
                      <Form.Item>
                        <Checkbox
                          className="mb_30"
                          onChange={this.handleAcraChecked}
                          checked={this.state.acra_checked}
                        >
                          Խնդրում ենք արտատպել ԷԿԵՆԳ/ՆՈՐՔ/ԱՔՌԱ հարցումներ կատարելու
                          թույլտվության համաձայնագիրը և ապահովել հաճախորդի ստորագրությունը:
                        </Checkbox>
                      </Form.Item>
                      : null
                    }


                    {(this.props.agreementPDF !== "" && this.state.acra_checked) &&
                    (<a
                        href={`data:application/octet-stream;charset=utf-8;base64,${this.props.agreementPDF}`}       download="ACRA_Agreement.pdf"
                        className="base_link mb_45"
                    >
                      <Icon type="print" size={20} />
                      ԷԿԵՆԳ/ՆՈՐՔ/ԱՔՌԱ հարցումներ կատարելու թույլտվության համաձայնագիր {<span style={{textDecoration: "underline"}} >(Տպել)</span>}
                    </a>)
                    }
                  </>
                </Spin>
              </div>
              <Button
                  className="btn mb_60"
                  htmlType="submit"
                  loading={this.props.loading.type === "save_customer_loading" && this.props.loading.loading_state}
                  disabled={this.props.agreementPDF === ""}
              >
                <span>ՀԱՍՏԱՏԵԼ</span>
                <span className="btn__icon">
                  <Icon type="microcard" size={22} />
                </span>
              </Button>
            </div>

          </div>
        </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.applicationReducer.dictionary,
    requestID: state.applicationReducer.customer_info_for_save.request.requestID,
    documentNumber: state.applicationReducer.customer_info_for_save.request.customerDetails.documentNumber,
    documentType: state.applicationReducer.customer_info_for_save.request.customerDetails.documentType,
    addInf: state.applicationReducer.customer_info_for_save.request.addInf,
    user_info: state.applicationReducer.customer_info_for_save.request.customerDetails,
    searching_streets: state.applicationReducer.searching_streets,
    residence_searching_streets: state.applicationReducer.residence_searching_streets,
    region: state.applicationReducer.region,
    townVillage: state.applicationReducer.townVillage,
    request_status: state.applicationReducer.request_status,
    loading: state.applicationReducer.loading,
    error_message: state.applicationReducer.error_message,
    agreementPDF: state.applicationReducer.agreementPDF,
    // customer_info_for_save: state.applicationReducer.customer_info_for_save,
  };
}

const IdentificationResultsContainer = Form.create()(IdentificationResults);

export default connect(mapStateToProps)(IdentificationResultsContainer);
