import React, { Component } from 'react';
import "./Tabs.scss"

export default class Tabs extends Component {
  render() {
    let classNames = [
      "flex-container align-middle tabs",
      this.props.className
    ];

    return (
      <div className={classNames.join(" ")} style={this.props.style}>
        { this.props.children }
      </div>
    )
  }
}
