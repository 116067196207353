import React, { Component } from 'react';
import "./PersonAvatar.scss";
import Title from "../Title/Title";

export default class PersonAvatar extends Component {
  state = {
    zoomIn: false,
  };

  handleAvatarZoom = () => {
    this.setState(prevState => ({
      zoomIn: !prevState.zoomIn,
    }))
  };

  render() {
    const {zoomIn} = this.state;

    return (
      <div className="flex-container align-middle align-justify person_ava">
        {
          !zoomIn ?
            <div
              className={`person_ava__img ${zoomIn ? 'person_ava__img__zoom' : ''}`}
              style={{ backgroundImage : 'url("' + this.props.avatar + '")' }}
              onClick={this.handleAvatarZoom}
            />
          :
            <div className="person-avatar-image-wrapper" >
              <img
                src={this.props.avatar}
                alt="avatar-icon"
                onClick={this.handleAvatarZoom}
              />
            </div>
        }

        <Title text={this.props.name} size="lg" weight="bold" />
      </div>
    )
  }
}