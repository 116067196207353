import React from "react";
import {connect} from "react-redux";
import {getReportList, getUsersListByMarketId} from "../../reducers/reportsReducer";
import {Button, DatePicker, Form, Select} from "antd";
import moment from "moment";
import FormHint from "../../components/FormHint/FormHint";
import Icon from "../../components/Icon/Icon";
import NumericInput from "../../components/NumericInput/NumericInput";

const {Option, OptGroup} = Select;

class Filter extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      marketIDInputClassName: '3',
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.startDate = values.startDate.format("YYYY/MM/DD");
        values.endDate = values.endDate.format("YYYY/MM/DD");
        values.marketID = values.marketID && values.marketID.length === 0 ? [0] : values.marketID;
        values.quality = values.quality.length === 0 ? [0] : values.quality;
        values.itemType = values.itemType.length === 0 ? [0] : values.itemType;
        values.userID = values.userID && values.userID.length === 0 ? [0] : values.userID;
        values.maxStartAmount = values.maxStartAmount !== "" ? values.maxStartAmount : 0;
        values.minStartAmount = values.minStartAmount !== "" ? values.minStartAmount : 0;

        this.props.dispatch(getReportList(values, 1));
      }
    })
  }

  handleMarketIdOnChange = (marketId_arr) => {
    this.setState({marketIDInputClassName: marketId_arr.length <= 1 ? '3' : '5'});
    if (marketId_arr.length !== 0) {
      this.props.dispatch(getUsersListByMarketId(marketId_arr));
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {marketIDInputClassName} = this.state;
    const dateFormat = 'DD/MM/YYYY';

    return (
      <Form
        onSubmit={this.onSubmit}
      >
        <div className="row mb_30">
          <div className="column small-12 large-5 flex-container">
            <div className="small-12 large-4">
              <Form.Item label="Ամսաթիվ">
                {getFieldDecorator('startDate', {
                  initialValue: moment(),
                  rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
                })(
                    <>
                      <DatePicker
                          defaultValue={moment(new Date())}
                          allowClear={false}
                          format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]}
                          className="date-sm"
                          placeholder="Ընտրել"
                          onChange={(e) => {
                            this.props.form.setFieldsValue({
                              "startDate": e,
                            })
                          }}
                      />
                      <FormHint>Սկիզբ</FormHint>
                    </>
                )}
              </Form.Item>
            </div>
            <div className="large-2 text-center dates_separator">-</div>
            <div className="small-12 large-4">
              <Form.Item label="." className="hide-label">
                {getFieldDecorator('endDate', {
                  initialValue: moment(),
                  rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
                })(
                    <>
                      <DatePicker
                        defaultValue={moment(new Date())}
                        allowClear={false}
                        format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]}
                        className="date-sm"
                        placeholder="Ընտրել"
                        onChange={(e) => {
                          this.props.form.setFieldsValue({
                            "endDate": e,
                          })
                        }}
                      />
                      <FormHint>Ավարտ</FormHint>
                    </>
                )}
              </Form.Item>
            </div>
          </div>

          <Form.Item label="Կարգավիճակ" className="column small-12 large-3">
            {getFieldDecorator('quality', {
              initialValue: [],
              rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
            })(
                <Select
                  showSearch
                  filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  mode="multiple"
                  placeholder="Բոլորը"
                >
                  {Object.keys(this.props.requests_quality).map((index) => (
                      <Select.Option key={index} >{this.props.requests_quality[index]}</Select.Option>
                  ))}
                </Select>
            )}
          </Form.Item>

          <Form.Item label="Ապրանքատեսակ" className="column small-12 large-3">
            {getFieldDecorator('itemType', {
              initialValue: [],
              rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
            })(
              <Select
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                mode="multiple"
                placeholder="Բոլորը"
              >
                {this.props.item_types.map((element, index) => {
                  if (this.props.item_types[index + 1] !== undefined) {
                    if (index === 0 || element.itemGroupID !== this.props.item_types[index + 1].itemGroupID) {
                      return (
                        <OptGroup
                          key={index === 0 ? this.props.item_types[index].id : this.props.item_types[index + 1].id}
                          label={<b><p style={{color: "rgb(140, 161, 9)"}} >{index === 0 ? this.props.item_types[index + 1].itemGroupDescription : this.props.item_types[index + 1].itemGroupDescription}</p></b>}>
                          <Option
                            key={index === 0 ? this.props.item_types[index].id : this.props.item_types[index + 1].id}
                            value={index === 0 ? this.props.item_types[index].id : this.props.item_types[index + 1].id}>{index === 0 ? this.props.item_types[index].itemDescription : this.props.item_types[index + 1].itemDescription}</Option>
                        </OptGroup>
                      )
                    } else {
                      return (
                        <Option key={this.props.item_types[index + 1].id}
                                value={this.props.item_types[index + 1].id}>{this.props.item_types[index + 1].itemDescription}</Option>
                      )
                    }
                  }
                })}
              </Select>
            )}
          </Form.Item>
        </div>
        <div className="row mb_30">
          <div className="column small-12 large-5 flex-container">
            <div className="small-12 large-4">
              <Form.Item label="Վարկի գումար">
                {getFieldDecorator('minStartAmount', {
                  initialValue: 0,
                  rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
                })(
                  <>
                    <NumericInput
                      className="input-sm"
                      formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="minimum"
                      suffix={<Icon type="dram" size={16} />}
                      onChange={(value) => {
                        this.props.form.setFieldsValue({
                          "minStartAmount": value,
                        })
                      }}
                    />
                    <FormHint>Նվազագույն</FormHint>
                  </>
                )}
              </Form.Item>
            </div>
            <div className="large-2 text-center dates_separator">-</div>
            <div className="small-12 large-4">
              <Form.Item label="." className="hide-label">
                {getFieldDecorator('maxStartAmount', {
                  initialValue: 0,
                  rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
                })(
                  <>
                    <NumericInput
                      className="input-sm"
                      formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="maximum"
                      suffix={<Icon type="dram" size={16} />}
                      onChange={(value) => {
                        this.props.form.setFieldsValue({
                          "maxStartAmount": value,
                        })
                      }}
                    />
                    <FormHint>Առավելագույն</FormHint>
                  </>
                )}
              </Form.Item>
            </div>
          </div>

          {
            this.props.user_type == 3 &&
              <Form.Item label="Խանութ" className={`column small-12 large-${marketIDInputClassName}`}>
                {getFieldDecorator('marketID', {
                  initialValue: [],
                  rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode="multiple"
                    placeholder="Բոլորը"
                    onChange={this.handleMarketIdOnChange}
                  >
                    {Object.keys(this.props.shops_list).map((index) => (
                      <Select.Option key={index} >{this.props.shops_list[index]}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
          }

          <Form.Item label="Օգտատեր" className="column small-12 large-3">
            {getFieldDecorator('userID', {
              initialValue: [],
              rules: [{ required: false, message: 'դաշտը լրացված չէ' }],
            })(
              <Select
                showSearch
                filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                mode="multiple"
                placeholder="Բոլորը"
              >
                {this.props.users_list.map((element) => (
                  <Select.Option key={element.id} >{`${element.firstName} ${element.lastName}`}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item className="column small-12 large-3 align-self-middle">
            <Button
              className="btn btn_group"
              htmlType="submit">
              <span>ՑՈՒՑԱԴՐԵԼ</span>

              <span className="btn__icon">
                  <Icon type="next" size={20} />
                </span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    shops_list: state.reportsReducer.shops_list,
    requests_quality: state.reportsReducer.requests_quality,
    item_types: state.reportsReducer.item_types,
    users_list: state.reportsReducer.users_list,
    user_type: state.initReducer.user_type
  }
}

const FilterContainer = Form.create()(Filter);

export default connect(mapStateToProps)(FilterContainer);