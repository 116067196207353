import React, { Component } from 'react';
import './Content.scss';

export default class Content extends Component {
  render() {
    return (
      <div className="column main_content">
        <div className="row">
          <div className="column small-12">
            { this.props.children }
          </div>
        </div>
      </div>
    )
  }
}
