import React from "react";
import {connect} from "react-redux";
import {Modal, Button, Spin, Radio, message} from 'antd';
import Icon from "../../components/Icon/Icon";
import {getRefuseReasonTypes, cancelRequest} from "../../reducers/applicationReducer";
import {element} from "prop-types";
import Title from "../../components/Title/Title";

import "./_cancel_request.scss";

class CancelRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      refuse_reason: "",
      refuse_reason_val: "",
    }
  }

  hideModal = () => {

    this.setState({
      modalVisible: false,
      refuse_reason: "",
      refuse_reason_val: "",
    });
  }

  refuseOnClick = () => {
      if(Object.keys(this.props.refuse_reason_types).length === 0 && this.props.refuse_reason_types.constructor === Object){
          this.props.dispatch(getRefuseReasonTypes());
      }

    this.setState({
      modalVisible: true,
    })
  };

  handleCancelRequest = () => {
    if (this.state.refuse_reason_val == "") {
      message.error("Խնդրում ենք ընտրել հրաժարման պատճառը");
    }
    else {
      Modal.confirm({
        title: 'Համոզվա՞ծ եք, որ տվյալ վարկն անհրաժեշտ է դարձնել «Հրաժարված վարկ»',
        okText: 'Այո',
        cancelText: 'Ոչ',
        onOk: () => {
          this.props.dispatch(cancelRequest(this.state.refuse_reason_val));
          this.hideModal();
        },
        onCancel: () => {
            this.hideModal();
        }
      });
    }
  };

  refuseReasonCheckbox = (e) => {

    this.setState({
      refuse_reason_val: e.target.value
    })
  }

  render() {
    return (
        <>
          <p
            className={`base_link ${this.props.acba_status === 7 || this.props.acba_status === 99 || this.props.acba_status === 100 || this.props.user_type == 3 ? "disabled" : ""}`}
            onClick={this.refuseOnClick}
            style={{cursor: "pointer", fontSize: 15, marginRight: 20, color: "#4C4C4D"}}
          >
            Հաճախորդը հրաժարվում է վարկից
          </p>

          <Modal
              title={<Title text="Ընտրել հրաժարման պատճառը" size="md" weight="black" />}
              className="base_modal"
              centered
              visible={this.state.modalVisible}
              onCancel = {() => this.hideModal()}
              footer={[
                <Button key="back" className="base_btn base_btn-red" onClick={this.hideModal}>
                  Չեղարկել
                </Button>,
                <Button
                  key="submit"
                  className="base_btn base_btn-green"
                  onClick={() => this.handleCancelRequest()}
                >
                  Հրաժարվել
                </Button>
              ]}
          >
              <Spin spinning={this.props.loading.type === "refuse_loading" && this.props.loading.loading_state}>
              <Radio.Group onChange={this.refuseReasonCheckbox} value={this.state.refuse_reason_val}>
                  {Object.keys(this.props.refuse_reason_types).map((element, index) => (
                      <div key={index}>
                          <Radio
                              value={element}
                              className="mb_30"
                              style={{marginLeft: 0}}
                          >
                              {this.props.refuse_reason_types[element]}
                          </Radio>
                      </div>
                  ))}
              </Radio.Group>
              </Spin>
          </Modal>
        </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    refuse_reason_types: state.applicationReducer.refuse_reason_types,
    loading: state.applicationReducer.loading,
    acba_status: state.applicationReducer.acba_status,
  };
}

export default connect(mapStateToProps)(CancelRequest);
