import React, { Component } from 'react';
import './NotificationsPopover.scss';
import Icon from "../../components/Icon/Icon";

export default class PopoverButton extends Component {
  render() {
    return (
      <div className="notification_btn">
        {
          this.props.count !== '' ?
            <span className="notification_btn__count" style={{display: this.props.hideCount ? 'none' : '' }}>
              {this.props.count}
            </span> : null
        }

        <Icon type="alert" size={19} />
      </div>
    )
  }
}
