import React, { Component } from 'react';
import {connect} from 'react-redux';
import { getNewRequestPermission, getUserType } from './reducers/initReducer';
import {getNotifications} from './reducers/notificationsReducer';
import './App.scss';
import {Route,Redirect, Switch} from "react-router-dom";
import Application from "./screens/Application/Application";
import Offer from "./screens/Offer/Offer";
import Content from "./layout/Content/Content";
import Menu from "./layout/Menu/Menu";
import HelpersUtil from "./layout/HelpersUtil";
import LoanTerms from "./screens/LoanTerms/LoanTerms";
import Success from "./screens/Success/Success";
import Materials from "./screens/Materials/Materials";
import Reports from "./screens/Reports/Reports";
import Notifications from "./screens/Notifications/Notifications";
import Users from "./screens/Users/Users";
import AllApplications from "./screens/AllApplications/AllApplications";
import NegativeAnswer from "./screens/NegativeAnswer/NegativeAnswer";
import Settings from "./screens/Settings/Settings";
import NewApplicationStatus from "./screens/NewApplicationStatus/NewApplicationStatus";
import NewMaterials from "./screens/NewMaterials/NewMaterials";
import Calculator from "./screens/Calculator/Calculator";
import VideoTutorials from "./screens/VideoTutorials/VideoTutorials";
import {notification} from "antd";

import history from './history';

class PrivateRoutes extends Component {
  constructor(props) {
    super(props);

    const {isCalledNewRequestPermission, password_change_requirement} = this.props;
    this.isNotificationFirstTimeRendered = false;

    if (isCalledNewRequestPermission && isCalledNewRequestPermission === "false") {
      this.props.dispatch(getNewRequestPermission());
    }

    if (password_change_requirement && password_change_requirement === "false") {
      // get notifications
      this.props.dispatch(getNotifications());
      setInterval(() => {
        this.props.dispatch(getNotifications());
      }, 600000);
    }
  }

  isComponentNotFound = () => {
    if (this.props.user_type == 4) {
      return true;
    }
    else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.isNotificationFirstTimeRendered) {
      if (prevProps.notifications_list.length !== this.props.notifications_list.length) {
        notification.info({
          message: "Առկա են չկարդացված հաղորդագրություններ",
          placement: "bottomRight",
          duration: null,
          onClick: () => {
            window.open('/notifications', '_blank');
            notification.destroy();
          },
        });
        this.isNotificationFirstTimeRendered = true;
      }
    }
  }

  componentDidMount() {
    if (localStorage.getItem('logged_in')) {
      this.props.dispatch(getUserType());
    }
  }

  render() {
    console.log("12/08/2022 16:00");
      return (
          localStorage.getItem("password_change_requirement") === "true" ?
            <Redirect to="/passwordreset" />
          :
          //check if user already authenticated and has token
          localStorage.getItem('logged_in') ? (
              <>
                  <div className="medium-large-flex-container row-wrap fluid-y">
                    <Menu />

                    <Content>

                      <Switch>
                        <Route path="/" component={ this.isComponentNotFound() || this.props.user_type == 3 ? Users : Application } exact />
                        <Route path="/application/:id" component={ this.isComponentNotFound() ? () => <h1>Not Found</h1> : Application} />
                        <Route path="/application/" component={ this.isComponentNotFound() || this.props.user_type == 3 ? () => <h1>Not Found</h1> : Application } />
                        <Route path="/applications" component={ this.isComponentNotFound() || this.props.user_type == 3 ? () => <h1>Not Found</h1> : AllApplications } exact />
                        <Route path="/offer/:id" component={ this.isComponentNotFound() ? () => <h1>Not Found</h1> : Offer } />
                        <Route path="/offer/" component={ this.isComponentNotFound() ? () => <h1>Not Found</h1> : Offer } />
                        <Route path="/loan/:id" component={ this.isComponentNotFound() ? () => <h1>Not Found</h1> : LoanTerms } />
                        <Route path="/success" component={ Success } />
                        <Route path="/users" component={ Users } exact />
                        <Route path="/reports" component={ this.isComponentNotFound() ? () => <h1>Not Found</h1> : Reports } exact />
                        {/*<Route path="/materials" component={ Materials } exact />*/}
                        <Route path="/notifications" component={ Notifications } exact />
                        <Route path="/rejection" component={ () => <NegativeAnswer status="rejection" text="Հայտը մերժվեց" /> } exact />
                        <Route path="/refused" component={ () => <NegativeAnswer status="refused" text="Հաճախորդը հրաժարվեց" /> } exact />
                        <Route path="/senttobank" component={ () => <NegativeAnswer status="sendToBank" text="Բանկ ուղարկված" /> } exact />
                        <Route path="/settings" component={ Settings } exact />
                        <Route path="/applicationstatus" component={ NewApplicationStatus } exact />
                        <Route path="/materials" component={ NewMaterials } exact />
                        <Route path="/calculator" component={ Calculator } exact />
                        <Route path="/video-tutorials" component={ VideoTutorials } exact />
                        <Route path="*" component={() => <h1>Not Found</h1>} />
                      </Switch>

                    </Content>
                  </div>

                  <HelpersUtil/>
              </>
          ):(
              <Redirect to="/signin"/>
          )
      )
  }
}

function mapStateToProps(state) {
  return {
    password_change_requirement: state.initReducer.password_change_requirement,
    isCalledNewRequestPermission: state.initReducer.isCalledNewRequestPermission,
    user_type: state.initReducer.user_type,
    notifications_list: state.notificationsReducer.notifications_list
  }
}

export default connect(mapStateToProps)(PrivateRoutes);
