import React, { Component } from 'react';
import moment from 'moment';
import './TopSection.scss';
import Title from "../../components/Title/Title";
import Person from "../../components/Person/Person";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import UserData from "../../components/UserData/UserData";
import {connect} from "react-redux";
import Icon from "../../components/Icon/Icon";
import PersonAvatar from "../../components/PersonAvatar/PersonAvatar";
import {Button, message, Modal} from 'antd';
import { Link } from "react-router-dom";
import {editUserInfo} from "../../reducers/applicationReducer"
import {restoreCancelRequest} from "../../reducers/applicationReducer";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";
import CancelRequest from "../../screens/CancelRequest/CancelRequest";

class TopSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
        };
    }

    showModal = () => {
        this.setState({
            show_modal: true
        });
    };

    closeModal = () => {
        this.setState({
            show_modal: false
        });
    }

    handleModalEditClick = () => {
        this.setState({
            show_modal: false
        });
        this.props.dispatch(editUserInfo());
    }

    isTopSectionHidden = () => {
      // this.props.acba_status === 5 || ... returns -> false, for showing we need the inverse value
      return !(this.props.acba_status === 5 || this.props.acba_status === 6 || this.props.acba_status === 7 || this.props.acba_status === 10 || this.props.acba_status === 99 || this.props.acba_status === 100);
    }

    getTitle = () => {
      const acba_status = this.props.acba_status;

      const status_list = {
        "0": "Նոր",
        "1": "Նոր",
        "2": "Ուղարկված",
        "3": "Դիտարկվող",
        "4": "Թերի",
        "5": "Դրական",
        "6": "Հաստատված",
        "7": "Տրամադրված",
        "8": "Ենթակա է Բանկ ուղարկման",
        "9": "Սահմանաչափի առաջարկ",
        "10": "Չտրամադրված",
        "99": "Հրաժարված",
        "100": "Մերժված"
      };

      const title = `${status_list[acba_status]} հայտ`;

      return title.toUpperCase();
    };

    restoreCancelRequest = (requestID) => {
      this.props.dispatch(restoreCancelRequest(requestID));
    };

    getStatusTextAndColor = () => {
      const {acba_status} = this.props;
      const {refuseCause, rejectionReason} = this.props.request_info;

      switch (acba_status) {
        case 100:
          return {
            text: rejectionReason,
            color: '#D60000',
          };
        case 99:
          return {
            text: refuseCause,
            color: '#840000'
          };
      }
    };

    isRegistrationDateAfterYesterday = () => {
      const userRefuseDate = moment(this.props.request_info.registrationDate);
      const yesterdayDate = moment().add(-1, 'days');

      return userRefuseDate.isAfter(yesterdayDate); // if true -> 24 jami mej
    };

    componentDidUpdate(prevProps, prevState) {
      if (this.props.error_message.type === "RestoreCancelRequest" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
        message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      }
    }

    render() {
      let customerName = this.props.request_info.customerDetails.lastName + " " + this.props.request_info.customerDetails.name + " " + this.props.request_info.customerDetails.fatherName;
      const {type, loading_state} = this.props.loading;

      return (
        <>
        <div className="row">
          <div className="column small-12">
            <div className="flex-container row-wrap align-justify align-middle" style={{alignItems: "baseline"}}>
              <div className="flex-container" style={{flexDirection: "column"}} >
                <Title text={this.getTitle()} size="md" weight="black" />

                {
                  this.props.request_info.requestID !== 0 && this.props.request_info.requestID !== "" ?
                    <div style={{marginTop: 20}} >
                      <Title text={`համար։ ${this.props.request_info.requestID}`} size="sm" weight="black" />
                    </div>
                    : null
                }
              </div>

              <div className="flex-container" style={{flexDirection: "column", alignItems: "flex-end"}}>
                {
                  this.props.request_status === 'confirmed' || this.props.acba_status > 0
                    ?
                    <Button type="link" ghost onClick={this.showModal}>
                      <Person
                        name={customerName}
                        avatar={this.props.request_info.customerDetails.customerPhoto ? 'data:image/jpeg;base64,' + this.props.request_info.customerDetails.customerPhoto : require("../../assets/img/no-user.gif")}
                      />
                    </Button>
                    : null
                }
                {
                  (this.props.acba_status != 0 && this.props.acba_status != 7) ?
                    <div className="cancel-request-button-wrapper" style={{marginTop: 30}} >
                      <CancelRequest />
                    </div>
                  : null
                }
              </div>
            </div>

            {
              this.getStatusTextAndColor() &&
                <div style={{marginTop: 20}}>
                  <Title
                    className='capitalize-first-letter'
                    style={{color: this.getStatusTextAndColor().color}}
                    text={this.getStatusTextAndColor().text}
                    size="sm"
                    weight="black"
                  />
                  {
                    this.props.acba_status === 99 && this.isRegistrationDateAfterYesterday() ?
                      <Button
                        style={{marginTop: 20}}
                        onClick={() => this.restoreCancelRequest(this.props.request_info.requestID)}
                        loading={loading_state && type === 'restore_cancel_request_loading'}
                      >
                        Վերականգնել
                      </Button>
                      : null
                  }
                </div>
            }

            {
              this.props.step === 1
              ?
                (
                  <Tabs className="mb_40">
                    <Tab key="1" id="1" text="Հաճախորդի տվյալներ" active={true} />
                    {
                      this.props.acba_status != 100 &&
                        <>
                          <Link to={`/offer/${this.props.request_info.requestID}`} >
                            {/*we check acba_status === 9 to show offer top section*/}
                            <Tab key="2" id="2" text="Բանկի առաջարկ / Ապրանքներ" hidden={this.isTopSectionHidden() && !(this.props.acba_status === 9)} />
                          </Link>
                          <Link to={`/loan/${this.props.request_info.requestID}`} >
                            <Tab key="3" id="3" text="Վարկի պայմաններ" hidden={this.isTopSectionHidden()} />
                          </Link>
                        </>
                    }
                  </Tabs>
                ) :
                this.props.step === 2 ?
                (
                  <Tabs className="mb_40">
                    <Link to={`/application/${this.props.request_info.requestID}`} >
                      <Tab key="1" id="1" text="Հաճախորդի տվյալներ" hidden={false} />
                    </Link>
                    <Tab key="2" id="2" text="Բանկի առաջարկ / Ապրանքներ" active={true} />
                    <Link to={`/loan/${this.props.request_info.requestID}`} >
                      <Tab key="3" id="3" text="Վարկի պայմաններ" hidden={this.isTopSectionHidden()} />
                    </Link>
                  </Tabs>
                ) :
                  this.props.step === 3 ?
                    (
                      <Tabs className="mb_40">
                        <Link to={`/application/${this.props.request_info.requestID}`} >
                          <Tab key="1" id="1" text="Հաճախորդի տվյալներ" hidden={false} />
                        </Link>
                        <Link to={`/offer/${this.props.request_info.requestID}`} >
                          <Tab key="2" id="2" text="Բանկի առաջարկ / Ապրանքներ" hidden={false} />
                        </Link>
                        <Tab key="3" id="3" text="Վարկի պայմաններ" active={true} />
                      </Tabs>
                    ) : null
            }
          </div>
        </div>
        <Modal
            className="user_info_modal"
            visible={this.state.show_modal}
            title={
                <>
                <Title text={customerName} size="md" weight="black" />
                <Button
                    disabled={(this.props.request_status === 'confirmed' || this.props.request_status === 'saved' || this.props.request_status === 'calc_analyse_in_bank'
                        || this.props.acba_status === 1 || this.props.acba_status === 4 ||
                        this.props.acba_status === 8 ||  this.props.acba_status === 9) ? false : true}
                    type="link" className="link_btn"
                    onClick={this.handleModalEditClick}
                >
                    <Icon type="pen" size={13} />
                    ԽՄԲԱԳՐԵԼ ՏՎՅԱԼՆԵՐԸ
                </Button>
                </>
            }
            onOk={this.closeModal}
            onCancel={this.closeModal}
            style={{ top: 20 }}
            footer={[
                <Button key="back" className="base_btn base_btn-red" onClick={this.closeModal}>
                    Փակել
                </Button>,
            ]}
        >
            <UserData user_info={this.props.request_info.customerDetails}/>
        </Modal>
        </>
      )
    }
}

function mapStateToProps(state) {
  return {
    error_message: state.applicationReducer.error_message,
    loading: state.applicationReducer.loading,
    request_info: state.applicationReducer.customer_info_for_save.request,
    request_status: state.applicationReducer.request_status,
    acba_status: state.applicationReducer.acba_status,
    user_type: state.initReducer.user_type,
  };
}
export default connect(mapStateToProps)(TopSection);
