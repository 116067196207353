import React from "react";
import { connect } from "react-redux";
import {Form, Select, Table, Modal, Button, Input, Spin, message} from "antd";
import {
  clearShopsList,
  getLoanUsersList,
  getPartnersList,
  getShopByPartnerId,
  userRequestActions
} from "../../reducers/usersReducer";
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import moment from "moment";
import {userInputLanguageValidation} from "../../utils/userInputLanguageValidation";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class TableContainer extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      editing_user_data: {},
      isFullNameDisabled: true,
      isEmailDisabled: true,
      isMarketDisabled: true,
      isPartnerShown: false,
      firstNameMaxLength: null,
      actionType: 0
    }

    this.columns = [
      {
        title: 'Անուն Ազգանուն',
        dataIndex: 'fullname',
        render: text => <b style={{fontSize: 18}}>{text}</b>,
        sorter: (a,b) => this.compareByAlphabet(a.fullname, b.fullname),
      },
      {
        title: 'Մուտքանուն',
        dataIndex: 'login',
        sorter: (a,b) => this.compareByAlphabet(a.login, b.login),
      },
      {
        title: 'Հասանելիություն',
        dataIndex: 'access',
        sorter: (a,b) => this.compareByAlphabet(a.access, b.access),
      },
      {
        title: 'Կարգավիճակ',
        dataIndex: 'status',
        sorter: (a,b) => this.compareByAlphabet(a.status, b.status),
      },
      {
        title: 'Բլոկավորված',
        dataIndex: 'isBlocked',
      },
      /*{
        title: 'Նախ. գաղտնաբառ',
        dataIndex: 'password',
      },*/
      {
        title: '',
        dataIndex: 'edit',
        render: editing_user_data => (
          <button
            type="button"
            className="cust_btn color-green"
            onClick={() => this.editUserInfo(editing_user_data)}
          >
            Խմբագրել
          </button>
        )
      }
    ];
    // if user is not admin, there is no need to show access column
    // access column index might be changed in a future, that's why we delete that element with index
    if (this.props.user_type != 4) {
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i]["dataIndex"] === "access") {
          this.columns.splice(i,1);
          break;
        }
      }
    }
  }

  compareByAlphabet = (a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  handlePageOnChange = (page_number) => {
    this.props.dispatch(getLoanUsersList(page_number, {}));
  }

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      isFullNameDisabled: true,
      isEmailDisabled: true,
      isMarketDisabled: true,
      isPartnerShown: false,
    });
    if (this.props.user_type == 4) {
      this.props.dispatch(clearShopsList());
    }
    this.props.form.resetFields();
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.firstName = values.firstName !== undefined && values.firstName != null ? values.firstName : "";
        values.lastName = values.lastName !== undefined && values.lastName != null ? values.lastName : "";
        values.login = values.login !== undefined && values.login != null ? values.login : "";
        values.email = values.email !== undefined && values.email != null ? values.email : "";
        values.marketID = values.marketID !== undefined && values.marketID != null ? values.marketID : 0;

        if (values.action_type == 1 || values.action_type == 2) {
          Modal.confirm({
            title: 'Համոզվա՞ծ եք, որ անհրաժեշտ է փոփոխել օգտատիրոջ կարգավիճակը',
            okText: 'Այո',
            cancelText: 'Ոչ',
            onOk: () => {
              this.props.dispatch(userRequestActions(values));
            },
          });
        } else {
          this.props.dispatch(userRequestActions(values));
        }
        if (this.props.user_type == 4) {
          this.props.dispatch(clearShopsList());
        }
        this.setState({actionType: values.action_type})
      }
    })
  }

  handleUserActionTypeOnChange = (e) => {
    const {user_type} = this.props;

    // check in which action type we need to disable some inputs
    const isFullNameDisabled = e == 1 || e == 2 || e == 3 || e == 5 || e == 6 || e == 8;
    const isEmailDisabled = e == 1 || e == 2 || e == 3 || e == 5 || e == 6 || e == 8;
    const isPasswordDisabled = e == 1 || e == 2 || e == 3 || e == 4 || e == 6 || e == 8;
    const isMarketDisabled = e == 1 || e == 2 || e == 4 || e == 5 || e == 6 || e == 8;
    const isPartnerShown = user_type == 4 && e == 3; // check if user is admin and need to change marketID we need to show partnerID input

    this.props.form.resetFields();
    this.setState({
      isFullNameDisabled,
      isEmailDisabled,
      isPasswordDisabled,
      isMarketDisabled,
      isPartnerShown
    })
  }

  partnersListOnSearch = (search_text) => {
    if (search_text) {
      this.props.dispatch(getPartnersList(search_text));
    }
  }

  partnersListOnChange = (partner_id) => {
    this.props.form.resetFields("marketID");
    this.props.dispatch(getShopByPartnerId(partner_id));
  }

  editUserInfo = (editing_user_data) => {
    if (this.props.user_type == 4) {
      this.props.dispatch(getShopByPartnerId(editing_user_data.contractID));
    }
    this.setState({
      modalVisible: true,
      editing_user_data
    })
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user_edited !== this.props.user_edited && this.props.user_edited) {
      if (this.state.actionType != 5) {
        message.success("Օգտատիրոջ տվյալները հաջողությամբ փոփոխվեցին");
      } else {
        message.success("Գաղտնաբառի վերաբերյալ տվյալներն ուղարկվել են օգտատիրոջ էլեկտրոնային հասցեին");
      }
      this.setState({
        modalVisible: false,
        editing_user_data: {},
        isFullNameDisabled: true,
        isEmailDisabled: true,
        isMarketDisabled: true,
        isPartnerShown: false,
        actionType: 0
      })
      this.props.form.resetFields();
    }

    if (this.props.error_message.type === "get_shop_by_partner_id" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
  }

  render() {
    const {loading} = this.props;
    const {getFieldDecorator} = this.props.form;
    const users_list = this.props.users_list.LoanUsers;
    const data = [];

    if (users_list !== undefined) {
      users_list.map((element) => {
        data.push({
          "fullname": `${element.firstName} ${element.lastName}`,
          "login": element.login,
          "access": element.permission,
          "status": element.quality ? "Գործող" : "Ապակտիվացված",
          "isBlocked": element.isBlocked ? "Այո" : "Ոչ",
          // "password": element.password != null ? element.password : "-",
          "edit": element,
        })
      })
    }

    //Total = mi eji objectneri qanak * te qani ej ka @ndhanrapes
    const paginationParams = {
      pageSize: data.length,
      total: data.length * this.props.users_list.PageCount,
      onChange: this.handlePageOnChange,
      hideOnSinglePage: true,
      current: this.props.users_list_filter.pageNumber,
    };

    return (
      <>
        {/*<Edit modal_visible={this.state.modalVisible} user_info={this.state.editing_user_data} />*/}
        <Modal
          className="base_modal"
          title={<Title text="Օգտատիրոջ տվյալների խմբագրում" size="md" weight="black" />}
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
          footer={[
            <Button key="back" className="base_btn base_btn-red" onClick={this.handleCancel}>
              Չեղարկել
            </Button>,
            <Button
              loading={this.props.loading.type === "user_request_actions_load" && this.props.loading.loading_state}
              key="submit"
              className="base_btn base_btn-green"
              type="primary"
              onClick={this.handleOk}
            >
              Պահպանել
            </Button>,
          ]}
        >
          <Spin spinning={loading.type === "get_shop_by_partner_id" && loading.loading_state} >
            <div className="panel_inner">
              <div className="row">
                <Form
                  onSubmit={this.onSubmit}
                  className="small-12"
                >
                  <div className="flex-container" >
                    <Form.Item label="Գործողության տեսակը" className="column small-12">
                      {getFieldDecorator('action_type', {
                        initialValue: "",
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                        <Select
                          onChange={this.handleUserActionTypeOnChange}
                        >
                          {!this.state.editing_user_data.quality ?
                            <Select.Option key="1">Ակտիվացում</Select.Option>
                            :
                            <Select.Option key="2">Ապաակտիվացում</Select.Option>
                          }
                          <Select.Option key="3">Տեղափոխել այլ խանութ</Select.Option>
                          <Select.Option key="4">Օգտագործողի տվյալների թարմացում</Select.Option>
                          <Select.Option key="5">Գաղտնաբառի վերականգնում</Select.Option>
                          {/*<Select.Option key="6">Գաղտնաբառի փոփոխում</Select.Option>*/}
                          {
                            this.state.editing_user_data.isBlocked
                              ? (
                                <Select.Option key="8">Ապաբլոկավորել</Select.Option>
                              )
                              : null
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                  <div style={{justifyContent: "space-between"}} className="flex-container">
                    <Form.Item label="Անուն" className="column small-6">
                      {getFieldDecorator('firstName', {
                        initialValue: this.state.editing_user_data.firstName,
                        rules: [
                          {required: !this.state.isFullNameDisabled, message: 'դաշտը լրացված չէ'},
                          {
                            validator: (rule, value) => (userInputLanguageValidation(value)),
                            message: `Խնդրում ենք գրել հայատառ`,
                          }
                        ],
                      })(
                        <Input
                          type="text"
                          disabled={this.state.isFullNameDisabled}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Ազգանուն" className="column small-6">
                      {getFieldDecorator('lastName', {
                        initialValue: this.state.editing_user_data.lastName,
                        rules: [
                          {required: !this.state.isFullNameDisabled, message: 'դաշտը լրացված չէ'},
                          {
                            validator: (rule, value) => (userInputLanguageValidation(value)),
                            message: `Խնդրում ենք գրել հայատառ`,
                          }
                        ],
                      })(
                        <Input
                          type="text"
                          disabled={this.state.isFullNameDisabled}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div style={{justifyContent: "space-between"}} className="flex-container">
                    <Form.Item label="Մուտքանուն" className="column small-6">
                      {getFieldDecorator('login', {
                        initialValue: this.state.editing_user_data.login,
                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                      })(
                        <Input
                          type="text"
                          name="login"
                          disabled={true}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Էլ․ հասցե" className="column small-6">
                      {getFieldDecorator('email', {
                        initialValue: this.state.editing_user_data.email,
                        rules: [{ required: !this.state.isEmailDisabled, message: 'դաշտը լրացված չէ'}],
                      })(
                        <Input
                          type="email"
                          name="email"
                          suffix={<Icon type="person" size={15} />}
                          disabled={this.state.isEmailDisabled}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div style={{justifyContent: "space-between"}} className="flex-container">
                    <Form.Item label="Խանութ" className="column small-6">
                      {getFieldDecorator('marketID', {
                        initialValue: this.state.editing_user_data.shopID ? this.state.editing_user_data.shopID.toString() : "",
                        rules: [{required: !this.state.isMarketDisabled, message: 'դաշտը լրացված չէ'}],
                      })(
                        <Select
                          disabled={this.state.isMarketDisabled}
                        >
                          {Object.keys(this.props.shops_list).map((index) => (
                            <Select.Option key={index}>{this.props.shops_list[index]}</Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </div>

                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
        <Table pagination={paginationParams} columns={this.columns} dataSource={data} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    market_id: state.initReducer.market_id,
    loading: state.usersReducer.loading,
    error_message: state.usersReducer.error_message,
    user_edited: state.usersReducer.user_edited,
    users_list: state.usersReducer.users_list,
    shops_list: state.usersReducer.shops_list,
    partners_list: state.usersReducer.partners_list,
    users_list_filter: state.usersReducer.filter,
  }
}

const TableFormContainer = Form.create()(TableContainer);

export default connect(mapStateToProps)(TableFormContainer);

