import React, { Component } from 'react';
import "./PlateCard.scss";

export default class PlateCard extends Component {
  render() {
    let classNames = [
      "plate_card",
      this.props.theme ? this.props.theme : "",
      this.props.className
    ];

    return (
      <div className={classNames.join(" ").trim()}>
        <div className={classNames[0] + "__label"}>
          { this.props.label }
        </div>
        <div className={classNames[0] + "__value"}>
          { this.props.value }
          {this.props.suffix ?
            <span className="plate_card__suffix">
              {this.props.suffix }
            </span>
            : null}
        </div>
      </div>
    )
  }
}
