import React, { Component } from 'react';
import Icon from "../../components/Icon/Icon";
import {Form, Input, Select, Button} from 'antd';
import {connect} from "react-redux";
import {guarantorIdentification} from "../../reducers/applicationReducer";

class GuarantorIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soc_cart_enabled: false,
    }
  }

  handleDocTypeChange = (e) => {
    this.setState({
      soc_cart_enabled: e == 2
    })
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.guarantors.length !== this.props.guarantors.length) {
      if (this.state.soc_cart_enabled) {
        this.props.form.resetFields(["docNumber", "socNumber"]);
      }
      else {
        this.props.form.resetFields(["docNumber"]);
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        onSubmit={
          (e) => {
            e.preventDefault();

            this.props.form.validateFields((err, values) => {
              if (!err) {
                this.props.dispatch(guarantorIdentification(values.docType, values.docNumber, values.socNumber))
              }
            })
          }
        } className="row align mb_30">
        <Form.Item label="Փաստաթղթի տեսակ" className="column small-12 large-4">
          {getFieldDecorator('docType', {
            initialValue: "1",
            rules: [{ required: false, message: '' }],
          })(
            <Select
              showSearch
              disabled={this.props.identified}
              onChange={this.handleDocTypeChange}
            >
              <Select.Option style={{paddingBottom: 25}} value="">

              </Select.Option>
              {
                this.props.dictionaries.document_types.length
                  ?
                  this.props.dictionaries.document_types.map((key)=>{
                    return (
                      <Select.Option key={key} value={key.value}>
                        {key.label}
                      </Select.Option>
                    )
                  })
                  :
                  null
              }
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Փաստաթղթի համար" className="column small-12 large-4">
          {getFieldDecorator('docNumber', {
            rules: [{ required: false, message: '' }],
          })(
            <Input
              type="text"
              name="docNumber"
              className="text-uppercase"
              autoComplete="off"
            />
          )}
        </Form.Item>

        {this.state.soc_cart_enabled &&
          <Form.Item label="Հանրային ծառայությունների համարանիշ" className="column small-12 large-4">
            {getFieldDecorator('socNumber', {
              rules: [{ required: false, message: '' }],
            })(
                <Input type="number"
                       name="socNumber"
                       autoComplete="off"
                />
            )}
          </Form.Item>
        }

        <Form.Item className="column small-12 text-right md-text-center">
          <Button
            className="btn btn-dashed btn_group"
            htmlType="submit"
            loading={this.props.loading.type === "guarantor_identification_loading" && this.props.loading.loading_state}>
            <span>ՆՈՒՅՆԱԿԱՆԱՑՈՒՄ</span>

            <span className="btn__icon">
              <Icon type="ident" size={23} />
            </span>
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.applicationReducer.dictionary,
    loading: state.applicationReducer.loading,
    guarantors: state.applicationReducer.guarantors
  };
}

const GuarantorIdentificationContainer = Form.create()(GuarantorIdentification);

export default connect(mapStateToProps)(GuarantorIdentificationContainer);