import commonUtils from "../utils/CommonUtils";
import produce from "immer";
import {unsetToken} from "./initReducer";
import history from "../history";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = "NOT_AUTHORIZED";

const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";
const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
const MARK_AS_READ_START = "MARK_AS_READ_START";
const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";
const MARK_AS_READ_FAIL = "MARK_AS_READ_FAIL";

//=================== ACTION CREATORS ======================
export function notAuthorized() {
  return {
    type: NOT_AUTHORIZED
  }
}

export function getNotificationsStart() {
  return {
    type: GET_NOTIFICATIONS_START,
  }
}

export function getNotificationsSuccess(payload) {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload
  }
}

export function getNotificationsFail(payload) {
  return {
    type: GET_NOTIFICATIONS_FAIL,
    payload,
  }
}

export function markAsReadStart() {
  return {
    type: MARK_AS_READ_START,
  }
}
export function markAsReadSuccess(payload) {
  return {
    type: MARK_AS_READ_SUCCESS,
    payload
  }
}
export function markAsReadFail(payload) {
  return {
    type: MARK_AS_READ_FAIL,
    payload
  }
}

//=================== thunks ======================
export function getNotifications() {
  return (dispatch, getState) => {

    commonUtils.apiCall("post", "Notification/GetUserNotifications", {})
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getNotificationsSuccess({notifications_list: response.data.result}));
        }
      })
      .catch((error) => {

      })
  }
}

export function markAsRead(requestID) {
  return (dispatch, getState) => {
    dispatch(markAsReadStart());

    commonUtils.apiCall("post", "Notification/MarNotificationReaded", {requestID})
      .then((response) => {
        if (response.data.resultCode === 1) {
          commonUtils.apiCall("post", "Notification/GetUserNotifications", {})
            .then((notificationResponse) => {
              if (notificationResponse.data.resultCode === 1) {
                dispatch(markAsReadSuccess({notifications_list: notificationResponse.data.result}));
              }
            })
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(markAsReadFail(response.data.result[0].validationMessage));
        }
      })
      .catch((error) => {

      })
  }
}

//=================== State ======================
const initial_state = {
  loading: {
    type: "",
    loading_state: false,
  },
  error_message: {
    type: '',
    message: '',
  },
  notifications_list: [],
}

export default function notificationsReducer(state = initial_state, action) {
  switch (action.type) {
    case NOT_AUTHORIZED:
      return produce(state, draft => {
        draft.loading.type = "";
        draft.loading.loading_state = false;
        draft.error_message.type = "NotAuthorized";
        draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
      })
    case GET_NOTIFICATIONS_SUCCESS:
      return produce(state, draft => {
        draft.notifications_list = action.payload.notifications_list;
      })
    case MARK_AS_READ_START:
      return produce(state, draft => {
        draft.loading.type = "MarkAsRead";
        draft.loading.loading_state = true;
      })
    case MARK_AS_READ_SUCCESS:
      return produce(state, draft => {
        draft.notifications_list = action.payload.notifications_list;
        draft.loading.loading_state = false;
      })
    case MARK_AS_READ_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
      })
    default:
      return state;
  }
}