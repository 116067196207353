import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CustomerIdentification.scss';
import Icon from "../../components/Icon/Icon";
import {
    initLoad,
  calculateRequestOffer,
  userIdentification,
  setMaxLoan,
  getItemTypes,
  changeCustomerSumNeed,
    customerPeriodOnChange,
  changeCustomerLoanAction,
  clearErrorMessage
} from "../../reducers/applicationReducer";
import {Form, Input, Button, Select, Col, Spin, message} from 'antd';
import commonUtils from "../../utils/CommonUtils";
import { Redirect } from 'react-router-dom';
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class CustomerIdentification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soc_cart_enabled: false
    };
  }

  componentDidMount() {
      // this.props.dispatch(initLoad())
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error_message.type === "PersonIdentification" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      this.props.dispatch(clearErrorMessage());
    }
    if (this.props.error_message.type === "InitApp" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      this.props.dispatch(clearErrorMessage());
    }
  }

  handleDocTypeChange = (e) => {
    this.setState({
      soc_cart_enabled: e == 2
    })
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(userIdentification(values.docType, values.docNumber, values.socNumber, values.sumNeed, values.period, values.buildType));
      }
    })
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;

    let user_identified = (this.props.request_status === 'identified') ? true : false;
    return (
        <Spin tip="Loading" spinning={this.props.loading.type === "init_app_loading" && this.props.loading.loading_state === true}>
          <div className="row">
            <div className="column small-12 medium-large-9">

              <Form
                onSubmit={this.onSubmit}
                className="row"
              >

                <Form.Item label="Փաստաթղթի տեսակ" className="column small-12 large-4">
                  {getFieldDecorator('docType', {
                    initialValue: "1",
                    rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                  })(
                    <Select disabled={user_identified} showSearch onChange={this.handleDocTypeChange}>
                      <Select.Option style={{paddingBottom: 25}} value="">

                      </Select.Option>
                      {
                        this.props.dictionaries.document_types.length
                          ?
                          this.props.dictionaries.document_types.map((key)=>{
                            return (
                              <Select.Option key={key} value={key.value}>
                                {key.label}
                              </Select.Option>
                            )
                          })
                          :
                          null
                      }
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Փաստաթղթի համար" className="column small-12 large-4">
                  {getFieldDecorator('docNumber', {
                    rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                  })(
                    <Input type="text"
                           disabled={user_identified}
                           name="docNumber"
                           className="text-uppercase"
                           autoComplete="off"
                    />
                  )}
                </Form.Item>

                {this.state.soc_cart_enabled &&
                  <Form.Item label="Հանրային ծառայությունների համարանիշ" className="column small-12 large-4">
                    {getFieldDecorator('socNumber', {
                      rules: [{ required: false, message: '' }],
                    })(
                        <Input type="number"
                               disabled={user_identified}
                               name="socNumber"
                               autoComplete="off"
                        />
                    )}
                  </Form.Item>
                }

                {
                  !user_identified ?
                    <Form.Item className="column small-12 text-right md-text-center">
                      <Button
                        className="btn btn-dashed"
                        htmlType="submit"
                        loading={this.props.loading.type === "identification_loading" && this.props.loading.loading_state}>
                        <span>ՆՈՒՅՆԱԿԱՆԱՑՈՒՄ</span>

                        <span className="btn__icon">
                          <Icon type="ident" size={22} />
                        </span>
                      </Button>
                    </Form.Item>
                    : null
                }
              </Form>
            </div>
          </div>
        </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.applicationReducer.dictionary,
    period: state.applicationReducer.period,
    loading: state.applicationReducer.loading,
    error_message: state.applicationReducer.error_message,
    request_status: state.applicationReducer.request_status,
  };
}

const CustomerIdentificationContainer = Form.create()(CustomerIdentification);

export default connect(mapStateToProps)(CustomerIdentificationContainer);