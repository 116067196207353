import React from "react";
import {connect} from "react-redux";
import {Button, Table, Tooltip, Typography} from "antd";
import Icon from "../../components/Icon/Icon";
import moment from "moment";
import {getRequestsList} from "../../reducers/requestReducer";

class TableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} >Հայտի համար</Typography.Text>,
        dataIndex: 'appNumber',
        render: element => (
          <a 
            target="_blank" 
            href={`/application/${element.text}`}
            className="base-text underlined"
            style={{fontSize: 14, color: element.color}}
          >
            {element.text}
          </a>
        ),
        sorter: (a, b) => parseInt(a.appNumber.text) - parseInt(b.appNumber.text)
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} >Անուն Ազգանուն</Typography.Text>,
        dataIndex: 'fullname',
        render: text => (<><b style={{fontSize: 13.5, whiteSpace: 'nowrap'}}>{text[0]}</b><div style={{color: '#565656', fontSize: 14}}>{text[1]}</div></>),
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} >Կարգավիճակ</Typography.Text>,
        dataIndex: 'status',
        render: payload => (
          <b className="base-text block_elem" style={{color: payload.color}}>{payload.text}</b>
        )
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Նախատեսվող գումար' >Նախատեսվող ֏</Tooltip></Typography.Text> ,
        dataIndex: 'projected',
        render: text => <b>{text} ֏</b>,
        sorter: (a, b) => parseInt(a.projected) - parseInt(b.projected),
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Հաստատված գումար'>Հաստատված ֏</Tooltip></Typography.Text>,
        dataIndex: 'confirmed',
        render: text => <b>{text} ֏</b>,
        sorter: (a, b) => parseInt(a.confirmed) - parseInt(b.confirmed),
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Մուտքագրման ամսաթիվ' >Մուտք. ա/թ.</Tooltip></Typography.Text>,
        dataIndex: 'registrationDate',
        render: text => <><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[0]}</div><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[1]}</div></>,
        sorter: (a,b) =>  moment(a.registrationDate.date).unix() - moment(b.registrationDate.date).unix()
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Ուղարկման ամսաթիվ' >Ուղարկման ա/թ</Tooltip></Typography.Text>,
        dataIndex: 'sendDate',
        render: text => <><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[0]}</div><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[1]}</div></>,
        sorter: (a,b) =>  moment(a.sendDate.date).unix() - moment(b.sendDate.date).unix()
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Պատասխանի ամսաթիվ' >Պատասխանի ա/թ.</Tooltip></Typography.Text>,
        dataIndex: 'answerDate',
        render: text => <><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[0]}</div><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[1]}</div></>,
        sorter: (a,b) => moment(a.answerDate.date).unix() - moment(b.answerDate.date).unix()
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Օգտագործող' >Օգտ․</Tooltip></Typography.Text>,
        dataIndex: 'userName',
        render: text => <b>{text}</b>
      },
      {
        width: 100,
        title: <Typography.Text style={{fontSize: 11, color: "white"}} ><Tooltip title='Բանկի ձևակերպման վերջնաժամկետ' >Բանկի ձև․ վերջ․</Tooltip></Typography.Text>,
        dataIndex: 'bankFormulationEnd',
        render: text => <><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[0]}</div><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[1]}</div></>,
        sorter: (a,b) => moment(a.bankFormulationEnd.date).unix() - moment(b.bankFormulationEnd.date).unix(),
      },
    ];
    this.currentPageNumber = 1;
  }

  tablePageOnChange = (value) => {
    // Get current filter data
    let param = this.props.requests_list_filter;
    param.pageNumber = value;
    switch (this.props.category) {
      case "tab-all":
        param.qualityGroup = 5;
        break;
      case "tab-editing":
        param.qualityGroup = 1;
        break;
      case "tab-sent":
        param.qualityGroup = 2;
        break;
      case "tab-received":
        param.qualityGroup = 3;
        break;
      case "tab-finished":
        param.qualityGroup = 4;
        break;
    }
    this.props.dispatch(getRequestsList(param));
  }

  setTextColorByQuality = (quality) => {
     let color = "";
     switch (quality) {
       case 1:
         color = "#245D7D";
         break;
       case 2:
         color = "#00A789";
         break;
       case 3:
         color = "#00A789";
         break;
       case 4:
         color = "#E48900";
         break;
       case 5:
         color = "#00A789";
         break;
       case 6:
         color = "#00A789";
         break;
       case 7:
         color = "#00A789";
         break;
       case 8:
         color = "#696969";
         break;
       case 9:
         color = "#8CA109";
         break;
       case 10:
         color = "#323232";
         break;
       case 99:
         color = "#840000";
         break;
       case 100:
         color = "#D60000";
         break;
     }
     return color;
  }

  formatDateWithMoment = (date) => {
    return moment(date).format("DD/MM/YYYY");
  }

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    const requests_list = this.props.requests_list.RequestList;
    const data = [];

    if (requests_list !== undefined) {
      requests_list.map((element, index) => {

        const registrationDate = element.registrationDate != null ? this.formatDateWithMoment(element.registrationDate.split('T')[0]) : ""; // get Date before T character
        const registrationDateHour = element.registrationDate != null ? element.registrationDate.split('T')[1] : ""; // get Hour after T character

        const sendDate = element.sendDate != null ? this.formatDateWithMoment(element.sendDate.split('T')[0]) : "";
        const sendDateHour = element.sendDate != null ? element.sendDate.split('T')[1] : "";

        const answerDate = element.answerDate != null ? this.formatDateWithMoment(element.answerDate.split('T')[0]) : "";
        const answerDateHour = element.answerDate != null ? element.answerDate.split('T')[1] : "";

        const bankFormulationDate = element.contractDate != null ? this.formatDateWithMoment(element.contractDate.split('T')[0]) : "";
        const bankFormulationHour = element.contractDate != null ? element.contractDate.split('T')[1] : "";

        data.push({
          key: index,
          appNumber: {
            text: element.requestID,
            color: this.setTextColorByQuality(element.quality),
          },
          fullname: [`${element.fullName}`, `${element.documentNumber}`],
          status: {
            text: element.qualityDescription,
            color: this.setTextColorByQuality(element.quality),
          },
          projected: this.formatNumber(element.startCapital),
          confirmed: this.formatNumber(element.approvedAmount),
          registrationDate: {
            date: element.registrationDate,
            forRender: [registrationDate, registrationDateHour]
          },
          sendDate: {
            date: element.sendDate,
            forRender: [sendDate, sendDateHour]
          },
          answerDate: {
            date: element.answerDate,
            forRender: [answerDate, answerDateHour]
          },
          userName: element.userName,
          bankFormulationEnd: {
            date: element.contractDate,
            forRender: [bankFormulationDate, bankFormulationHour],
          },
        })
      });
    }

    //Total = mi eji objectneri qanak * te qani ej ka @ndhanrapes
    const paginationParams = {
      hideOnSinglePage: true,
      pageSize: data.length,
      total: data.length * this.props.requests_list.PageCount,
      onChange: this.tablePageOnChange,
      current: this.props.requests_list_filter.pageNumber,
    };
    return (
        <Table
            columns={this.columns}
            dataSource={data}
            // onChange={this.tablePageOnChange}
            className="mt_10"
            pagination={paginationParams}
        />
    )
  }
}

function mapStateTopProps(state) {
  return {
    requests_list: state.requestReducer.requests_list,
    requests_list_filter: state.requestReducer.filter,
  }
}

export default connect(mapStateTopProps)(TableContainer);