import React, { Component } from 'react';
import IdentificationResults from "../IdentificationResults/IdentificationResults";
import CustomerIdentification from "../CustomerIdentification/CustomerIdentification";
import TopSection from "../../layout/TopSection/TopSection";
import {connect} from "react-redux";
import {message, Spin} from "antd";
import {clearErrorMessage, initLoad} from "../../reducers/applicationReducer";
import RequestTerms from "../RequestTerms/RequestTerms";
import {Redirect} from "react-router-dom";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identified: false,
      step: 1
    };
  }

  componentDidMount() {
    let request_id = this.props.match.params.id === undefined ? '' : this.props.match.params.id;
    this.props.dispatch(initLoad(request_id));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error_message.type === "init_app_loading" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      this.props.dispatch(clearErrorMessage());
    }
  }

  print_component(){
    let rendered = '';
    if (this.props.request_status === 'new') {
      rendered = <CustomerIdentification />;
    }
    else if(this.props.request_status === 'identified' || this.props.request_status === 'edit_user_info') {
      rendered = <IdentificationResults />;
    }
    /*else if(this.props.acba_status === 2) {
      rendered = <Redirect to="/senttobank" />;
    }*/
    else if (this.props.acba_status === 8) {
      rendered = <RequestTerms />;
    }
    /*else if(this.props.acba_status === 9) {
      rendered = <Redirect to={`/offer/${this.props.requestID}`} />;
    }*/
    /*else if(this.props.acba_status === 100) {
      rendered = <Redirect to="/rejection" />;
    }*/
    else {
      rendered = <RequestTerms />;
    }
    return rendered
  }

  render() {
    let render_component = this.print_component();
    return (
      <>
        <TopSection step={this.state.step} />

        <Spin tip="Calculating..." spinning={this.props.loading.type === "calculating_loading" && this.props.loading.loading_state}>
          <div className="application">
            {render_component}
          </div>
        </Spin>

      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.applicationReducer.loading,
    request_status: state.applicationReducer.request_status,
    acba_status: state.applicationReducer.acba_status,
    requestID: state.applicationReducer.customer_info_for_save.request.requestID,
    error_message: state.applicationReducer.error_message,
  };
}

export default connect(mapStateToProps)(Application);