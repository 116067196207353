import axios from 'axios';

const axiosInstance = axios.create({ withCredentials: true });

export default class commonUtils{

    // static apiUrl = 'http://192.168.0.3';
    // static apiUrl = 'http://37.252.70.184'; // local
    // static apiUrl = 'https://acba.zoom.am'; // for dev_build
    // static apiUrl = 'https://aparik-test.acba.am'; // for acba_build
    // static apiUrl = 'https://aparik1.acba.am'; // for acba_build
    static apiUrl = 'https://aparik.acba.am'; // for acba_build

    static apiCall(method, callName, params={},token='') {

        let apiUrl = this.apiUrl + '/' + callName;
        let response;
        let options = {};

        //'autorizacionToken':'0gbsxFuWzF0Q49HQ2rng'
        switch (method) {
            case 'get':
                options = {
                  headers: {'Content-Type': 'application/x-www-form-urlencoded'/*,'userSessionToken':token*/},
                    params
                };
                response = axiosInstance.get(apiUrl,options);
                break;
            case 'post':
                options = {
                  headers: {'Content-Type': 'application/json'/*,'userSessionToken':token*/}
                };

                let data = {};
               if( params instanceof FormData){
                    data = params;
               }else{
                    data = new FormData();
                   for (let key in params) {
                       if(params[key] !== null && (typeof(params[key]) === 'object' || Array.isArray(params[key]))){
                           for (var i = 0; i < params[key].length; i++) {
                               data.append(key+'[]', (params[key][i]));
                           }
                       }else{
                           data.append(key, params[key]);
                       }
                   }
               }

                response = axiosInstance.post(apiUrl, params, options);

                break;
            default:
        }
        return response;
    }

    static getIpAddress() {
      return axios.get('https://api.ipify.org/?format=json');
    }
}
