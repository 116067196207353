import {unsetToken} from "./initReducer";
import commonUtils from "../utils/CommonUtils";
import produce from 'immer';
import history from "../history";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
const GET_NEWS_START = 'GET_NEWS_START';
const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

//=================== ACTION CREATORS ======================
const notAuthorized = () => ({
  type: NOT_AUTHORIZED
});

const getNewsStart = () => ({
  type: GET_NEWS_START
});

const getNewsSuccess = (payload) => ({
  type: GET_NEWS_SUCCESS,
  payload
});

const getNewsFail = (payload) => ({
  type: GET_NEWS_FAIL,
  payload
});

//=================== thunks ======================
export function getNews() {
  return (dispatch, getState) => {
    dispatch(getNewsStart());

    commonUtils.apiCall('get', 'Info/GetNews', {})
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            dispatch(getNewsSuccess({news: response.data.result}));
            break;
          case 3:
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
            break;
          case 4:
            dispatch(getNewsFail(response.data[0].validationMessage));
            break;
          default:
        }
      })
      .catch((error) => {
        dispatch(getNewsFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

//=================== State ======================
const initialState = {
  loading: {
    type: "",
    loading_state: false,
  },
  error_message: {
    type: '',
    message: '',
  },
  news: []
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case NOT_AUTHORIZED:
      return produce(state, draft => {
        draft.loading.type = "";
        draft.loading.loading_state = false;
        draft.error_message.type = "NotAuthorized";
        draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
      });
    case GET_NEWS_START:
      return produce(state, draft => {
        draft.loading.type = "GetNews";
        draft.loading.loading_state = true;
      });
    case GET_NEWS_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.news = action.payload.news;
      });
    case GET_NEWS_FAIL:
      return produce(state, draft => {
        draft.error_message.type = "GetNews";
        draft.error_message.message = action.payload;
        draft.loading.loading_state = false;
      });
    default:
      return state;
  }
}