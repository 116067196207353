import React, {Component} from 'react';
import {connect} from 'react-redux';
// import './CustomerIdentification.scss';
import Icon from "../../components/Icon/Icon";
import {
  getWorkReferenceTypes,
  getClearSalary,
  getEmployerTypes,
  fillUserIncomes,
  resetClearSalary,
  showIncomesModal
} from "../../reducers/applicationReducer";
import {Form, Input, Button, Select, Checkbox, Modal, message} from 'antd';

import Title from "../../components/Title/Title";
import Collapsible from "react-collapsible";
import IncomeCard from "../../components/IncomeCard/IncomeCard";
import NumericInput from "../../components/NumericInput/NumericInput";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class UserIncomes extends Component {
  constructor(props) {
    super(props);
    const currentIncome = this.props.income_index !== undefined ? this.props.user_incomes[this.props.income_index] : {};
    const isCurrentIncomeEmpty = Object.entries(currentIncome).length === 0;

    this.isDirtySalaryCalled = false;
    this.isEmployerTypeByGroupCalled = false;
    this.state = {
      workReferenceTypes: [],
      workEmployerTypesDesc: [],
      workEmployerTypesDesc_visible: !isCurrentIncomeEmpty && (currentIncome.employerGroup == 10001 || currentIncome.employerGroup == 10002),
      workEmployerType: !isCurrentIncomeEmpty ? currentIncome.employerGroup : "",
      additional_desc: !isCurrentIncomeEmpty ? (currentIncome.employeerDescription !== undefined && currentIncome.employeerDescription !== "" && currentIncome.employeerDescription != null ? true : false) : false,
      fieldsVisibility: !isCurrentIncomeEmpty ? (currentIncome.profitType == 1 || currentIncome.profitType == 2) : false,
      socNumbNotExists: !isCurrentIncomeEmpty ? currentIncome.withoutCodeOfTax : false,

      user_income: currentIncome,

      show_position: !isCurrentIncomeEmpty ? ((currentIncome.referenceType == 2) ? true : false): false,
      show_work_place: !isCurrentIncomeEmpty ? (currentIncome.profitType == 2 ? false : true) : true,
      show_modal: false,

      salary_edited: false,  //will be changed in edin mode, when user change salary

      disable_dirty_money: !isCurrentIncomeEmpty ? (currentIncome.money !== undefined && currentIncome.money === "") : false,
      disable_clear_money: !isCurrentIncomeEmpty ? (currentIncome.clearMoney !== undefined && currentIncome.clearMoney === "") : false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clear_money !== this.props.clear_money) {
      /*this.props.form.setFieldsValue({
        "clearMoney": this.props.clear_money,
      });*/
      this.setState({salary_edited:true});
    }

    if (this.state.show_modal && this.props.userIncomeChanged) {
      this.setState({
        show_modal: false
      })
    }

    if (this.props.error_message.type === "FillUserIncomes" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }

    if (this.state.show_modal && !this.isEmployerTypeByGroupCalled) {
      const currentIncome = this.state.user_income;
      const isCurrentIncomeEmpty = Object.entries(currentIncome).length === 0;
      if (!isCurrentIncomeEmpty && (currentIncome.employerGroup == 10001 || currentIncome.employerGroup == 10002)) {
        this.isEmployerTypeByGroupCalled = true;
        this.props.dispatch(getEmployerTypes(currentIncome.employerGroup));
      }
    }
  }

  salaryIncludedTaxBlur = (event) => {
    if (!this.isDirtySalaryCalled) {
      this.props.dispatch(getClearSalary(event.target.value, this.props.dateOfBirth));
      this.isDirtySalaryCalled = true;
    }
  };

  handleOnEnterKeyDown = (event) => {
    if (event.key === 'Enter' && !this.isDirtySalaryCalled) {
      this.props.dispatch(getClearSalary(event.target.value, this.props.dateOfBirth));
      this.isDirtySalaryCalled = true;
    }
  };

  handleWorkEmployerTypeChange = (item) => {
    const profitType = this.props.form.getFieldValue('profitType');

    let work_employer_types_desc_visible, additional_desc;
    for (let i = 0; i < this.props.dictionaries.work_employer_type.length; i++) {
      if (this.props.dictionaries.work_employer_type[i]['value'] == item) {
          //check if we need to dispatch and load EmpoyerTypes dictionary
          if (this.props.dictionaries.work_employer_type[i]['includeSubGroup']) {
              this.props.dispatch(getEmployerTypes(item.toString()));
              work_employer_types_desc_visible = true;
          }else{
              work_employer_types_desc_visible = false;
          }

          //if we need to show additional description field
          if(this.props.dictionaries.work_employer_type[i]['requireEmployerDescription']){
              additional_desc = true;
          }else{
              additional_desc = false;
          }

          break;
      }
    }

    /*this.props.form.validateFields(() => {

    });*/
    this.props.form.setFieldsValue({
      employerGroup: "",
    });

    this.setState({
      workEmployerTypesDesc_visible: work_employer_types_desc_visible,
      additional_desc,
      workEmployerType:item,
      socNumbNotExists: item == 298 && profitType == 1,
    })
  };

  handleWorkEmployerTypeDesc = (item) => {
    let selected_obj = this.props.dictionaries.employer_type_by_group.find(element => element.id === item);
    if (selected_obj !== undefined) {
      if(selected_obj.requireEmployerDescription){
        this.setState({
          additional_desc: true,
        })
      }else{
        this.setState({
          additional_desc: false,
        })
      }
    }
  };

  handleIncomeModalCancel = () => {
    this.props.dispatch(resetClearSalary());
    const currentIncome = this.props.income_index !== undefined ? this.props.user_incomes[this.props.income_index] : {};
    const isCurrentIncomeEmpty = Object.entries(currentIncome).length === 0;
    this.setState({
      workEmployerTypesDesc_visible: !isCurrentIncomeEmpty && (currentIncome.employerGroup == 10001 || currentIncome.employerGroup == 10002),
      workEmployerType: !isCurrentIncomeEmpty ? currentIncome.employerGroup : "",
      additional_desc: !isCurrentIncomeEmpty ? (currentIncome.employeerDescription !== undefined && currentIncome.employeerDescription !== "" ? true : false) : false,
      fieldsVisibility: !isCurrentIncomeEmpty ? (currentIncome.profitType == 1 || currentIncome.profitType == 2) : false,
      socNumbNotExists: !isCurrentIncomeEmpty ? currentIncome.withoutCodeOfTax : false,

      user_income: currentIncome,

      show_position: !isCurrentIncomeEmpty ? ((currentIncome.referenceType == 2) ? true : false): false,
      show_work_place: !isCurrentIncomeEmpty ? (currentIncome.profitType == 2 ? false : true) : true,
      show_modal: false,

      salary_edited: false,  //will be changed in edin mode, when user change salary

      disable_dirty_money: !isCurrentIncomeEmpty ? (currentIncome.money !== undefined && currentIncome.money === "") : false,
      disable_clear_money: !isCurrentIncomeEmpty ? (currentIncome.clearMoney !== undefined && currentIncome.clearMoney === "") : false,
    });
    this.props.form.resetFields();
  };

  handleIncomeModalOk = () => {
    this.props.form.validateFields((err, user_incomes_info) => {
      if (!err) {
        if (this.state.show_work_place === false) {
          user_incomes_info['employerGroup'] = "298";
        }

        if (user_incomes_info.workExperienceYear) {
          const value = user_incomes_info.workExperienceYear.toString();

          if (((value.includes(',') || value.includes('.')) && value.length <= 4) || ((!value.includes(',') || !value.includes('.')) && value.length <= 2)) {
            this.submitUserIncomeModal(user_incomes_info);
          }
          else {
            this.props.form.setFields({
              workExperienceYear: {
                value,
                errors: [new Error('Աշխատանքային փորձ դաշտում լրացված է սխալ տվյալ. խնդրում ենք ուղղել')]
              }
            });
          }
        }
        else {
          this.submitUserIncomeModal(user_incomes_info);
        }
      }
    });
  };

  submitUserIncomeModal = (user_incomes_info) => {
    // user_incomes_info['employerId'] = user_incomes_info.detailed_employerId !== undefined ? user_incomes_info.detailed_employerId : user_incomes_info.employerId;

    // user_incomes_info.clearMoney = this.props.clear_money;
    this.props.dispatch(fillUserIncomes(user_incomes_info, this.props.income_index));

    /*this.setState({
      show_modal: false,
    })*/
  };

  showIncomesModal = (index) => {
    if (index === null) {
        this.props.dispatch(showIncomesModal({'index': null}));
        this.props.form.resetFields();
        this.setState({
          fieldsVisibility: false,
          disable_clear_money: false,
        })
    } else {
        this.props.dispatch(showIncomesModal({'index': this.props.income_index}));
        this.props.form.setFieldsValue({
          "clearMoney": this.props.user_incomes[index].clearMoney,
        });
    }

    this.setState({
      show_modal: true
    });
  };

  handleMoneyOnChange = (value) => {
    this.isDirtySalaryCalled = false;
    /*this.props.form.setFieldsValue({
      "clearMoney": "",
    });*/
    if (this.props.clear_money !== "") {
      this.props.dispatch(resetClearSalary());
    }
    /*this.setState({
      disable_clear_money: value != null && value.length !== 0,
    })*/
  };

  handleClearMoneyOnChange = (value) => {
    const dirtyMoney = this.props.form.getFieldsValue().money;

    if (dirtyMoney == null || dirtyMoney.length === 0) {
      this.setState({
        disable_dirty_money: true,
      })
    }
    if (value == null || value.length === 0) {
      this.props.form.setFieldsValue({
        "money": "",
      });
      this.setState({
        disable_dirty_money: false,
      })
    }
  };

  handleProfitTypeOnChange = (value) => {
    this.props.dispatch(getWorkReferenceTypes(value));
    this.props.form.setFieldsValue({
      profitType: "",
      referenceType: "",
      employerGroup: "",
      employerId: "",
      employeerDescription: "",
      workExperienceYear: "",
      position: "",
      codeOfTax: "",
      money: "",
      clearMoney: "",
    });
      // this.props.form.resetFields();

    this.setState({
      user_income: {},
      fieldsVisibility: value == 1 || value == 2 ? true : false,
      disable_dirty_money: false,
      show_work_place: value == 1 ? true : false,  // ashxatavair
      workEmployerTypesDesc_visible: false,
        additional_desc: false,
    })
  };

  handleReferenceTypeOnChange = (value) => {
    const profitType = this.props.form.getFieldValue('profitType');
    const employerGroup = this.props.form.getFieldValue('employerGroup');

    this.setState({
      show_position: value == 2,
      socNumbNotExists: (value == 0 && profitType == 2) || (value == 0 && employerGroup == 298 && profitType == 1),
    })
  };

  handleTaxCodeOnChange = () => {
    this.setState({
      socNumbNotExists: !this.state.socNumbNotExists,
    });
    this.props.form.setFieldsValue({
      "codeOfTax": "",
    })
  };

  showSocNumber = () => {
    const employerGroup = this.props.form.getFieldValue("employerGroup");
    const employerId = this.props.form.getFieldValue("employerId");

    if ((this.state.workEmployerType == 298 || this.state.show_work_place === false) || (employerGroup == 10001 && employerId == 247) || (employerGroup == 10002 && employerId == 297)) {
      return true;
    }

    return false;
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const currentIncome = this.state.user_income;
    const isCurrentIncomeEmpty = Object.entries(this.state.user_income).length === 0;

    return (
      <>
        <div>
            {this.props.hasOwnProperty("income_index")?
                <button
                  type="button"
                  className="cust_btn color-green"
                  key={this.props.income_index}
                  onClick={() => this.showIncomesModal(this.props.income_index)}
                >
                  Խմբագրել
                </button>
                :
                <button
                  type="button"
                  className="cust_btn mr_10 color-green"
                  onClick={() => this.showIncomesModal(null)}
                >
                  <Icon type="plus" size={32}/>
                  <span>
                        Ավելացնել
                      </span>
                </button>
            }


            {/*{this.props.user_incomes.map((element,index) => {*/}
              {/*let work_profit_type = this.props.dictionaries.work_profit_type.find((index) => (index.value === element.profitType));*/}
              {/*let text = work_profit_type.label + '`' + element.clearMoney;*/}
              {/*return (*/}
                {/*<IncomeCard text={text} key={index} />*/}
              {/*)*/}
            {/*})}*/}
        </div>

        <Modal
          className="base_modal"
          visible={this.state.show_modal}
          title={<Title text={`${this.props.hasOwnProperty("income_index") ? "Փոփոխել" : "Ավելացնել"} ԵԿԱՄՈՒՏ`} size="md" weight="black" />}
          onOk={this.handleIncomeModalOk}
          onCancel={this.props.loading.type === "fill_user_incomes" && this.props.loading.loading_state ? null : this.handleIncomeModalCancel}
          footer={[
            <Button key="back" className="base_btn base_btn-red" onClick={this.handleIncomeModalCancel}>
              Չեղարկել
            </Button>,
            <Button
              key="submit"
              className="base_btn base_btn-green"
              type="primary"
              onClick={this.handleIncomeModalOk}
              loading={this.props.loading.type === "fill_user_incomes" && this.props.loading.loading_state}
            >
              {this.props.hasOwnProperty("income_index") ? "Փոփոխել" : "Ավելացնել"}
            </Button>,
          ]}
        >
          <div className="panel_inner">
              <div className="row">
                <Form.Item label="Եկամտի տեսակ" className="column small-12 large-6">
                  {getFieldDecorator(`profitType`, {
                    initialValue: !isCurrentIncomeEmpty ? currentIncome.profitType.toString() : "",
                    rules: [{required: true, message: "դաշտը լրացված չէ"}],
                  })(
                      <Select
                        showSearch
                        onChange={this.handleProfitTypeOnChange}
                        dropdownStyle={{ minWidth: "45%" }}
                      >
                        <Select.Option style={{paddingBottom: 25}} value="">

                        </Select.Option>
                        {
                          this.props.dictionaries.work_profit_type.length
                              ?
                              this.props.dictionaries.work_profit_type.map((key) => {
                                return (
                                    <Select.Option key={key} value={key.value}>
                                      {key.label}
                                    </Select.Option>
                                )
                              })
                              :
                              null
                         }
                      </Select>
                  )}
                </Form.Item>

                <Form.Item label="Հիմքի տեսակ" className="column small-12 large-6">
                  {getFieldDecorator('referenceType', {
                    initialValue: !isCurrentIncomeEmpty ? currentIncome.referenceType.toString() : "",
                    rules: [{required: true, message: "դաշտը լրացված չէ"}],
                  })(
                      <Select
                        showSearch
                        onChange={this.handleReferenceTypeOnChange}
                      >
                        <Select.Option style={{paddingBottom: 25}} value="">

                        </Select.Option>
                        {
                          typeof this.props.dictionaries.work_reference_type !== 'undefined' ?
                            this.props.dictionaries.work_reference_type.map((key) => {
                              return (
                                <Select.Option key={key} value={key.value}>
                                  {key.label}
                                </Select.Option>
                              )
                            }) : null
                        }
                      </Select>
                  )}
                </Form.Item>
              </div>

              {
                this.state.fieldsVisibility === true ?
                    <>
                      <div className="row flex-nowrap stretch-columns">
                        {
                        this.state.show_work_place ?
                          <>
                            <Form.Item label="Աշխատավայր" className="column">
                              {getFieldDecorator('employerGroup', {
                                initialValue: !isCurrentIncomeEmpty ? currentIncome.employerGroup : "",
                                rules: [{required: true, message: "դաշտը լրացված չէ"}],
                              })(
                                  <Select
                                      showSearch
                                      onChange={(value) => {
                                        this.handleWorkEmployerTypeChange(value);
                                      }}
                                      filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                      dropdownStyle={{ minWidth: "55%" }}
                                  >
                                    <Select.Option style={{paddingBottom: 25}} value="">

                                    </Select.Option>
                                    {
                                      this.props.dictionaries.work_employer_type.length
                                          ?
                                          this.props.dictionaries.work_employer_type.map((key) => {
                                            return (
                                                <Select.Option key={key} value={key.value}>
                                                  {key.label}
                                                </Select.Option>
                                            )
                                          })
                                          :
                                          null
                                    }
                                  </Select>
                              )}
                            </Form.Item>
                            {this.state.workEmployerTypesDesc_visible === true &&
                              <Form.Item label="Աշխատավայրի մանրամասն նկարագիր" className="column">
                              {getFieldDecorator('employerId', {
                                initialValue: !isCurrentIncomeEmpty ? currentIncome.employerId : "",
                                rules: [{required: true, message: "դաշտը լրացված չէ"}],
                              })(
                                  <Select
                                      showSearch
                                      filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                      onChange={(e) => this.handleWorkEmployerTypeDesc(e)}
                                      dropdownStyle={{ minWidth: "50%" }}
                                  >
                                    <Select.Option style={{paddingBottom: 25}} value="">

                                    </Select.Option>
                                    {
                                      typeof this.props.dictionaries.employer_type_by_group !== 'undefined' ?
                                          this.props.dictionaries.employer_type_by_group.map((key) => {
                                            return (
                                                <Select.Option key={key} value={key.id}>
                                                  {key.description}
                                                </Select.Option>
                                            )
                                          })
                                          :
                                          null
                                    }
                                  </Select>
                              )}
                            </Form.Item>
                            }
                          </>
                          :
                          null
                        }

                        {this.state.additional_desc === true || this.state.show_work_place === false ?
                          <Form.Item label="Նկարագիր" className="column">
                            {getFieldDecorator('employeerDescription', {
                              initialValue: !isCurrentIncomeEmpty ? currentIncome.employeerDescription : "",
                              rules: [{required: true, message: "դաշտը լրացված չէ"}],
                            })(
                              <Input
                                type="text"
                                name={`employeerDescription`}
                                id={`employeerDescription`}
                                autoComplete="off"
                              />
                            )}
                          </Form.Item>
                          :
                          null
                        }
                      </div>

                      <div className="row flex-nowrap stretch-columns">
                        <Form.Item label="Աշխատանքային փորձ" className="column">
                          {getFieldDecorator('workExperienceYear', {
                            initialValue: !isCurrentIncomeEmpty ? currentIncome.workExperienceYear : "",
                            rules: [
                              {
                                  required: true,
                                  message: "դաշտը լրացված չէ"
                              }
                            ],
                          })(
                              <Input
                                type="text"
                                suffix="տարի"
                                autoComplete="off"
                              />
                          )}
                        </Form.Item>

                        {this.state.show_position &&
                        <Form.Item label="Պաշտոն" className="column">
                          {getFieldDecorator('position', {
                            initialValue: !isCurrentIncomeEmpty && currentIncome.position ? currentIncome.position.toString() : "",
                            rules: [{required: true, message: "դաշտը լրացված չէ"}],
                          })(
                              <Select showSearch>
                                <Select.Option style={{paddingBottom: 25}} value="">

                                </Select.Option>
                                {
                                  this.props.dictionaries.work_position_type.length
                                    ?
                                      this.props.dictionaries.work_position_type.map((key) => {
                                        return (
                                            <Select.Option key={key} value={key.value}>
                                              {key.label}
                                            </Select.Option>
                                        )
                                      })
                                    :
                                    null
                                }
                              </Select>
                          )}
                        </Form.Item>
                        }
                        {/* SHOW ՀՎՀՀ realetd fields IF Եկամտի տեսակ == Ձեռնարկատիրական OR Աշխատավայր == Այլ*/}
                        {this.showSocNumber() ?
                            <div className="column">

                              <Form.Item label="ՀՎՀՀ" className="m_0">
                                {getFieldDecorator('codeOfTax', {
                                  initialValue: !isCurrentIncomeEmpty ? currentIncome.codeOfTax : "",
                                  rules: [
                                      {required: !this.state.socNumbNotExists, message: "դաշտը լրացված չէ"},
                                      { max: 8, message: "ՀՎՀՀ-ն պետք է բաղկացած լինի 8 թվերից։ Խնդրում ենք ստուգել"},
                                      { min: 8, message: "ՀՎՀՀ-ն պետք է բաղկացած լինի 8 թվերից։ Խնդրում ենք ստուգել"},
                                  ],
                                  validateTrigger: "onBlur"
                                })(
                                    <Input type="number" disabled={this.state.socNumbNotExists} autoComplete="off"/>
                                )}
                              </Form.Item>

                              <Form.Item className="m_0">
                                {getFieldDecorator('withoutCodeOfTax', {
                                  valuePropName: 'checked',
                                  initialValue: !isCurrentIncomeEmpty ? currentIncome.withoutCodeOfTax : this.state.socNumbNotExists,
                                  rules: [{required: this.state.socNumbNotExists, message: "դաշտը լրացված չէ"}],
                                })(
                                    <Checkbox
                                      className="mt_10"
                                      onChange={this.handleTaxCodeOnChange}
                                    >
                                      ՀՎՀՀ-ն բացակայում է
                                    </Checkbox>
                                )}
                              </Form.Item>
                            </div>
                            :
                            null
                        }
                      </div>

                    </>
                    : null
              }

              <div className="row flex-nowrap stretch-columns">
                <Form.Item label="Աշխատավարձ" className="column">
                  {getFieldDecorator('money', {
                    initialValue: !isCurrentIncomeEmpty ? currentIncome.money : "",
                    rules: [{required: this.state.disable_dirty_money !== true, message: "դաշտը լրացված չէ"}],
                    validateTrigger: "blur",
                    // valuePropName: "defaultValue"
                  })(
                    <NumericInput
                      formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      suffix={<Icon type="dram" size={13}/>}
                      disabled={this.state.disable_dirty_money}
                      onChange={this.handleMoneyOnChange}
                      /*onBlur={this.salaryIncludedTaxBlur}
                      onKeyDown={this.handleOnEnterKeyDown}*/
                    />
                  )}
                </Form.Item>

                {/*<Form.Item label="Մաքուր աշխատավարձ" className="column">
                  {getFieldDecorator('clearMoney', {
                    initialValue: (!isCurrentIncomeEmpty && this.state.salary_edited) ? this.props.clear_money : currentIncome.clearMoney ,
                    rules: [{required: true, message: "դաշտը լրացված չէ"}],
                  })(
                    <NumericInput
                      formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      suffix={<Icon type="dram" size={13}/>}
                      disabled={this.state.disable_clear_money}
                      onChange={(value) => this.handleClearMoneyOnChange(value)}
                    />
                  )}
                </Form.Item>*/}
              </div>

              {/*<div className="mb_20"/>*/}
            </div>
        </Modal>
        </>
    )
  }
}

class Incomes extends Component {
  render() {
    return (
        <div className={this.props.className} style={this.props.style}>
          {this.props.children}
        </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    error_message: state.applicationReducer.error_message,
    loading: state.applicationReducer.loading,
    dictionaries: state.applicationReducer.dictionary,
    income_temp_dictionary: state.applicationReducer.income_temp_dictionary,
    clear_money: state.applicationReducer.clear_money,
    user_incomes: state.applicationReducer.customer_info_for_save.request.personWorkInfList,
    userIncomeChanged: state.applicationReducer.userIncomeChanged
  };
}

export default Form.create()(connect(mapStateToProps)(UserIncomes));

