import React, { Component } from 'react';
import "./Person.scss";

export default class Person extends Component {
  render() {
    return (
      <div className="person">
        <div className="person__avatar" style={{ backgroundImage : 'url("' + this.props.avatar + '")' }}>
        </div>
        <div className="person__name">
          {this.props.name}
        </div>
      </div>
    )
  }
}