
import React, {Component, isValidElement} from 'react';
import {Redirect} from 'react-router-dom';
import './LoanTerms.scss';
import Icon from "../../components/Icon/Icon";
import Title from "../../components/Title/Title";
import FormHint from "../../components/FormHint/FormHint";
import GuarantorIdentification from "./GuarantorIdentification";
import Guarantor from "./Guarantor";
import GuarantorCard from "../../components/GuarantorCard/GuarantorCard";
import TopSection from "../../layout/TopSection/TopSection";
import OfferTerms from "../OfferTerms/OfferTerms";
import {Form, Input, Button, Modal, Select, DatePicker, Spin, message} from 'antd';
import moment from 'moment';
import {
  loanTermsInitialLoad,
  confirmLoanTerms,
  clearErrorMessage,
  editRequestTerms
} from '../../reducers/applicationReducer';
import {connect} from "react-redux";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class LoanTerms extends Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id === 'undefined') {
      window.location.replace("/");
    }
    this.state = {
      phoneNumbers: [{}, {}],
      emails: [{}],
      selected_date: '',
      purchaseAddressType: 0,
      purchaseAddress: '',
      show_mount_address: false,
      isEditMode: false
    };
    this.areTheSamePhoneNumbers = false;
  }

  handleRemove = (index, state) => {
    let arr = this.state[state];

    arr.splice(index, 1);

    this.setState({[state]: arr});
    this.props.form.resetFields();
  };

  handleAdd = (state) => {
    let arr = [...this.state[state], {}];

    this.setState({[state]: arr});
  };

  hasDuplicates = (array) => {
    return new Set(array).size !== array.length;
  }

  handleGetData = (item, index, name, type, state) => {
    let arr = this.state[state];

    switch (type) {
      case "input":
        arr[index][name] = item.target.value;
        if (name === 'telNumber') {
          let are_the_same = false;
          if (arr.length >= 2) {
            /*let phoneTelCodes = [], phoneTelNumbers = [];
            arr.map((element) => {
              phoneTelCodes.push(element.telCode);
              phoneTelNumbers.push(element.telNumber)
            })
            are_the_same = this.hasDuplicates(phoneTelCodes) && this.hasDuplicates(phoneTelNumbers);*/

            are_the_same = arr.every((value) => value.telNumber === arr[0].telNumber && value.telCode === arr[0].telCode);
          }
          if (are_the_same) {
            this.areTheSamePhoneNumbers = true;
            message.error("Միևնույն հետախոսահամարը մուտքագրվել է 2 անգամ");
          } else {
            this.areTheSamePhoneNumbers = false;
          }
        }
        break;
      case "select":
        arr[index][name] = item;

        if (name === 'telCode') {
          let are_the_same = false;
          if (arr.length >= 2) {
            /*let phoneTelCodes = [], phoneTelNumbers = [];
            arr.map((element) => {
              phoneTelCodes.push(element.telCode);
              phoneTelNumbers.push(element.telNumber)
            })
            are_the_same = this.hasDuplicates(phoneTelCodes) && this.hasDuplicates(phoneTelNumbers);*/
            are_the_same = arr.every( (value) => value.telNumber === arr[0].telNumber && value.telCode === arr[0].telCode);
          }
          if (are_the_same) {
            this.areTheSamePhoneNumbers = true;
            message.error("Միևնույն հետախոսահամարը մուտքագրվել է 2 անգամ");
          } else {
            this.areTheSamePhoneNumbers = false;
          }
        }

        if (name === 'workProfitType') {
          if (item === '1' || item === '2') {
            arr[index]['fieldsVisibility'] = true;
          } else {
            arr[index]['fieldsVisibility'] = false;
          }
        }
        break;
      case "checkbox":
        arr[index][name] = item.target.checked;
        break;
    }

    this.setState({[state]: arr})
  };

  handleMountPlaceChange = (value) => {
    this.setState({
      show_mount_address: value == 3,
      purchaseAddressType: value,
      purchaseAddress: value != 3 && '',
    })
  };

  handleMountAddressDescriptionOnChange = (event) => {
    this.setState({
      purchaseAddress: event.target.value,
    })
  };

  onSubmit = (e) => {
    e.preventDefault();

    const submitFunction = values => {
      let needed_date_format_for_first_repayment;
      if (this.state.selected_date !== '') {
        needed_date_format_for_first_repayment = moment(this.state.selected_date).format('YYYY-MM-DDTHH:mm:ss');
        this.props.dispatch(confirmLoanTerms(this.state, needed_date_format_for_first_repayment, values.billingNumber));
      } else {
        needed_date_format_for_first_repayment = moment(this.props.first_repayment_date).format('YYYY-MM-DDTHH:mm:ss');
        this.props.dispatch(confirmLoanTerms(this.state, needed_date_format_for_first_repayment, values.billingNumber));
      }
    };

    this.props.form.validateFields((err, values) => {
      if (this.areTheSamePhoneNumbers) {
        message.error("Միևնույն հետախոսահամարը մուտքագրվել է 2 անգամ");
      } else {
        if (!err) {
          const {isEditMode} = this.state;
          if (isEditMode) {
            Modal.confirm({
              title: "Կրկին հաստատելու դեպքում դրամական պահանջի զիջումը կկատարվի ավելի ուշ",
              okText: "Այո",
              cancelText: "Ոչ",
              onOk: () => {
                this.setState({
                  isEditMode: false
                }, () => {
                  submitFunction(values);
                });
              }
            });
          }
          else {
            submitFunction(values);
          }
        }
      }
    })
  };

  fillUserLoanContract = () => {
    const {phoneList, emailList} = this.props.loan_contract;

    let phoneNumbers = [];
    let emails = [];

    if (phoneList.length) {
      phoneList.map((element) => {
        phoneNumbers.push({
          telCode: element.areaCode,
          telNumber: element.number,
        })
      })
    }

    if (emailList.length) {
      emailList.map((element) => {
        emails.push({
          email: element,
        })
      })
    }

    this.setState(previousState => ({
      phoneNumbers: phoneNumbers.length ? phoneNumbers : previousState.phoneNumbers,
      emails: emails.length ? emails : previousState.emails,
    }))
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let request_id = this.props.match.params.id === undefined ? '' : this.props.match.params.id;
    this.props.dispatch(loanTermsInitialLoad(request_id));

    if (Object.entries(this.props.loan_contract).length) {
      this.fillUserLoanContract();
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.error_message.type === "LoanTermsInitialLoad" || this.props.error_message.type === "SaveLoanContract" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      this.props.dispatch(clearErrorMessage());
    }
    if (this.props.error_message.type === "GuarantorIdentification" && (previousProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }

    // if loan_contract gets full
    if (Object.entries(previousProps.loan_contract).length !== Object.entries(this.props.loan_contract).length) {
      this.fillUserLoanContract();
    }
  }

  handlePurchaseAddressOnChange = (e) => {
    this.setState({
      purchaseAddress: e.target.value,
    })
  };

  isNeededGuarantorIdentification = () => {
    const {guarantors} = this.props;
    const {requiredGuarantorCount} = this.props.request_conditions;
    let confirmedGuarantorsCount = 0;

    guarantors.length !== 0 &&
      guarantors.map((element) => {
        if (element.guarantStatus == 2) {
          confirmedGuarantorsCount++;
        }
      });

    return confirmedGuarantorsCount !== requiredGuarantorCount; // if true -> chkan chisht qanaki hastatvac guarantorner
  }

  isConfirmButtonDisabled = () => {
    const {acba_status, user_type} = this.props;

    //comment for BT319 task
    /*if (this.isNeededGuarantorIdentification()) {
      return true;
    }*/
    /*else */if ((acba_status == 5 || acba_status == 6) && (user_type == 1 || user_type == 2)) {
      return false;
    }
    else {
      return true;
    }
  }

  handleEmailOnBlur = (e, index) => {
    const {value} = e.target;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value && !emailRegex.test(String(value).toLowerCase())) {
      this.props.form.setFields({
        [`email_${index}`]: {
          value,
          errors: [new Error('Էլեկտրոնային հասցեն պետք է պարունակի @ սիմվոլը')]
        }
      });
    }
  };

  isInputDisabled = () => {
    const {acba_status, request_status} = this.props;

    return request_status !== "loan_fail" && parseInt(acba_status) === 6;
  };

  handleEditMode = () => {
    this.setState({
      isEditMode: true
    })
  };

  disabledDate = current => {
    const { factoring_date_of_beginning } = this.props;
    let customDate = moment(new Date(factoring_date_of_beginning).addDays(45)).format("YYYY-MM-DD");
    return current && current >= moment(customDate, "YYYY-MM-DD");
  };

  render() {
    const {isEditMode} = this.state;
    const {purchaseAddress, purchaseAddressType, billingNumber} = this.props.loan_contract;
    const {getFieldDecorator} = this.props.form;
    const { factoring_date_of_beginning } = this.props;
    const dateFormat = 'DD/MM/YYYY';

    Date.prototype.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    Date.prototype.minusDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() - days);
      return date;
    };

    return (
        <>
          <TopSection step={3}/>
          <Spin
            spinning={(this.props.loading.type === "loan_terms_loading" && this.props.loading.loading_state) || (this.props.loading.type === "confirm_loan_loading" && this.props.loading.loading_state)}
          >
            <div className="row">
              <div className="column small-12 medium-large-9">
                <div>
                  <OfferTerms terms={this.props.request_conditions}/>
                </div>

                {this.props.request_conditions.requiredGuarantorCount !== 0 &&
                  <Title text="ԵՐԱՇԽԱՎՈՐՆԵՐ" size="lg" weight="bold" icon="protect" className="mb_30"/>
                }

                {
                  this.props.guarantors.length !== 0 &&
                    this.props.guarantors.map(element => (
                      <GuarantorCard
                        guarantorStatus={element.guarantStatus}
                        name={`${element.lastName} ${element.name} ${element.fatherName}`}
                      />
                    ))
                }

                <Spin
                  spinning={this.props.loading.type === "guarantor_save_loading" && this.props.loading.loading_state}
                >
                  {this.isNeededGuarantorIdentification() ?
                    <GuarantorIdentification/>
                    : null
                  }

                  {Object.entries(this.props.guarantor_info).length !== 0 &&
                    <Guarantor/>
                  }
                </Spin>

                <Form
                  onSubmit={
                    this.onSubmit
                  }
                  className="mb_30"
                >
                  <Title text="Պայմանագրում արտատպվող տվյալներ" size="lg" weight="bold" icon="info" className="mb_30"/>

                  {(this.props.customer_info_for_save.request.action == 29 || this.props.customer_info_for_save.request.action == 30 || this.props.customer_info_for_save.request.action == 31) &&
                    <div className="row">
                      <Form.Item label="Տեղադրման հասցե" className="column small-12 large-4">
                        {getFieldDecorator('mountPlace', {
                          initialValue: purchaseAddressType ? purchaseAddressType.toString() : "",
                          rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                        })(
                          <Select
                            showSearch
                            onChange={(e) => this.handleMountPlaceChange(e)}
                            disabled={!isEditMode && this.isInputDisabled()}
                          >
                            <Select.Option style={{paddingBottom: 25}} value="">

                            </Select.Option>
                            {
                              this.props.address_type.length
                                ?
                                this.props.address_type.map((key) => {
                                  return (
                                    <Select.Option key={key} value={key.id}>
                                      {key.label}
                                    </Select.Option>
                                  )
                                })
                                :
                                null
                            }
                          </Select>
                        )}
                      </Form.Item>

                      {(this.state.show_mount_address || purchaseAddress) &&
                      <Form.Item
                        label="Տեղադրման հասցեի նկարագրություն"
                        className="column small-12 large-8 mb_20"
                      >
                        {getFieldDecorator('mountAddress', {
                          rules: [{
                            required: true,
                            message: 'դաշտը լրացված չէ'
                          }],
                        })(
                          <Input
                            type="text"
                            name="mountAddress"
                            onChange={this.handlePurchaseAddressOnChange}
                            autoComplete="off"
                            disabled={!isEditMode && this.isInputDisabled()}
                          />
                        )}
                      </Form.Item>
                      }
                    </div>
                  }

                  <div className="row align-middle">
                    <Form.Item label="Առաջին մարման ամսաթիվ" className="column small-12 large-4">
                      {getFieldDecorator('firstPayDate', {
                        initialValue: this.props.loan_contract.firstRepaymentDay ? moment(new Date(this.props.loan_contract.firstRepaymentDay), dateFormat) : moment(new Date(this.props.first_repayment_date), dateFormat),
                        rules: [{required: false, message: ''}],
                      })(
                          <DatePicker
                            placeholder=""
                            onChange={(e) => this.setState({
                              selected_date: e._d
                            })}
                            allowClear={false}
                            format={dateFormat}
                            // disabledDate={d => !d || d.isAfter(new Date(factoring_date_of_beginning).addDays(45)) || d.isBefore(new Date(factoring_date_of_beginning).minusDays(45))}
                            // disabledDate={d => !d || moment(factoring_date_of_beginning).isAfter("2021-01-30") || moment(factoring_date_of_beginning).isBefore("2021-01-30")}
                            disabledDate={this.disabledDate}
                            disabled={!isEditMode && this.isInputDisabled()}
                          />
                      )}
                    </Form.Item>

                    <div className="column small-12 large-5">
                      <FormHint
                        className="m_0"
                        text="Առաջին մարման ամսաթիվը պետք է չգերազանցի վարկի հաստատման օր +44 օրացուցային օրը"
                      />
                    </div>
                  </div>

                  <div className="medium-large-flex-container row-expanded align-start">
                    <div className="column small-12 large-6">
                      {
                        this.state.phoneNumbers.map((item, index) => {
                          return (
                            <CloneField
                              className="clone_panel"
                              key={index}
                              index={index}
                              value={item}
                            >
                              <div className="medium-large-flex-container align-middle">
                                <div className="small-12">
                                  <div className="row">
                                    <Form.Item label="Կոդ" className="column small-12 large-4 mb_20">
                                      {getFieldDecorator(`telCode_${index}`, {
                                        initialValue: item.telCode,
                                        rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                                      })(
                                          <Select
                                            id={`telCode_${index}`}
                                            className="phone-code-selectbox"
                                            onChange={(value) => {
                                              this.handleGetData(value, index, 'telCode', 'select', 'phoneNumbers');
                                            }}
                                            showSearch
                                            filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            disabled={!isEditMode && this.isInputDisabled()}
                                          >
                                            <Select.Option style={{paddingBottom: 25}} value="">

                                            </Select.Option>
                                            {
                                              this.props.mobile_phone_codes.length
                                                ?
                                                this.props.mobile_phone_codes.map((key) => {
                                                  return (
                                                    <Select.Option key={key} value={key}>
                                                      {key}
                                                    </Select.Option>
                                                  )
                                                })
                                                :
                                                null
                                            }
                                          </Select>
                                      )}
                                    </Form.Item>

                                    <Form.Item label="Հեռախոսահամար" className="column small-12 large-8 mb_20">
                                      {getFieldDecorator(`telNumber_${index}`, {
                                        initialValue: item.telNumber,
                                        rules: [
                                          {required: true, message: 'դաշտը լրացված չէ'},
                                          {
                                            max: 6,
                                            message: 'Հեռախոսահամարը պետք է պարունակի 6 թիվ. խնդրում ենք ուղղել'
                                          },
                                          {
                                            min: 6,
                                            message: 'Հեռախոսահամարը պետք է պարունակի 6 թիվ. խնդրում ենք ուղղել'
                                          }
                                        ],
                                        validateTrigger: "onBlur",
                                      })(
                                        <Input
                                          type="number"
                                          name="telNumber"
                                          onChange={(event) => {
                                            this.handleGetData(event, index, 'telNumber', 'input', 'phoneNumbers')
                                          }}
                                          autoComplete="off"
                                          disabled={!isEditMode && this.isInputDisabled()}
                                        />
                                      )}
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="column">
                                  {
                                    index === 1 ?
                                      <div
                                        className="column pt_24 fluid-y"
                                        style={{paddingLeft: "0"}}
                                      >
                                        <button
                                          type="button"
                                          className="cust_btn mt_10 color-green"
                                          onClick={() => {
                                            this.handleAdd('phoneNumbers')
                                          }}
                                          disabled={!isEditMode && this.isInputDisabled()}
                                        >
                                          <Icon type="plus" size={31}/>
                                        </button>
                                      </div>
                                    :
                                    (this.state.phoneNumbers.length > 2 && index !== 0) ?
                                      <button
                                        type="button"
                                        className="cust_btn mb_10 color-green"
                                        onClick={() => {
                                          this.handleRemove(index, 'phoneNumbers')
                                        }}
                                        disabled={!isEditMode && this.isInputDisabled()}
                                      >
                                        <Icon type="minus" size={32}/>
                                      </button>
                                    : null
                                  }
                                </div>
                              </div>
                            </CloneField>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className="medium-large-flex-container row-expanded align-start">
                    <div className="column small-12 large-4">
                      {
                        this.state.emails.map((item, index) => {
                          return (
                            <CloneField
                              className="clone_panel"
                              key={index}
                              index={index}
                              value={item}
                            >
                              <div className="medium-large-flex-container align-middle">
                                <div className="small-12 lareg-4">
                                  <Form.Item label="Էլ փոստի հասցե">
                                    {getFieldDecorator(`email_${index}`, {
                                      initialValue: item.email,
                                      rules: [{required: false, message: ''}],
                                    })(
                                      <Input
                                        name={`email_${index}`}
                                        id={`email_${index}`}
                                        onBlur={(e) => this.handleEmailOnBlur(e, index)}
                                        onChange={(event) => {
                                         this.handleGetData(event, index, 'email', 'input', 'emails')
                                        }}
                                        autoComplete="off"
                                        disabled={!isEditMode && this.isInputDisabled()}
                                      />
                                    )}
                                  </Form.Item>
                                </div>

                                <div className="column">
                                  {index > 0 ?
                                    <button
                                      type="button"
                                      className="cust_btn mb_10 color-green"
                                      onClick={() => {
                                        this.handleRemove(index, 'emails')
                                      }}
                                      disabled={!isEditMode && this.isInputDisabled()}
                                    >
                                      <Icon type="minus" size={32}/>
                                    </button>
                                    : null
                                  }
                                </div>
                              </div>
                            </CloneField>
                          )
                        })
                      }
                    </div>

                    <div
                      className="column pt_24 fluid-y"
                      style={{paddingLeft: "0"}}
                    >
                      <button
                        type="button"
                        className="cust_btn mt_10 color-green"
                        onClick={() => {
                          this.handleAdd('emails')
                        }}
                        disabled={!isEditMode && this.isInputDisabled()}
                      >
                        <Icon type="plus" size={31}/>
                      </button>
                    </div>
                  </div>

                  {
                    this.props.billing_requirement || billingNumber ?
                      <div className="medium-large-flex-container row-expanded align-start" >
                        <div className="column small-12 large-4" >
                          <Form.Item label="Գործարքի նկարագիր">
                            {getFieldDecorator('billingNumber', {
                              initialValue: billingNumber,
                              rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                            })(
                              <Input
                                type="text"
                                autoComplete="off"
                                disabled={!isEditMode && this.isInputDisabled()}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                      : null
                  }

                  {
                    !isEditMode && this.isInputDisabled() ?
                      <Button
                        type="link"
                        className="link_btn"
                        onClick={this.handleEditMode}
                      >
                        <Icon type="pen" size={13} />
                        ԽՄԲԱԳՐԵԼ
                      </Button>
                    :
                      null
                  }

                  {
                    (isEditMode || !this.isInputDisabled()) ?
                      <div className="row">
                        <div className="column small-12">
                          <div className="flex-container row-wrap align-justify align-middle" style={{justifyContent: "flex-end"}} >
                            <Button
                              className="btn btn-green btn_group"
                              htmlType="submit"
                              disabled={this.isConfirmButtonDisabled()}
                            >
                              <span>ՀԱՍՏԱՏԵԼ</span>
                              <span className="btn__icon">
                              <Icon type="letter" size={20}/>
                            </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    :
                      null
                  }
                </Form>
              </div>
            </div>
          </Spin>
        </>
    )
  }
}

class CloneField extends Component {
  render() {
    return (
        <div className={this.props.className} style={this.props.style}>
          {this.props.children}
        </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    acba_status: state.applicationReducer.acba_status,
    request_status: state.applicationReducer.request_status,
    address_type: state.applicationReducer.address_type,
    customer_info_for_save: state.applicationReducer.customer_info_for_save,
    guarantor_info: state.applicationReducer.guarantor_info,
    guarantors: state.applicationReducer.guarantors,
    mobile_phone_codes: state.applicationReducer.mobile_phone_codes,
    first_repayment_date: state.applicationReducer.first_repayment_date,
    request_conditions: state.applicationReducer.request_conditions,
    offer_terms: state.applicationReducer.offer_terms,
    error_message: state.applicationReducer.error_message,
    loading: state.applicationReducer.loading,
    loan_contract: state.applicationReducer.loan_contract,
    billing_requirement: state.applicationReducer.billing_requirement,
    factoring_date_of_beginning: state.applicationReducer.factoring_date_of_beginning,
  };
}

const LoanTermsContainer = Form.create()(LoanTerms);

export default connect(mapStateToProps)(LoanTermsContainer);
