import React, { Component } from "react";
import {connect} from "react-redux";
import './Users.scss';
import { initUsersLoad } from "../../reducers/usersReducer";
import Title from "../../components/Title/Title";
import {Form, Spin, message} from 'antd';
import AddUser from "../AddUser/AddUser";
import Table from "./Table";
import NotificationsPopover from "../NotificationsPopover/NotificationsPopover";

import Filter from './Filter';
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class Users extends Component {

  componentDidMount() {
    this.props.dispatch(initUsersLoad());
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error_message.type === "init_reducer_fail" || this.props.error_message.type === "user_request_actions_fail" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
  }

  render() {
    return (
      <Spin
        spinning={(this.props.loading.type === "init_reducer_load" || this.props.loading.type === "get_loan_user") && this.props.loading.loading_state}
      >
        {/*Users component may not need to be a form*/}
        {/*<Form>*/}

          <div className="row">
            <div className="column small-12">
              <div className="flex-container row-wrap align-justify align-middle mb_40">
                <Title text="ՕԳՏԱՏԵՐԵՐ" size="md" weight="black" />

                <NotificationsPopover />
              </div>
            </div>

            <div className="column small-12 medium-large-9">
              <AddUser className="mb_40" />
            </div>

            <div className="column small-12 medium-large-9">
              <Filter />
            </div>

            <div className="column small-12 mb_60">
              <Table />
            </div>

            {/*<div className="column small-12 medium-large-9">
              <Form.Item className="small-12">
                <Button
                    className="btn"
                    htmlType="submit"
                    loading={false}>
                  <span>ՊԱՀՊԱՆԵԼ</span>

                  <span className="btn__icon">
                      <Icon type="microcard" size={22} />
                    </span>
                </Button>
              </Form.Item>
            </div>*/}
          </div>
        {/*</Form>*/}
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    error_message: state.usersReducer.error_message,
    loading: state.usersReducer.loading,
  }
}

const UsersContainer = Form.create({name: 'users'})(Users);

export default connect(mapStateToProps)(UsersContainer);
