import React from 'react';
import '../assets/scss/core/_helpers.scss';

const HelpersUtil = () => {
  return(
    <i className="helpersUtil">
    </i>
  )
};

export default HelpersUtil;