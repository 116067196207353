import React from "react";
import createMarkup from "../../utils/createMarkup";

export default function ErrorMessageContent (props) {
  const {text} = props;

  return (
    <div
      className="text-left"
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  )
}