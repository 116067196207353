import React, { Component } from 'react';
import './AllApplications.scss';
import Filter from './Filter'
import TableContainer from './Table';
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import {Form, Table, Select, Tabs, Pagination, DatePicker, Input, Button, Spin, message} from 'antd';
import moment from 'moment';
import NotificationsPopover from "../NotificationsPopover/NotificationsPopover";
import FormHint from "../../components/FormHint/FormHint";
import {getRequestsList, initRequestsListLoad, resetFilter} from "../../reducers/requestReducer";
import {connect} from "react-redux";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class AllApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabName: "Բոլորը",
    };
  }

  componentDidMount() {
    this.props.dispatch(initRequestsListLoad());
  }

  componentWillUnmount() {
    this.props.dispatch(resetFilter());
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.error_message.type === "get_request_list_error" || this.props.error_message.type === "request_init_fail") && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
  }

  onTabPaneClick = (tab_name) => {
    let param = {};
    let activeTabName = "";
    switch (tab_name) {
      case "tab-all":
        param.qualityGroup = 5;
        activeTabName = "Բոլորը";
        break;
      case "tab-editing":
        param.qualityGroup = 1;
        activeTabName = "Խմբագրվող";
        break;
      case "tab-sent":
        param.qualityGroup = 2;
        activeTabName = "Ուղարկված";
        break;
      case "tab-received":
        param.qualityGroup = 3;
        activeTabName = "Ստացված";
        break;
      case "tab-finished":
        param.qualityGroup = 4;
        activeTabName = "Ավարտված";
        break;
    }
    param.quality = [0];
    param.pageNumber = 1;

    this.setState({
      activeTabName,
    });

    this.props.dispatch(getRequestsList(param));
  }

  render() {
    const { TabPane } = Tabs;

    return (
      <Spin spinning={this.props.loading.loading_state && (this.props.loading.type === "requests_init_load" || this.props.loading.type === "get_requests_list")} >
        <div className="row">
          <div className="column small-12">
            <div className="flex-container row-wrap align-justify align-middle mb_40">
              <Title text={"ՀԱՅՏԵՐ / " + this.state.activeTabName} size="md" weight="black" />

              <NotificationsPopover />
            </div>
          </div>

          <div className="column small-12">
            <Tabs animated={false} onTabClick={(i, e) => this.onTabPaneClick(i)}>
              <TabPane
                tab={[<Icon type="all" size={15} className="tab_icon" />, 'Բոլորը']}
                key="tab-all"
              >
                <Filter category={"tab-all"} />
                <TableContainer category={"tab-all"} />
              </TabPane>

              <TabPane
                tab={[<Icon type="pen" size={12} className="tab_icon" />, 'Խմբագրվող']}
                key="tab-editing"
              >
                <Filter category={"tab-editing"} />
                <TableContainer category={"tab-editing"} />
              </TabPane>

              <TabPane
                tab={[<Icon type="arrow-top" size={10} className="tab_icon" />, 'Ուղարկված']}
                key="tab-sent"
              >
                <Filter category={"tab-sent"} />
                <TableContainer category={"tab-sent"} />
              </TabPane>

              <TabPane
                tab={[<Icon type="arrow-bottom" size={10} className="tab_icon" />, 'Ստացված']}
                key="tab-received"
              >
                <Filter category={"tab-received"} />
                <TableContainer category={"tab-received"} />
              </TabPane>

              <TabPane
                tab={[<Icon type="done" size={13} className="tab_icon" />, 'Ավարտված']}
                key="tab-finished"
              >
                <Filter category={"tab-finished"} />
                <TableContainer category={"tab-finished"} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.requestReducer.loading,
    error_message: state.requestReducer.error_message,
  };
}

const AllApplicationsContainer = Form.create()(AllApplications);

export default connect(mapStateToProps)(AllApplicationsContainer);
