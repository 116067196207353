const charsAllowList = [
  'ա', 'բ', 'գ', 'դ', 'ե', 'զ', 'է', 'ը', 'թ', 'ժ', 'ի', 'լ',
  'խ', 'ծ', 'կ', 'հ', 'ձ', 'ղ', 'ճ', 'մ', 'յ', 'ն', 'շ', 'ո',
  'չ', 'պ', 'ջ', 'ռ', 'ս', 'վ', 'տ', 'ր', 'ց', 'ւ', 'փ', 'ք',
  'օ', 'ֆ', 'և', '-', ' '
];

export const userInputLanguageValidation = (inputted_text) => { // if function returns false -> validation will work
  if (inputted_text) {
    const inputtedCharArray = inputted_text.split('');
    let validCharsCount = 0;

    for (let i = 0; i < inputtedCharArray.length; i++) {
      for (let j = 0; j < charsAllowList.length; j++) {
        if (inputtedCharArray[i].toLowerCase() === charsAllowList[j]) {
          validCharsCount++;
        }
      }
    }

    if (validCharsCount !== inputtedCharArray.length) {
      return false;
    }
  }
  return true;
};
