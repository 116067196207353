import React, { Component } from 'react';
import "./FormHint.scss";

export default class FormHint extends Component {
  render() {
    let classNames = [
      "form_hint",
      this.props.className
    ];

    return (
      <div className={classNames.join(" ").trim()} style={this.props.style}>
        { this.props.children }
      </div>
    )
  }
}
