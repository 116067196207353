import commonUtils from "../utils/CommonUtils";
import produce from 'immer';
import history from "../history";
import {unsetToken} from "./initReducer";
import moment from "moment";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = 'NOT_AUTHORIZED';

const INIT_REPORTS_LOAD_START = "INIT_REPORTS_LOAD_START";
const INIT_REPORTS_LOAD_SUCCESS = "INIT_REPORTS_LOAD_SUCCESS";
const INIT_REPORTS_LOAD_FAIL = "INIT_REPORTS_LOAD_FAIL";
const GET_REPORT_LIST_START = "GET_REPORT_LIST_START";
const GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS";
const GET_REPORT_LIST_FAIL = "GET_REPORT_LIST_FAIL";
const GET_USERS_LIST_BY_MARKET_ID_START = "GET_USERS_LIST_BY_MARKET_ID_START";
const GET_USERS_LIST_BY_MARKET_ID_SUCCESS = "GET_USERS_LIST_BY_MARKET_ID_SUCCESS";
const GET_USERS_LIST_BY_MARKET_ID_FAIL = "GET_USERS_LIST_BY_MARKET_ID_FAIL";
const PRINT_REQUEST_REPORTS_START = "PRINT_REQUEST_REPORTS_START";
const PRINT_REQUEST_REPORTS_SUCCESS = "PRINT_REQUEST_REPORTS_SUCCESS";
const PRINT_REQUEST_REPORTS_FAIL = "PRINT_REQUEST_REPORTS_FAIL";

//=================== ACTION CREATORS ======================
export function notAuthorized() {
  return {
    type: NOT_AUTHORIZED
  }
}

export function initReportsLoadStart() {
  return {
    type: INIT_REPORTS_LOAD_START,
  }
}
export function initReportsLoadSuccess(payload) {
  return {
    type: INIT_REPORTS_LOAD_SUCCESS,
    payload,
  }
}
export function initReportsLoadFail(payload) {
  return {
    type: INIT_REPORTS_LOAD_FAIL,
    payload,
  }
}
export function getReportListStart() {
  return {
    type: GET_REPORT_LIST_START,
  }
}
export function getReportListSuccess(payload) {
  return {
    type: GET_REPORT_LIST_SUCCESS,
    payload,
  }
}
export function getReportListFail(payload) {
  return {
    type: GET_REPORT_LIST_FAIL,
    payload,
  }
}
export function getUsersListByMarketIdStart() {
  return {
    type: GET_USERS_LIST_BY_MARKET_ID_START,
  }
}
export function getUsersListByMarketIdSuccess(payload) {
  return {
    type: GET_USERS_LIST_BY_MARKET_ID_SUCCESS,
    payload
  }
}
export function getUsersListByMarketIdFail(payload) {
  return {
    type: GET_USERS_LIST_BY_MARKET_ID_FAIL,
    payload
  }
}
export function printRequestReportsStart() {
  return {
    type: PRINT_REQUEST_REPORTS_START
  }
}
export function printRequestReportsSuccess(payload) {
  return {
    type: PRINT_REQUEST_REPORTS_SUCCESS,
    payload
  }
}
export function printRequestReportsFail(payload) {
  return {
    type: PRINT_REQUEST_REPORTS_FAIL,
    payload
  }
}

//=================== thunks ======================
export function initReportsLoad() {
  return (dispatch, getState) => {
    dispatch(initReportsLoadStart());

    commonUtils.apiCall("post", "Authorization/CheckToken", {})
      .then((response) => {
        if (response.data.result) {
          let api_calls = [];

          let report_list_param = {
            filter: {
              startDate: moment().format("YYYY/MM/DD"),
              endDate: moment().format("YYYY/MM/DD"),
              userID: [0],
              quality: [0],
              itemType: [0],
              minStartAmount: 0,
              maxStartAmount: 0,
              minApprovedAmount: 0,
              maxApprovedAmount: 0,
              marketID: [0],
              pageNumber: 1
            }
          };

          const userType = getState().initReducer.user_type;
          const contractID = getState().initReducer.contract_id;

          api_calls.push(
            commonUtils.apiCall("post", "RequestData/GetRequestReportList", report_list_param),
            commonUtils.apiCall("get", "Info/GetItemTypes", {}),
            commonUtils.apiCall("post", "Info/GetShops", {contractID}),
            commonUtils.apiCall("get", "Info/GetRequestQuality", {}),
          );

          if (userType == 2) {
            api_calls.push(
              commonUtils.apiCall("post", "Info/GetUsersList", {marketsID: []}),
            )
          }

          let payload = {};

          if (api_calls.length > 0) {
            Promise.all(api_calls)
              .then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                  if (responses[i].data.resultCode === 1) {
                    let call_method = responses[i].config.url.split('/').slice(-1)[0];
                    switch (call_method) {
                      case "GetRequestReportList":
                        payload.reports_list = responses[i].data.result;
                        break;
                      case "GetItemTypes":
                        payload.item_types = responses[i].data.result;
                        break;
                      case "GetUsersList":
                        payload.users_list = responses[i].data.result;
                        break;
                      case "GetShops":
                        payload.shops_list = responses[i].data.result;
                        break;
                      case "GetRequestQuality":
                        payload.requests_quality = responses[i].data.result;
                        break;
                    }
                  }
                  if (responses[i].data.resultCode === 3) {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                    break;
                  }
                  if (responses[i].data.resultCode === 4) {
                    dispatch(initReportsLoadFail(responses[i].data.result[0].validationMessage));
                  }
                }
                dispatch(initReportsLoadSuccess(payload));
              })
              .catch((error) => {
                dispatch(initReportsLoadFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
              })
          } else {
            dispatch(initReportsLoadSuccess(payload));
          }
        }
        else {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
      })
      .catch((error) => {
        dispatch(initReportsLoadFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

export function getReportList(input_values, page_number) {
  return (dispatch, getState) => {
    dispatch(getReportListStart());

    commonUtils.apiCall("post", "Authorization/CheckToken", {})
      .then((authorizationTokenResponse) => {
        if (authorizationTokenResponse.data.result) {
          let report_list_param = {
            filter: {
              startDate: input_values.startDate,
              endDate: input_values.endDate,
              userID: input_values.userID,
              quality: input_values.quality,
              itemType: input_values.itemType,
              minStartAmount: input_values.minStartAmount,
              maxStartAmount: input_values.maxStartAmount,
              minApprovedAmount: 0,
              maxApprovedAmount: 0,
              marketID: input_values.marketID,
              pageNumber: page_number
            }
          };

          commonUtils.apiCall("post", "RequestData/GetRequestReportList", report_list_param)
            .then((response) => {
              if (response.data.resultCode === 1) {
                dispatch(getReportListSuccess({filter: report_list_param.filter, reports_list: response.data.result}));
              }
              if (response.data.resultCode === 3) {
                history.push('/signin');
                dispatch(unsetToken());
                dispatch(notAuthorized());
              }
              if (response.data.resultCode === 4) {
                dispatch(getReportListFail(response.data.result[0].validationMessage));
              }
            })
            .catch((error) => {
              dispatch(getReportListFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
            })
        } else {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
      })
      .catch((error) => {
        dispatch(getReportListFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

export function getUsersListByMarketId(marketsID) {
  return (dispatch, getState) => {
    dispatch(getUsersListByMarketIdStart());

    commonUtils.apiCall("post", "Info/GetUsersList", {marketsID})
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getUsersListByMarketIdSuccess({users_list: response.data.result}));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(getUsersListByMarketIdFail());
        }
      })
      .catch((error) => {
        dispatch(getUsersListByMarketIdFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })

  }
}

export function printRequestReports() {
  return (dispatch, getState) => {
    dispatch(printRequestReportsStart());

    const filter = getState().reportsReducer.filter;
    const param = { filter };

    commonUtils.apiCall("post", "RequestData/PrintRequestReports", param)
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(printRequestReportsSuccess({excelBase64: response.data.result}));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(printRequestReportsFail(response.data.result[0].validationMessage));
        }
      })
      .catch((error) => {
        dispatch(printRequestReportsFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}
//=================== State ======================
const initial_state = {
  loading: {
    type: "",
    loading_state: false,
  },
  error_message: {
    type: '',
    message: '',
  },
  reports_list: {},
  item_types: [],
  users_list: [],
  shops_list: {},
  requests_quality: {},
  filter: {
    startDate: moment().format("YYYY/MM/DD"),
    endDate: moment().format("YYYY/MM/DD"),
    userID: [0],
    quality: [0],
    itemType: [0],
    minStartAmount: 0,
    maxStartAmount: 0,
    minApprovedAmount: 0,
    maxApprovedAmount: 0,
    marketID: [0],
    pageNumber: 1
  },
  reportsExcelFile: "",
};

export default function reportsReducer(state = initial_state, action) {
  switch (action.type) {
    case NOT_AUTHORIZED:
      return produce(state, draft => {
        draft.loading.type = "";
        draft.loading.loading_state = false;
        draft.error_message.type = "NotAuthorized";
        draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
      })
    case INIT_REPORTS_LOAD_START:
      return produce(state, draft => {
        draft.loading.type = "init_reports_load";
        draft.loading.loading_state = true;
        draft.error_message.message = "";
      })
    case INIT_REPORTS_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;

        draft.filter = {
          startDate: moment().format("YYYY/MM/DD"),
          endDate: moment().format("YYYY/MM/DD"),
          userID: [0],
          quality: [0],
          itemType: [0],
          minStartAmount: 0,
          maxStartAmount: 0,
          minApprovedAmount: 0,
          maxApprovedAmount: 0,
          marketID: [0],
          pageNumber: 1
        };
        action.payload.hasOwnProperty("reports_list") && (draft.reports_list = action.payload.reports_list);
        action.payload.hasOwnProperty("item_types") && (draft.item_types = action.payload.item_types);
        action.payload.hasOwnProperty("users_list") && (draft.users_list = action.payload.users_list);
        action.payload.hasOwnProperty("shops_list") && (draft.shops_list = action.payload.shops_list);
        action.payload.hasOwnProperty("requests_quality") && (draft.requests_quality = action.payload.requests_quality);

      })
    case INIT_REPORTS_LOAD_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "init_reports_fail";
        draft.error_message.message = action.payload;
      })
    case GET_REPORT_LIST_START:
      return produce(state, draft => {
        draft.loading.type = "get_reports_load";
        draft.loading.loading_state = true;
        draft.error_message.message = "";
      })
    case GET_REPORT_LIST_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.filter = action.payload.filter;
        draft.reports_list = action.payload.reports_list;
      })
    case GET_REPORT_LIST_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "get_reports_fail";
        draft.error_message.message = action.payload;
      })
    case GET_USERS_LIST_BY_MARKET_ID_START:
      return produce(state, draft => {

      })
    case GET_USERS_LIST_BY_MARKET_ID_SUCCESS:
      return produce(state, draft => {
        draft.users_list = action.payload.users_list;
      })
    case GET_USERS_LIST_BY_MARKET_ID_FAIL:
      return produce(state, draft => {

      })
    case PRINT_REQUEST_REPORTS_START:
      return produce(state, draft => {
        draft.loading.type = "print_request_reports";
        draft.loading.loading_state = true;
        draft.error_message.message = "";
      });
    case PRINT_REQUEST_REPORTS_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.reportsExcelFile = action.payload.excelBase64;
      });
    case PRINT_REQUEST_REPORTS_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "print_request_reports_fail";
        draft.error_message.message = action.payload;
      });
    default:
      return state;
  }
}
