import { combineReducers } from 'redux';
import applicationReducer from './applicationReducer';
import initReducer from './initReducer';
import requestReducer from "./requestReducer";
import usersReducer from "./usersReducer";
import notificationsReducer from "./notificationsReducer";
import reportsReducer from "./reportsReducer";
import newsReducer from "./newsReducer";

export default combineReducers({
  initReducer,applicationReducer,requestReducer,usersReducer,reportsReducer,notificationsReducer,newsReducer
})
