import React, { Component } from 'react';
import "./Button.scss";
import {Link} from "react-router-dom";

export default class Button extends Component {
  render() {
    return (
      this.props.type === 'submit' || this.props.type === 'button' ? (

        <button className={"btn " + this.props.className}
                type={this.props.type}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                style={this.props.style}>

          <span>
            {this.props.text}
          </span>

          {
            this.props.icon ? (
              <span className="btn__icon">
                {this.props.icon}
              </span>
            ) : (
              null
            )
          }
        </button>

      ) : this.props.href ? (
        <a href={this.props.href}
           className={"btn " + this.props.className}
           target={this.props.target}
           rel={this.props.rel}
           style={this.props.style}>

          <span>
            {this.props.text}
          </span>

          {
            this.props.icon ? (
              <span className="btn__icon">
                {this.props.icon}
              </span>
            ) : (
              null
            )
          }
        </a>
      ) : this.props.linkTo ? (
        <Link to={this.props.linkTo}
           className={"btn " + this.props.className}
           style={this.props.style}>

          <span>
            {this.props.text}
          </span>

          {
            this.props.icon ? (
              <span className="btn__icon">
                {this.props.icon}
              </span>
            ) : (
              null
            )
          }
        </Link>
      ) : (
        <div className={"btn " + this.props.className} onClick={this.props.onClick} >
          <span>
            {this.props.text}
          </span>

          {
            this.props.icon ? (
              <span className="btn__icon">
            {this.props.icon}
              </span>
            ) : (
              null
            )
          }
        </div>
      )
    )
  }
}
