import React, { Component } from 'react';
import {connect} from "react-redux";
import {registerUser, getPartnersList, getShopByPartnerId} from "../../reducers/usersReducer";
import './AddUser.scss';
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import {Modal, Button, Form, Input, Select, message, Spin} from 'antd';
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";
import {userInputLanguageValidation} from "../../utils/userInputLanguageValidation";

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      user_status: false,
    };
  }

  setModalVisible = (modalVisible) => {
    this.setState({
      modalVisible,
      user_status: modalVisible === true ? false : true,
    });
    if (!modalVisible) {
      this.props.form.resetFields();
    }
  };

  handleUserTypeOnChange = (e) => {
    this.setState({
      user_status: e,
    })
  };

  partnersListOnSearch = (search_text) => {
    if (search_text) {
      this.props.dispatch(getPartnersList(search_text));
    }
  }

  partnersListOnChange = (partner_id) => {
    this.props.form.resetFields("marketID");
    this.props.dispatch(getShopByPartnerId(partner_id));
  }

  emailValidator = (rule, value) => {
    const re =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value) {
      return re.test(String(value).toLowerCase());
    }

    return true;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, input_values) => {
      if (!err) {
        if (input_values.marketID != null && input_values.marketID.constructor === Object) {    // check if marketID comes as key;label or not
          input_values.marketID = input_values.marketID.key;
        }
        else if (input_values.marketID === undefined) { // this will be when user is logged with Manager user type and market input is not shown
          input_values.marketID = this.props.market_id;
        }
        this.props.dispatch(registerUser(input_values));
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error_message.type === "register_user_error" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }

    if (prevProps.user_saved !== this.props.user_saved && this.props.user_saved) {
      message.success("Օգտատերը հաջողությամբ ավելացվեց", 10);
      this.setModalVisible(false);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={this.props.className}>
        <Button
          className="btn btn-green"
          htmlType="button"
          disabled={this.props.user_type != 1 ? false : true}
          onClick={() => this.setModalVisible(true)}
        >
          <span>ՆՈՐ ՕԳՏԱՏԵՐ</span>

          <span className="btn__icon">
              <Icon type="add-user" size={20} />
            </span>
        </Button>

        <Modal
          className="user_modal"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(false)}
          onCancel={() => this.setModalVisible(false)}
        >
          <div className="user_modal_header">
            <img src={require("../../assets/img/acba-white.svg")} alt="Acba Bank"/>
          </div>
          <div className="flex-container row-wrap align-center user_modal_main">
            <Title text="ՆՈՐ ՕԳՏԱՏԵՐ" size="md" weight="black" className="small-12 text-center mb_40" />

            <Form
              className="small-12"
              onSubmit={this.onSubmit}>

              <div style={{justifyContent: "space-around"}} className="flex-container" >
                <Form.Item label="Անուն" className="small-5">
                  {getFieldDecorator('firstName', {
                    rules: [
                      {required: true, message: 'դաշտը լրացված չէ'},
                      {
                        validator: (rule, value) => (userInputLanguageValidation(value)),
                        message: `Խնդրում ենք գրել հայատառ`,
                      }
                    ],
                  })(
                      <Input
                          type="text"
                          name="userName"
                          suffix={<Icon type="person" size={15} />}
                          autoComplete="off"
                      />
                  )}
                </Form.Item>

                <Form.Item label="Ազգանուն" className="small-5">
                  {getFieldDecorator('lastName', {
                    rules: [
                      { required: true, message: 'դաշտը լրացված չէ'},
                      {
                        validator: (rule, value) => (userInputLanguageValidation(value)),
                        message: `Խնդրում ենք գրել հայատառ`,
                      }
                    ],
                  })(
                      <Input
                          type="text"
                          name="lastName"
                          suffix={<Icon type="person" size={15} />}
                          autoComplete="off"
                      />
                  )}
                </Form.Item>
              </div>

              <div style={{justifyContent: "space-around"}} className="flex-container">
                <Form.Item label="Տեսակ" className="small-5">
                  {getFieldDecorator('userType', {
                    initialValue: this.props.user_type == 2 ? "User" : this.props.user_type == 3 ? "Manager" : "",
                    rules: [{ required: true, message: 'դաշտը լրացված չէ'}],
                  })(
                      this.props.user_type == 4 ?
                        // check if user_type = adminka => options: Manager,Supermanager; if user_type = Supermanager/Manager => Manager/User
                        <Select onChange={this.handleUserTypeOnChange} >
                          <Select.Option key='2' value='2'>
                            Manager
                          </Select.Option>
                          <Select.Option key='3' value='3'>
                            Supermanager
                          </Select.Option>
                        </Select>
                      :
                      <Select disabled />
                  )}
                </Form.Item>

                {this.props.user_type == 4 &&
                  <Form.Item label="Կազմակերպություն" className="small-5">
                    {getFieldDecorator('contractID', {
                      // initialValue: "",
                      rules: [{ required: true, message: 'դաշտը լրացված չէ'}],
                    })(
                      <Select
                        showSearch
                        mode="default"
                        // labelInValue
                        placeholder="Մուտքագրեք կազմակերպությունը"
                        filterOption={false}
                        onSearch={this.partnersListOnSearch}
                        onChange={this.partnersListOnChange}
                      >
                        {/*
                            Դաշտը ցուցադրել միայն ադմինկա օգտատիրոջ դեպքում, երբ <Տեսակ> դաշտում ընտրվել է Supermanager տարբերակը:
                            TZ(page 20)
                          */}
                        {Object.entries(this.props.partners_list).length !== 0 &&
                        Object.keys(this.props.partners_list).map((index) => (
                            <Select.Option key={index}>
                              {this.props.partners_list[index]}
                            </Select.Option>
                        ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                }
              </div>

              <div style={{justifyContent: "space-around"}} className="flex-container">
                <Form.Item label="Խանութ" className="small-5">
                  {getFieldDecorator('marketID', {
                    initialValue: this.props.user_type == 2 ? this.props.market_id.toString() : null,
                    rules: [{ required: this.state.user_status != 3 && Object.entries(this.props.shops_list).length !== 0, message: 'դաշտը լրացված չէ'}],
                  })(
                    /*
                      Դաշտը պետք է խմբագրելի լինի ադմին օգտատիրոջ դեպքում, երբ <Տեսակ> դաշտում ընտրվել է Manager տարբերակը և Supermanager-ի
                      դեպքում, իսկ Manager–ի դեպքում պետք է լռելյայն արտացոլվի իր խանութի անվանումը՝ առանց խմբագրման հանարվորության
                      TZ(page 20)
                    */
                    <Select
                      showSearch
                      filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      disabled={this.props.user_type == 2 || this.state.user_status == 3 || Object.entries(this.props.shops_list).length === 0}
                    >
                      {Object.entries(this.props.shops_list).length !== 0 &&
                        Object.keys(this.props.shops_list).map((index) => (
                          <Select.Option key={index}>
                            {this.props.shops_list[index]}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Էլ․ հասցե" className="small-5">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: 'դաշտը լրացված չէ',
                      },
                      {
                        validator: this.emailValidator,
                        message: 'Էլ․ հասցե-ի ֆորմատը սխալ է',
                      },
                    ],
                  })(
                      <Input
                          name="email"
                          suffix={<Icon type="person" size={15} />}
                          autoComplete="off"
                      />
                  )}
                </Form.Item>
              </div>
              <div className="flex-container">
                <Form.Item className="align-left small-6">
                  <Button
                      className="btn btn-grey"
                      onClick={() => this.setModalVisible(false)}
                      htmlType="button">
                    <span>ՉԵՂԱՐԿԵԼ</span>

                    <span className="btn__icon">
                      <Icon type="reject" size={22} />
                    </span>
                  </Button>
                </Form.Item>

                <Form.Item className="align-right small-6">
                  <Button
                      className="btn"
                      htmlType="submit"
                      loading={this.props.loading.type === "register_user" && this.props.loading.loading_state}>
                    <span>ՊԱՀՊԱՆԵԼ</span>

                    <span className="btn__icon">
                      <Icon type="microcard" size={22} />
                    </span>
                  </Button>
                </Form.Item>
              </div>


            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error_message: state.usersReducer.error_message,
    loading: state.usersReducer.loading,
    user_type: state.initReducer.user_type,
    market_id: state.initReducer.market_id,
    shops_list: state.usersReducer.shops_list,
    partners_list: state.usersReducer.partners_list,
    user_saved: state.usersReducer.user_saved,
  }
}

const AddUserContainer = Form.create()(AddUser);

export default connect(mapStateToProps)(AddUserContainer);
