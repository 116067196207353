import React, { Component } from 'react';
import "./Tabs.scss"

export default class Tab extends Component {
  render() {
    let classNames = [
      "tab",
      this.props.className,
      this.props.active ? "active" : ""
    ];

    if(this.props.hidden === true) {
      return null;
    } else {
      return (
        <span className={classNames.join(" ")} style={this.props.style}>
        <span className={classNames[0] + "__number"}>
          {this.props.id}
        </span>
          <span className={classNames[0] + "__text"}>
          {this.props.text}
        </span>
        </span>
      )
    }
  }
}
