import React from "react";
import {connect} from "react-redux";
import {Form} from "antd";
import Icon from "../../components/Icon/Icon";
import UserIncomes_new from "./UserIncomes";

class UserIncomesList extends React.Component {
  render() {
    return (
        <div>
          {this.props.user_incomes.map((element) => {
            let work_profit_type = this.props.dictionaries.work_profit_type.find((index) => (index.value === element.profitType));
            return (
                <div className="row" >
                  {`${work_profit_type.label}: ${element.money} `}
                  <UserIncomes_new
                      income_index = {1}
                  />
                  <button
                      type="button"
                      className="cust_btn mb_10 mr_10 color-green"
                  >
                    Ջնջել
                  </button>
                </div>
            )
          })}
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.applicationReducer.dictionary,
    user_incomes: state.applicationReducer.customer_info_for_save.request.personWorkInfList,
  }
}

const UserIncomesListContainer = Form.create()(UserIncomesList);

export default connect(mapStateToProps)(UserIncomesListContainer);
