import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Select, message} from 'antd';
import {setNewRequestPermission} from '../../reducers/initReducer';

import Title from '../../components/Title/Title';

class NewApplicationStatus extends Component {
  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, value) => {
      if (!err) {
        switch (value.Allow) {
          case 0:
            value.Allow = false;
            break;
          case 1:
            value.Allow = true;
            break;
        }
        this.props.dispatch(setNewRequestPermission(value.Allow));
      }
    })
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {new_request_permission} = this.props;

    return (
      <div className='column col-6' >
        <Title
          text='ՆՈՐ ՀԱՅՏ ԳԵՆԵՐԱՑՆԵԼՈՒ ԿԱՐԳԱՎԻՃԱԿԻ ՓՈՓՈԽՈՒԹՅՈՒՆ'
          size='md'
          weight='black'
          className='mb_35'
        />

        <div className='new-application-action-wrapper' >
          <Form
            onSubmit={this.onSubmit}
            className='row'
          >
            <Form.Item label="Կարգավիճակ" className="column small-12 large-4" >
              {getFieldDecorator('Allow', {
                initialValue: new_request_permission ? 1 : 0,
                rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
              })(
                <Select>
                  <Select.Option key={1} value={1}>
                    Ակտիվ
                  </Select.Option>
                  <Select.Option key={2} value={0}>
                    Պասիվ
                  </Select.Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item className="column small-12 ">
              <Button
                className="btn btn-green"
                htmlType="submit"
                loading={this.props.loading}
              >
                <span>Փոխել</span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.initReducer.loading,
    new_request_permission: state.initReducer.new_request_permission,
  }
}

const NewApplicationStatusContainer = Form.create()(NewApplicationStatus);

export default connect(mapStateToProps)(NewApplicationStatusContainer);