import React, {Component} from "react";
import "./_video-tutorial.scss";

const videoList = [
  {
    link: "https://www.youtube.com/embed/PBV-3pgmxEU",
    title: "Հայտի մուտքագրում՝ վարկի տրամադրման ավտոմատ որոշմամբ"
  },
  {
    link: "https://www.youtube.com/embed/VQd80DubFeQ",
    title: "Հայտի մուտքագրում՝ Բանկի աշխատակցի կողմից որոշման կայացմամբ"
  },
  {
    link: "https://www.youtube.com/embed/MagpR0NPsro",
    title: "Հայտի մուտքագրում՝ բացասական որոշմամբ"
  },
  {
    link: "https://www.youtube.com/embed/eZoF8bwZyGE",
    title: "Հայտի մուտքագորւմ՝ գնորդի հրաժարմամբ"
  }
];

class VideoTutorials extends Component {
  render() {
    return (
      <div className="video-tutorials-screen-wrapper" >
        <div className="main-section-wrapper" >
          <h1 className="title-wrapper" >Ծրագրի օգտագործման ուղեցույց</h1>
          <i>Հարցերի դեպքում՝ +374 77 700 673, +374 98 700 631</i>
        </div>
        <div style={{display: "flex", justifyContent: "space-between"}} >
          <div className="video-item-wrapper" style={{marginRight: 10}} >
            <p className="video-title" ><b>{videoList[0].title}</b></p>
            <iframe src={videoList[0].link} frameBorder="0" allowFullScreen />
          </div>
          <div className="video-item-wrapper" >
            <p className="video-title" ><b>{videoList[1].title}</b></p>
            <iframe src={videoList[1].link} frameBorder="0" allowFullScreen />
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "space-between"}} >
          <div className="video-item-wrapper" style={{marginRight: 10}} >
            <p className="video-title" ><b>{videoList[2].title}</b></p>
            <iframe src={videoList[2].link} frameBorder="0" allowFullScreen />
          </div>
          <div className="video-item-wrapper" >
            <p className="video-title" ><b>{videoList[3].title}</b></p>
            <iframe src={videoList[3].link} frameBorder="0" allowFullScreen />
          </div>
        </div>
      </div>
    )
  }
}

export default VideoTutorials;