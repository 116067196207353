import React, { Component } from 'react';
import './IncomeCard.scss';
import {cancelRequest, deleteUserIncome, getWorkReferenceTypes} from "../../reducers/applicationReducer";
import {connect} from "react-redux";
import UserIncomes from "../../screens/UserIncomes/UserIncomes";
import {Modal} from "antd";
import Icon from "../Icon/Icon";

class IncomeCard extends Component {

  handleRemove = () => {
    Modal.confirm({
      title: 'Համոզվա՞ծ եք, որ տվյալ եկամուտն անհրաժեշտ է հեռացնել',
      okText: 'Այո',
      cancelText: 'Ոչ',
      onOk: () => {
        this.props.dispatch(deleteUserIncome({index: this.props.income_index}));
      },
    });
  };

  render() {
    let classNames = [
      "income_card",
      "flex-container align-justify align-middle",
      this.props.className
    ];

    return (
        <>
            <div className={classNames.join(" ").trim()} >
              <div className={classNames[0] + "__text"} >
                {this.props.text}
                <Icon
                  type="dram"
                  size={13}
                  style={{marginLeft: "5px"}}
                />
              </div>
              {
                this.props.request_status === "edit" ?
                  <div className={classNames[0] + "__actions"} >
                    <UserIncomes income_index={this.props.income_index} />
                    <button className="cust_btn color-green" type="button" onClick={this.handleRemove}>
                      Ջնջել
                    </button>
                  </div>
                  :
                  null
              }
            </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    request_status: state.applicationReducer.request_status,
  }
}

export default connect(mapStateToProps)(IncomeCard);
