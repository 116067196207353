import React, { Component } from 'react';
import './App.scss';
// import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Router, Route, Switch} from "react-router-dom";
import SignIn from "./noAuth/SignIn";
import PrivateRoutes from "./PrivateRoutes";
import { connect } from 'react-redux';
import PasswordReset from "./screens/PasswordReset/PasswordReset";
import {getIpAddress} from './reducers/initReducer';
import {message} from 'antd';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import RestorePassword from './screens/RestorePassword/RestorePassword';

class App extends Component {
    componentDidMount() {
      this.props.dispatch(getIpAddress());
    }

    componentDidUpdate(prevProps, prevState) {
      const {error_message} = this.props;

      if (error_message.type === "GetIPAddress" && (error_message.message !== prevProps.error_message.message) && (error_message.message !== "")) {
        message.error(error_message.message);
      }
    }

  render() {
    return (
        <Switch>
          {
            this.props.logged_in == null &&
              <Route path="/signin" component={ SignIn } exact />
          }
          <Route path="/RestorePassword" component={RestorePassword} exact />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/passwordreset" component={ PasswordReset } exact />
          <Route path="/" component={ PrivateRoutes }/>
        </Switch>
    )
  }
}

function mapStateToProps(state) {
    return {
        error_message: state.initReducer.error_message,
        session_id: state.initReducer.session_id,
        logged_in: state.initReducer.logged_in,
    };
}

export default connect(mapStateToProps)(App)
