import React from 'react';
import {connect} from 'react-redux';

import { getLoanUsersList } from '../../reducers/usersReducer';

import {Button, Form, Input, Select} from 'antd';
import Icon from '../../components/Icon/Icon';
import {userInputLanguageValidation} from "../../utils/userInputLanguageValidation";

class Filter extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.quality = values.quality === 'active';
        values.IsBlocked = values.IsBlocked ? values.IsBlocked === 'blocked' : '';
        this.props.dispatch(getLoanUsersList(1, values));
      }
    })
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {firstName, lastName, userName, quality, userType, IsBlocked} = this.props.users_list_filter;

    return (
      <Form
        className='small-12'
        onSubmit={this.onSubmit}
        style={{marginLeft: 10}}
      >
        <div className='flex-container'>
          <Form.Item style={{marginRight: 10}} label='Անուն' className='small-4' >
            {getFieldDecorator('firstName', {
              initialValue: firstName,
              rules: [
                {
                  validator: (rule, value) => (userInputLanguageValidation(value)),
                  message: `Խնդրում ենք գրել հայատառ`,
                }
              ],
            })(
              <Input type="text" autoComplete="off" />
            )}
          </Form.Item>

          <Form.Item style={{marginRight: 10}} label='Ազգանուն' className='small-4' >
            {getFieldDecorator('lastName', {
              initialValue: lastName,
              rules: [
                {
                  validator: (rule, value) => (userInputLanguageValidation(value)),
                  message: `Խնդրում ենք գրել հայատառ`,
                }
              ],
            })(
              <Input type="text" autoComplete="off" />
            )}
          </Form.Item>

          <Form.Item style={{marginRight: 10}} label='Մուտքանուն' className='small-4' >
            {getFieldDecorator('userName', {
              initialValue: userName,
            })(
              <Input type="text" autoComplete="off" />
            )}
          </Form.Item>

          <Form.Item label='Բլոկավորված' className='large-4 small-3' >
            {getFieldDecorator('IsBlocked', {
              initialValue: IsBlocked,
            })(
              <Select>
                <Select.Option value='' >
                  Բոլորը
                </Select.Option>
                <Select.Option value='blocked' >
                  Բլոկավորված
                </Select.Option>
                <Select.Option value='active' >
                  Ապաբլոկավորված
                </Select.Option>
              </Select>
            )}
          </Form.Item>
        </div>

        <div className='flex-container'>
          <Form.Item style={{marginRight: 10}} label='Կարգավիճակ' className='small-4' >
            {getFieldDecorator('quality', {
              initialValue: quality ? 'active' : 'passive',
            })(
              <Select>
                <Select.Option key={1} value={'active'}>
                  Գործող
                </Select.Option>
                <Select.Option key={2} value={'passive'}>
                  Ապակտիվացված
                </Select.Option>
              </Select>
            )}
          </Form.Item>

          {this.props.user_type == 4 &&
            <Form.Item style={{marginRight: 10}} label='Հասանելիություն' className='small-4' >
              {getFieldDecorator('userType', {
                initialValue: userType,
              })(
                <Select>
                  <Select.Option key={0} value='0' />
                  <Select.Option key={2} value='2'>
                    Manager
                  </Select.Option>
                  <Select.Option key={3} value='3'>
                    Supermanager
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          }

          <Form.Item>
            <Button
              className="small-4 btn btn_group"
              htmlType="submit"
              style={{marginTop: 18}}
            >
              <span>ՑՈՒՑԱԴՐԵԼ</span>
              <span className="btn__icon">
              <Icon type="next" size={20} />
            </span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    )
  }
}

const FilterContainer = Form.create()(Filter);

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    users_list_filter: state.usersReducer.filter,
  }
}

export default connect(mapStateToProps)(FilterContainer);