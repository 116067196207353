import React from "react";
import {connect} from "react-redux";
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import {Form, Select, Spin, Input, Button, message} from "antd";
import {
  changeCustomerSumNeed,
  customerPeriodOnChange,
  setMaxLoan,
  changeCustomerLoanAction,
  saveCustomerInfo,
  calculateRequestOffer,
  editRequestTerms, clearErrorMessage, sendRequestToBank
} from "../../reducers/applicationReducer";
import UserIncomes from "../UserIncomes/UserIncomes";
import IncomeCard from "../../components/IncomeCard/IncomeCard";
import UserIncomesList from "../UserIncomes/UserIncomesList";
import Collapsible from "react-collapsible";
import {Redirect} from "react-router-dom";
import NumericInput from "../../components/NumericInput/NumericInput";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class RequestTerms extends React.Component {
  handleChange = (event) => {
    let data = {
      'amount': this.props.form.getFieldValue('sumNeed'),
      'action': this.props.dictionaries.actions.length !== 0 ? event : 0,
    }

    this.props.dispatch(setMaxLoan(data));
    this.props.dispatch(changeCustomerLoanAction({loan_action: event}));
    this.props.form.resetFields('period');
  };

  handleBlur = (e) => {
    let data = {
      'amount': this.props.form.getFieldValue('sumNeed'),
      'action': this.props.dictionaries.actions.length !== 0 ? this.props.form.getFieldValue('buildType') : 0,
    }

    console.log(data)
    this.props.dispatch(setMaxLoan(data));
    this.props.form.resetFields('period');
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.acba_status === 0 || this.props.acba_status === 9 || this.props.request_status === "edit" || this.props.request_status === "confirmed") {
          this.props.dispatch(saveCustomerInfo(values.addInf));
        }
        else if (this.props.acba_status === 1 || (this.props.acba_status === 4 && this.props.request_status === "saved")) {
          this.props.dispatch(calculateRequestOffer());
        }
        else if ((this.props.acba_status === 4 && this.props.request_status !== "saved") || this.props.acba_status === 8) {
          this.props.dispatch(sendRequestToBank());
        }
      }
    })
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.request_status !== this.props.request_status && this.props.request_status === "calc_analyse_in_bank") {
      message.warning(<ErrorMessageContent text={this.props.calculate_request} />, 5);
    }
    if (this.props.error_message.type === "SaveFail" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      // this.props.dispatch(clearErrorMessage());
    }
    if (this.props.error_message.type === "SendToBank" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.warning(<ErrorMessageContent text={this.props.error_message.message} />, 5);
      // this.props.dispatch(clearErrorMessage());
    }
    if (this.props.error_message.type === "CalculateRequestOffer" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
    if (this.props.error_message.type === "AuthenticationError" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
    if (this.props.error_message.type === "GetClearSalary" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }
  }

  isRequestInputsDisabled = () => {
    if (this.props.request_status === "edit") {
      return false;
    } else {
      return this.props.request_status === "saved" || this.props.acba_status === 5 || this.props.acba_status === 6 || this.props.acba_status === 7
          || this.props.acba_status === 8 || this.props.acba_status === 9 || this.props.acba_status === 10 || this.props.acba_status === 99 ||
          this.props.acba_status === 100 || this.props.user_type == 3;
    }
  }

  isActionsButtonDisabled = () => {
    const {acba_status, request_status, user_type} = this.props;

    if ((acba_status === 9 && request_status === "edit")) {
      return false;
    }
    else if ((acba_status !== 1 && acba_status !== 4 && acba_status !== 8) || user_type == 3) { // պետք է միշտ լինի խմբագրելի Նոր, Թերի, Ենթակա է Բանկ ուղարկման կարգավիճակների դեպքում
      return true;
    } else {
      return false;
    }
  }

  isEditButtonDisabled = () => {
    const {request_status, acba_status, user_type} = this.props;
    if (user_type == 3) {
      return true;
    }
    else if (request_status === 'saved' || request_status === 'calc_analyse_in_bank'
        || acba_status === 1 || acba_status === 4 || acba_status === 8 || acba_status === 9) {
      return false;
    }
    else {
      return true;
    }
  }

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  formOnKeyDown = (e) => {
    return e.keyCode == 13 ? e.preventDefault(): '';
  };

  render() {
    const {addInf} = this.props.customer_info_for_save.request;
    const { getFieldDecorator } = this.props.form;
    return (
        <Spin spinning={this.props.loading.type === "init_app_loading" && this.props.loading.loading_state} >
        <div className="flex-container row-wrap align-justify align-middle">
          <Title text="ՀԱՅՏԻ ՏՎՅԱԼՆԵՐ" size="lg" weight="bold" icon="dram" className="mb_40"/>
          {/*Հայտը թույլատրվում է պահպանել միայն Նոր, Թերի, Ենթակա է բանկ ուղարկման, Սահմանաչափի առաջարկ կարգավիճակների դեպքում*/}
          <Button
            disabled={this.isEditButtonDisabled()}
            type="link" className="link_btn"
            onClick={() => {this.props.dispatch(editRequestTerms())}}
          >
            <Icon type="pen" size={13} />
            ԽՄԲԱԳՐԵԼ ՏՎՅԱԼՆԵՐԸ
          </Button>
        </div>

        <Form onKeyDown={this.formOnKeyDown} onSubmit={this.handleSubmit} className="row">

          <Form.Item label="Վարկի ցանկալի գումար" className="column small-12 large-4">
            {getFieldDecorator('sumNeed', {
              initialValue: this.props.customer_info_for_save.request.startCapital !== "" ? this.props.customer_info_for_save.request.startCapital : "",
              rules: [
                {required: true, message: 'դաշտը լրացված չէ'},
              ],
            })(
              <NumericInput
                suffix={<Icon type="dram" size={13}/>}
                formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => this.props.dispatch(changeCustomerSumNeed({sum_need: value}))}
                onBlur={this.handleBlur}
                disabled={this.isRequestInputsDisabled()}
              />
            )}
          </Form.Item>

          <Form.Item label="Տևողություն" className="column small-12 large-4">
            {
              getFieldDecorator('period', {
                getValueFromEvent: (e) => {
                  const convertedValue = Number(e.currentTarget.value);
                  if (isNaN(convertedValue)) {
                    return Number(this.props.form.getFieldValue("period"));
                  } else {
                    return convertedValue === 0 ? "" : convertedValue;
                  }
                },
                rules: [
                  {required: true, message: 'դաշտը լրացված չէ',},
                  {
                    max: this.props.max_loan,
                    message: `Ժամկետը գերազանցում է թույլատրելի առավելագույն չափը ${this.props.max_loan} ամիսը`,
                    type: "number"
                  },
                ],
                initialValue: this.props.period
              })
              (
                  <Input
                      type="number"
                      name="period"
                      suffix="ամիս"
                      onChange={(e) => this.props.dispatch(customerPeriodOnChange({period: e.target.value}))}
                      disabled={this.isRequestInputsDisabled()}
                      autoComplete="off"
                  />
              )
            }
          </Form.Item>

          {this.props.dictionaries.actions.length !== 0 &&
            <Form.Item label="Արշավ" className="column small-12 large-4">
            {getFieldDecorator('buildType', {
              initialValue: this.props.customer_info_for_save.request.action !== "" ? this.props.customer_info_for_save.request.action.toString() : "0",
              rules: [{required: false, message: ''}],
            })(
                <Select
                  disabled={this.isRequestInputsDisabled()}
                  onChange={this.handleChange}
                  showSearch
                >
                  <Select.Option style={{paddingBottom: 25}} value="0">

                  </Select.Option>
                  {
                    this.props.dictionaries.actions.length
                        ?
                        this.props.dictionaries.actions.map((key) => {
                          return (
                              <Select.Option key={key} value={key.value}>
                                {key.label}
                              </Select.Option>
                          )
                        })
                        :
                        null
                  }
                </Select>
            )}
          </Form.Item>
          }

          <div className="column small-12 mt_10">
            <div className="flex-container row-wrap align-justify align-middle mb_40">
              {this.props.request_status === "edit" || this.props.user_incomes.length !== 0 ?
                <Title text="ՀԱՃԱԽՈՐԴԻ ԵԿԱՄՈՒՏՆԵՐ" icon="dram" iconSize={18} size="lg" weight="bold"/>
                : null
              }
              {/*{(this.props.request_status === "edit" && this.props.acba_status !== 1) &&*/}
              {this.props.request_status === "edit" &&
                <UserIncomes />
              }
            </div>
          </div>

          <div className="column small-12 mb_30">
            {this.props.user_incomes.length !== 0 ?
              this.props.user_incomes.map((element,index) => {
                let work_profit_type = this.props.dictionaries.work_profit_type.find((index) => (index.value == element.profitType));
                let work_reference_type = {};
                if (this.props.income_temp_dictionary.work_reference_type[index] !== undefined) {
                  let temp_work_reference_type = this.props.income_temp_dictionary.work_reference_type[index].find((index) => index.value == element.referenceType);
                  if(temp_work_reference_type === undefined){
                    work_reference_type.label = "";
                  }
                  else {
                    work_reference_type = temp_work_reference_type;
                  }
                } else {
                  work_reference_type.label = "";
                }

                let text;
                if ((work_profit_type.label !== undefined && work_profit_type.label != null && work_profit_type.label !== "") &&
                    (work_reference_type.label !== undefined && work_reference_type.label != null && work_reference_type.label !== "")) {
                  if (element.money !== undefined) {
                    text = work_profit_type.label + ', ' + work_reference_type.label + '` ' + this.formatNumber(element.money);
                  } else {
                    text = work_profit_type.label + ', ' + work_reference_type.label;
                  }
                }
                else {
                  text = element.money !== undefined ? this.formatNumber(element.money) : "";
                }

                if (element.money) { // if clearMoney is calculated -> render the card
                  return (
                    <IncomeCard text={text} key={index} income_index={index} />
                  )
                }
              })
              :
              null
            }
          </div>

          {
            (this.props.request_status === "edit" || addInf) &&
              <div className="column small-12 mb_30" >
                <Collapsible
                  trigger={<Title text="ԼՐԱՑՈՒՑԻՉ ՏՎՅԱԼՆԵՐ" icon="info" size="lg" weight="bold" />}
                  triggerTagName="div"
                  open={this.props.acba_status == 4 && this.props.customer_info_for_save.request.addInf}
                >
                  <div className="panel_inner">
                    <Form.Item>
                      {getFieldDecorator('addInf', {
                        initialValue: this.props.customer_info_for_save.request.addInf,
                        rules: [{ required: false, message: '' }],
                      })(
                        <Input.TextArea style={{minHeight: "60px"}} />
                      )}
                    </Form.Item>
                  </div>
                </Collapsible>
              </div>
          }

          {(this.props.request_status === "confirmed" || this.props.acba_status === 0) ?
            <div className="column small-12">
              <div className="flex-container align-middle align-justify" style={{justifyContent: "flex-end"}}>
                <Form.Item className="text-right md-text-center m_0">
                  <Button
                    className="btn btn_group"
                    htmlType="submit"
                    loading={this.props.loading.loading_state && this.props.loading.type === "save_customer_loading"}
                  >
                    <span>ՊԱՀՊԱՆԵԼ</span>
                    <span className="btn__icon">
                  <Icon type="microcard" size={22} />
                </span>
                  </Button>
                </Form.Item>
              </div>
            </div>
            :
            <div className="column small-12">
              <div className="flex-container align-middle align-justify" style={{justifyContent: "flex-end"}}>
                <Form.Item className="text-right md-text-center m_0">
                  <Button
                      className="btn btn_group"
                      htmlType="submit"
                      disabled={this.isActionsButtonDisabled()}
                      loading={this.props.loading.loading_state && (this.props.loading.type === "save_customer_loading" || this.props.loading.type === "send_to_bank")}
                  >
                    {(this.props.request_status === "edit" || (this.props.acba_status !== 1 && this.props.acba_status !== 2 && this.props.acba_status !== 4 &&  this.props.acba_status !== 8)) && <span>ՊԱՀՊԱՆԵԼ</span>}
                    {((this.props.acba_status === 1 && this.props.request_status !== "edit") || (this.props.acba_status === 4 && this.props.request_status === "saved")) && <span>ՀԱՇՎԱՐԿԵԼ</span>}
                    {(((this.props.acba_status === 4 && this.props.request_status !== "saved") || this.props.acba_status === 8) && this.props.request_status !== "edit") && <span>Ուղարկել Բանկ</span>}
                    {(this.props.acba_status === 2) && <span>Ուղարկված</span>}
                    <span className="btn__icon">
                     <Icon type="calc" size={22} />
                    </span>
                  </Button>
                </Form.Item>
              </div>
            </div>
          }
        </Form>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    dictionaries: state.applicationReducer.dictionary,
    income_temp_dictionary: state.applicationReducer.income_temp_dictionary,
    request_status: state.applicationReducer.request_status,
    acba_status: state.applicationReducer.acba_status,
    customer_info_for_save: state.applicationReducer.customer_info_for_save,
    period: state.applicationReducer.period,
    max_loan: state.applicationReducer.max_loan,
    calculate_request: state.applicationReducer.calculate_request,
    user_incomes: state.applicationReducer.customer_info_for_save.request.personWorkInfList,
    loading: state.applicationReducer.loading,
    agreementPDF: state.applicationReducer.agreementPDF,
    error_message: state.applicationReducer.error_message,
  }
}

const RequestTermsContainer = Form.create()(RequestTerms);

export default connect(mapStateToProps)(RequestTermsContainer);
