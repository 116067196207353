import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NotificationsPopover.scss';
import { Popover } from 'antd';
import PopoverButton from "./PopoverButton";
import NotificationsPopoverContent from "../NotificationsPopoverContent/NotificationsPopoverContent";

class NotificationsPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  render() {
    const {notifications_list} = this.props;

    return (
      <Popover
        placement="bottomRight"
        content={<NotificationsPopoverContent/>}
        trigger="click"
        onVisibleChange={this.handleVisibleChange}
      >
        <div className="inline_elem">
          <PopoverButton count={notifications_list.length} hideCount={false} />
        </div>
      </Popover>
    )
  }
}

function mapStateToProps(state) {
  return {
    notifications_list: state.notificationsReducer.notifications_list,
  }
}

export default connect(mapStateToProps)(NotificationsPopover);