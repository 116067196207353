import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Spin} from "antd";
import './Notifications.scss';
import Title from "../../components/Title/Title";
import NotificationsPopover from "../NotificationsPopover/NotificationsPopover";
import Notice from "../Notice/Notice";

import {markAsRead} from "../../reducers/notificationsReducer";

class Notifications extends Component {
  setNotificationIconByStatus = (customerStatus, guarantorStatus) => {
    if (guarantorStatus == 0) {
      switch (customerStatus) {
        case 1:
          return ["confirm", "#00A789"];
          break;
        case 2:
          return ["product", "#00A789"];
          break;
        case 3:
          return ["time"];
          break;
        case 4:
          return ["info", "rgb(226, 175, 41)"]
          break;
        case 5:
        case 6:
        case 7:
          return ["confirm", "#00A789"]
          break;
        case 8:
        case 9:
          return ["question", "#828282"];
          break;
        case 10:
        case 99:
        case 100:
          return ["cancel", "#D60000"];
          break;
        default:
      }
    }
    else {
      switch (guarantorStatus) {
        case 2:
          return ["confirm", "#00A789"]
          break;
        case 3:
          return ["cancel", "#D60000"];
          break;
        default:
      }
    }
  }

  setNotificationTitleByStatus = (status) => {
    switch (status) {
      case 1:
        return "Նոր";
        break;
      case 2:
        return "Ուղարկված";
        break;
      case 3:
        return "Բանկում դիտարկվող";
        break;
      case 4:
        return "Թերի";
        break;
      case 5:
        return "Դրական";
        break;
      case 6:
        return "Հաստատված";
        break;
      case 7:
        return "Տրամադրված";
        break;
      case 8:
        return "Ենթակա է Բանկ ուղարկման";
        break;
      case 9:
        return "Սահմանաչափի առաջարկ";
        break;
      case 10:
        return "Չտրամադրված";
        break;
      case 99:
        return "Հրաժարված";
        break;
      case 100:
        return "Մերժված";
        break;
      default:
    }
  }

  getNotificationMessage = (userInfo) => {
    const {
      requestID,
      quality,
      guarantorStatus,
      customerFullName
    } = userInfo;
    let notificationMessage = "";

    if (guarantorStatus == 0) {
      notificationMessage = `Հայտի պատասխան (${this.setNotificationTitleByStatus(quality)})`;
    }
    else {
      switch (guarantorStatus) {
        case 2:
          notificationMessage =
            <>
              {customerFullName}ի (հայտի համար՝ <a onClick={() => this.markAsRead(requestID)} href={`/application/${requestID}`} target="_blank">{requestID}</a>) հայտի երաշխավորը հաստատվել է․ խնդրում ենք դիտարկել
            </>;
          break;
        case 3:
          notificationMessage =
            <>
              {customerFullName}ի (հայտի համար՝ <a onClick={() => this.markAsRead(requestID)} href={`/application/${requestID}`} target="_blank">{requestID}</a>) հայտի երաշխավորը մերժվել է․ խնդրում ենք դիտարկել
            </>;
          break;
        default:
      }
    }

    return notificationMessage;
  }

  markAsRead = (request_id) => {
    this.props.dispatch(markAsRead(request_id));
  }

  render() {
    const {notifications_list} = this.props;
    const {type, loading_state} = this.props.loading;

    return (
      <div className="row">
        <div className="column small-12 mb_50">
          <div className="flex-container row-wrap align-justify align-middle">
            <Title text="ԾԱՆՈՒՑՈՒՄՆԵՐ" size="md" weight="black" />

            <NotificationsPopover />
          </div>
        </div>

        {/*<div className="column small-12 notifications_all">
          <Notice
            status="unread"
            icon="confirm"
            iconColor="#00A789"
            message="Հայտի պատասխան"
            desc={["Պողողյան Պողոս (հայտի համար՝", <a href="">00025231023</a>,  ")"]}
            key="nn-1"
          />
          <Notice
            status="unread"
            icon="cancel"
            iconColor="#D60000"
            message="Հայտի պատասխան"
            desc={["Պողողյան Պողոս (հայտի համար՝", <a href="">00025231023</a>,  ")"]}
            key="nn-2"
          />
          <Notice
            status=""
            icon="product"
            iconColor="#00A789"
            message="ՆՈՐ ՊՐՈԴՈՒԿՏ"
            messageColor="#00A789"
            desc="Ծանուցման տեքստ"
            key="nn-3"
          />
          <Notice
            status=""
            icon="info"
            iconColor="#00A789"
            message="ՆՈՐ ՏԵՂԵԿԱՏՎԱԿԱՆ ՆՅՈՒԹ"
            messageColor="#00A789"
            desc="Ծանուցման տեքստ"
            key="nn-4"
          />
          <Notice
            status=""
            icon="question"
            iconColor="#828282"
            message="գործողություն պահանջող ԾԱՆՈՒՑՄԱՆ վերնագիր"
            desc="Ծանուցման տեքստ"
            buttons={[
              <button typeof="button" className="base_btn base_btn-green">
                ԸՆԴՈՒՆԵԼ
              </button>,
              <button typeof="button" className="base_btn base_btn-red">
                ՄԵՐԺԵԼ
              </button>
            ]}
            key="nn-5"
          />
          <Notice
            status=""
            icon="file"
            iconColor="#00A789"
            message="Կցված ֆայլով ԾԱՆՈՒՑՄԱՆ վերնագիր"
            messageColor="#00A789"
            desc="Paymaagir.docx"
            buttons={
              <a href="" download="" className="base_btn">
                <Icon type="download" size={17} />
                ՆԵՐԲԵՌՆԵԼ
              </a>
            }
            key="nn-6"
          />
          <Notice
            status=""
            icon="image"
            iconColor="#00A789"
            message="Կցված նկարով ԾԱՆՈՒՑՄԱՆ վերնագիր"
            messageColor="#00A789"
            desc="Ծանուցման տեքստ"
            buttons={
              <img src={require("../../assets/img/img.jpg")} alt=""/>
            }
            key="nn-7"
          />
          <Notice
            status=""
            icon="flag"
            iconColor="#00A789"
            message="ՆՈՐ արշավ"
            messageColor="#00A789"
            desc="Ծանուցման տեքստ"
            key="nn-8"
          />
          <Notice
            status=""
            icon="power"
            iconColor="#D60000"
            message="ծրագրի խափանում / անջատում"
            desc="Ծանուցման տեքստ"
            key="nn-9"
          />
        </div>*/}
        <Spin spinning={type === "MarkAsRead" && loading_state} >
          <div className="column small-12 notifications_all" >
            {notifications_list.map((element, index) => (
              <Notice
                status="unread"
                icon={this.setNotificationIconByStatus(element.quality, element.guarantorStatus)[0]}
                iconColor={this.setNotificationIconByStatus(element.quality, element.guarantorStatus)[1]}
                message={this.getNotificationMessage(element)}
                desc={
                  element.guarantorStatus == 0 ?
                    [`${element.customerFullName} (հայտի համար: `,
                      <a
                        onClick={() => this.markAsRead(element.requestID)}
                        href={`/application/${element.requestID}`}
                        target='_blank'
                      >
                        {element.requestID}
                      </a>,
                      ")"]
                  : null
                }
                key={index}
              />
            ))}
          </div>
        </Spin>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    notifications_list: state.notificationsReducer.notifications_list,
    loading: state.notificationsReducer.loading,
  }
}

export default connect(mapStateToProps)(Notifications);