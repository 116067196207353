import React, { Component, createRef } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {Form, Input, Button, message} from 'antd';

import { CAPTCHA_CLIENT_ID } from '../constants/captcha';
import Menu from "../layout/Menu/Menu";
import Content from "../layout/Content/Content";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import { authUser, checkGoogleCaptcha, checkUserPassword } from '../reducers/initReducer';
import ErrorMessageContent from "../components/ErrorMessageContent/ErrorMessageContent";

class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.captchaRef = createRef();
    this.state = {
      captchaToken: null,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { isCaptchaNeeded } = this.props;
        const { captchaToken } = this.state;

        if (captchaToken && isCaptchaNeeded) {
          this.props.dispatch(checkGoogleCaptcha({ Token: captchaToken, userName: values.username, password: values.password }));
        } else {
          this.props.dispatch(authUser(values.username, values.password));
        }
      }
    })
  };

  captchaOnChange = (value) => this.setState({ captchaToken: value });

  componentDidUpdate(prevProps, prevState) {
    const {error_message} = this.props;

    if (error_message.type === "AuthError" && (error_message.message !== prevProps.error_message.message) && (error_message.message !== "")) {
      if (this.captchaRef.current) {
        this.setState({
          captchaToken: null,
        }, () => {
          this.captchaRef.current.reset();
        });
      }
      if (error_message.message !== 'validation-error') {
        message.error(<ErrorMessageContent text={error_message.message} />, 5);
      }
    }
    if (this.props.error_message_applicationReducer.type === "NotAuthorized" && (prevProps.error_message_applicationReducer.message !== this.props.error_message_applicationReducer.message && this.props.error_message_applicationReducer.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message_applicationReducer.message} />, 5);
      // this.props.dispatch(clearErrorMessage());
      // this.props.dispatch(newIdentification());
    }
  }

  render() {
    const { captchaToken } = this.state;
    const {
      blockedMinutesRemaining,
      isCaptchaNeeded,
      isBlocked,
    } = this.props;
    const {getFieldDecorator} = this.props.form;

    return (
      <>
        <div className="medium-large-flex-container row-wrap fluid-y">
          <Menu/>

          <Content>
            <div className="row">
              <div className="column small-12 medium-large-6">
                {
                  isBlocked
                    ? (
                      <Title
                        size="md"
                        weight="black"
                        className="mt_35 capitalize-first-letter"
                        text={`Խնդրում ենք փորձել ${blockedMinutesRemaining || 15} րոպեից`}
                      />
                    )
                    : (
                      <>
                        <Title text="Մուտք" size="md" weight="black" className="mb_35"/>
                        <Form onSubmit={this.handleSubmit} className="">

                          <Form.Item label="Մուտքանուն">
                            {getFieldDecorator('username', {
                              rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                            })(
                              <Input type="text" />
                            )}
                          </Form.Item>

                          <Form.Item label="Գաղտնաբառ" className='m_0' >
                            {getFieldDecorator('password', {
                              rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                            })(
                              <Input type="password"
                              />
                            )}
                          </Form.Item>

                          <div className='mt_10 mb_10' >
                            <NavLink to="/forgot-password" >
                              Մոռացել եմ գաղտնաբառը
                            </NavLink>
                          </div>

                          {
                            isCaptchaNeeded
                              ? (
                                <div className='mb_10' >
                                  <ReCAPTCHA
                                    ref={this.captchaRef}
                                    sitekey={CAPTCHA_CLIENT_ID}
                                    onChange={this.captchaOnChange}
                                  />
                                </div>
                              )
                              : null
                          }

                          <Form.Item>
                            <Button
                              disabled={!captchaToken && isCaptchaNeeded}
                              className="btn btn-green"
                              htmlType="submit" loading={this.props.loading}
                            >
                              <span>ՄՈՒՏՔ</span>

                              <span className="btn__icon">
                                <Icon type="ident" size={22}/>
                              </span>
                            </Button>
                          </Form.Item>

                        </Form>
                      </>
                    )
                }
              </div>
            </div>
          </Content>
        </div>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {
    loading: state.initReducer.loading,
    error_message_applicationReducer: state.applicationReducer.error_message,
    error_message: state.initReducer.error_message,
    blockedMinutesRemaining: state.initReducer.blockedMinutesRemaining,
    isBlocked: state.initReducer.isBlocked,
    isCaptchaNeeded: state.initReducer.isCaptchaNeeded,
  };
}

const SignupFormContainer = Form.create({name: 'sign_up'})(SignupForm);

export default connect(mapStateToProps)(SignupFormContainer);
// export default Form.create()(connect(mapStateToProps)(SignupForm));


