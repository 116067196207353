import React from "react";
import {connect} from "react-redux";
import Plate from "../../components/Plate/Plate";
import Icon from "../../components/Icon/Icon";
import PieChart from "react-minimal-pie-chart";

class ReportsInfo extends React.Component{
  constructor(props) {
    super(props);
  }

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  setStatusColorByQuality = (quality) => {
    let color = "";
    switch (quality) {
      case 1:
        color = "#245D7D";
        break;
      case 2:
        color = "#00A789";
        break;
      case 3:
        color = "#00A789";
        break;
      case 4:
        color = "#E48900";
        break;
      case 5:
        color = "#00A789";
        break;
      case 6:
        color = "#00A789";
        break;
      case 7:
        color = "#00A789";
        break;
      case 8:
        color = "#696969";
        break;
      case 9:
        color = "#8CA109";
        break;
      case 10:
        color = "#323232";
        break;
      case 99:
        color = "#840000";
        break;
      case 100:
        color = "#D60000";
        break;
    }
    return color;
  }

  render() {
    const sumOfLoans = this.props.reports_list.RequestSum != undefined ? this.formatNumber(this.props.reports_list.RequestSum) : 0;
    const countOfLoans = this.props.reports_list.RequestCount != undefined ? this.formatNumber(this.props.reports_list.RequestCount) : 0;

    const requestSumByQuality = this.props.reports_list.RequestRequestSumByQuality;
    const isReportsRequestEmpty = requestSumByQuality !== undefined && Object.entries(requestSumByQuality).length !== 0 ? false : true;

    const pieChart = [
      /*{ label: 'ԹԵՐԻ', summa: '300,000 ֏', value: 50, color: '#E48900' },
      { label: 'ՍԱՀՄԱՆԱՉԱՓ', summa: '150,000 ֏', value: 25, color: '#8CA109' },
      { label: 'ԴՐԱԿԱՆ', summa: '150,000 ֏', value: 25, color: '#00A789' },*/
    ];

    if (!isReportsRequestEmpty) {
      Object.keys(requestSumByQuality).map((index, id) => {
        pieChart.push({
          label: index,
          summa: requestSumByQuality[index],
          value: (parseInt(requestSumByQuality[index]) * 100) / parseInt(sumOfLoans),
          color: this.setStatusColorByQuality(id),
        })
      })
    }

    return (
      <div className="mb_30">
        <div style={{flexDirection: "column"}} className="row flex-container">
          <div className="small-12">
            <div className="row">
              <div className="column small-3 mb_30">
                <Plate label={<>Հաստատված վարկերի <br/> քանակ</>} value={countOfLoans} type="" theme="grey" />
              </div>
              <div className="column small-3 mb_30">
                <Plate label={<>Հաստատված վարկերի <br/> հանրագումար</>} value={sumOfLoans} type={<Icon type="dram" size={15} />} theme="green" />
              </div>
              {/*{!isReportsRequestEmpty ?
                Object.keys(requestSumByQuality).map((index) => {
                  return (
                    <div className="column small-3 mb_30" >
                      <Plate label={index} value={this.formatNumber(requestSumByQuality[index])} type="" theme="grey" />
                    </div>
                  )
                })
              : null}*/}
            </div>
          </div>

          {/*<div className="column small-12 large-6">
            <PieChart
              className="pie-chart-wrapper"
              data={pieChart}
              label={props => { return props.data[props.dataIndex].summa;}}
              labelPosition={95}
              labelStyle={{fontSize: "5px", color: "black"}}
            />
          </div>*/}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    reports_list: state.reportsReducer.reports_list,
  }
}

export default connect(mapStateToProps)(ReportsInfo);