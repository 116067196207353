import React from "react";
import Icon from "../../components/Icon/Icon";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import {newIdentification} from "../../reducers/applicationReducer";
import {connect} from "react-redux";

class NegativeAnswer extends React.Component {
  handleNewIdentification = () => {
    this.props.dispatch(newIdentification());
  }
  render() {
    const {text, status, rejectionReason} = this.props;

    return (
        <div className="rejection">
          <div className="row">
            <div className="column small-12 medium-large-5">
              <Icon type="confirm" size={46} className="color-green mb_35" />

              <Title text={text} size="xl" weight="bold" className="mb_20" />

              {
                status === "rejection" &&
                  <Title
                    className='mb_20 capitalize-first-letter'
                    style={{color: '#D60000'}}
                    text={rejectionReason}
                    size="sm"
                    weight="black"
                  />
              }

              <div className="direction_buttons">
                <div
                  onClick={this.handleNewIdentification}
                >
                  <Button
                      text="ՆՈՐ ՀԱՅՏ"
                      className="btn-wide no-decor mb_30"
                      linkTo="/"
                      icon={
                        <Icon type="plus-big" size={27} />
                      }
                  />
                </div>

                <Button
                  text="ՀԱՅՏԵՐ"
                  className="btn-wide mb_30"
                  href="/applications"
                  icon={
                    <Icon type="box" size={23} />
                  }
                />
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export function mapStateToProps(state) {
  return {
    rejectionReason: state.applicationReducer.customer_info_for_save.request.rejectionReason,
  };
}

export default connect(mapStateToProps)(NegativeAnswer);