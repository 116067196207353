import React, { Component } from "react";
import {connect} from "react-redux";
import history from "../../history";

import "./Success.scss";

import {Spin, Button, message} from "antd";
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";

import { downloadAllContracts, newIdentification, getPrizeOffer } from "../../reducers/applicationReducer";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areButtonsDisabled: true,
    }
  }

  downloadAllContracts = () => {
    this.props.dispatch(downloadAllContracts());
  };

  handleNewIdentification = () => {
    history.push('/');
    this.props.dispatch(newIdentification());
  };

  renderPrizeWinText = (text) => (
    <Title
      text={text}
      size="lg"
      weight="bold"
      className='color-green'
    />
  );

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error_message.type === "DownloadAllContracts" && (prevProps.error_message.message !== this.props.error_message.message && this.props.error_message.message !== "")) {
      message.error(<ErrorMessageContent text={this.props.error_message.message} />, 5);
    }

    if (this.props.all_contracts_pdf != prevProps.all_contracts_pdf) {
      const contracts = this.props.all_contracts_pdf;

      if (Array.isArray(contracts)) {
        contracts.forEach((contract, index) => {
          contract = "data:application/octet-stream;charset=utf-8;base64," + contract;
          setTimeout(() => {
            let link = document.createElement('a');
            link.download = `Պայմանագիր_${index + 1}.pdf`;
            link.href = contract;
            link.dispatchEvent(new MouseEvent('click'))
          }, 50)
        })
      }

      this.setState({areButtonsDisabled: false});
    }
  }

  /*componentDidMount() {
    this.props.dispatch(getPrizeOffer());
  }*/

  render() {
    const { loading, prizeWinAmount } = this.props;
    const {loading_state, type} = loading;
    const {areButtonsDisabled} = this.state;
    const formatPrizeAmount = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return (
      <Spin spinning={loading_state && type === 'get_prize_offer_start'} tip="Հաղթող հայտի ստուգում..." >
        <div className="success">
          <div className="row">
            <div className="column small-12 medium-large-6">
              <Icon type="confirm" size={46} className="color-green mb_35" />

              <Title text="Հայտը Հաջողությամբ Ձևակերպվեց" size="xl" weight="bold" className="mb_45" />

              { prizeWinAmount ?
                (
                  <div className='prize-win-amount-label-wrapper' >
                    {this.renderPrizeWinText('Շնորհավորում ենք։')}
                    {this.renderPrizeWinText('Հաճախորդը ճանաչվել է հաղթող:')}
                    {this.renderPrizeWinText(`Պարգևատրման չափը՝ ${formatPrizeAmount(prizeWinAmount)} ՀՀ դրամ։`)}
                  </div>
                )
                : null
              }

              <div className="direction_buttons">
                <Button
                  className="btn btn-green btn_group btn-wide mb_30"
                  loading={loading_state && type === "download_all_contracts_start"}
                  onClick={this.downloadAllContracts}
                >
                  <span>ԱՐՏԱՏՊԵԼ ՊԱՅՄԱՆԱԳՐԵՐԸ</span>
                  <span className="btn__icon">
                    <Icon type="print" size={23} />
                  </span>
                </Button>

                <Button
                  className="btn btn-green btn_group btn-wide mb_30"
                  onClick={this.handleNewIdentification}
                  disabled={areButtonsDisabled}
                >
                  <span>ՆՈՐ ՀԱՅՏ</span>
                  <span className="btn__icon">
                    <Icon type="plus-big" size={27} />
                  </span>
                </Button>

                <Button
                  className="btn btn_group btn-wide mb_30"
                  onClick={() => history.push('/applications')}
                  disabled={areButtonsDisabled}
                >
                  <span>ՀԱՅՏԵՐ</span>
                  <span className="btn__icon">
                    <Icon type="box" size={23} />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    error_message: state.applicationReducer.error_message,
    loading: state.applicationReducer.loading,
    all_contracts_pdf: state.applicationReducer.all_contracts_pdf,
    prizeWinAmount: state.applicationReducer.prizeWinAmount,
  }
}

export default connect(mapStateToProps)(Success);
