import commonUtils from "../utils/CommonUtils";
import toBoolean from "../utils/toBoolean";
import history from "../history";
import produce from 'immer';
import axios from 'axios';
import {message} from "antd";


//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = "NOT_AUTHORIZED";
const RESET_EMAIL_SENT_STATUS = "RESET_EMAIL_SENT_STATUS";

const SET_TOKEN = 'SET_TOKEN';
const SET_USER_ID = 'SET_USER_ID';
const UNSET_TOKEN = 'UNSET_TOKEN';
const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAIL = 'AUTH_FAIL';
const LOG_OUT_START = 'LOG_OUT_START';
const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
const GET_WORK_REFERENCE_START = 'GET_WORK_REFERENCE_START';
const GET_WORK_REFERENCE_SUCCESS = 'GET_WORK_REFERENCE_SUCCESS';
const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const CHANGE_PASSWORD_REQUIREMENT_STATE = 'CHANGE_PASSWORD_REQUIREMENT_STATE';

const GET_IP_ADDRESS_START = 'GET_IP_ADDRESS_START';
const GET_IP_ADDRESS_SUCCESS = 'GET_IP_ADDRESS_SUCCESS';
const GET_IP_ADDRESS_FAIL = 'GET_IP_ADDRESS_FAIL';

const SET_NEW_REQUEST_PERMISSION_START = 'SET_NEW_REQUEST_PERMISSION_START';
const SET_NEW_REQUEST_PERMISSION_SUCCESS = 'SET_NEW_REQUEST_PERMISSION_SUCCESS';
const SET_NEW_REQUEST_PERMISSION_FAIL = 'SET_NEW_REQUEST_PERMISSION_FAIL';

const GET_NEW_REQUEST_PERMISSION_START = 'GET_NEW_REQUEST_PERMISSION_START';
const GET_NEW_REQUEST_PERMISSION_SUCCESS = 'GET_NEW_REQUEST_PERMISSION_SUCCESS';
const GET_NEW_REQUEST_PERMISSION_FAIL = 'GET_NEW_REQUEST_PERMISSION_FAIL';

const GET_USER_TYPE_START = 'GET_USER_TYPE_START';
const GET_USER_TYPE_SUCCESS = 'GET_USER_TYPE_SUCCESS';
const GET_USER_TYPE_FAIL = 'GET_USER_TYPE_FAIL';

const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS'; // from applicationReducer action type
const NEW_IDENTIFICATION_SUCCESS = 'NEW_IDENTIFICATION_SUCCESS'; // from applicationReducer action type

const USER_REQUEST_ACTION_RESTORE_START = 'USER_REQUEST_ACTION_RESTORE_START';
const USER_REQUEST_ACTION_RESTORE_SUCCESS = 'USER_REQUEST_ACTION_RESTORE_SUCCESS';
const USER_REQUEST_ACTION_RESTORE_FAIL = 'USER_REQUEST_ACTION_RESTORE_FAIL';

const USER_REQUEST_ACTION_BLOCK_START = 'USER_REQUEST_ACTION_BLOCK_START';
const USER_REQUEST_ACTION_BLOCK_SUCCESS = 'USER_REQUEST_ACTION_BLOCK_SUCCESS';
const USER_REQUEST_ACTION_BLOCK_FAIL = 'USER_REQUEST_ACTION_BLOCK_FAIL';

const RESTORE_USER_PASSWORD_START = 'RESTORE_USER_PASSWORD_START';
const RESTORE_USER_PASSWORD_SUCCESS = 'RESTORE_USER_PASSWORD_SUCCESS';
const RESTORE_USER_PASSWORD_FAIL = 'RESTORE_USER_PASSWORD_FAIL';

const CHECK_RESTORE_TOKEN_TIME_START = 'CHECK_RESTORE_TOKEN_TIME_START';
const CHECK_RESTORE_TOKEN_TIME_SUCCESS = 'CHECK_RESTORE_TOKEN_TIME_SUCCESS';
const CHECK_RESTORE_TOKEN_TIME_FAIL = 'CHECK_RESTORE_TOKEN_TIME_FAIL';

const CHECK_USER_PASSWORD_START = 'CHECK_USER_PASSWORD_START';
const CHECK_USER_PASSWORD_SUCCESS = 'CHECK_USER_PASSWORD_SUCCESS';
const CHECK_USER_PASSWORD_FAIL = 'CHECK_USER_PASSWORD_FAIL';

export const CHECK_GOOGLE_CAPTCHA_START = 'CHECK_GOOGLE_CAPTCHA_START';
export const CHECK_GOOGLE_CAPTCHA_SUCCESS = 'CHECK_GOOGLE_CAPTCHA_SUCCESS';
export const CHECK_GOOGLE_CAPTCHA_FAIL = 'CHECK_GOOGLE_CAPTCHA_FAIL';

//=================== ACTION CREATORS ======================
export function notAuthorized() {
    return {
        type: NOT_AUTHORIZED
    }
}
export function resetEmailSentStatus() {
  return {
    type: RESET_EMAIL_SENT_STATUS,
  }
}

export function authStart() {
    return{
        type: AUTH_START,
    }
}
export function authSuccess(session_id) {
    return{
        type: AUTH_SUCCESS,
        payload: session_id
    }
}
export function authFail(payload) {
    return{
        type: AUTH_FAIL,
        payload: payload,
    }
}

export function logoutStart() {
  return {
    type: LOG_OUT_START,
  };
}
export function logoutSuccess(payload) {
  return {
    type: LOG_OUT_SUCCESS,
    payload,
  }
}
export function logoutFail(payload) {
  return {
    type: LOG_OUT_FAIL,
    payload,
  }
}

export function setToken(session_id) {
    return{
        type: SET_TOKEN,
        payload: session_id
    }
}

export function setUserID(payload) {
    return {
        type: SET_USER_ID,
        payload,
    }
}

export function changePasswordStart() {
    return {
        type: CHANGE_PASSWORD_START,
    }
}
export function changePasswordSuccess(payload) {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload,
    }
}
export function changePasswordFail(payload) {
    return {
        type: CHANGE_PASSWORD_FAIL,
        payload,
    }
}
export function changePasswordRequirementState(payload) {
    return {
        type: CHANGE_PASSWORD_REQUIREMENT_STATE,
        payload,
    }
}

export function unsetToken(session_id) {
    localStorage.removeItem('session_id');
    localStorage.removeItem('logged_in');
    return{
        type: UNSET_TOKEN,
    }
}

export function getIpAddressStart() {
  return {
    type: GET_IP_ADDRESS_START,
  }
}
export function getIpAddressSuccess(payload) {
  return {
    type: GET_IP_ADDRESS_SUCCESS,
    payload,
  }
}
export function getIpAddressFail(payload) {
  return {
    type: GET_IP_ADDRESS_FAIL,
    payload,
  }
}

export function setNewRequestPermissionStart() {
  return {
    type: SET_NEW_REQUEST_PERMISSION_START,
  }
}
export function setNewRequestPermissionSuccess(payload) {
  return {
    type: SET_NEW_REQUEST_PERMISSION_SUCCESS,
    payload
  }
}
export function setNewRequestPermissionFail(payload) {
  return {
    type: SET_NEW_REQUEST_PERMISSION_FAIL,
    payload
  }
}

export function getNewRequestPermissionStart() {
  return {
    type: GET_NEW_REQUEST_PERMISSION_START,
  }
}
export function getNewRequestPermissionSuccess(payload) {
  return {
    type: GET_NEW_REQUEST_PERMISSION_SUCCESS,
    payload
  }
}
export function getNewRequestPermissionFail(payload) {
  return {
    type: GET_NEW_REQUEST_PERMISSION_FAIL,
    payload
  }
}

export function getUserTypeStart() {
  return {
    type: GET_USER_TYPE_START,
  }
}
export function getUserTypeSuccess(payload) {
  return {
    type: GET_USER_TYPE_SUCCESS,
    payload,
  }
}
export function getUserTypeFail(payload) {
  return {
    type: GET_USER_TYPE_FAIL,
    payload,
  }
}

export function userRequestActionRestoreStart() {
  return {
    type: USER_REQUEST_ACTION_RESTORE_START,
  };
}
export function userRequestActionRestoreSuccess(payload) {
  return {
    type: USER_REQUEST_ACTION_RESTORE_SUCCESS,
    payload,
  };
}
export function userRequestActionRestoreFail(payload) {
  return {
    type: USER_REQUEST_ACTION_RESTORE_FAIL,
    payload,
  };
}

export function userRequestActionBlockStart() {
  return {
    type: USER_REQUEST_ACTION_BLOCK_START,
  };
}
export function userRequestActionBlockSuccess(payload) {
  return {
    type: USER_REQUEST_ACTION_BLOCK_SUCCESS,
    payload,
  }
}
export function userRequestActionBlockFail(payload) {
  return {
    type: USER_REQUEST_ACTION_BLOCK_FAIL,
    payload,
  }
}

export function restoreUserPasswordStart() {
  return {
    type: RESTORE_USER_PASSWORD_START,
  };
}
export function restoreUserPasswordSuccess(payload) {
  return {
    type: RESTORE_USER_PASSWORD_SUCCESS,
    payload,
  }
}
export function restoreUserPasswordFail(payload) {
  return {
    type: RESTORE_USER_PASSWORD_FAIL,
    payload,
  }
}

export function checkRestoreTokenTimeStart() {
  return {
    type: CHECK_RESTORE_TOKEN_TIME_START,
  };
}
export function checkRestoreTokenTimeSuccess(payload) {
  return {
    type: CHECK_RESTORE_TOKEN_TIME_SUCCESS,
    payload,
  };
}
export function checkRestoreTokenTimeFail(payload) {
  return {
    type: CHECK_RESTORE_TOKEN_TIME_FAIL,
    payload,
  };
}

export function checkUserPasswordStart() {
  return {
    type: CHECK_USER_PASSWORD_START,
  };
}
export function checkUserPasswordSuccess(payload) {
  return {
    type: CHECK_USER_PASSWORD_SUCCESS,
    payload,
  };
}
export function checkUserPasswordFail(payload) {
  return {
    type: CHECK_USER_PASSWORD_FAIL,
    payload,
  };
}

export function checkGoogleCaptchaStart() {
  return {
    type: CHECK_GOOGLE_CAPTCHA_START,
  };
}
export function checkGoogleCaptchaSuccess(payload) {
  return {
    type: CHECK_GOOGLE_CAPTCHA_SUCCESS,
    payload,
  };
}
export function checkGoogleCaptchaFail(payload) {
  return {
    type: CHECK_GOOGLE_CAPTCHA_FAIL,
    payload,
  };
}

//=================== thunks ======================
export function authUser(username, pass){
    return (dispatch, getState) => {
        const ipAddress = getState().initReducer.ip_address;

        let params = {
          ipAddress,
          'userName': username,
          'password': pass
        }
        dispatch(authStart());
        commonUtils.apiCall('post','Authorization/AuthorizeByPassword',params)
        // commonUtils.apiCall('get','Info/GetDocumentTypes',{},'asdasdasdasd')
            .then((response)=>{
                if(response.data.result.isAuthorized){
                  // localStorage.setItem('session_id',response.data.result.sessionID);
                  localStorage.setItem('logged_in', '1');
                  localStorage.setItem('user_id',response.data.result.userID);
                  // localStorage.setItem('user_type',response.data.result.userType);
                  localStorage.setItem('market_id',response.data.result.marketID);
                  localStorage.setItem("password_change_requirement", response.data.result.passwordChangeRequirment);
                  localStorage.setItem("contract_id", response.data.result.contractID);
                  localStorage.removeItem('username');
                  dispatch(authSuccess({
                    // session_id: response.data.result.sessionID,
                    logged_in: '1',
                    wrongPasswordsCount: null,
                    user_id: response.data.result.userID,
                    user_type: response.data.result.userType,
                    market_id: response.data.result.marketID,
                    password_change_requirement: response.data.result.passwordChangeRequirment,
                    contract_id: response.data.result.contractID
                  }));
                  history.push('/materials');
                }else {
                    // SignIn error
                    const isBlocked = response.data.result.resultCode === 2;
                    const isCaptchaNeeded = response.data.result.resultCode === 5;
                    if (localStorage.getItem('username') !== username) {
                      localStorage.setItem('username', username);
                    }
                    if (isBlocked) {
                      dispatch(checkUserPassword(username));
                    }
                    dispatch(authFail({
                      error_message: isCaptchaNeeded
                        ? 'Օգտանունը կամ գաղտնաբառը սխալ է։'
                        : !isBlocked ? response.data.result.resultCodeDescription
                        : 'validation-error',
                      isBlocked,
                      isCaptchaNeeded,
                    }))
                }
            })
            .catch(error=>{
                dispatch(authFail({ error_message: 'Կապի խնդիր. խնդրում ենք կրկին փորձել' }))
            })
    }
}

export function logout() {
  return (dispatch, getState) => {
    dispatch(logoutStart());
    const SessionID = getState().initReducer.session_id;

    commonUtils.apiCall('post', 'Authorization/LogOut', { SessionID }, SessionID)
      .then((response) => {
        if (response.data.resultCode === 1) {
          localStorage.clear();
          window.location.reload();
        } else {
          dispatch(logoutFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
        }
      })
      .catch(() => dispatch(logoutFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function changePassword(input_values) {
    return(dispatch, getState) => {
        dispatch(changePasswordStart());

        const ipAddress = getState().initReducer.ip_address;

        let param = {
            newPasswordInfo: {
                ipAddress,
                newPassword: input_values.newPassword,
                password: input_values.password,
                retypePassword: input_values.retypePassword,
            }
        }

        commonUtils.apiCall("post", "Authorization/ChangePassword", param)
            .then((response) => {
                if (response.data.resultCode === 1) {
                    dispatch(changePasswordSuccess());
                    message.success(response.data.result.description);
                    setTimeout(() => {
                      localStorage.clear();
                      window.location.reload();
                    }, 1000);
                }
                if (response.data.resultCode === 3) {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                }
                if (response.data.resultCode === 4) {
                    dispatch(changePasswordFail(response.data.result[0].validationMessage));
                }
            })
            .catch((error) => {
                dispatch(changePasswordFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
            })
    }
}

export function getIpAddress() {
  return (dispatch, getState) => {
    dispatch(getIpAddressStart());

    commonUtils.getIpAddress()
      .then((response) => {
        if (response.data) {
          localStorage.setItem('ip_address', response.data.ip);
          dispatch(getIpAddressSuccess({ip_address: response.data.ip}));
        } else {
          dispatch(getIpAddressFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
        }
      })
      .catch(() => {
        dispatch(getIpAddressFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
      })
  }
}

export function setNewRequestPermission(status) {
  return (dispatch, getState) => {
    dispatch(setNewRequestPermissionStart());

    const param = {
      Allow: status
    };

    commonUtils.apiCall('post', `Info/SetNewRequestPermission`, param)
      .then((response) => {
        if (response.data.resultCode === 1) {
          message.success('ՆՈՐ ՀԱՅՏ ԳԵՆԵՐԱՑՆԵԼՈՒ ԿԱՐԳԱՎԻՃԱԿԸ ՀԱՋՈՂՈՒԹՅԱՄԲ ՓՈՓՈԽՎԵՑ');
          dispatch(setNewRequestPermissionSuccess());
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(setNewRequestPermissionFail(response.data.result[0].validationMessage));
        }
      })
      .catch(() => {
        dispatch(setNewRequestPermissionFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
      });
  }
}

export function getNewRequestPermission() {
  return (dispatch, getState) => {
    dispatch(getNewRequestPermissionStart());

    commonUtils.apiCall('get', 'Info/GetNewRequestPermission', {})
      .then((response) => {
        if (response.data.resultCode === 1) {
          localStorage.setItem('new_request_permission',response.data.result);
          dispatch(getNewRequestPermissionSuccess({status: response.data.result}));
        }
        /*if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }*/
        if (response.data.resultCode === 4) {
          dispatch(getNewRequestPermissionFail(response.data.result[0].validationMessage));
        }
      })
      .catch(() => {
        dispatch(getNewRequestPermissionFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
      });
  }
}

export function getUserType() {
  return (dispatch, getState) => {
    dispatch(getUserTypeStart());

    commonUtils.apiCall('get', 'Info/GetUserType', {})
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getUserTypeSuccess({ userType: response.data.result }));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(getUserTypeFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
        }
      })
      .catch(() => dispatch(getUserTypeFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function userRequestActionBlock({ userName, RequestType }) {
  return (dispatch, getState) => {
    dispatch(userRequestActionBlockStart());

    const IPAddress = getState().initReducer.ip_address;
    const params = {
      RequestType,
      UserDetails: { userName },
      IPAddress,
    };

    commonUtils.apiCall('post', 'Authorization/UserRequestActionsUnauthorized', params)
      .then((response) => {
        if (response.data.resultCode === 1 && response.data.result.resultCode === 1) {
          dispatch(userRequestActionBlockSuccess({ canLogin: false }));
        }
      })
      .catch(() => dispatch(userRequestActionBlockFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function userRequestActionRestore(userName) {
  return (dispatch, getState) => {
    dispatch(userRequestActionRestoreStart());

    const IPAddress = getState().initReducer.ip_address;
    const params = {
      RequestType: 6,
      UserDetails: { userName },
      IPAddress,
    };

    commonUtils.apiCall('post', 'Authorization/UserRequestActionsUnauthorized', params)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            dispatch(userRequestActionRestoreSuccess({ success: true }));
            break;
          case 4:
            dispatch(userRequestActionRestoreFail(response.data.result[0].validationMessage));
            break;
          default:
            break;
        }
      })
      .catch(() => dispatch(userRequestActionRestoreFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')))
  };
}

export function restoreUserPassword(params) {
  return (dispatch, getState) => {
    dispatch(restoreUserPasswordStart());

    commonUtils.apiCall('post', 'Authorization/RestoreUserPassword', params)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            if (response.data.result.resultCode === 3) {
              dispatch(restoreUserPasswordFail(response.data.result.resultCodeDescription));
            } else {
              message.success('Գաղտնաբառը հաջողությամբ փոխվել է');
              dispatch(restoreUserPasswordSuccess({ success: true }));
              history.push('/');
            }
            break;
          case 4:
            dispatch(restoreUserPasswordFail(response.data.result[0].validationMessage));
            break;
          default:
            break;
        }
      })
      .catch(() => dispatch(restoreUserPasswordFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function checkRestoreTokenTime(params) {
  return (dispatch, getState) => {
    dispatch(checkRestoreTokenTimeStart());

    commonUtils.apiCall('post', `Authorization/CheckRestoreTokenTime?UserName=${params.UserName}&TokenHash=${params.Token}`)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            if (response.data.result.resultCode === 3) {
              dispatch(checkRestoreTokenTimeSuccess({ isRestoreLinkValid: false }));
            } else {
              const passedSeconds = +response.data.result.description;
              const delay = (30 * 60 - passedSeconds) * 1000;
              setTimeout(() => dispatch(checkRestoreTokenTimeSuccess({ isRestoreLinkValid: false })), delay);
            }
            break;
          case 4:
            dispatch(checkRestoreTokenTimeFail(response.data.result[0].validationMessage));
            break;
          default:
            break;
        }
      })
      .catch(() => dispatch(checkRestoreTokenTimeFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function checkUserPassword(UserName) {
  return (dispatch, getState) => {
    dispatch(checkUserPasswordStart());

    commonUtils.apiCall('post', `Authorization/CheckUserPassword?UserName=${UserName}`)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            if (response.data.result.resultCode === 3) {
              const secondsAlreadyBlocked = +response.data.result.description;
              const blockedMinutesRemaining = Math.round((900 - secondsAlreadyBlocked) / 60) || 15;
              dispatch(checkUserPasswordSuccess({
                blockedMinutesRemaining
              }));
            } else {
              const isBlocked = false;
              const isCaptchaNeeded = +response.data.result.description === 1;
              /*dispatch(checkUserPasswordSuccess({
                canLogin: true,
                isBlocked,
                isCaptchaNeeded,
                wrongPasswordsCount: 0,
              }));*/
            }
            break;
          case 4:
            dispatch(checkUserPasswordFail(response.data.result[0].validationMessage));
            break;
          default:
            break;
        }
      })
      .catch(() => dispatch(checkUserPasswordFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

export function checkGoogleCaptcha({ Token, userName, password }) {
  return (dispatch, getState) => {
    dispatch(checkGoogleCaptchaStart());

    commonUtils.apiCall('post', `Authorization/CheckGoogleCaptcha?Token=${Token}`)
      .then((response) => {
        if (response.data.resultCode === 1) {
          if (response.data.result === 'True') {
            dispatch(authUser(userName, password));
          } else {
            dispatch(checkGoogleCaptchaFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
          }
        } else {
          dispatch(checkGoogleCaptchaFail(response.data.result));
        }
      })
      .catch(() => dispatch(checkGoogleCaptchaFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
  };
}

//=================== State ======================

const initial_state = {
    error_message: {
      type: '',
      message: '',
    },
    isBlocked: false,
    isCaptchaNeeded: false,
    loading: false,
    isEmailSent: false,
    isRestoreLinkValid: true,
    blockedMinutesRemaining: 15,
    wrongPasswordsCount: localStorage.getItem('wrong_passwords_count'),
    session_id: localStorage.getItem('session_id'),
    logged_in: localStorage.getItem('logged_in'), // can be either 0 or 1
    user_id: localStorage.getItem('user_id'),
    user_type: null,
    market_id: localStorage.getItem('market_id'),
    password_change_requirement: toBoolean(localStorage.getItem("password_change_requirement")),
    ip_address: localStorage.getItem('ip_address'),
    new_request_permission: toBoolean(localStorage.getItem('new_request_permission')),
    contract_id: localStorage.getItem("contract_id"),
    isCalledNewRequestPermission: false
}

export default function initReducer(state=initial_state, action){
    switch (action.type){
        case RESET_EMAIL_SENT_STATUS:
          return produce(state, draft => {
            draft.isEmailSent = false;
          });
        case AUTH_START:
            return produce(state,draft => {
                draft.loading = true;
                if (state.error_message.message !== "") {
                    draft.error_message.message = "";
                }
            })
        case AUTH_SUCCESS:
            return produce(state,draft => {
               // draft.session_id = action.payload.session_id;
                draft.logged_in = action.payload.logged_in;
               draft.loading = false;
               draft.user_id = action.payload.user_id;
               draft.wrongPasswordsCount = action.payload.wrongPasswordsCount;
               draft.user_type = action.payload.user_type;
               draft.market_id = action.payload.market_id;
               draft.password_change_requirement = action.payload.password_change_requirement;
               draft.contract_id = action.payload.contract_id;
               draft.isCalledNewRequestPermission = true;
            })
        case AUTH_FAIL:
            return produce(state, draft => {
                draft.error_message.type = 'AuthError';
                draft.error_message.message = action.payload.error_message;
                draft.wrongPasswordsCount = action.payload.wrongPasswordsCount;
                if (action.payload.hasOwnProperty('isBlocked')) {
                  draft.isBlocked = action.payload.isBlocked;
                }
                if (action.payload.hasOwnProperty('isCaptchaNeeded')) {
                  draft.isCaptchaNeeded = action.payload.isCaptchaNeeded;
                }
                draft.loading = false;
            })
        case SET_TOKEN:
            return produce(state,draft => {
                draft.session_id = action.payload;
            })
        case SET_USER_ID:
            return produce(state, draft => {
                draft.user_id = action.payload;
            })
        case UNSET_TOKEN:
            return produce(state,draft => {
                draft.session_id = null;
                draft.logged_in = null;
            })
        case CHANGE_PASSWORD_START:
            return produce(state, draft => {
                draft.loading = true;
                if (state.error_message.message !== "") {
                  draft.error_message.message = "";
                }
            })
        case CHANGE_PASSWORD_SUCCESS:
            return produce(state, draft => {
              draft.loading = false;
            })
        case CHANGE_PASSWORD_FAIL:
            return produce(state, draft => {
                draft.error_message.type = 'ChangePass';
                draft.error_message.message = action.payload;
                draft.loading = false;
            })
        case CHANGE_PASSWORD_REQUIREMENT_STATE:
            return produce(state, draft => {
                draft.password_change_requirement = toBoolean(action.payload);
                localStorage.setItem("password_change_requirement", action.payload);
            })
        case GET_IP_ADDRESS_START:
          return produce(state, draft => {

          })
        case GET_IP_ADDRESS_SUCCESS:
          return produce(state, draft => {
            draft.ip_address = action.payload.ip_address;
          })
        case GET_IP_ADDRESS_FAIL:
          return produce(state, draft => {
            draft.error_message.type = 'GetIPAddress';
            draft.error_message.message = action.payload;
          })
        case SET_NEW_REQUEST_PERMISSION_START:
          return produce(state, draft => {
            draft.loading = true;
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          })
        case SET_NEW_REQUEST_PERMISSION_SUCCESS:
          return produce(state, draft => {
            draft.loading = false;
          })
        case SET_NEW_REQUEST_PERMISSION_FAIL:
          return produce(state, draft => {
            draft.loading = false;
            draft.error_message.type = 'NewRequestPermission';
            draft.error_message.message = action.payload;
          })
        case GET_NEW_REQUEST_PERMISSION_START:
          return produce(state, draft => {

          })
        case GET_NEW_REQUEST_PERMISSION_SUCCESS:
          return produce(state, draft => {
            draft.new_request_permission = toBoolean(action.payload.status);
          })
        case GET_NEW_REQUEST_PERMISSION_FAIL:
          return produce(state, draft => {

          })
        case INIT_APP_SUCCESS:
          return produce(state, draft => {
            action.payload.loaded_data.hasOwnProperty("newRequestStatus") && (draft.new_request_permission = action.payload.loaded_data.newRequestStatus);
          })
        case NEW_IDENTIFICATION_SUCCESS:
          return produce(state, draft => {
            draft.new_request_permission = action.payload.newRequestStatus;
          })
        case GET_USER_TYPE_SUCCESS:
          return produce(state, draft => {
            draft.user_type = action.payload.userType;
          });
        case LOG_OUT_START:
          return produce(state, draft => {
            draft.error_message.type = '';
            draft.error_message.message = '';
          });
        case LOG_OUT_FAIL:
          return produce(state, draft => {
            draft.error_message.type = 'LogOut';
            draft.error_message.message = action.payload;
          });
        case USER_REQUEST_ACTION_RESTORE_START:
          return produce(state, draft => {
            draft.loading = true;
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case USER_REQUEST_ACTION_RESTORE_SUCCESS:
          return produce(state, draft => {
            draft.loading = false;
            draft.isEmailSent = true;
          });
        case USER_REQUEST_ACTION_RESTORE_FAIL:
          return produce(state, draft => {
            draft.loading = false;
            draft.error_message.type = 'UserRequestActionRestore';
            draft.error_message.message = action.payload;
          });
        case RESTORE_USER_PASSWORD_START:
          return produce(state, draft => {
            draft.loading = true;
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case RESTORE_USER_PASSWORD_SUCCESS:
          return produce(state, draft => {
            draft.loading = false;
          });
        case RESTORE_USER_PASSWORD_FAIL:
          return produce(state, draft => {
            draft.loading = false;
            draft.error_message.type = 'RestoreUserPassword';
            draft.error_message.message = action.payload;
          });
        case CHECK_RESTORE_TOKEN_TIME_START:
          return produce(state, draft => {
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case CHECK_RESTORE_TOKEN_TIME_SUCCESS:
          return produce(state, draft => {
            draft.isRestoreLinkValid = action.payload.isRestoreLinkValid;
          });
        case CHECK_RESTORE_TOKEN_TIME_FAIL:
          return produce(state, draft => {
            draft.error_message.type = 'CheckRestoreTokenTime';
            draft.error_message.message = action.payload;
          });
        case CHECK_USER_PASSWORD_START:
          return produce(state, draft => {
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case CHECK_USER_PASSWORD_SUCCESS:
          return produce(state, draft => {
            if (action.payload.hasOwnProperty('canLogin')) {
              draft.canLogin = action.payload.canLogin;
            }
            if (action.payload.hasOwnProperty('wrongPasswordsCount')) {
              draft.wrongPasswordsCount = action.payload.wrongPasswordsCount;
            }
            if (action.payload.hasOwnProperty('isBlocked')) {
              draft.isBlocked = action.payload.isBlocked;
            }
            if (action.payload.hasOwnProperty('isCaptchaNeeded')) {
              draft.isCaptchaNeeded = action.payload.isCaptchaNeeded;
            }
            draft.blockedMinutesRemaining = action.payload?.blockedMinutesRemaining;
          });
        case CHECK_USER_PASSWORD_FAIL:
          return produce(state, draft => {
            draft.error_message.type = 'CheckUserPassword';
            draft.error_message.message = action.payload;
          });
        case USER_REQUEST_ACTION_BLOCK_START:
          return produce(state, draft => {
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case USER_REQUEST_ACTION_BLOCK_SUCCESS:
          return produce(state, draft => {
            draft.canLogin = action.payload.canLogin;
          });
        case USER_REQUEST_ACTION_BLOCK_FAIL:
          return produce(state, draft => {
            draft.error_message.type = 'UserRequestActionBlock';
            draft.error_message.message = action.payload;
          });
        case CHECK_GOOGLE_CAPTCHA_START:
          return produce(state, draft => {
            draft.loading = true;
            if (state.error_message.message !== "") {
              draft.error_message.message = "";
            }
          });
        case CHECK_GOOGLE_CAPTCHA_SUCCESS:
          return produce(state, draft => {
            draft.loading = false;
          });
        case CHECK_GOOGLE_CAPTCHA_FAIL:
          return produce(state, draft => {
            draft.loading = false;
            draft.error_message.type = 'CheckGoogleCaptcha';
            draft.error_message.message = action.payload;
          });
      default:
          return state;
    }
};
