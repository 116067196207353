import commonUtils from "../utils/CommonUtils";
import produce from 'immer';
import moment from "moment";
import history from "../history";
import {unsetToken} from "./initReducer";
import {message} from "antd";
import toBoolean from "../utils/toBoolean";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = "NOT_AUTHORIZED";

const INIT_APP_START = 'INIT_APP_START';
const INIT_APP_FAIL = 'INIT_APP_FAIL';
const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS';
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
const CLEAR_REQUEST_STATUS = 'CLEAR_REQUEST_STATUS';
const GET_MAX_LOAN_START = 'GET_MAX_LOAN_START';
const GET_MAX_LOAN_SUCCESS = "GET_MAX_LOAN_SUCCESS";
const GET_MAX_LOAN_FAIL = "GET_MAX_LOAN_FAIL";
const CHANGE_CUSTOMER_PERIOD = "CHANGE_CUSTOMER_PERIOD";
const GET_WORK_REFERENCE_TYPES_START = "GET_WORK_REFERENCE_TYPES_START";
const GET_WORK_REFERENCE_TYPES_SUCCESS = "GET_WORK_REFERENCE_TYPES_SUCCESS";
const GET_WORK_REFERENCE_TYPES_FAIL = "GET_WORK_REFERENCE_TYPES_FAIL";
const IDENTIFICATION_START = 'IDENTIFICATION_START';
const IDENTIFICATION_SUCCESS = 'IDENTIFICATION_SUCCESS';
const IDENTIFICATION_FAIL = 'IDENTIFICATION_FAIL';

const NEW_IDENTIFICATION_START = 'NEW_IDENTIFICATION_START';
const NEW_IDENTIFICATION_SUCCESS = 'NEW_IDENTIFICATION_SUCCESS';
const NEW_IDENTIFICATION_FAIL = 'NEW_IDENTIFICATION_FAIL';

const EDIT_REQUEST_TERMS = 'EDIT_REQUEST_TERMS';
const EDIT_USER_INFO = 'EDIT_USER_INFO';
const STREET_SEARCH_START = 'STREET_SEARCH_START';
const STREET_SEARCH = 'STREET_SEARCH';
const STREET_SEARCH_FAIL = 'STREET_SEARCH_FAIL';
const STREET_SEARCH_SELECT = 'SEARCH_SELECT';

const GET_REGIONS_START = 'GET_REGIONS_START';
const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
const GET_REGIONS_FAIL = 'GET_REGIONS_FAIL';

const GET_ALL_STREETS_START = 'GET_ALL_STREETS_START';
const GET_ALL_STREETS_SUCCESS = 'GET_ALL_STREETS_SUCCESS';
const GET_ALL_STREETS_FAIL = 'GET_ALL_STREETS_FAIL';

const CLEAR_RESIDENCE_ADDRESS = 'CLEAR_RESIDENCE_ADDRESS';
const CONFIRM_CUSTOMER_INFO = 'CONFIRM_CUSTOMER_INFO';
const SAVE_CUSTOMER_INFO_START = 'SAVE_CUSTOMER_INFO_START';
const SAVE_CUSTOMER_INFO_SUCCESS = 'SAVE_CUSTOMER_INFO_SUCCESS';
const SAVE_CUSTOMER_INFO_FAIL = 'SAVE_CUSTOMER_INFO_FAIL';
const DELETE_USER_INCOME = 'DELETE_USER_INCOME';
const CALCULATE_REQUEST_START = 'CALCULATE_REQUEST_START';
const CALCULATE_REQUEST_SUCCESS = 'CALCULATE_REQUEST_SUCCESS';
const CALCULATE_REQUEST_FAIL = 'CALCULATE_REQUEST_FAIL';
const GET_CLEAR_SALARY_START = 'GET_CLEAR_SALARY_START';
const GET_CLEAR_SALARY_SUCCESS = 'GET_CLEAR_SALARY_SUCCESS';
const GET_CLEAR_SALARY_FAIL = 'GET_CLEAR_SALARY_FAIL';
const RESET_CLEAR_SALARY = 'RESET_CLEAR_SALARY';
const FILL_USER_INCOMES = 'FILL_USER_INCOMES';
const FILL_USER_INCOMES_START = 'FILL_USER_INCOMES_START';
const FILL_USER_INCOMES_SUCCESS = 'FILL_USER_INCOMES_SUCCESS';
const FILL_USER_INCOMES_FAIL = 'FILL_USER_INCOMES_FAIL';

const OFFER_TERMS_LOAD_START = 'OFFER_TERMS_LOAD_START';
const OFFER_TERMS_LOAD_SUCCESS = 'OFFER_TERMS_LOAD_SUCCESS';
const OFFER_INIT_LOAD_START = 'OFFER_INIT_LOAD_START';
const OFFER_INIT_LOAD_SUCCESS = 'OFFER_INIT_LOAD_SUCCESS';
const OFFER_INIT_LOAD_FAIL= 'OFFER_INIT_LOAD_FAIL';

const HANDLE_USER_INCOME = 'HANDLE_USER_INCOME';
const SHOW_INCOME_MODAL = 'SHOW_INCOME_MODAL';
const SEND_TO_BANK_START = 'SEND_TO_BANK_START';
const SEND_TO_BANK_SUCCESS = 'SEND_TO_BANK_SUCCESS';
const SEND_TO_BANK_FAIL = 'SEND_TO_BANK_FAIL';

const GUARANTOR_IDENTIFICATION_START = 'GUARANTOR_IDENTIFICATION_START';
const GUARANTOR_IDENTIFICATION_SUCCESS = 'GUARANTOR_IDENTIFICATION_SUCCESS';
const GUARANTOR_IDENTIFICATION_FAIL = 'GUARANTOR_IDENTIFICATION_FAIL';

const GUARANTOR_SAVE_START = 'GUARANTOR_SAVE_START';
const GUARANTOR_SAVE_SUCCESS = 'GUARANTOR_SAVE_SUCCESS';
const GUARANTOR_SAVE_FAIL = 'GUARANTOR_SAVE_FAIL';

const LOAN_TERMS_INITIAL_LOAD_START = 'LOAN_TERMS_INITIAL_LOAD_START';
const LOAN_TERMS_INITIAL_LOAD_SUCCESS = 'LOAN_TERMS_INITIAL_LOAD_SUCCESS';
const LOAN_TERMS_INITIAL_LOAD_FAIL = 'LOAN_TERMS_INITIAL_LOAD_FAIL';

const CONFIRM_LOAN_TERMS_START = 'CONFIRM_LOAN_TERMS_START';
const CONFIRM_LOAN_TERMS_SUCCESS = 'CONFIRM_LOAN_TERMS_SUCCESS';
const CONFIRM_LOAN_TERMS_FAIL = 'CONFIRM_LOAN_TERMS_FAIL';

const DOWNLOAD_ALL_CONTRACTS_START = 'DOWNLOAD_ALL_CONTRACTS_START';
const DOWNLOAD_ALL_CONTRACTS_SUCCESS = 'DOWNLOAD_ALL_CONTRACTS_SUCCESS';
const DOWNLOAD_ALL_CONTRACTS_FAIL = 'DOWNLOAD_ALL_CONTRACTS_FAIL';

const GET_PRIZE_OFFER_START = 'GET_PRIZE_OFFER_START';
const GET_PRIZE_OFFER_SUCCESS = 'GET_PRIZE_OFFER_SUCCESS';
const GET_PRIZE_OFFER_FAIL = 'GET_PRIZE_OFFER_FAIL';

const LOAD_ACRA_AGREEMENT_START = 'LOAD_ACRA_AGREEMENT_START';
const LOAD_ACRA_AGREEMENT_SUCCESS = 'LOAD_ACRA_AGREEMENT_SUCCESS';
const LOAD_ACRA_AGREEMENT_FAIL = 'LOAD_ACRA_AGREEMENT_FAIL';

const GET_REFUSE_REASON_TYPES_START = 'GET_REFUSE_REASON_TYPES_START';
const GET_REFUSE_REASON_TYPES_SUCCESS = 'GET_REFUSE_REASON_TYPES_SUCCESS';
const GET_REFUSE_REASON_TYPES_FAIL = 'GET_REFUSE_REASON_TYPES_FAIL';

const CANCEL_REQUEST_START = 'CANCEL_REQUEST_START';
const CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS';
const CANCEL_REQUEST_FAIL = 'CANCEL_REQUEST_FAIL';

const CUSTOMER_SUM_NEED_ON_CHANGE = 'CUSTOMER_SUM_NEED_ON_CHANGE';

const CHANGE_CUSTOMER_LOAN_ACTION = 'CHANGE_CUSTOMER_LOAN_ACTION';

const SAVE_REQUEST_ITEMS_START = 'SAVE_REQUEST_ITEMS_START';
const SAVE_REQUEST_ITEMS_SUCCESS = 'SAVE_REQUEST_ITEMS_SUCCESS';
const SAVE_REQUEST_ITEMS_FAIL = 'SAVE_REQUEST_ITEMS_FAIL';

const GET_EMPLOYER_TYPE_SUCCESS = 'GET_EMPLOYER_TYPE_SUCCESS';
const GET_EMPLOYER_TYPE_FAIL = 'GET_EMPLOYER_TYPE_FAIL';

const CHANGE_EDIT_STATUS = 'CHANGE_EDIT_STATUS';

const GET_ARMENIAN_PLACES_BY_REGION_ID_START = "GET_ARMENIAN_PLACES_BY_REGION_ID_START";
const GET_ARMENIAN_PLACES_BY_REGION_ID_SUCCESS = "GET_ARMENIAN_PLACES_BY_REGION_ID_SUCCESS";
const GET_ARMENIAN_PLACES_BY_REGION_ID_FAIL = "GET_ARMENIAN_PLACES_BY_REGION_ID_FAIL";

const GET_MULTIPLE_WORK_REFERENCE_TYPES_START = "GET_MULTIPLE_WORK_REFERENCE_TYPES_START";
const GET_MULTIPLE_WORK_REFERENCE_TYPES_SUCCESS = "GET_MULTIPLE_WORK_REFERENCE_TYPES_SUCCESS";
const GET_MULTIPLE_WORK_REFERENCE_TYPES_FAIL = "GET_MULTIPLE_WORK_REFERENCE_TYPES_FAIL";

const RESTORE_CANCEL_REQUEST_START = "RESTORE_CANCEL_REQUEST_START";
const RESTORE_CANCEL_REQUEST_SUCCESS = "RESTORE_CANCEL_REQUEST_SUCCESS";
const RESTORE_CANCEL_REQUEST_FAIL = "RESTORE_CANCEL_REQUEST_FAIL";
//=================== ACTION CREATORS ======================
export function notAuthorized() {
    return {
        type: NOT_AUTHORIZED
    }
}

export function initAppSuccess(payload) {
    return{
        type: INIT_APP_SUCCESS,
        payload: payload
    }
}
export function initAppStart() {
    return{
        type: INIT_APP_START,
    }
}
export function initAppFail(error_message) {
    return{
        type: INIT_APP_FAIL,
        payload: error_message
    }
}

export function clearErrorMessage() {
    return {
        type: CLEAR_ERROR_MESSAGE,
    }
}
export function clearRequestStatus() {
    return {
        type: CLEAR_REQUEST_STATUS,
    }
}

export function getMaxLoanStart() {
    return {
        type: GET_MAX_LOAN_START,
    }
}
export function getMaxLoanSuccess(payload) {
    return {
        type: GET_MAX_LOAN_SUCCESS,
        payload: payload
    }
}
export function getMaxLoanFail(payload) {
    return {
        type: GET_MAX_LOAN_FAIL,
        payload,
    }
}

export function customerPeriodOnChange(payload) {
    return {
        type: CHANGE_CUSTOMER_PERIOD,
        payload,
    }
}

export function getWorkReferenceTypesStart() {
    return{
        type: GET_WORK_REFERENCE_TYPES_START,
    }
}
export function getWorkReferenceTypesSuccess(payload) {
    return{
        type: GET_WORK_REFERENCE_TYPES_SUCCESS,
        payload: payload
    }
}
export function getWorkReferenceTypesFail(payload) {
    return{
        type: GET_WORK_REFERENCE_TYPES_FAIL,
        payload: payload
    }
}

export function identificationStart() {
    return{
        type: IDENTIFICATION_START,
    }
}
export function identificationSuccess(payload) {
    return{
        type: IDENTIFICATION_SUCCESS,
        payload: payload
    }
}
export function identificationFail(payload) {
    return {
        type: IDENTIFICATION_FAIL,
        payload: payload
    }
}

export function newIdentificationStart() {
    return {
        type: NEW_IDENTIFICATION_START,
    }
}
export function newIdentificationSuccess(payload) {
    return {
        type: NEW_IDENTIFICATION_SUCCESS,
        payload
    }
}
export function newIdentificationFail(payload) {
    return {
        type: NEW_IDENTIFICATION_FAIL,
        payload
    }
}

export function editRequestTerms() {
    return {
        type: EDIT_REQUEST_TERMS,
    }
}
export function editUserInfo() {
    return {
        type: EDIT_USER_INFO,
    }
}

export function confirmCustomerInfo(payload) {
    return {
        type: CONFIRM_CUSTOMER_INFO,
        payload,
    }
}
export function saveCustomerInfoStart() {
    return {
        type: SAVE_CUSTOMER_INFO_START
    }
}
export function saveCustomerInfoSuccess(payload) {
    return {
        type: SAVE_CUSTOMER_INFO_SUCCESS,
        payload: payload
    }
}
export function saveCustomerInfoFail(payload) {
    return {
        type:  SAVE_CUSTOMER_INFO_FAIL,
        payload,
    }
}
export function deleteUserIncome(payload) {
    return {
        type: DELETE_USER_INCOME,
        payload,
    }
}

export function streetSearchStart() {
    return {
        type: STREET_SEARCH_START,
    }
}
export function streetSearchFail(payload) {
    return {
        type: STREET_SEARCH_FAIL,
        payload
    }
}
export function streetSearch(payload) {
    return{
        type: STREET_SEARCH,
        payload: payload
    }
}
export function searchSelect(data, id, type) {
    let payload = {
        id: id,
        type,
        found_street: data,
    };
    return {
        type: STREET_SEARCH_SELECT,
        payload,
    }
}
export function clearResidenceAddress() {
    return {
        type: CLEAR_RESIDENCE_ADDRESS
    }
}

export function getRegionsStart() {
  return {
    type: GET_REGIONS_START
  }
}
export function getRegionsSuccess(payload) {
  return {
    type: GET_REGIONS_SUCCESS,
    payload
  }
}
export function getRegionsFail(payload) {
  return {
    type: GET_REGIONS_FAIL,
    payload
  }
}

export function getAllStreetsStart() {
  return {
    type: GET_ALL_STREETS_START
  }
}

export function getAllStreetsSuccess(payload) {
  return {
    type: GET_ALL_STREETS_SUCCESS,
    payload
  }
}

export function getAllStreetsFail(payload) {
  return {
    type: GET_ALL_STREETS_FAIL,
    payload
  }
}

export function calculateRequestOfferStart() {
    return{
        type: CALCULATE_REQUEST_START,
    }
}
export function calculateRequestOfferSuccess(payload) {
    return {
        type: CALCULATE_REQUEST_SUCCESS,
        payload: payload,
    }
}
export function calculateRequestOfferFail(payload) {
    return {
        type: CALCULATE_REQUEST_FAIL,
        payload,
    }
}

export function getClearSalaryStart() {
    return {
        type: GET_CLEAR_SALARY_START,
    }
}
export function getClearSalarySuccess(payload) {
    return {
        type: GET_CLEAR_SALARY_SUCCESS,
        payload: payload,
    }
}
export function getClearSalaryFail(payload) {
    return {
        type: GET_CLEAR_SALARY_FAIL,
        payload,
    }
}
export function resetClearSalary() {
    return {
        type: RESET_CLEAR_SALARY,
    }
}

/*export function fillUserIncomes(payload) {
    return {
        type: FILL_USER_INCOMES,
        payload
    }
}*/
export function fillUserIncomesStart() {
  return{
    type: FILL_USER_INCOMES_START
  };
}
export function fillUserIncomesSuccess(payload) {
  return {
    type: FILL_USER_INCOMES_SUCCESS,
    payload
  }
}
export function fillUserIncomesFail(payload) {
  return {
    type: FILL_USER_INCOMES_FAIL,
    payload
  }
}

export function offerInitLoadStart() {
    return {
        type: OFFER_INIT_LOAD_START,
    }
}
export function offerInitLoadSuccess(payload) {
    return {
        type: OFFER_INIT_LOAD_SUCCESS,
        payload,
    }
}
export function offerInitLoadFail(payload) {
    return {
        type: OFFER_INIT_LOAD_FAIL,
        payload,
    }
}

export function UserIncomeChange(payload) {
    return {
        type: HANDLE_USER_INCOME,
        payload
    }
}

export function showIncomesModal(payload) {
    return {
        type: SHOW_INCOME_MODAL,
        payload
    }
}

export function sendToBankStart() {
    return {
        type: SEND_TO_BANK_START,
    }
}
export function sendToBankSuccess(payload) {
    return {
        type: SEND_TO_BANK_SUCCESS,
        payload,
    }
}
export function sendToBankFail(payload) {
    return {
        type: SEND_TO_BANK_FAIL,
        payload
    }
}

export  function guarantorIdentificationStart() {
    return {
        type: GUARANTOR_IDENTIFICATION_START,
    }
}
export  function guarantorIdentificationSuccess(payload) {
    return {
        type: GUARANTOR_IDENTIFICATION_SUCCESS,
        payload: payload
    }
}
export  function guarantorIdentificationFail(payload) {
    return {
        type: GUARANTOR_IDENTIFICATION_FAIL,
        payload: payload
    }
}

export  function guarantorSaveStart() {
    return {
        type: GUARANTOR_SAVE_START,
    }
}
export  function guarantorSaveSuccess(payload) {
    return {
        type: GUARANTOR_SAVE_SUCCESS,
        payload,
    }
}
export  function guarantorSaveFail(payload) {
    return {
        type: GUARANTOR_SAVE_FAIL,
        payload,
    }
}

export function loanTermsInitialLoadStart() {
    return {
        type: LOAN_TERMS_INITIAL_LOAD_START,
    }
}
export function loanTermsInitialLoadSuccess(payload) {
    return {
        type: LOAN_TERMS_INITIAL_LOAD_SUCCESS,
        payload
    }
}
export function loanTermsInitialLoadFail(payload) {
    return {
        type: LOAN_TERMS_INITIAL_LOAD_FAIL,
        payload
    }
}

export function confirmLoanTermsStart() {
    return {
        type: CONFIRM_LOAN_TERMS_START,
    }
}
export function confirmLoanTermsSuccess() {
    return {
        type: CONFIRM_LOAN_TERMS_SUCCESS,
    }
}
export function confirmLoanTermsFail(payload) {
    return {
        type: CONFIRM_LOAN_TERMS_FAIL,
        payload,
    }
}

export function downloadAllContractsStart() {
    return {
        type: DOWNLOAD_ALL_CONTRACTS_START,
    }
}
export function downloadAllContractsSuccess(payload) {
    return {
        type: DOWNLOAD_ALL_CONTRACTS_SUCCESS,
        payload
    }
}
export function downloadAllContractsFail(payload) {
    return {
        type: DOWNLOAD_ALL_CONTRACTS_FAIL,
        payload,
    }
}

export function getPrizeOfferStart() {
    return {
        type: GET_PRIZE_OFFER_START,
    }
}
export function getPrizeOfferSuccess(payload) {
    return {
        type: GET_PRIZE_OFFER_SUCCESS,
        payload,
    }
}
export function getPrizeOfferFail(payload) {
    return {
        type: GET_PRIZE_OFFER_FAIL,
        payload,
    }
}

export function loadAcraAgreementStart() {
    return {
        type: LOAD_ACRA_AGREEMENT_START,
    }
}
export function loadAcraAgreementSuccess(payload) {
    return {
        type: LOAD_ACRA_AGREEMENT_SUCCESS,
        payload,
    }
}
export function loadAcraAgreementFail(payload) {
    return {
        type: LOAD_ACRA_AGREEMENT_FAIL,
        payload,
    }
}

export function getRefuseReasonTypesStart() {
    return {
        type: GET_REFUSE_REASON_TYPES_START,
    }
}
export function getRefuseReasonTypesSuccess(payload) {
    return {
        type: GET_REFUSE_REASON_TYPES_SUCCESS,
        payload,
    }
}
export function getRefuseReasonTypesFail(payload) {
    return {
        type: GET_REFUSE_REASON_TYPES_FAIL,
        payload,
    }
}

export function cancelRequestStart() {
    return {
        type: CANCEL_REQUEST_START,
    }
}
export function cancelRequestSuccess(payload) {
    return {
        type: CANCEL_REQUEST_SUCCESS,
        payload,
    }
}
export function cancelRequestFail(payload) {
    return {
        type: CANCEL_REQUEST_FAIL,
        payload
    }
}

export function changeCustomerSumNeed(payload) {
    return {
        type: CUSTOMER_SUM_NEED_ON_CHANGE,
        payload,
    }
}
export function changeCustomerLoanAction(payload) {
    return {
        type: CHANGE_CUSTOMER_LOAN_ACTION,
        payload,
    }
}

export function saveRequestItemsStart() {
    return {
        type: SAVE_REQUEST_ITEMS_START,
    }
}
export function saveRequestItemsSuccess(payload) {
    return {
        type: SAVE_REQUEST_ITEMS_SUCCESS,
        payload,
    }
}
export function saveRequestItemsFail(payload) {
    return {
        type: SAVE_REQUEST_ITEMS_FAIL,
        payload,
    }
}

export function getEmployerTypesSuccess(payload) {
    return {
        type: GET_EMPLOYER_TYPE_SUCCESS,
        payload,
    }
}
export function getEmployerTypesFail(payload) {
    return {
        type: GET_EMPLOYER_TYPE_FAIL,
        payload,
    }
}

export function changeEditStatus() {
    return {
        type: CHANGE_EDIT_STATUS,
    }
}

export function getArmenianPlacesByRegionIdStart() {
    return {
        type: GET_ARMENIAN_PLACES_BY_REGION_ID_START
    }
}

export function getArmenianPlacesByRegionIdSuccess(payload) {
    return {
        type: GET_ARMENIAN_PLACES_BY_REGION_ID_SUCCESS,
        payload
    }
}

export function getArmenianPlacesByRegionIdFail(payload) {
    return {
        type: GET_ARMENIAN_PLACES_BY_REGION_ID_FAIL,
        payload
    }
}

export function getMultipleWorkReferenceTypesStart() {
    return {
        type: GET_MULTIPLE_WORK_REFERENCE_TYPES_START,
    }
}

export function getMultipleWorkReferenceTypesSuccess(payload) {
    return {
        type: GET_MULTIPLE_WORK_REFERENCE_TYPES_SUCCESS,
        payload
    }
}

export function getMultipleWorkReferenceTypesFail(payload) {
    return {
        type: GET_MULTIPLE_WORK_REFERENCE_TYPES_FAIL,
        payload
    }
}

export function restoreCancelRequestStart() {
  return {
    type: RESTORE_CANCEL_REQUEST_START,
  }
}
export function restoreCancelRequestSuccess(payload) {
  return {
    type: RESTORE_CANCEL_REQUEST_SUCCESS,
    payload
  }
}
export function restoreCancelRequestFail(payload) {
  return {
    type: RESTORE_CANCEL_REQUEST_FAIL,
    payload,
  }
}
//=================== thunks ======================
export function newIdentification() {
    return (dispatch, getState) => {
        dispatch(newIdentificationStart());
        const session_id = getState().initReducer.session_id;

        commonUtils.apiCall("get", "Info/GetNewRequestPermission", {}, session_id)
          .then(response => {
            if (response.data.resultCode === 1) {
              localStorage.setItem("new_request_permission", response.data.result);
              dispatch(newIdentificationSuccess({newRequestStatus: response.data.result}));

              if (response.data.result) {
                history.push("/");
              }
              else {
                history.push("/applications");
                message.error("Այս պահին նոր հայտ հնարավոր չէ մուտքագրել", 5)
              }
            }
            if (response.data.resultCode === 3) {
              history.push('/signin');
              dispatch(unsetToken());
              dispatch(notAuthorized());
            }
          })
          .catch(() => {
            dispatch(newIdentificationFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
          });
    }
}

export function getMultipleWorkReferenceTypes(profit_types) {
    return (dispatch, getState) => {
        dispatch(getMultipleWorkReferenceTypesStart());

        const session_id = getState().initReducer.session_id;

        let api_calls = [];

        profit_types.map((profit_type) => {
            api_calls.push(
              commonUtils.apiCall('get', `Info/GetWorkReferenceType?profitType=${profit_type}`, {}, session_id)
            )
        });

        let referenceTypes = [];

        Promise.all(api_calls)
            .then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    if (responses[i].data.resultCode === 1) {
                        referenceTypes.push(responses[i].data.result);
                    }
                }

                let neededFormattedArray = [];

                for (let i = 0; i < referenceTypes.length; i++) {
                    let tmpArray = [];
                    for (let key in referenceTypes[i]) {
                        if (referenceTypes[i].hasOwnProperty(key)) {
                            tmpArray.push({
                                value: key,
                                label: referenceTypes[i][key]
                            });
                        }
                    }
                    neededFormattedArray.push(tmpArray);
                }

                dispatch(getMultipleWorkReferenceTypesSuccess({reference_types: neededFormattedArray}));
            })
            .catch((error) => {
                dispatch(getMultipleWorkReferenceTypesFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
            });
    }
}

export function initLoad(request='') {
  return (dispatch, getState) => {
    dispatch(initAppStart());

    const {isNewRequestPermissionCalledFromNewIdentification} = getState().applicationReducer;
    const session_id = getState().initReducer.session_id;
    const request_id = request;
    let loaded_data = {};

    if (!request_id && !isNewRequestPermissionCalledFromNewIdentification) {
      commonUtils.apiCall("get", "Info/GetNewRequestPermission", {}, session_id)
        .then(newRequestResponse => {
          if (newRequestResponse.data.resultCode === 1) {
            if (newRequestResponse.data.result) {
              commonUtils.apiCall('post', 'Authorization/CheckToken', {}, session_id)
                .then((authorizationTokenResponse) => {
                  if (authorizationTokenResponse.data.result) {
                    //preparing required API call to make a simultaneous requests
                    let api_calls = [];

                    if (Object.entries(getState().applicationReducer.dictionary.actions).length === 0 && getState().applicationReducer.dictionary.actions.constructor === Object) {
                      api_calls.push(
                        commonUtils.apiCall('get','Info/GetActions',{},session_id),
                      )
                    }

                    //user indentification related dictionaries
                    if(Object.entries(getState().applicationReducer.dictionary.document_types).length === 0 && getState().applicationReducer.dictionary.document_types.constructor === Object){
                      api_calls.push(
                        commonUtils.apiCall('get','Info/GetDocumentTypes',{},session_id),
                        commonUtils.apiCall('get','Info/GetBuildingTypes',{},session_id),
                        commonUtils.apiCall('get','Info/GetCountries',{},session_id),
                        // commonUtils.apiCall('get','Info/GetRegions',{countryId: 51},session_id),
                      )
                    }

                    //user incomes related dictionaries
                    if(Object.entries(getState().applicationReducer.dictionary.work_profit_type).length === 0 && getState().applicationReducer.dictionary.work_profit_type.constructor === Object) {
                      api_calls.push(
                        commonUtils.apiCall('get', 'Info/GetWorkProfitType', {}, session_id),  //ԵԿԱՄՏԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                        commonUtils.apiCall('get', 'Info/GetWorkPositionType', {}, session_id),  //ՊԱՇՏՈՆՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                        commonUtils.apiCall('get', 'Info/GetEmployerGroups', {}, session_id),  //ԱՇԽԱՏԱՎԱՅՐԵՐԻ ՏԵՂԵԿԱՏՈՒ
                      )
                    }

                    //user data, if request already exist in ACBA database
                    if(request_id != 0 && request_id !== "" && getState().applicationReducer.customer_info_for_save.request.requestID === 0) {
                      api_calls.push(
                        commonUtils.apiCall('post', 'Person/GetCustomerAndRequestInfo', {"requestID": request_id}, session_id),
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id)
                      )
                    }

                    if (api_calls.length > 0) {
                      Promise.all(api_calls)
                        .then((responses)=>{
                          //iterate through responses and check data
                          for (let i = 0; i < responses.length; i++) {
                            if (responses[i].data.resultCode === 1) {
                              let call_method = responses[i].config.url.split('/').slice(-1)[0];
                              switch (call_method) {
                                case 'GetDocumentTypes':
                                  if (!Array.isArray(responses[i].data.result)) {
                                    let document_types_arr = [];
                                    for (let key in responses[i].data.result) {
                                      if (responses[i].data.result.hasOwnProperty(key)) {
                                        document_types_arr.push({
                                          value: key,
                                          label: responses[i].data.result[key]
                                        });
                                      }
                                    }
                                    loaded_data.document_types = document_types_arr;
                                  }
                                  else {
                                    let document_types_arr = [];
                                    responses[i].data.result.map((element, index) => {
                                      document_types_arr.push({
                                        value: element.key,
                                        label: element.value
                                      });
                                    });
                                    loaded_data.document_types = document_types_arr;
                                  }
                                  break;
                                case 'GetBuildingTypes':
                                  let building_types_arr = [];
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      building_types_arr.push({
                                        value: key,
                                        label: responses[i].data.result[key]
                                      });
                                    }
                                  }
                                  loaded_data.building_types = building_types_arr;
                                  break;
                                case 'GetCountries':
                                  let countries_arr = [];
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      countries_arr.push({
                                        value: key,
                                        label: responses[i].data.result[key]
                                      });
                                    }
                                  }
                                  loaded_data.countries = countries_arr;
                                  break;
                                case 'GetRegions':
                                  let regions_arr = [];
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      regions_arr.push({
                                        value: responses[i].data.result[key].region,
                                        label: responses[i].data.result[key].name
                                      });
                                    }
                                  }
                                  loaded_data.regions = regions_arr;
                                  break;
                                case 'GetActions':
                                  let actions_arr = [];
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      actions_arr.push({
                                        value: key,
                                        label: responses[i].data.result[key]
                                      });
                                    }
                                  }
                                  loaded_data.actions = actions_arr;
                                  break;
                                case 'GetWorkProfitType':
                                  let work_profit_type_arr = []
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      work_profit_type_arr.push({
                                        value: key,
                                        label: responses[i].data.result[key]
                                      });
                                    }
                                  }
                                  loaded_data.work_profit_type = work_profit_type_arr;
                                  break;
                                case 'GetWorkPositionType':
                                  let work_position_type_arr = []
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      work_position_type_arr.push({
                                        value: key,
                                        label: responses[i].data.result[key]
                                      });
                                    }
                                  }
                                  loaded_data.work_position_type = work_position_type_arr;
                                  break;
                                case 'GetEmployerGroups':
                                  let work_employer_type_arr = []
                                  for (let key in responses[i].data.result) {
                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                      work_employer_type_arr.push({
                                        value: responses[i].data.result[key].id,
                                        label: responses[i].data.result[key].description,
                                        includeSubGroup: responses[i].data.result[key].includeSubGroup,
                                        requireEmployerDescription: responses[i].data.result[key].requireEmployerDescription,
                                      });
                                    }
                                  }
                                  loaded_data.work_employer_type = work_employer_type_arr;
                                  break;
                                case 'GetCustomerAndRequestInfo':
                                  loaded_data.user_info = responses[i].data.result.customerDetails;
                                  loaded_data.customer_info_for_save = responses[i].data.result;
                                  loaded_data.sum_need = responses[i].data.result.startCapital;
                                  loaded_data.period = responses[i].data.result.periodRequested;
                                  loaded_data.work_inf = responses[i].data.result.personWorkInfList;
                                  loaded_data.requestID = responses[i].data.result.requestID;

                                  if (responses[i].data.result.personWorkInfList.length) {
                                    const profit_types = responses[i].data.result.personWorkInfList.map(element => element.profitType);

                                    dispatch(getMultipleWorkReferenceTypes(profit_types));
                                  }
                                  break;
                                case 'GetQualityByRequestID':
                                  loaded_data.acba_status = responses[i].data.result;
                                  break;
                              }
                            }
                            if (responses[i].data.resultCode === 3) {
                              history.push('/signin');
                              dispatch(unsetToken());
                              dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
                              break;
                            }
                            if (responses[i].data.resultCode === 4) {
                              const validationMessage = responses[i].data.description ? responses[i].data.description : responses[i].data[0].validationMessage;
                              dispatch(initAppFail(validationMessage));
                              history.push("/applications");
                              break;
                            }
                          }

                          // getting maxLoan from user's action
                          const action = loaded_data.hasOwnProperty('customer_info_for_save') ? loaded_data.customer_info_for_save.action : 0;
                          if (loaded_data.hasOwnProperty('customer_info_for_save')) {
                            if (loaded_data.customer_info_for_save.customerDetails.addressList) {
                              const addressList = loaded_data.customer_info_for_save.customerDetails.addressList;
                              if (addressList[0].addressType == 1) {
                                let tmp = addressList[1];
                                addressList[1] = addressList[0];
                                addressList[0] = tmp;
                              }
                            }
                          }

                          commonUtils.apiCall('post', 'Person/MaxLoanDuration', {action}, session_id)
                            .then((maxLoanResponse) => {
                              if (maxLoanResponse.data.resultCode === 1) {
                                loaded_data.maxLoan = maxLoanResponse.data.result;
                                loaded_data.newRequestStatus = newRequestResponse.data.result;
                                localStorage.setItem('new_request_permission', newRequestResponse.data.result);
                                dispatch(initAppSuccess({ loaded_data }));
                              }
                              if (maxLoanResponse.data.resultCode === 3) {
                                dispatch(initAppFail(''));
                                history.push('/signin');
                                dispatch(unsetToken());
                                dispatch(notAuthorized());
                              }
                              if (maxLoanResponse.data.resultCode === 4) {
                                dispatch(initAppFail(maxLoanResponse.data[0].validationMessage))
                              }
                            })
                            .catch(() => {
                              dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
                            });
                        })
                        .catch((error) => {
                          dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
                        })
                    } else {
                      commonUtils.apiCall('post', 'Person/MaxLoanDuration', {action: 0}, session_id)
                        .then((response) => {
                          if (response.data.resultCode === 1) {
                            loaded_data.maxLoan = response.data.result;
                            loaded_data.newRequestStatus = newRequestResponse.data.result;
                            localStorage.setItem('new_request_permission', newRequestResponse.data.result);
                            dispatch(initAppSuccess({ loaded_data }));
                          }
                        });
                    }

                  }
                  else {
                    dispatch(initAppFail(''));
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                  }
                })
                .catch((error) => {
                  dispatch(initAppFail(''));
                  history.push('/signin');
                  dispatch(unsetToken());
                  dispatch(notAuthorized());
                })
            }
            else {
              loaded_data.newRequestStatus = newRequestResponse.data.result;
              localStorage.setItem('new_request_permission', newRequestResponse.data.result);
              dispatch(initAppSuccess({ loaded_data }));
              history.push("/applications");
            }
          }
          if (newRequestResponse.data.resultCode === 3) {
            dispatch(initAppFail(''));
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
          }
        })
        .catch(() => {
          dispatch(initAppFail(''));
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        });
    }
    else {
      commonUtils.apiCall('post', 'Authorization/CheckToken', {}, session_id)
        .then((authorizationTokenResponse) => {
          if (authorizationTokenResponse.data.result) {
            //preparing required API call to make a simultaneous requests
            let api_calls = [];

            if (Object.entries(getState().applicationReducer.dictionary.actions).length === 0 && getState().applicationReducer.dictionary.actions.constructor === Object) {
              api_calls.push(
                commonUtils.apiCall('get','Info/GetActions',{},session_id),
              )
            }

            //user indentification related dictionaries
            if(Object.entries(getState().applicationReducer.dictionary.document_types).length === 0 && getState().applicationReducer.dictionary.document_types.constructor === Object){
              api_calls.push(
                commonUtils.apiCall('get','Info/GetDocumentTypes',{},session_id),
                commonUtils.apiCall('get','Info/GetBuildingTypes',{},session_id),
                commonUtils.apiCall('get','Info/GetCountries',{},session_id),
              );

              if (request_id) {
                api_calls.push(
                  commonUtils.apiCall('get','Info/GetRegions',{countryId: 51},session_id),
                )
              }
            }

            //user incomes related dictionaries
            if(Object.entries(getState().applicationReducer.dictionary.work_profit_type).length === 0 && getState().applicationReducer.dictionary.work_profit_type.constructor === Object) {
              api_calls.push(
                commonUtils.apiCall('get', 'Info/GetWorkProfitType', {}, session_id),  //ԵԿԱՄՏԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                commonUtils.apiCall('get', 'Info/GetWorkPositionType', {}, session_id),  //ՊԱՇՏՈՆՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                commonUtils.apiCall('get', 'Info/GetEmployerGroups', {}, session_id),  //ԱՇԽԱՏԱՎԱՅՐԵՐԻ ՏԵՂԵԿԱՏՈՒ
              )
            }

            //user data, if request already exist in ACBA database
            if(request_id != 0 && request_id !== "" && getState().applicationReducer.customer_info_for_save.request.requestID === 0) {
              api_calls.push(
                commonUtils.apiCall('post', 'Person/GetCustomerAndRequestInfo', {"requestID": request_id}, session_id),
                commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id)
              )
            }

            if (api_calls.length > 0) {
              Promise.all(api_calls)
                .then((responses)=>{
                  //iterate through responses and check data
                  for (let i = 0; i < responses.length; i++) {
                    if (responses[i].data.resultCode === 1) {
                      let call_method = responses[i].config.url.split('/').slice(-1)[0];
                      switch (call_method) {
                        case 'GetDocumentTypes':
                          if (!Array.isArray(responses[i].data.result)) {
                            let document_types_arr = [];
                            for (let key in responses[i].data.result) {
                              if (responses[i].data.result.hasOwnProperty(key)) {
                                document_types_arr.push({
                                  value: key,
                                  label: responses[i].data.result[key]
                                });
                              }
                            }
                            loaded_data.document_types = document_types_arr;
                          }
                          else {
                            let document_types_arr = [];
                            responses[i].data.result.map((element, index) => {
                              document_types_arr.push({
                                value: element.key,
                                label: element.value
                              });
                            });
                            loaded_data.document_types = document_types_arr;
                          }
                          break;
                        case 'GetBuildingTypes':
                          let building_types_arr = [];
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              building_types_arr.push({
                                value: key,
                                label: responses[i].data.result[key]
                              });
                            }
                          }
                          loaded_data.building_types = building_types_arr;
                          break;
                        case 'GetCountries':
                          let countries_arr = [];
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              countries_arr.push({
                                value: key,
                                label: responses[i].data.result[key]
                              });
                            }
                          }
                          loaded_data.countries = countries_arr;
                          break;
                        case 'GetRegions':
                          let regions_arr = [];
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              regions_arr.push({
                                value: responses[i].data.result[key].region,
                                label: responses[i].data.result[key].name
                              });
                            }
                          }
                          loaded_data.regions = regions_arr;
                          break;
                        case 'GetActions':
                          let actions_arr = [];
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              actions_arr.push({
                                value: key,
                                label: responses[i].data.result[key]
                              });
                            }
                          }
                          loaded_data.actions = actions_arr;
                          break;
                        case 'GetWorkProfitType':
                          let work_profit_type_arr = []
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              work_profit_type_arr.push({
                                value: key,
                                label: responses[i].data.result[key]
                              });
                            }
                          }
                          loaded_data.work_profit_type = work_profit_type_arr;
                          break;
                        case 'GetWorkPositionType':
                          let work_position_type_arr = []
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              work_position_type_arr.push({
                                value: key,
                                label: responses[i].data.result[key]
                              });
                            }
                          }
                          loaded_data.work_position_type = work_position_type_arr;
                          break;
                        case 'GetEmployerGroups':
                          let work_employer_type_arr = []
                          for (let key in responses[i].data.result) {
                            if (responses[i].data.result.hasOwnProperty(key)) {
                              work_employer_type_arr.push({
                                value: responses[i].data.result[key].id,
                                label: responses[i].data.result[key].description,
                                includeSubGroup: responses[i].data.result[key].includeSubGroup,
                                requireEmployerDescription: responses[i].data.result[key].requireEmployerDescription,
                              });
                            }
                          }
                          loaded_data.work_employer_type = work_employer_type_arr;
                          break;
                        case 'GetCustomerAndRequestInfo':
                          if (responses[i].data.result.customerDetails.addressList.length === 0) {
                            responses[i].data.result.customerDetails.addressList = [
                              {
                                addressType: 1,
                                appartment: "",
                                building: "",
                                country: "51",
                                house: "",
                                id: 0,
                                postCode: null,
                                priority: 0,
                                region: 0,
                                regionDescription: "",
                                street: 0,
                                streetDescription: "",
                                townVillage: 0,
                                townVillageDescription: "",
                              },
                              {
                                addressType: 2,
                                appartment: "",
                                building: "",
                                country: "51",
                                house: "",
                                id: 0,
                                postCode: null,
                                priority: 0,
                                region: 0,
                                regionDescription: "",
                                street: 0,
                                streetDescription: "",
                                townVillage: 0,
                                townVillageDescription: "",
                              }
                            ]
                          }
                          loaded_data.user_info = responses[i].data.result.customerDetails;
                          loaded_data.customer_info_for_save = responses[i].data.result;
                          loaded_data.sum_need = responses[i].data.result.startCapital;
                          loaded_data.period = responses[i].data.result.periodRequested;
                          loaded_data.work_inf = responses[i].data.result.personWorkInfList;
                          loaded_data.requestID = responses[i].data.result.requestID;

                          if (responses[i].data.result.personWorkInfList.length) {
                            const profit_types = responses[i].data.result.personWorkInfList.map(element => element.profitType);

                            dispatch(getMultipleWorkReferenceTypes(profit_types));
                          }
                          break;
                        case 'GetQualityByRequestID':
                          loaded_data.acba_status = responses[i].data.result;
                          break;
                      }
                    }
                    if (responses[i].data.resultCode === 3){
                      history.push('/signin');
                      dispatch(unsetToken());
                      dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
                      break;
                    }
                    if (responses[i].data.resultCode === 4) {
                      const validationMessage = responses[i].data.description ? responses[i].data.description : responses[i].data[0].validationMessage;
                      dispatch(initAppFail(validationMessage));
                      history.push("/applications");
                      break;
                    }
                  }

                  // getting maxLoan from user's action
                  const action = loaded_data.hasOwnProperty('customer_info_for_save') ? loaded_data.customer_info_for_save.action : 0;
                  if (loaded_data.hasOwnProperty('customer_info_for_save')) {
                    if (loaded_data.customer_info_for_save.customerDetails.addressList) {
                      const addressList = loaded_data.customer_info_for_save.customerDetails.addressList;
                      if (addressList[0].addressType == 1) {
                        let tmp = addressList[1];
                        addressList[1] = addressList[0];
                        addressList[0] = tmp;
                      }
                    }
                  }

                  commonUtils.apiCall('post', 'Person/MaxLoanDuration', {action}, session_id)
                    .then((maxLoanResponse) => {
                      if (maxLoanResponse.data.resultCode === 1) {
                        loaded_data.maxLoan = maxLoanResponse.data.result;
                        dispatch(initAppSuccess({ loaded_data }));
                      }
                      if (maxLoanResponse.data.resultCode === 3) {
                        dispatch(initAppFail(''));
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                      }
                      if (maxLoanResponse.data.resultCode === 4) {
                        dispatch(initAppFail(maxLoanResponse.data[0].validationMessage))
                      }
                    })
                    .catch(() => {
                      dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
                    });
                })
                .catch((error) => {
                  dispatch(initAppFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'))
                })
            } else {
              commonUtils.apiCall('post', 'Person/MaxLoanDuration', {action: 0}, session_id)
                .then((response) => {
                  if (response.data.resultCode === 1) {
                    loaded_data.maxLoan = response.data.result;
                    dispatch(initAppSuccess({ loaded_data }));
                  }
                });
            }

          }
          else {
            dispatch(initAppFail(''));
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
          }
        })
        .catch((error) => {
          dispatch(initAppFail(''));
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        })
    }
  }
}

export function getShopMaxLoan() {
    return (dispatch, getState) => {
        dispatch(getMaxLoanStart());
        let session_id = getState().initReducer.session_id;
        let param = {
            "action": 0,
        };
        commonUtils.apiCall('post', 'Person/MaxLoanDuration', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        dispatch(getMaxLoanSuccess({max_loan: response.data.result}));
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {

            })
    }
}

export function setMaxLoan(data) {
    return (dispatch, getState) => {
        dispatch(getMaxLoanStart());
        let session_id = getState().initReducer.session_id;
        let param = {
            "action": data.action,
            "amount": data.amount,
        };
        commonUtils.apiCall('post', 'Person/MaxLoanDuration', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        dispatch(getMaxLoanSuccess({max_loan: response.data.result}));
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized( ));
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {

            })
    }
}

export function getWorkReferenceTypes(work_profit_type){
    return (dispatch,getState) => {
        let session_id = getState().initReducer.session_id;
        let params = {
            'profitType': work_profit_type,
        }
        dispatch(getWorkReferenceTypesStart());

        commonUtils.apiCall('get','Info/GetWorkReferenceType',params,session_id)
            .then((response)=>{
                if(response.status === 200 && response.statusText === "OK"){
                    switch (response.data.resultCode) {
                        case 1:
                            dispatch(getWorkReferenceTypesSuccess({work_reference_types:response.data.result}));
                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                            break;
                        default:
                            break;
                    }
                }
            })
    }
}

export function getEmployerTypes(work_employer_group) {
    return (dispatch, getState) => {
        // dispatch(getEmployerTypesStart());
        let session_id = getState().initReducer.session_id;

        let params = {
            'GroupID': work_employer_group,
        }

        commonUtils.apiCall('get','Info/GetEmployerTypesByGroup',params,session_id)
            .then((response)=>{
                if(response.status === 200 && response.statusText === "OK"){
                    switch (response.data.resultCode) {
                        case 1:
                            dispatch(getEmployerTypesSuccess({employer_type_by_group: response.data.result}));
                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                            break;
                        default:
                            break;
                    }
                }
            })
    }
}

export function userIdentification(doc_type,doc_number,soc_number,sum_need,period,action){
    return (dispatch,getState) => {
        dispatch(identificationStart());

        let session_id = getState().initReducer.session_id;
        let params = {
            'documentType': doc_type,
            'documentNumber': doc_number.toUpperCase(),
        };

        if (soc_number !== undefined) {
            params['socialNumber'] = soc_number;
        }

        commonUtils.apiCall('post', 'Person/PersonIdentification', params, session_id)
            .then((response) => {
                if (response.status === 200 && response.statusText === "OK") {
                    switch (response.data.resultCode) {
                        case 1:
                            let user_info;
                            if (response.data.result != null) {
                                user_info =  response.data.result;
                                if (user_info.addressList && user_info.addressList[0]) {
                                  for (let property in user_info.addressList[0]) {
                                    if (user_info.addressList[0].hasOwnProperty(property)) {
                                      if (user_info.addressList[0][property] < 0) {
                                        user_info.addressList[0][property] = "";
                                      }
                                      if (property === "building" && user_info.addressList[0][property] === 0) {
                                        user_info.addressList[0][property] = "";
                                      }
                                    }
                                  }
                                }
                            } else {
                                user_info = {
                                    "address": "",
                                        "addressList": [],
                                        "applicationID": 0,
                                        "applicationProcessingStatus": 0,
                                        "automatScoreRejectionReason": "",
                                        "automatScoreStep": "",
                                        "citizenship": "",
                                        "customerIdentificationGroup": 0,
                                        "customerNumber": 0,
                                        "customerPhoto": "",
                                        "customerQuality": 0,
                                        "dateOfBirth": "",
                                        "documentDate": "",
                                        "documentInf": "",
                                        "documentNumber": "",
                                        "documentType": "",
                                        "documentValidDate": "",
                                        "familyCount": 0,
                                        "familyWorkingCount": 0,
                                        "fatherName": "",
                                        "filialCode": 0,
                                        "guarantStatusQuality": 0,
                                        "homePhone": "",
                                        "lastName": "",
                                        "maritalStatus": "",
                                        "marketPhone": "",
                                        "mobilePhone": "",
                                        "name": "",
                                        "notDocSalary": 0,
                                        "notDocSalarySource": "",
                                        "offeredCustomerNumber": 0,
                                        "otherDocSalary": 0,
                                        "otherPhone": "",
                                        "quality": 0,
                                        "realAddress": "",
                                        "requestQualityDescr": "",
                                        "resident": "",
                                        "scoreResult": 0,
                                        "socialNumber": ""
                                };
                            }
                            let search_street = user_info.addressList.length !== 0 ? user_info.addressList : [];

                            const region_id = user_info.addressList != null && user_info.addressList[0] !== undefined &&
                                                user_info.addressList[0].region != null &&
                                                user_info.addressList[0].region !== undefined ? user_info.addressList[0].region : 0;

                            commonUtils.apiCall('get',`Info/GetArmenianPlaces?RegionID=${region_id}`,{},session_id)
                                .then((getCitiesResponse) => {
                                    if (getCitiesResponse.data.resultCode === 1) {
                                      const countryId = user_info.addressList != null && user_info.addressList[0] !== undefined &&
                                                        user_info.addressList[0].country != null &&
                                                        user_info.addressList[0].country !== undefined ? user_info.addressList[0].country : 51;                                    

                                      commonUtils.apiCall("get", "Info/GetRegions", {countryId}, session_id)
                                        .then(regionResponse => {
                                          if (regionResponse.data.resultCode === 1) {
                                            let regions_arr = [];
                                            for (let key in regionResponse.data.result) {
                                              if (regionResponse.data.result.hasOwnProperty(key)) {
                                                regions_arr.push({
                                                  value: regionResponse.data.result[key].region,
                                                  label: regionResponse.data.result[key].name
                                                });
                                              }
                                            }

                                            dispatch(identificationSuccess({
                                              customer_info_for_save: user_info,
                                              default_searching_street: search_street,
                                              cities: getCitiesResponse.data.result,
                                              regions: regions_arr
                                            }));
                                          }
                                        })

                                    }
                                    if (getCitiesResponse.data.resultCode === 4) {
                                        dispatch(identificationFail({error_message: getCitiesResponse.data.result[0].validationMessage}));
                                    }
                                })
                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                            break;
                        default:
                            let error_messages = [];
                            response.data.result.map(i => {
                                error_messages.push(i.validationMessage);
                            });
                            dispatch(identificationFail({error_message: error_messages}));
                            break;
                    }

                }else{
                    dispatch(identificationFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
                }
            })
            .catch((err) => {
                dispatch(identificationFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
            })
    }
}

export function saveCustomerInfo(addInf) {
    return (dispatch, getState) => {
        dispatch(saveCustomerInfoStart());
        let session_id = getState().initReducer.session_id;
        let save_object = getState().applicationReducer.customer_info_for_save;
        let param = JSON.parse(JSON.stringify(save_object));

        param.request.personWorkInfList.map((element) => {
            if (element['detailed_employerId'] !== undefined) {
                element['employerId'] = element['detailed_employerId'];
                delete element['detailed_employerId'];
            }

            if (element['workExperienceYear'] && element['workExperienceYear'].toString().includes(',')) { // if 12,5 -> 12.5
                element['workExperienceYear'] = element['workExperienceYear'].replace(',', '.');
            }
        });
        param.request.addInf = addInf

        commonUtils.apiCall('post', 'Person/SaveCustomerAndRequestInfo', param, session_id)
            .then((resp) => {
                if (resp.data.resultCode === 1) {
                    let requestID = resp.data.result;
                    commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": requestID}, session_id)
                        .then((response) => {
                            if (response.data.resultCode === 1) {
                                // history.push(`/application/${requestID}`);
                                dispatch(saveCustomerInfoSuccess({requestID, acba_status: response.data.result}));
                            }
                            else if(resp.data.resultCode === 3){
                                history.push('/signin');
                                dispatch(unsetToken());
                                dispatch(notAuthorized());
                            }
                            else if (resp.data.resultCode === 4) {
                                let error_message = {
                                    type: "AuthenticationError",
                                    message: resp.data.description
                                };
                                dispatch(saveCustomerInfoFail({error_message}))
                            }
                        })
                }
                if (resp.data.resultCode === 3) {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized())
                }
                if (resp.data.resultCode === 4) {
                    let error_message = {
                        type: "AuthenticationError",
                        message: resp.data.description ? resp.data.description : resp.data.result[0].validationMessage
                    };
                    dispatch(saveCustomerInfoFail({error_message}))
                }
            })
            .catch((error) => {
                let error_message = {
                    type: "SaveFail",
                    message: "Կապի խնդիր. խնդրում ենք կրկին փորձել",
                };
                dispatch(saveCustomerInfoFail({error_message}));
            })
    }
}

export function guarantorIdentification(doc_type,doc_number,soc_number){
    return (dispatch,getState) => {
        let session_id = getState().initReducer.session_id;

        dispatch(guarantorIdentificationStart());

        let params = {
            'documentType': doc_type,
            'documentNumber': doc_number.toUpperCase(),
            'socialNumber': soc_number,
        };

        commonUtils.apiCall('post', 'Person/PersonIdentification', params, session_id)
            .then((response) => {
                if (response.status === 200 && response.statusText === "OK") {
                    switch (response.data.resultCode) {
                        case 1:
                            let guarantor_info;
                            if (response.data.result != null) {
                                guarantor_info = response.data.result;
                                if (guarantor_info.addressList && guarantor_info.addressList[0]) {
                                  for (let property in guarantor_info.addressList[0]) {
                                    if (guarantor_info.addressList[0].hasOwnProperty(property)) {
                                      if (guarantor_info.addressList[0][property] < 0) {
                                        guarantor_info.addressList[0][property] = "";
                                      }
                                      if (property === "building" && guarantor_info.addressList[0][property] === 0) {
                                        guarantor_info.addressList[0][property] = "";
                                      }
                                    }
                                  }
                                }
                            } else {
                                guarantor_info = {
                                    "address": "",
                                    "addressList": [],
                                    "applicationID": 0,
                                    "applicationProcessingStatus": 0,
                                    "automatScoreRejectionReason": "",
                                    "automatScoreStep": "",
                                    "citizenship": "",
                                    "customerIdentificationGroup": 0,
                                    "customerNumber": 0,
                                    "customerPhoto": "",
                                    "customerQuality": 0,
                                    "dateOfBirth": "",
                                    "documentDate": "",
                                    "documentInf": "",
                                    "documentNumber": "",
                                    "documentType": "",
                                    "documentValidDate": "",
                                    "familyCount": 0,
                                    "familyWorkingCount": 0,
                                    "fatherName": "",
                                    "filialCode": 0,
                                    "guarantStatusQuality": 0,
                                    "homePhone": "",
                                    "lastName": "",
                                    "maritalStatus": "",
                                    "marketPhone": "",
                                    "mobilePhone": "",
                                    "name": "",
                                    "notDocSalary": 0,
                                    "notDocSalarySource": "",
                                    "offeredCustomerNumber": 0,
                                    "otherDocSalary": 0,
                                    "otherPhone": "",
                                    "quality": 0,
                                    "realAddress": "",
                                    "requestQualityDescr": "",
                                    "resident": "",
                                    "scoreResult": 0,
                                    "socialNumber": ""
                                };
                            }
                            let guarantor_address_list =  guarantor_info.addressList.length !== 0 && guarantor_info.addressList != null ? guarantor_info.addressList : [];
                            let search_street = guarantor_info.addressList.length !== 0 ? guarantor_info.addressList : [];

                            const region_id = guarantor_info.addressList != null && guarantor_info.addressList[0] !== undefined &&
                                            guarantor_info.addressList[0].region != null &&
                                            guarantor_info.addressList[0].region !== undefined ? guarantor_info.addressList[0].region : 0;

                            commonUtils.apiCall('get',`Info/GetArmenianPlaces?RegionID=${region_id}`,{},session_id)
                                .then((guarantorIdentificationResponse) => {
                                    if (guarantorIdentificationResponse.data.resultCode === 1) {
                                      const countryId = guarantor_info.addressList != null && guarantor_info.addressList[0] !== undefined &&
                                                        guarantor_info.addressList[0].country != null &&
                                                        guarantor_info.addressList[0].country !== undefined ? guarantor_info.addressList[0].country : 0;

                                      commonUtils.apiCall("get", "Info/GetRegions", {countryId}, session_id)
                                        .then(regionResponse => {
                                          if (regionResponse.data.resultCode === 1) {
                                            let regions_arr = [];
                                            for (let key in regionResponse.data.result) {
                                              if (regionResponse.data.result.hasOwnProperty(key)) {
                                                regions_arr.push({
                                                  value: regionResponse.data.result[key].region,
                                                  label: regionResponse.data.result[key].name
                                                });
                                              }
                                            }

                                            dispatch(guarantorIdentificationSuccess({
                                              guarantor_data: guarantor_info,
                                              default_searching_street: search_street,
                                              guarantor_address_list,
                                              cities: guarantorIdentificationResponse.data.result,
                                              regions: regions_arr
                                            }));
                                          }
                                        })

                                    }
                                    if (guarantorIdentificationResponse.data.resultCode === 4) {
                                        dispatch(guarantorIdentificationFail({error_message: guarantorIdentificationResponse.data.result[0].validationMessage}))
                                    }
                                })

                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                        default:
                          dispatch(guarantorIdentificationFail({error_message: response.data.result[0].validationMessage}));
                    }
                }else{
                    dispatch(guarantorIdentificationFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
                }
            })
            .catch((err) => {
                dispatch(guarantorIdentificationFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
            })
    }
}

export function saveGuarantorInfo(guarantor_info, guarantor_contacts) {
    return (dispatch, getState) => {
        dispatch(guarantorSaveStart());
        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID;
        // let guarantor_info = getState().applicationReducer.guarantor_info;
        // guarantor_info.addressList = getState().applicationReducer.guarantor_address_list;

        let param = {
            "requestID": request_id,
            "customerDetails": guarantor_info,
            "phoneList": guarantor_contacts.phone,
            "emailList": guarantor_contacts.email,
        };

        commonUtils.apiCall('post', 'Guarantor/SaveGuarantor', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        guarantor_info.guarantStatus = response.data.result;
                        dispatch(guarantorSaveSuccess({guarantors: guarantor_info}));
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    case 4:
                        dispatch(guarantorSaveFail({error_message: response.data.result[0].validationMessage}))
                    default:
                        break;
                }
            })
            .catch((error) => {
                dispatch(guarantorSaveFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}))
            });

    }
}

export function loadAcraAgreement(type, doc_number, user_info) {
    return (dispatch, getState) => {
        dispatch(loadAcraAgreementStart());
        let session_id = getState().initReducer.session_id;
        const {registrationDate} = getState().applicationReducer.customer_info_for_save.request;
        const requestDate = registrationDate ? moment(registrationDate).format('YYYY.MM.DD') : moment().format('YYYY.MM.DD');

        let agreement_params = {
            "fullName": user_info.name + " " + user_info.lastName + " " + user_info.fatherName,
            "passportNumber": doc_number,
            requestDate
        };

        commonUtils.apiCall('post', 'RequestContract/ACRAAgreement', agreement_params, session_id)
            .then((response) => {
                if (response.status === 200 && response.statusText === "OK") {
                    switch (response.data.resultCode) {
                        case 1:
                            dispatch(loadAcraAgreementSuccess({agreementPDF: response.data.result, type:type}));
                            // if (type === "person") {
                            //     dispatch(loadAcraAgreementSuccess({agreementPDF: response.data.result, type}));
                            // }
                            // if (type === "guarantor") {
                            //     dispatch(loadAcraAgreementSuccess({guarantorPDF: response.data.result, type}));
                            // }
                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch((err) => {
                dispatch(loadAcraAgreementFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
            })
    }

}

export function fetchStreet(value, armPlaceID, addressType) {
    return (dispatch, getState) => {
        dispatch(streetSearchStart());
        let session_id = getState().initReducer.session_id;

        let search_param = {
          serachStreet: value,
          armPlaceID: armPlaceID,
        };

        commonUtils.apiCall('get', 'Info/GetStreetsByName', search_param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        dispatch(streetSearch({searching_streets: response.data.result, addressType}));
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {

            })
    }
}

export function getAllStreets(arm_place_id, id, type, region) {
  return (dispatch, getState) => {
    dispatch(getAllStreetsStart());
    const session_id = getState().initReducer.session_id;

    commonUtils.apiCall('get', `Info/GetAllStreets?ArmPlaceID=${arm_place_id}`, {}, session_id)
      .then((response) => {
        if (response.data.resultCode === 1) {
          const all_streets = response.data.result;
          let formatted_arr = [];
          let townVillageObject;

          if (id === 1) {
            townVillageObject = getState().applicationReducer.dictionary.cities.find(item => item.number == arm_place_id);
          } else {
            townVillageObject = getState().applicationReducer.dictionary.residence_cities.find(item => item.number == arm_place_id);
          }

          all_streets.map((element, index) => {
            formatted_arr.push({
              addressType: null,
              appartment: null,
              building: null,
              country: null,
              house: null,
              postCode: null,
              priority: null,
              region: region.key,
              regionDescription: region.label,
              street: element.id,
              streetDescription: element.description,
              townVillage: townVillageObject.number,
              townVillageDescription: townVillageObject.description,
              id: 0
            })
          });

          dispatch(getAllStreetsSuccess({searching_streets: formatted_arr, type, id}));
        }
      })
  }
}

export function getRegions(countryID, id) {
  return (dispatch, getState) => {
    dispatch(getRegionsStart());
    const session_id = getState().initReducer.session_id;

    commonUtils.apiCall('get', `Info/GetRegions?countryId=${countryID}`, {}, session_id)
      .then((response) => {
        if (response.data.resultCode === 1) {
          let regions_arr = [];
          for (let key in response.data.result) {
            if (response.data.result.hasOwnProperty(key)) {
              regions_arr.push({
                value: response.data.result[key].region,
                label: response.data.result[key].name
              });
            }
          }
          dispatch(getRegionsSuccess({regions: regions_arr, id}));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(getRegionsFail(response.data[0].validationMessage));
        }
      })
      .catch(() => {
        dispatch(getRegionsFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

export function calculateRequestOffer() {
    return (dispatch, getState) => {
        dispatch(calculateRequestOfferStart());
        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID;

        let param = {
            requestID: request_id
        };

        commonUtils.apiCall('post', 'RequestOffer/CalculateRequestOffer', param, session_id)
            .then((response) => {
                let calc_status = '';

                if (response.data.resultCode === 1) {
                    let calculate_request = response.data.result.resultDescription;
                    switch (response.data.result.resultCode) {
                        case 1:
                            calc_status = "calc_success";
                            break;
                        case 2:
                            calc_status = "calc_rejection";
                            break;
                        case 3:
                            calc_status = "calc_analyse_in_bank";
                            break;
                        case 4:
                            calc_status = "formulation_in_bank";
                            break;
                    }
                    commonUtils.apiCall('post', 'Person/GetQualityByRequestID', param, session_id)
                        .then((response) => {
                            switch (response.data.resultCode) {
                                case 1:
                                    if (calc_status === "calc_success") {
                                        history.push(`/offer/${request_id}`);
                                    }
                                    if (calc_status === "calc_rejection") {
                                      history.push("/rejection");
                                    }
                                    dispatch(calculateRequestOfferSuccess({calculate_request, request_status: calc_status, acba_status: response.data.result}));
                                    break;
                                case 3:
                                    history.push('/signin');
                                    dispatch(unsetToken());
                                    dispatch(notAuthorized());
                                    break;
                            }
                        })
                        .catch((error) => {
                            dispatch(calculateRequestOfferFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}))
                        })
                }
                if (response.data.resultCode === 3) {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                }
                if (response.data.resultCode === 4) {
                    dispatch(calculateRequestOfferFail({
                        error_message: response.data.description ? response.data.description : response.data.result[0].validationMessage,
                    }));
                }
            })
            .catch((error) => {
                dispatch(calculateRequestOfferFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}))
            })
    }
}

export function recalculateRequestOffer(incomes) {
    return (dispatch, getState) => {
        dispatch(calculateRequestOfferStart());
        let work_inf_list = [];
        incomes.map(element => {
            let employer_id;

            if (element.workEmployerTypesDesc_visible && element.workEmployerTypeDesc !== "") {
                employer_id = element.workEmployerTypeDesc;
            } else if (element.workEmployerType !== "") {
                employer_id = element.workEmployerType;
            } else {
                employer_id = 0;
            }

            work_inf_list.push({
                // "workType": element.workProfitType !== undefined ? element.workProfitType : "",
                "position": element.workPositionType !== undefined ? element.workPositionType : 0,
                "money": element.salaryIncludeTaxes !== undefined ? element.salaryIncludeTaxes : "",
                "clearMoney": element.netSalary !== undefined ? element.netSalary : "",
                "codeOfTax": element.socNumb !== undefined ? element.socNumb : "",
                "withoutCodeOfTax": element.socNumbNotExists !== undefined ? element.socNumbNotExists : "",
                "workExperienceYear": element.workExperience !== undefined ? element.workExperience : "",
                "referenceType": element.workReferenceType !== undefined ? element.workReferenceType : "",
                "profitType": element.workProfitType !== undefined ? element.workProfitType : "",
                "employeerDescription": element.additional_desc !== undefined ? element.additional_desc : "",
                "employerId": employer_id,
            })
        });

        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID;

        let recalculate_param = {
            "requestID": request_id
        };

        let get_offer_param = {
            "requestID": request_id
        };

        let save_work_inf_param = {
            "workInfoList": work_inf_list,
            "requestID": request_id
        };

        commonUtils.apiCall('post', 'PersonWorkInf/SavePersonWorkInf', save_work_inf_param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        commonUtils.apiCall('post', 'RequestOffer/CalculateRequestOffer', recalculate_param, session_id)
                            .then((response) => {
                                if (response.data.result.resultCode === 1) {
                                    // For new offer term
                                    commonUtils.apiCall('post', 'RequestOffer/GetRequestOffer', get_offer_param, session_id)
                                        .then((response) => {

                                        })
                                        .catch((error) => {

                                        })
                                }
                                else {
                                    dispatch(calculateRequestOfferFail({error_message: response.data.result.resultDescription}))
                                }
                            })
                            .catch((error) => {

                            });
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                }
            })
            .catch((error) => {

            })
    }
}

export function sendRequestToBank() {
    return (dispatch, getState) => {
        dispatch(sendToBankStart());
        let session_id = getState().initReducer.session_id;
        let requestID = getState().applicationReducer.customer_info_for_save.request.requestID;

        let param = {
            requestID,
        };

        commonUtils.apiCall('post', 'RequestData/SendRequestToBank', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {requestID}, session_id)
                            .then((response) => {
                                if (response.data.resultCode === 1) {
                                    history.push("/senttobank");
                                    dispatch(sendToBankSuccess({acba_status: response.data.result}));
                                }
                                if (response.data.resultCode === 3) {
                                    history.push('/signin');
                                    dispatch(unsetToken());
                                    dispatch(notAuthorized());
                                }
                            })
                            .catch((error) => {
                                dispatch(sendToBankFail({error_message: response.data.result[0].validationMessage}));
                            })
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    case 4:
                        dispatch(sendToBankFail({error_message: response.data.result[0].validationMessage}));
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {

            })
    }
}

export function getClearSalary(dirty_salary, birthday) {
  return (dispatch, getState) => {
        if (dirty_salary != 0) {
            dispatch(getClearSalaryStart());
            let session_id = getState().initReducer.session_id;
            let birth_day = getState().applicationReducer.customer_info_for_save.request.customerDetails.dateOfBirth;
            let param = {
                "salary": dirty_salary,
                "birthday": birth_day
            }
            commonUtils.apiCall('post', 'Info/GetClearSalary', param, session_id)
                .then((response) => {
                    switch (response.data.resultCode) {
                        case 1:
                            dispatch(getClearSalarySuccess({clear_money: response.data.result}));
                            break;
                        case 3:
                            history.push('/signin');
                            dispatch(unsetToken());
                            dispatch(notAuthorized());
                            break;
                        case 4:
                          dispatch(getClearSalaryFail(response.data.result[0].validationMessage));
                        default:
                            break;
                    }
                })
                .catch((error) => {
                    dispatch(getClearSalaryFail("Տեղի ունեցավ սխալ․ մաքուր աշխատավարձը հաշվարկելիս"));
                })
        }
  }
}

export function offerPageInitLoad(request) {
    return (dispatch, getState) => {
        dispatch(offerInitLoadStart());
        let session_id = getState().initReducer.session_id;
        let request_id = request;

        // Check if user session_token es not expired then make initialLoad calls
        commonUtils.apiCall('post', 'Authorization/CheckToken', {}, session_id)
            .then((authorizationTokenResponse) => {
                if (authorizationTokenResponse.data.result) {
                    //preparing required API call to make a simultaneous requests
                    let api_calls = [];

                    // loading necessary data in /offer screen
                    if (Object.entries(getState().applicationReducer.offer_terms).length === 0 && getState().applicationReducer.offer_terms.constructor === Object) {
                        // Need to move GetRequestOffer, GetQualityByRequestID, GetRequestItems calls in request_id if statement
                        api_calls.push(
                            commonUtils.apiCall('post', 'RequestOffer/GetRequestOffer', {"requestID": request_id}, session_id),
                            commonUtils.apiCall('get', 'Info/GetItemTypes', {}, session_id),
                        );
                    }

                    //check if RequestID exist
                    // if user comes from listing not from Step 1 requestID can be 0
                    if(request_id != 0 && request_id !== "" && getState().applicationReducer.customer_info_for_save.request.requestID === 0){
                        api_calls.push(
                            commonUtils.apiCall('post', 'Person/GetCustomerAndRequestInfo', {"requestID": request_id}, session_id),
                            commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id),
                        )
                    }

                    // check if request items is empty and acba status is about 3rd tab
                    if (getState().applicationReducer.acba_status !== 9 && getState().applicationReducer.request_items.requestID === "") {
                        api_calls.push(
                            commonUtils.apiCall('post', 'RequestData/GetRequestItems', {"requestID": request_id}, session_id),
                        )
                    }

                    //user indentification related dictionaries
                    if(Object.entries(getState().applicationReducer.dictionary.document_types).length === 0 && getState().applicationReducer.dictionary.document_types.constructor === Object){
                        api_calls.push(
                            commonUtils.apiCall('get','Info/GetDocumentTypes',{},session_id),
                            commonUtils.apiCall('get','Info/GetBuildingTypes',{},session_id),
                            commonUtils.apiCall('get','Info/GetCountries',{},session_id),
                            // commonUtils.apiCall('get','Info/GetRegions',{countryId: 51},session_id),
                            commonUtils.apiCall('get','Info/GetArmenianPlaces',{},session_id),
                        )
                    }

                    // if(Object.entries(getState().applicationReducer.dictionary.work_profit_type).length === 0 && getState().applicationReducer.dictionary.work_profit_type.constructor === Object) {
                    //     api_calls.push(
                    //         commonUtils.apiCall('get', 'Info/GetWorkProfitType', {}, session_id),  //ԵԿԱՄՏԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //         commonUtils.apiCall('get', 'Info/GetWorkPositionType', {}, session_id),  //ՊԱՇՏՈՆՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //         commonUtils.apiCall('get', 'Info/GetEmployerGroups', {}, session_id),  //ԱՇԽԱՏԱՎԱՅՐԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //     )
                    // }

                    /*if(Object.entries(getState().applicationReducer.mobile_phone_codes).length === 0){
                        api_calls.push(
                            commonUtils.apiCall('post', 'RequestOffer/GetRequestConditions', {"requestID": request_id}, session_id),
                            commonUtils.apiCall('get', 'RequestContract/GetFirstRepaymentDate', {}, session_id),
                            commonUtils.apiCall('get', 'Info/GetMobilePhoneCodes', {}, session_id),
                            commonUtils.apiCall('get', 'Info/GetPurchaseAddressType', {}, session_id),
                        )
                    }*/

                    let payload = {};
                    if(api_calls.length > 0) {
                        Promise.all(api_calls)
                            .then((responses)=>{
                                //iterate through responses and check data
                                for (let i = 0; i < responses.length; i++) {
                                    if (responses[i].data.resultCode === 1) {
                                        let call_method = responses[i].config.url.split('/').slice(-1)[0];
                                        switch (call_method) {
                                            case 'GetRequestOffer':
                                                payload.offer_terms = responses[i].data.result;
                                                break;
                                            case 'GetItemTypes':
                                                payload.item_types = responses[i].data.result;
                                                break;
                                            case 'GetCustomerAndRequestInfo':
                                                if (responses[i].data.result.customerDetails.addressList.length === 0) {
                                                  responses[i].data.result.customerDetails.addressList = [
                                                    {
                                                      addressType: 1,
                                                      appartment: "",
                                                      building: "",
                                                      country: "51",
                                                      house: "",
                                                      id: 0,
                                                      postCode: null,
                                                      priority: 0,
                                                      region: 0,
                                                      regionDescription: "",
                                                      street: 0,
                                                      streetDescription: "",
                                                      townVillage: 0,
                                                      townVillageDescription: "",
                                                    },
                                                    {
                                                      addressType: 2,
                                                      appartment: "",
                                                      building: "",
                                                      country: "51",
                                                      house: "",
                                                      id: 0,
                                                      postCode: null,
                                                      priority: 0,
                                                      region: 0,
                                                      regionDescription: "",
                                                      street: 0,
                                                      streetDescription: "",
                                                      townVillage: 0,
                                                      townVillageDescription: "",
                                                    }
                                                  ]
                                                }
                                                // whole data stores in customer_info_for_save
                                                payload.customer_info_for_save = responses[i].data.result;
                                                payload.user_info = responses[i].data.result.customerDetails;
                                                payload.sum_need = responses[i].data.result.startCapital;
                                                payload.work_inf = responses[i].data.result.personWorkInfList;
                                                break;
                                            case 'GetQualityByRequestID':
                                                payload.acba_status = responses[i].data.result
                                                break;
                                            case 'GetRequestItems':
                                                payload.request_items = responses[i].data.result
                                                break;
                                            case 'GetDocumentTypes':
                                                if (!Array.isArray(responses[i].data.result)) {
                                                  let document_types_arr = [];
                                                  for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                      document_types_arr.push({
                                                        value: key,
                                                        label: responses[i].data.result[key]
                                                      });
                                                    }
                                                  }
                                                  payload.document_types = document_types_arr;
                                                }
                                                else {
                                                  let document_types_arr = [];
                                                  responses[i].data.result.map((element, index) => {
                                                    document_types_arr.push({
                                                      value: element.key,
                                                      label: element.value
                                                    });
                                                  });
                                                  payload.document_types = document_types_arr;
                                                }
                                                break;
                                            case 'GetActions':
                                                let actions_arr = [];
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        actions_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.actions = actions_arr;
                                                break;
                                            case 'MaxLoanDuration':
                                                payload.maxLoan = responses[i].data.result;
                                                break;
                                            case 'GetBuildingTypes':
                                                let building_types_arr = [];
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        building_types_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.building_types = building_types_arr;
                                                break;
                                            case 'GetCountries':
                                                let countries_arr = [];
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        countries_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.countries = countries_arr;
                                                break;
                                            case 'GetRegions':
                                                let regions_arr = [];
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        regions_arr.push({
                                                            value: responses[i].data.result[key].region,
                                                            label: responses[i].data.result[key].name
                                                        });
                                                    }
                                                }
                                                payload.regions = regions_arr;
                                                break;
                                            case 'GetArmenianPlaces':
                                                let arm_places_arr = [];
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        arm_places_arr.push({
                                                            value: responses[i].data.result[key].number,
                                                            label: responses[i].data.result[key].description
                                                        });
                                                    }
                                                }
                                                payload.cities = arm_places_arr;
                                                break;
                                            case 'GetWorkProfitType':
                                                let work_profit_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_profit_type_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.work_profit_type = work_profit_type_arr;
                                                break;
                                            case 'GetWorkPositionType':
                                                let work_position_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_position_type_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.work_position_type = work_position_type_arr;
                                                break;
                                            case 'GetEmployerGroups':
                                                let work_employer_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_employer_type_arr.push({
                                                            value: responses[i].data.result[key].id,
                                                            label: responses[i].data.result[key].description,
                                                            includeSubGroup: responses[i].data.result[key].includeSubGroup,
                                                            requireEmployerDescription: responses[i].data.result[key].requireEmployerDescription,
                                                        });
                                                    }
                                                }
                                                payload.work_employer_type = work_employer_type_arr;
                                                break;
                                            /*case 'GetRequestConditions':
                                                payload.request_conditions = responses[i].data.result;
                                                break;
                                            case 'GetFirstRepaymentDate':
                                                payload.first_repayment_date = responses[i].data.result;
                                                break;
                                            case 'GetMobilePhoneCodes':
                                                payload.mobile_phone_codes = Object.values(responses[i].data.result);
                                                break;
                                            case 'GetPurchaseAddressType':
                                                let address_type_arr = [];
                                                for (let j = 0; j < Object.keys(responses[i].data.result).length; j++) {
                                                    address_type_arr[j] = {
                                                        id: Object.keys(responses[i].data.result)[j],
                                                        label: Object.values(responses[i].data.result)[j]
                                                    };
                                                }
                                                payload.address_type = address_type_arr;
                                                break;*/
                                        }
                                    }
                                    if(responses[i].data.resultCode === 2){
                                      dispatch(offerInitLoadFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
                                      break;
                                    }
                                    if(responses[i].data.resultCode === 3){
                                        history.push('/signin');
                                        dispatch(unsetToken());
                                        dispatch(notAuthorized());
                                        break;
                                    }
                                    if(responses[i].data.resultCode === 4){
                                        const validationMessage = responses[i].data.description ? responses[i].data.description : responses[i].data[0].validationMessage;
                                        dispatch(offerInitLoadFail(validationMessage));
                                        history.push("/applications");
                                        break;
                                    }
                                }
                                payload.requestID = request_id;

                                if (payload.hasOwnProperty('customer_info_for_save')) {
                                  if (payload.customer_info_for_save.customerDetails.addressList) {
                                    const addressList = payload.customer_info_for_save.customerDetails.addressList;
                                    if (addressList[0].addressType == 1) {
                                      let tmp = addressList[1];
                                      addressList[1] = addressList[0];
                                      addressList[0] = tmp;
                                    }
                                  }
                                }

                                dispatch(offerInitLoadSuccess(payload));
                            })
                            .catch(error=>{
                                dispatch(offerInitLoadFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
                            })
                    }else{
                        dispatch(offerInitLoadSuccess(payload))
                    }
                }
                else {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                }
            })
            .catch((error) => {
                history.push('/signin');
                dispatch(unsetToken());
                dispatch(notAuthorized());
                dispatch(offerInitLoadFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
            })

    }
}

export function saveRequestItems(products, prepaymentAmount) {
    return (dispatch, getState) => {
        dispatch(saveRequestItemsStart());
        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID;
        let item_types = getState().applicationReducer.item_types;

        const items = [];
        products.map((element) => {
            items.push({
                "itemId":"",
                "itemModel": element.itemModel,
                "itemPrice": element.itemPrice,
                "itemType": element.itemType,
                "itemTypeID": element.itemTypeID,
            })
        });

        let param = {
            "product": {
                "items": items,
                "requestID": request_id,
                "prepaymentAmount": parseInt(prepaymentAmount)
            }
        };

        commonUtils.apiCall('post', 'RequestData/SaveRequestItems', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id)
                            .then((response) => {
                                if (response.data.resultCode === 1) {
                                    dispatch(saveRequestItemsSuccess({acba_status: response.data.result, request_items: param.product}));
                                }
                                if (response.data.resultCode === 3) {
                                    history.push('/signin');
                                    dispatch(unsetToken());
                                    dispatch(notAuthorized());
                                }
                            })
                            .catch((error) => {

                            })
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    case 4:
                        let message = "";
                        response.data.result.map((element) => {
                            message += element.validationMessage + "\n";
                        })
                        dispatch(saveRequestItemsFail({confirm_offer_fail_message: message}));
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                dispatch(saveRequestItemsFail({confirm_offer_fail_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
            })

    }
}

export function loanTermsInitialLoad(request_id) {
    return (dispatch, getState) => {
        dispatch(loanTermsInitialLoadStart());

        const session_id = getState().initReducer.session_id;
        let payload = {};

        // Check if user session_token es not expired then make initialLoad calls
        commonUtils.apiCall('post', 'Authorization/CheckToken', {}, session_id)
            .then((authorizationTokenResponse) => {
                if (authorizationTokenResponse.data.result) {
                    //preparing required API call to make a simultaneous requests  GetRequestConditions
                    let api_calls = [];

                    if (Object.entries(getState().applicationReducer.request_conditions).length === 0) {
                      api_calls.push(
                        commonUtils.apiCall('get', 'Info/GetFactoringDateOfBeginning', {}, session_id),
                        commonUtils.apiCall('post', 'RequestOffer/GetRequestConditions', {"requestID": request_id}, session_id),
                      )
                    }

                    //if we come from offer page and offer was confirmed or page was refreshed(offer_term is empty), we need to get new conditions
                    if(Object.entries(getState().applicationReducer.mobile_phone_codes).length === 0){
                      api_calls.push(
                        commonUtils.apiCall('get', 'Info/GetMobilePhoneCodes', {}, session_id),
                        commonUtils.apiCall('get', 'RequestContract/GetFirstRepaymentDate', {}, session_id),
                        commonUtils.apiCall('get', 'Info/GetPurchaseAddressType', {}, session_id),
                        commonUtils.apiCall('get', 'RequestContract/GetBillingRequirement', {}, session_id),
                        // commonUtils.apiCall('post', 'RequestContract/GetLoanContract', {"requestID": request_id}, session_id),
                      )
                    }

                    if (getState().applicationReducer.guarantors.length === 0) {
                      api_calls.push(
                        commonUtils.apiCall('post', 'Guarantor/GetGuarantors', {"requestID": request_id}, session_id),
                      )
                    }

                    //check if UserIdentification related dictionary loaded
                    // if user comes from listing not from Step 1 that dictionary can be empty
                    if (Object.entries(getState().applicationReducer.dictionary.document_types).length === 0 && getState().applicationReducer.dictionary.document_types.constructor === Object) {
                        api_calls.push(
                            commonUtils.apiCall('get', 'Info/GetDocumentTypes', {}, session_id),
                            commonUtils.apiCall('get', 'Info/GetBuildingTypes', {}, session_id),
                            commonUtils.apiCall('get', 'Info/GetCountries', {}, session_id),
                            // commonUtils.apiCall('get', 'Info/GetRegions', {countryId: 51}, session_id),
                            commonUtils.apiCall('get', 'Info/GetArmenianPlaces', {}, session_id),
                        )
                    }

                    // if(Object.entries(getState().applicationReducer.dictionary.work_profit_type).length === 0 && getState().applicationReducer.dictionary.work_profit_type.constructor === Object) {
                    //     api_calls.push(
                    //         commonUtils.apiCall('get', 'Info/GetWorkProfitType', {}, session_id),  //ԵԿԱՄՏԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //         commonUtils.apiCall('get', 'Info/GetWorkPositionType', {}, session_id),  //ՊԱՇՏՈՆՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //         commonUtils.apiCall('get', 'Info/GetEmployerGroups', {}, session_id),  //ԱՇԽԱՏԱՎԱՅՐԵՐԻ ՏԵՂԵԿԱՏՈՒ
                    //     )
                    // }

                    // if Request already exist in ACBA database
                    // load user_info
                    if(request_id != 0 && request_id !== "" && getState().applicationReducer.customer_info_for_save.request.requestID === 0) {
                      api_calls.push(
                        commonUtils.apiCall('post', 'Person/GetCustomerAndRequestInfo', {"requestID": request_id}, session_id),
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id)
                      )
                    }

                    if (Object.entries(getState().applicationReducer.loan_contract).length === 0) {
                      api_calls.push(
                        commonUtils.apiCall('post', 'RequestContract/GetLoanContract', {"requestID": request_id}, session_id),
                      )
                    }

                    // loading /offer screens data
                    // if (Object.entries(getState().applicationReducer.offer_terms).length === 0 && getState().applicationReducer.offer_terms.constructor === Object) {
                    //     api_calls.push(
                    //         commonUtils.apiCall('post', 'RequestOffer/GetRequestOffer', {"requestID": request_id}, session_id),
                    //         commonUtils.apiCall('get', 'Info/GetItemTypes', {}, session_id),
                    //         commonUtils.apiCall('post', 'RequestData/GetRequestItems', {"requestID": request_id}, session_id)
                    //     );
                    // }

                    //check if we need to make api calls
                    if(api_calls.length > 0) {
                        Promise.all(api_calls)
                            .then((responses) => {
                                //iterate through responses and check data
                                for (let i = 0; i < responses.length; i++) {
                                    if (responses[i].data.resultCode === 1) {
                                        let call_method = responses[i].config.url.split('/').slice(-1)[0];
                                        switch (call_method) {
                                            case 'GetRequestConditions':
                                                payload.request_conditions = responses[i].data.result;
                                                break;
                                            case 'GetFactoringDateOfBeginning':
                                                payload.factoring_date_of_beginning = responses[i].data.result;
                                                break;
                                            case 'GetBillingRequirement':
                                                payload.billing_requirement = responses[i].data.result;
                                                // payload.billing_requirement = true;
                                                break;
                                            case 'GetGuarantors':
                                                let guarantorsList = responses[i].data.result;

                                                guarantorsList.map((element) => {
                                                  // setting object's keys same as in save_customer_info_for_save

                                                  element.fatherName = element.guarantorFatherName;
                                                  element.name = element.guarantorName;
                                                  element.lastName = element.guarantorLastName;

                                                  delete element.guarantorFatherName;
                                                  delete element.guarantorName;
                                                  delete element.guarantorLastName;
                                                });

                                                payload.guarantors = guarantorsList;
                                                break;
                                            case 'GetFirstRepaymentDate':
                                                payload.first_repayment_date = responses[i].data.result;
                                                break;
                                            case 'GetMobilePhoneCodes':
                                                payload.mobile_phone_codes = Object.values(responses[i].data.result);
                                                break;
                                            case 'GetPurchaseAddressType':
                                                let address_type_arr = [];
                                                for (let j = 0; j < Object.keys(responses[i].data.result).length; j++) {
                                                    address_type_arr[j] = {
                                                        id: Object.keys(responses[i].data.result)[j],
                                                        label: Object.values(responses[i].data.result)[j]
                                                    };
                                                }
                                                payload.address_type = address_type_arr;
                                                break;
                                            case 'GetDocumentTypes':
                                                if (!Array.isArray(responses[i].data.result)) {
                                                  let document_types_arr = [];
                                                  for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                      document_types_arr.push({
                                                        value: key,
                                                        label: responses[i].data.result[key]
                                                      });
                                                    }
                                                  }
                                                  payload.document_types = document_types_arr;
                                                }
                                                else {
                                                  let document_types_arr = [];
                                                  responses[i].data.result.map((element, index) => {
                                                    document_types_arr.push({
                                                      value: element.key,
                                                      label: element.value
                                                    });
                                                  });
                                                  payload.document_types = document_types_arr;
                                                }
                                                break;
                                            case 'GetBuildingTypes':
                                                let building_types_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        building_types_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.building_types = building_types_arr;
                                                break;
                                            case 'GetCountries':
                                                let countries_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        countries_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.countries = countries_arr;
                                                break;
                                            case 'GetRegions':
                                                let regions_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        regions_arr.push({
                                                            value: responses[i].data.result[key].region,
                                                            label: responses[i].data.result[key].name
                                                        });
                                                    }
                                                }
                                                payload.regions = regions_arr;
                                                break;
                                            case 'GetArmenianPlaces':
                                                let armenian_places_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        armenian_places_arr.push({
                                                            value: responses[i].data.result[key].number,
                                                            label: responses[i].data.result[key].description
                                                        });
                                                    }
                                                }
                                                payload.cities = armenian_places_arr;
                                                break;
                                            case 'GetCustomerAndRequestInfo':
                                                if (responses[i].data.result.customerDetails.addressList.length === 0) {
                                                  responses[i].data.result.customerDetails.addressList = [
                                                    {
                                                      addressType: 1,
                                                      appartment: "",
                                                      building: "",
                                                      country: "51",
                                                      house: "",
                                                      id: 0,
                                                      postCode: null,
                                                      priority: 0,
                                                      region: 0,
                                                      regionDescription: "",
                                                      street: 0,
                                                      streetDescription: "",
                                                      townVillage: 0,
                                                      townVillageDescription: "",
                                                    },
                                                    {
                                                      addressType: 2,
                                                      appartment: "",
                                                      building: "",
                                                      country: "51",
                                                      house: "",
                                                      id: 0,
                                                      postCode: null,
                                                      priority: 0,
                                                      region: 0,
                                                      regionDescription: "",
                                                      street: 0,
                                                      streetDescription: "",
                                                      townVillage: 0,
                                                      townVillageDescription: "",
                                                    }
                                                  ]
                                                }
                                                // whole data stores in customer_info_for_save
                                                payload.customer_info_for_save = responses[i].data.result;
                                                break;
                                            case 'MaxLoanDuration':
                                                payload.maxLoan = responses[i].data.result;
                                                break;
                                            case 'GetQualityByRequestID':
                                                payload.acba_status = responses[i].data.result;
                                                break;
                                            case 'GetLoanContract':
                                                if (responses[i].data.result.phoneList.length) { // if phoneList is empty => request has not been succeed
                                                  payload.loan_contract = responses[i].data.result;
                                                }
                                                break;
                                            case 'GetWorkProfitType':
                                                let work_profit_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_profit_type_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.work_profit_type = work_profit_type_arr;
                                                break;
                                            case 'GetWorkPositionType':
                                                let work_position_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_position_type_arr.push({
                                                            value: key,
                                                            label: responses[i].data.result[key]
                                                        });
                                                    }
                                                }
                                                payload.work_position_type = work_position_type_arr;
                                                break;
                                            case 'GetEmployerGroups':
                                                let work_employer_type_arr = []
                                                for (let key in responses[i].data.result) {
                                                    if (responses[i].data.result.hasOwnProperty(key)) {
                                                        work_employer_type_arr.push({
                                                            value: responses[i].data.result[key].id,
                                                            label: responses[i].data.result[key].description,
                                                            includeSubGroup: responses[i].data.result[key].includeSubGroup,
                                                            requireEmployerDescription: responses[i].data.result[key].requireEmployerDescription,
                                                        });
                                                    }
                                                }
                                                payload.work_employer_type = work_employer_type_arr;
                                                break;
                                            case 'GetRequestOffer':
                                                payload.offer_terms = responses[i].data.result;
                                                break;
                                            case 'GetItemTypes':
                                                payload.item_types = responses[i].data.result;
                                                break;
                                            case 'GetRequestItems':
                                                payload.request_items = responses[i].data.result
                                                break;
                                        }
                                    }
                                    if (responses[i].data.resultCode === 3) {
                                        history.push('/signin');
                                        dispatch(unsetToken());
                                        dispatch(notAuthorized());
                                        break;
                                    }
                                    if (responses[i].data.resultCode === 4) {
                                      const validationMessage = responses[i].data.description ? responses[i].data.description : responses[i].data[0].validationMessage;
                                      dispatch(loanTermsInitialLoadFail(validationMessage));
                                      history.push("/applications");
                                      break;
                                    }
                                }

                                if (payload.hasOwnProperty('customer_info_for_save')) {
                                  if (payload.customer_info_for_save.customerDetails.addressList) {
                                    const addressList = payload.customer_info_for_save.customerDetails.addressList;
                                    if (addressList[0].addressType == 1) {
                                      let tmp = addressList[1];
                                      addressList[1] = addressList[0];
                                      addressList[0] = tmp;
                                    }
                                  }
                                }
                                dispatch(loanTermsInitialLoadSuccess(payload));
                            })
                            .catch((error) => {
                                dispatch(loanTermsInitialLoadFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"))
                            })
                    }else{
                        dispatch(loanTermsInitialLoadSuccess(payload))
                    }
                }
                else {
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized( ));
                }
            })
            .catch(() => {
                history.push('/signin');
                dispatch(unsetToken());
                dispatch(notAuthorized());
                dispatch(offerInitLoadFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
            })
    }
}

export function confirmLoanTerms(user_contacts, first_repayment_date, billing_number) {
    return (dispatch, getState) => {
        dispatch(confirmLoanTermsStart());

        const session_id = getState().initReducer.session_id;
        const user_info = getState().applicationReducer.customer_info_for_save.request;
        const request_id = user_info.requestID;

        let user_phone_list = [];
        let user_email_list = [];

        user_contacts.phoneNumbers.map((element, index) => {
            user_phone_list.push({
                areaCode: element.telCode,
                number: element.telNumber
            });
        });

        user_contacts.emails.map((element, index) => {
            element.email && user_email_list.push(element.email);
        });

        let param = {
            "loanContract": {
                "requestID": request_id,
                "customerNumber": user_info.customerDetails.customerNumber,
                "phoneList": user_phone_list,
                "emailList": user_email_list,
                "firstRepaymentDay": first_repayment_date,
                "purchaseAddressType": user_contacts.purchaseAddressType,
                "purchaseAddress": user_contacts.purchaseAddress,
                "action": user_info.action,
                "billingNumber": billing_number ? billing_number : "",
            }
        };

        commonUtils.apiCall('post', 'RequestContract/SaveLoanContract', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                      dispatch(confirmLoanTermsSuccess());
                      history.push('/success');
                      break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    case 4:
                        dispatch(confirmLoanTermsFail({error_message: response.data.description}));
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                dispatch(confirmLoanTermsFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}))
            })
    }
}

export function downloadAllContracts() {
    return (dispatch, getState) => {
        dispatch(downloadAllContractsStart());
        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID; // 1242230
        // let request_id = 1242230;

        let param = {
            "requestID": request_id,
        };

        commonUtils.apiCall('post', 'RequestContract/DownloadAllContracts', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        let all_contracts = response.data.result;
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', param, session_id)
                            .then((response) => {
                                if (response.data.resultCode === 1) {
                                    dispatch(getPrizeOffer());
                                    dispatch(downloadAllContractsSuccess( {all_contracts_pdf: all_contracts, acba_status: response.data.result} ));
                                }
                                if (response.data.resultCode === 3) {
                                    history.push('/signin');
                                    dispatch(unsetToken());
                                    dispatch(notAuthorized());
                                }
                            })
                            .catch((error) => {

                            })
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    case 4:
                        dispatch(downloadAllContractsFail(response.data.result[0].validationMessage));
                        break;
                }
            })
            .catch((error) => {
                dispatch(downloadAllContractsFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
            })
    }
}

export function getPrizeOffer() {
    return (dispatch, getState) => {
        dispatch(getPrizeOfferStart());
        const session_id = getState().initReducer.session_id;
        const { requestID } = getState().applicationReducer.customer_info_for_save.request || 1;
        const params = { requestID };

        commonUtils.apiCall('post', 'PrizeOffer/GetPrizeOffer', params, session_id)
          .then((response) => {
            switch (response.data.resultCode) {
                case 1:
                    if (response.data?.result) {
                        const { amount } = response.data.result;
                        dispatch(getPrizeOfferSuccess({ amount }))
                    } else {
                        dispatch(getPrizeOfferFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
                    }
                    break;
                case 3:
                    history.push('/signin');
                    dispatch(unsetToken());
                    dispatch(notAuthorized());
                    break;
                case 4:
                    dispatch(getPrizeOfferFail(response.data.result[0].validationMessage));
                    break;
                default:
            }
          })
          .catch(() => dispatch(getPrizeOfferFail('Կապի խնդիր. խնդրում ենք կրկին փորձել')));
    };
}

export function getRefuseReasonTypes() {
    return (dispatch, getState) => {
        dispatch(getRefuseReasonTypesStart());

        let session_id = getState().initReducer.session_id;
        commonUtils.apiCall('get', 'Info/GetRefuseReasonTypes', {}, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        dispatch(getRefuseReasonTypesSuccess({refuse_reason_types: response.data.result}));
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {

            })
    }
}

export function cancelRequest(refuse_reason) {
    return (dispatch, getState) => {
        dispatch(cancelRequestStart());
        let session_id = getState().initReducer.session_id;
        let request_id = getState().applicationReducer.customer_info_for_save.request.requestID;

        refuse_reason = refuse_reason.replace(/,\s*$/, "");

        let param = {
            "requestID": request_id,
            "refuseCause": refuse_reason,
        };

        commonUtils.apiCall('post', 'RequestData/CancelRequest', param, session_id)
            .then((response) => {
                switch (response.data.resultCode) {
                    case 1:
                        commonUtils.apiCall('post', 'Person/GetQualityByRequestID', {"requestID": request_id}, session_id)
                            .then((response) => {
                                if (response.data.resultCode === 1) {
                                    history.push('/refused');
                                    dispatch(cancelRequestSuccess({acba_status: response.data.result}))

                                }
                                if (response.data.resultCode === 3) {
                                    history.push('/signin');
                                    dispatch(unsetToken());
                                    dispatch(notAuthorized());
                                }
                            })
                            .catch((error) => {
                                dispatch(cancelRequestFail({error_message: "Կապի խնդիր. խնդրում ենք կրկին փորձել"}));
                            })
                        break;
                    case 3:
                        history.push('/signin');
                        dispatch(unsetToken());
                        dispatch(notAuthorized());
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {

            })
    }
}

export function getArmenianPlacesByRegionId(region_id, region_type) {
    return (dispatch, getState) => {
        dispatch(getArmenianPlacesByRegionIdStart());
        const session_id = getState().initReducer.session_id;

        commonUtils.apiCall("get", `Info/GetArmenianPlaces?RegionID=${region_id}`, {}, session_id)
            .then((response) => {
                if (response.data.resultCode === 1) {
                    dispatch(getArmenianPlacesByRegionIdSuccess({cities: response.data.result, region_type}));
                }
                if (response.data.resultCode === 3) {
                  history.push('/signin');
                  dispatch(unsetToken());
                  dispatch(notAuthorized());
                }
            })
            .catch((error) => {
                dispatch(getArmenianPlacesByRegionIdFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
            })

    }
}

export function restoreCancelRequest(requestID) {
  return (dispatch, getState) => {
    dispatch(restoreCancelRequestStart());
    const session_id = getState().initReducer.session_id;

    const param = {
      requestID,
    };

    commonUtils.apiCall('post', 'RequestData/RestoreCancelRequest', param, session_id)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            commonUtils.apiCall('post', 'Person/GetQualityByRequestID', param, session_id)
              .then((response) => {
                if (response.data.resultCode === 1) {
                  dispatch(restoreCancelRequestSuccess({acba_status: response.data.result}));
                }
                else if (response.data.resultCode === 3) {
                  history.push('/signin');
                  dispatch(unsetToken());
                  dispatch(notAuthorized());
                }
                else if (response.data.resultCode === 4) {
                  dispatch(restoreCancelRequestFail(response.data.result[0].validationMessage));
                }
              })
              .catch(() => {
                dispatch(restoreCancelRequestFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
              })
            break;
          case 2:
            dispatch(restoreCancelRequestFail('Some Error'));
            break;
          case 3:
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
            break;
          case 4:
            dispatch(restoreCancelRequestFail(response.data.description));
            break;
        }
      })
      .catch(() => {
        dispatch(restoreCancelRequestFail('Կապի խնդիր. խնդրում ենք կրկին փորձել'));
      });
  }
}

export function fillUserIncomes(user_incomes_info, index) {
  return (dispatch, getState) => {
    dispatch(fillUserIncomesStart());
    const session_id = getState().initReducer.session_id;
    let birth_day = getState().applicationReducer.customer_info_for_save.request.customerDetails.dateOfBirth;
    let param = {
      "salary": user_incomes_info.money,
      "birthday": birth_day
    };

    commonUtils.apiCall('post', 'Info/GetClearSalary', param, session_id)
      .then((response) => {
        switch (response.data.resultCode) {
          case 1:
            user_incomes_info.clearMoney = response.data.result;
            dispatch(fillUserIncomesSuccess({
              user_incomes_info,
              index
            }));
            break;
          case 3:
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
            break;
          case 4:
            dispatch(fillUserIncomesFail(response.data.result[0].validationMessage));
          default:
            break;
        }
      })
      .catch((error) => {
        dispatch(getClearSalaryFail("Տեղի ունեցավ սխալ․ մաքուր աշխատավարձը հաշվարկելիս"));
      })
  }
}
//=================== State ======================

const initial_state = {
    dictionary: {
        building_types: {},
        document_types: {},
        countries: {},
        cities: {},
        residence_cities: {},
        regions: {},
        residence_regions: {},
        actions: {}, //ԱՐՇԱՎՆԵՐԻ ՑԱՆԿԻ ՏԵՂԵԿԱՏՈՒ
        work_profit_type: {},  //ԵԿԱՄՏԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
        work_reference_type: [], //ՀԻՄՔԻ ՏԵՍԱԿՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
        work_position_type: {}, //ՊԱՇՏՈՆՆԵՐԻ ՏԵՂԵԿԱՏՈՒ
        work_employer_type: {}, //ԱՇԽԱՏԱՎԱՅՐԵՐԻ ՏԵՂԵԿԱՏՈՒ
        employer_type_by_group: [],
    },
    income_temp_dictionary:{
        work_reference_type: [],
        employer_type_by_group: []
    },
    error_message: {
        type: '',
        message: '',
    },
    max_loan: 0,
    period: 0,
    loading: {
        type: "",
        loading_state: false,
    },
    request_status: 'new', //cant take the following values(identified, saved, rejected, approved, sent_to_bank)
    acba_status: 0,  // (new, send_to_bank, sent_to_bank,
    user_info:{}, // can be deleted when initLoad will refactored with customer_info_for_save
    customer_info_for_save: {
        "request": {
            "action": 0,
            "startCapital": "",
            "periodRequested": "",
            "addInf": "",
            "refuseCause": "",
            "requestID": 0,
            "customerDetails": {
                "address": "",
                "addressList": [],
                "applicationID": 0,
                "applicationProcessingStatus": 0,
                "automatScoreRejectionReason": "",
                "automatScoreStep": "",
                "citizenship": "",
                "customerIdentificationGroup": 0,
                "customerNumber": 0,
                "customerPhoto": "",
                "customerQuality": 0,
                "dateOfBirth": "",
                "documentDate": "",
                "documentInf": "",
                "documentNumber": "",
                "documentType": "",
                "documentValidDate": "",
                "familyCount": 0,
                "familyWorkingCount": 0,
                "fatherName": "",
                "filialCode": 0,
                "guarantStatusQuality": 0,
                "homePhone": "",
                "lastName": "",
                "maritalStatus": "",
                "marketPhone": "",
                "mobilePhone": "",
                "name": "",
                "notDocSalary": 0,
                "notDocSalarySource": "",
                "offeredCustomerNumber": 0,
                "otherDocSalary": 0,
                "otherPhone": "",
                "quality": 0,
                "realAddress": "",
                "requestQualityDescr": "",
                "resident": "",
                "scoreResult": 0,
                "socialNumber": ""
            },
            "personWorkInfList": []
        }
    },
    calculate_request: {},
    searching_streets: [],
    residence_searching_streets: [],
    searching_street_name: '',
    searching_street_id: '',
    searching_street_region_description: '',
    searching_street_townVillage_description: '',
    request_items: {
        items: [{
            "itemPrice": "",
        }],
        requestID: "",
        prepaymentAmount: "",
    },
    item_types: [],
    address_type: [],
    clear_money: '',
    offer_terms: {},
    // user_income: [],
    request_conditions: {},
    loan_contract: {},
    guarantor_info: {},
    guarantors: [],
    guarantor_address_list: [],
    hide_guarantor_result: false,
    mobile_phone_codes: {},
    first_repayment_date: '',
    refuse_reason_types: {},
    isNewRequestPermissionCalledFromNewIdentification: false,
    factoring_date_of_beginning: '',
    agreementPDF: '',
    guarantorPDF: '',
    billing_requirement: false,
    userIncomeChanged: false,
    prizeWinAmount: 0,
    all_contracts_pdf: [],
}

export default function applicationReducer(state=initial_state, action){
    switch (action.type){
        case NOT_AUTHORIZED:
            return produce(state, draft => {
                draft.loading.type = "";
                draft.loading.loading_state = false;
                draft.error_message.type = "NotAuthorized";
                draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
                draft.user_info = {};
                draft.agreementPDF = '';
                draft.guarantorPDF = '';
                draft.all_contracts_pdf = [];
                draft.searching_streets = [];
                draft.calculate_request = {};
                draft.user_income = [];
                draft.request_status = 'new';
                draft.acba_status = 0;
                draft.customer_info_for_save = {
                  "request": {
                    "action": 0,
                    "startCapital": "",
                    "periodRequested": draft.max_loan,
                    "addInf": "",
                    "refuseCause": "",
                    "requestID": 0,
                    "customerDetails": {
                      "address": "",
                      "addressList": [],
                      "applicationID": 0,
                      "applicationProcessingStatus": 0,
                      "automatScoreRejectionReason": "",
                      "automatScoreStep": "",
                      "citizenship": "",
                      "customerIdentificationGroup": 0,
                      "customerNumber": 0,
                      "customerPhoto": "",
                      "customerQuality": 0,
                      "dateOfBirth": "",
                      "documentDate": "",
                      "documentInf": "",
                      "documentNumber": "",
                      "documentType": "",
                      "documentValidDate": "",
                      "familyCount": 0,
                      "familyWorkingCount": 0,
                      "fatherName": "",
                      "filialCode": 0,
                      "guarantStatusQuality": 0,
                      "homePhone": "",
                      "lastName": "",
                      "maritalStatus": "",
                      "marketPhone": "",
                      "mobilePhone": "",
                      "name": "",
                      "notDocSalary": 0,
                      "notDocSalarySource": "",
                      "offeredCustomerNumber": 0,
                      "otherDocSalary": 0,
                      "otherPhone": "",
                      "quality": 0,
                      "realAddress": "",
                      "requestQualityDescr": "",
                      "resident": "",
                      "scoreResult": 0,
                      "socialNumber": ""
                    },
                    "personWorkInfList": []
                  }
                };
                draft.guarantor_info = {};
                draft.guarantors = [];
                draft.guarantor_address_list = [];
                draft.request_items = {
                  items: [{
                    "itemPrice": "",
                  }],
                  requestID: "",
                  prepaymentAmount: "",
                };
                draft.offer_terms = {};
                draft.request_conditions = {};
                draft.loan_contract = {};
                draft.first_repayment_date = '';
                draft.mobile_phone_codes = {};
                draft.billing_requirement = false;
            })
        case INIT_APP_START:
            return produce(state, draft => {
                draft.loading.type = "init_app_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case INIT_APP_SUCCESS:
            return produce(state,draft => {
                // draft.dictionary = action.payload.dictionary;
                draft.loading.type = "init_app_loading";
                draft.loading.loading_state = false;
                // draft.period = action.payload.period;

                action.payload.loaded_data.hasOwnProperty("document_types") && (draft.dictionary.document_types = action.payload.loaded_data.document_types);
                action.payload.loaded_data.hasOwnProperty("building_types") && (draft.dictionary.building_types = action.payload.loaded_data.building_types);
                action.payload.loaded_data.hasOwnProperty("countries") && (draft.dictionary.countries = action.payload.loaded_data.countries);
                action.payload.loaded_data.hasOwnProperty("regions") && (draft.dictionary.regions = action.payload.loaded_data.regions);
                action.payload.loaded_data.hasOwnProperty("regions") && (draft.dictionary.residence_regions = action.payload.loaded_data.regions);
                action.payload.loaded_data.hasOwnProperty("actions") && (draft.dictionary.actions = action.payload.loaded_data.actions);
                action.payload.loaded_data.hasOwnProperty("work_profit_type") && (draft.dictionary.work_profit_type = action.payload.loaded_data.work_profit_type);
                action.payload.loaded_data.hasOwnProperty("work_position_type") && (draft.dictionary.work_position_type = action.payload.loaded_data.work_position_type);
                action.payload.loaded_data.hasOwnProperty("work_employer_type") && (draft.dictionary.work_employer_type = action.payload.loaded_data.work_employer_type);
                if (action.payload.loaded_data.hasOwnProperty("maxLoan") && !action.payload.loaded_data.hasOwnProperty("customer_info_for_save")) {
                    draft.period = action.payload.loaded_data.maxLoan;
                    draft.max_loan = action.payload.loaded_data.maxLoan;
                    draft.customer_info_for_save.request.periodRequested = action.payload.loaded_data.maxLoan;
                } else if (action.payload.loaded_data.hasOwnProperty("customer_info_for_save")) {
                  draft.max_loan = action.payload.loaded_data.maxLoan;
                  draft.period = action.payload.loaded_data.customer_info_for_save.periodRequested;
                }
                action.payload.loaded_data.hasOwnProperty("user_info") && (draft.user_info = action.payload.loaded_data.user_info);
                action.payload.loaded_data.hasOwnProperty("customer_info_for_save") && (draft.customer_info_for_save.request = action.payload.loaded_data.customer_info_for_save);
                action.payload.loaded_data.hasOwnProperty("sum_need") && (draft.customer_info_for_save.request.startCapital = action.payload.loaded_data.sum_need);
                action.payload.loaded_data.hasOwnProperty("requestID") && (draft.customer_info_for_save.request.requestID = action.payload.loaded_data.requestID);
                action.payload.loaded_data.hasOwnProperty("work_inf") && (draft.customer_info_for_save.request.personWorkInfList = action.payload.loaded_data.work_inf);
                action.payload.loaded_data.hasOwnProperty("acba_status") && (draft.acba_status = action.payload.loaded_data.acba_status);
                action.payload.loaded_data.hasOwnProperty("agreementPDF") && (draft.agreementPDF = action.payload.loaded_data.agreementPDF);
                action.payload.loaded_data.hasOwnProperty("all_contracts_pdf") && (draft.all_contracts_pdf = action.payload.loaded_data.all_contracts_pdf);
                switch (draft.acba_status) {
                    case 0:
                        draft.request_status = "new";
                        break;
                    case 1:
                        draft.request_status = "saved";
                        break;
                    case 4:
                      draft.request_status = "edit";
                      break;
                    case 5:
                        draft.request_status = "loan_success";
                        break;
                    case 8:
                        draft.request_status = "calc_analyse_in_bank";
                        break;
                    case 9:
                        if (state.request_status !== "edit") { // ete offer-i screen-i edit-ov chen ekel, nor sarqel offer_success, vor flow@ chxaxtvi
                          draft.request_status = "offer_success";
                        }
                        break;
                    default:
                        draft.request_status = "";
                        break;
                }
            })
        case INIT_APP_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "InitApp";
                draft.error_message.message = action.payload;

                draft.loading.type = "init_app_loading";
                draft.loading.loading_state = false;
            })
        case CLEAR_ERROR_MESSAGE:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case CLEAR_REQUEST_STATUS:
            return produce(state, draft => {
                draft.request_status = "";
            })
        case GET_MAX_LOAN_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case GET_MAX_LOAN_SUCCESS:
            return produce(state, draft => {
                draft.period = action.payload.max_loan;
                draft.max_loan = action.payload.max_loan;
                draft.customer_info_for_save.request.periodRequested = action.payload.max_loan;
            })
        case GET_MAX_LOAN_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "GetMaxLoan";
                draft.error_message.message = action.payload.error_message;
            });
        case GET_WORK_REFERENCE_TYPES_SUCCESS:
            let formatted_obj=[];

            for (let key in action.payload.work_reference_types) {
                if (action.payload.work_reference_types.hasOwnProperty(key)) {
                    formatted_obj.push({
                        value: key,
                        label: action.payload.work_reference_types[key]
                    });
                }
            }

            return produce(state,draft => {
                draft.dictionary.work_reference_type = formatted_obj;
            })
        case GET_WORK_REFERENCE_TYPES_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "GetWorkReferenceType";
                draft.error_message.message = action.payload.error_message;
            });
        case IDENTIFICATION_START:
            return produce(state,draft => {
                draft.loading.type = "identification_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case IDENTIFICATION_SUCCESS:
            return produce(state,draft => {
                draft.loading.type = "identification_loading";
                draft.loading.loading_state = false;
                draft.request_status = 'identified';
                draft.searching_streets = action.payload.default_searching_street;
                draft.customer_info_for_save.request.customerDetails = action.payload.customer_info_for_save;
                draft.dictionary.cities = action.payload.cities;
                draft.dictionary.residence_cities = action.payload.cities;
                draft.dictionary.regions = action.payload.regions;
                draft.dictionary.residence_regions = action.payload.regions;
            })
        case IDENTIFICATION_FAIL:
            return produce(state,draft => {
                draft.loading.type = "identification_loading";
                draft.loading.loading_state = false;
                draft.error_message.type = "PersonIdentification";
                draft.error_message.message = action.payload.error_message;
            })
        case NEW_IDENTIFICATION_START:
            return produce(state, draft => {
              draft.isNewRequestPermissionCalledFromNewIdentification = false;
            })
        case NEW_IDENTIFICATION_SUCCESS:
            return produce(state,draft => {
                draft.isNewRequestPermissionCalledFromNewIdentification = true;
                draft.loading.type = "new_identification_loading";
                draft.loading.loading_state = false;
                draft.user_info = {};
                draft.agreementPDF = '';
                draft.guarantorPDF = '';
                draft.all_contracts_pdf = [];
                draft.searching_streets = [];
                draft.calculate_request = {};
                draft.user_income = [];
                draft.request_status = 'new';
                draft.acba_status = 0;
                draft.customer_info_for_save = {
                    "request": {
                        "action": 0,
                        "startCapital": "",
                        "periodRequested": draft.max_loan,
                        "addInf": "",
                        "refuseCause": "",
                        "requestID": 0,
                        "customerDetails": {
                        "address": "",
                            "addressList": [],
                            "applicationID": 0,
                            "applicationProcessingStatus": 0,
                            "automatScoreRejectionReason": "",
                            "automatScoreStep": "",
                            "citizenship": "",
                            "customerIdentificationGroup": 0,
                            "customerNumber": 0,
                            "customerPhoto": "",
                            "customerQuality": 0,
                            "dateOfBirth": "",
                            "documentDate": "",
                            "documentInf": "",
                            "documentNumber": "",
                            "documentType": "",
                            "documentValidDate": "",
                            "familyCount": 0,
                            "familyWorkingCount": 0,
                            "fatherName": "",
                            "filialCode": 0,
                            "guarantStatusQuality": 0,
                            "homePhone": "",
                            "lastName": "",
                            "maritalStatus": "",
                            "marketPhone": "",
                            "mobilePhone": "",
                            "name": "",
                            "notDocSalary": 0,
                            "notDocSalarySource": "",
                            "offeredCustomerNumber": 0,
                            "otherDocSalary": 0,
                            "otherPhone": "",
                            "quality": 0,
                            "realAddress": "",
                            "requestQualityDescr": "",
                            "resident": "",
                            "scoreResult": 0,
                            "socialNumber": ""
                    },
                        "personWorkInfList": []
                    }
                };
                draft.guarantor_info = {};
                draft.guarantors = [];
                draft.guarantor_address_list = [];
                draft.request_items = {
                    items: [{
                        "itemPrice": "",
                    }],
                    requestID: "",
                    prepaymentAmount: "",
                };
                draft.offer_terms = {};
                draft.request_conditions = {};
                draft.loan_contract = {};
                draft.first_repayment_date = '';
                draft.mobile_phone_codes = {};
                draft.prizeWinAmount = 0;
                draft.billing_requirement = false;
            })
        case NEW_IDENTIFICATION_FAIL:
            return produce(state, draft => {

            })
        case EDIT_REQUEST_TERMS:
            return produce(state, draft => {
                draft.request_status = "edit";
            })
        case EDIT_USER_INFO:
            return produce(state, draft => {
                draft.request_status = "edit_user_info";
                draft.agreementPDF = "";
            })
        case STREET_SEARCH:
            return produce(state, draft => {
                if (action.payload.addressType === "registrationAddress") {
                  draft.searching_streets = action.payload.searching_streets;
                }
                else {
                  draft.residence_searching_streets = action.payload.searching_streets;;
                }
            })
        case STREET_SEARCH_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case STREET_SEARCH_SELECT:
            return produce(state, draft => {
                let {found_street, id} = action.payload;

                if (!found_street.building) {
                  found_street.building = "";
                }

                if (action.payload.type === 'person') {
                    if (draft.customer_info_for_save.request.customerDetails.addressList != null && draft.customer_info_for_save.request.customerDetails.addressList[id] !== undefined) {
                        draft.customer_info_for_save.request.customerDetails.addressList[id] = found_street;
                    }
                    else {
                        if (draft.customer_info_for_save.request.customerDetails.addressList != null) {
                            draft.customer_info_for_save.request.customerDetails.addressList.push(found_street);
                        }
                        else {
                            draft.customer_info_for_save.request.customerDetails.addressList = [found_street];
                        }
                    }
                }

                if (action.payload.type === 'guarantor') {
                    draft.guarantor_info.addressList[id] = found_street;
                    /*draft.guarantor_address_list[id].streetDescription = action.payload.text;
                    draft.guarantor_address_list[id].street = action.payload.value;
                    draft.guarantor_address_list[id].regionDescription = action.payload.region;
                    draft.guarantor_address_list[id].townVillageDescription = action.payload.townVillage;
                    draft.guarantor_address_list[id].house  = "";
                    draft.guarantor_address_list[id].appartment  = "";*/
                }
            })
        case GET_REGIONS_START:
          return produce(state, draft => {

          })
        case GET_REGIONS_SUCCESS:
          return produce(state, draft => {
            if (action.payload.id == 1) {
              draft.dictionary.regions = action.payload.regions;
            } else {
              draft.dictionary.residence_regions = action.payload.regions;
            }
          })
        case GET_REGIONS_FAIL:
          return produce(state, draft => {
            draft.error_message.type = "GetRegions";
            draft.error_message.message = action.payload;
          })
        case CLEAR_RESIDENCE_ADDRESS:
            return produce(state, draft => {
                if (draft.customer_info_for_save.request.customerDetails.addressList[1] !== undefined) {
                    draft.customer_info_for_save.request.customerDetails.addressList.splice(1, 1);
                }
            })
        case STREET_SEARCH_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "StreetSearchFail";
                draft.error_message.message = action.payload.error_message;
            })
        case CONFIRM_CUSTOMER_INFO:
            return produce(state, draft => {
                draft.request_status = "confirmed";
                draft.customer_info_for_save.request.addInf = action.payload.customerDetails.addInf;
                draft.customer_info_for_save.request.customerDetails = action.payload.customerDetails.customerDetailsInfo;
            })
        case SAVE_CUSTOMER_INFO_START:
            return produce(state, draft => {
                draft.loading.type = "save_customer_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
                // draft.request_status = draft.request_status !== 'calc_analyse_in_bank' ? "save_start" : 'calc_analyse_in_bank';

                if (draft.item_types.length !== 0) {
                    draft.item_types = [];
                    draft.address_type = {};
                    draft.offer_terms = {};
                }
            })
        case SAVE_CUSTOMER_INFO_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "save_customer_loading";
                draft.loading.loading_state = false;

                draft.customer_info_for_save.request.requestID = action.payload.requestID;
                draft.request_status = "saved";
                draft.acba_status = action.payload.acba_status;  // check with Taguhi
            })
        case SAVE_CUSTOMER_INFO_FAIL:
            return produce(state, draft => {
                draft.loading.type = "save_customer_loading";
                draft.loading.loading_state = false;
                draft.error_message = action.payload.error_message;
                // draft.request_status = "save_fail";
            })
        case DELETE_USER_INCOME:
            return produce(state, draft => {
                draft.customer_info_for_save.request.personWorkInfList.splice(action.payload.index, 1);
                draft.income_temp_dictionary.work_reference_type.splice(action.payload.index, 1);
                draft.income_temp_dictionary.employer_type_by_group.splice(action.payload.index, 1);
            })
        case CALCULATE_REQUEST_START:
            return produce(state, draft => {
                draft.loading.type = "calculating_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case CALCULATE_REQUEST_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "calculating_loading";
                draft.loading.loading_state = false;
                draft.request_status = action.payload.request_status;
                draft.acba_status = action.payload.acba_status;
                draft.calculate_request = action.payload.calculate_request;
                if (action.payload.request_status === "calc_rejection") {
                  draft.customer_info_for_save.request.rejectionReason = action.payload.calculate_request;
                }
            })
        case CALCULATE_REQUEST_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "CalculateRequestOffer";
                draft.error_message.message = action.payload.error_message;
                draft.loading.type = "calculating_loading";
                draft.loading.loading_state = false;
                // draft.request_status = 'send_to_bank';
            })
        case SEND_TO_BANK_START:
            return produce(state, draft => {
                draft.loading.type = "send_to_bank";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case SEND_TO_BANK_SUCCESS:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.request_status = 'sent_to_bank_success';
                draft.acba_status = action.payload.acba_status;
            })
        case SEND_TO_BANK_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "SendToBank";
                draft.error_message.message = action.payload.error_message;
            })
        case GET_CLEAR_SALARY_START:
            return produce(state, draft => {
                draft.loading.type = "clear_salary_loading";
                draft.loading.loading_state = true;

                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case RESET_CLEAR_SALARY:
            return produce(state, draft => {
                draft.clear_money = '';
            })
        case GET_CLEAR_SALARY_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "clear_salary_loading";
                draft.loading.loading_state = false;

                draft.clear_money = action.payload.clear_money;
            })
        case GET_CLEAR_SALARY_FAIL:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.error_message.type = "GetClearSalary";
                draft.error_message.message = action.payload;
            })
        case FILL_USER_INCOMES_START:
          return produce(state, draft => {
            draft.loading.type = "fill_user_incomes";
            draft.loading.loading_state = true;

            draft.error_message.type = "";
            draft.error_message.message = "";
            draft.userIncomeChanged = false;
          })
        case FILL_USER_INCOMES_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "fill_user_incomes";
                draft.loading.loading_state = false;
                draft.userIncomeChanged = true;
                //if we have index we edit already exist user income
                if(action.payload.index !== undefined){
                    draft.customer_info_for_save.request.personWorkInfList[action.payload.index] = action.payload.user_incomes_info;

                    //edit loaded dictionaries
                    draft.income_temp_dictionary.work_reference_type[action.payload.index] = state.dictionary.work_reference_type;
                    draft.income_temp_dictionary.employer_type_by_group[action.payload.index] = state.dictionary.employer_type_by_group;
                    draft.dictionary.work_reference_type = [];
                    draft.dictionary.employer_type_by_group = [];
                }else{
                    draft.customer_info_for_save.request.personWorkInfList.push(action.payload.user_incomes_info);

                    //save loaded dictionaries
                    draft.income_temp_dictionary.work_reference_type.push(state.dictionary.work_reference_type);
                    draft.income_temp_dictionary.employer_type_by_group.push(state.dictionary.employer_type_by_group);
                    draft.dictionary.work_reference_type = [];
                    draft.dictionary.employer_type_by_group = [];
                }
                // draft.clear_money = '';
            })
        case FILL_USER_INCOMES_FAIL:
          return produce(state, draft => {
            draft.loading.loading_state = false;
            draft.error_message.type = "FillUserIncomes";
            draft.error_message.message = action.payload;

            draft.userIncomeChanged = false;
          })
        case OFFER_TERMS_LOAD_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case OFFER_TERMS_LOAD_SUCCESS:
            return produce(state, draft => {
                draft.offer_terms = action.payload.offer_terms;
            })
        case OFFER_INIT_LOAD_START:
            return produce(state, draft => {
                draft.loading.type = "offer_init_load_loading";
                draft.loading.loading_state = true;

                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case OFFER_INIT_LOAD_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "offer_init_load_loading";
                draft.loading.loading_state = false;
                action.payload.hasOwnProperty("requestID") && (draft.customer_info_for_save.request.requestID = action.payload.requestID);
                action.payload.hasOwnProperty("item_types") && (draft.item_types = action.payload.item_types);
                action.payload.hasOwnProperty("offer_terms") && (draft.offer_terms = action.payload.offer_terms);
                action.payload.hasOwnProperty("user_info") && (draft.user_info = action.payload.user_info);
                action.payload.hasOwnProperty("sum_need") && (draft.customer_info_for_save.request.startCapital = action.payload.sum_need);
                action.payload.hasOwnProperty("user_income") && (draft.user_income = action.payload.user_income);
                action.payload.hasOwnProperty("acba_status") && (draft.acba_status = action.payload.acba_status);
                action.payload.hasOwnProperty("customer_info_for_save") && (draft.customer_info_for_save.request = action.payload.customer_info_for_save);
                action.payload.hasOwnProperty("request_items") && (draft.request_items = action.payload.request_items.items.length !== 0 ? action.payload.request_items : draft.request_items);
                action.payload.hasOwnProperty("document_types") && (draft.dictionary.document_types = action.payload.document_types);
                action.payload.hasOwnProperty("actions") && (draft.dictionary.actions = action.payload.actions);
                if (action.payload.hasOwnProperty("maxLoan")) {
                    draft.period = action.payload.maxLoan;
                    draft.max_loan = action.payload.maxLoan;
                }
                action.payload.hasOwnProperty("building_types") && (draft.dictionary.building_types = action.payload.building_types);
                action.payload.hasOwnProperty("countries") && (draft.dictionary.countries = action.payload.countries);
                action.payload.hasOwnProperty("regions") && (draft.dictionary.regions = action.payload.regions);
                action.payload.hasOwnProperty("regions") && (draft.dictionary.residence_regions = action.payload.regions);
                action.payload.hasOwnProperty("cities") && (draft.dictionary.cities = action.payload.cities);
                action.payload.hasOwnProperty("work_profit_type") && (draft.dictionary.work_profit_type = action.payload.work_profit_type);
                action.payload.hasOwnProperty("work_position_type") && (draft.dictionary.work_position_type = action.payload.work_position_type);
                action.payload.hasOwnProperty("work_employer_type") && (draft.dictionary.work_employer_type = action.payload.work_employer_type);
                action.payload.hasOwnProperty("agreementPDF") && (draft.agreementPDF = action.payload.agreementPDF);
                action.payload.hasOwnProperty("all_contracts_pdf") && (draft.all_contracts_pdf = action.payload.all_contracts_pdf);
                draft.request_status = 'offer_success'
              /*action.payload.hasOwnProperty("request_conditions") && (draft.request_conditions = action.payload.request_conditions);
              action.payload.hasOwnProperty("first_repayment_date") && (draft.first_repayment_date = action.payload.first_repayment_date);
              action.payload.hasOwnProperty("mobile_phone_codes") && (draft.mobile_phone_codes = action.payload.mobile_phone_codes);
              action.payload.hasOwnProperty("address_type") && (draft.address_type = action.payload.address_type);*/
            })
        case OFFER_INIT_LOAD_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "GetRequestOffer";
                draft.error_message.message = action.payload;
                draft.loading.type = "offer_init_load_loading";
                draft.loading.loading_state = false;
            })
        case HANDLE_USER_INCOME:
            return produce(state, draft => {
                draft.user_income = action.payload;
            })
        case SHOW_INCOME_MODAL:
            const {index} = action.payload;
            return produce(state, draft => {
                draft.userIncomeChanged = false;
                if (index != null) {
                  draft.dictionary.work_reference_type = draft.income_temp_dictionary.work_reference_type[index];
                  draft.dictionary.employer_type_by_group = draft.income_temp_dictionary.employer_type_by_group[index];
                }
            })
        case GUARANTOR_IDENTIFICATION_START:
            return produce(state,draft => {
                draft.loading.type = "guarantor_identification_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
                draft.guarantorPDF = "";
            })
        case GUARANTOR_IDENTIFICATION_SUCCESS:
            return produce(state,draft => {
                draft.loading.type = "guarantor_identification_loading";
                draft.loading.loading_state = false;
                draft.guarantor_info = action.payload.guarantor_data;
                draft.searching_streets = action.payload.default_searching_street;
                draft.guarantor_address_list = action.payload.guarantor_address_list;
                draft.hide_guarantor_result = draft.hide_guarantor_result && false;
                draft.dictionary.cities = action.payload.cities;
                draft.dictionary.regions = action.payload.regions;
            })
        case GUARANTOR_IDENTIFICATION_FAIL:
            return produce(state,draft => {
                draft.loading.type = "guarantor_identification_loading";
                draft.loading.loading_state = false;

                draft.error_message.type = "GuarantorIdentification";
                draft.error_message.message = action.payload.error_message;
            })
        case GUARANTOR_SAVE_START:
            return produce(state, draft => {
                draft.loading.type = "guarantor_save_loading";
                draft.loading.loading_state = true;

                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case GUARANTOR_SAVE_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "guarantor_save_loading";
                draft.loading.loading_state = false;

                draft.guarantors.push(action.payload.guarantors);
                draft.hide_guarantor_result = true;
                draft.guarantorPDF = "";
            })
        case GUARANTOR_SAVE_FAIL:
            return produce(state, draft => {
                draft.loading.type = "guarantor_save_loading";
                draft.loading.loading_state = false;
                draft.error_message.type = "GuarantorIdentification";
                draft.error_message.message = action.payload.error_message;
            })
        case LOAN_TERMS_INITIAL_LOAD_START:
            return produce(state, draft => {
                draft.loading.type = "loan_terms_loading";
                draft.loading.loading_state = true;

                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case LOAN_TERMS_INITIAL_LOAD_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "loan_terms_loading";
                draft.loading.loading_state = false;

                action.payload.hasOwnProperty("request_conditions") && (draft.request_conditions = action.payload.request_conditions);
                action.payload.hasOwnProperty("billing_requirement") && (draft.billing_requirement = action.payload.billing_requirement);
                action.payload.hasOwnProperty("guarantors") && (draft.guarantors = action.payload.guarantors);
                action.payload.hasOwnProperty("first_repayment_date") && (draft.first_repayment_date = action.payload.first_repayment_date);
                action.payload.hasOwnProperty("mobile_phone_codes") && (draft.mobile_phone_codes = action.payload.mobile_phone_codes);
                action.payload.hasOwnProperty("address_type") && (draft.address_type = action.payload.address_type);
                action.payload.hasOwnProperty("document_types") && (draft.dictionary.document_types = action.payload.document_types);
                action.payload.hasOwnProperty("building_types") && (draft.dictionary.building_types = action.payload.building_types);
                action.payload.hasOwnProperty("countries") && (draft.dictionary.countries = action.payload.countries);
                action.payload.hasOwnProperty("regions") && (draft.dictionary.regions = action.payload.regions);
                action.payload.hasOwnProperty("regions") && (draft.dictionary.residence_regions = action.payload.regions);
                action.payload.hasOwnProperty("cities") && (draft.dictionary.cities = action.payload.cities);
                action.payload.hasOwnProperty("offer_terms") && (draft.offer_terms = action.payload.offer_terms);
                action.payload.hasOwnProperty("customer_info_for_save") && (draft.customer_info_for_save.request = action.payload.customer_info_for_save);
                if (action.payload.hasOwnProperty("maxLoan")) {
                    draft.period = action.payload.maxLoan;
                    draft.max_loan = action.payload.maxLoan;
                }
                action.payload.hasOwnProperty("acba_status") && (draft.acba_status = action.payload.acba_status);
                action.payload.hasOwnProperty("loan_contract") && (draft.loan_contract = action.payload.loan_contract);
                action.payload.hasOwnProperty("work_profit_type") && (draft.dictionary.work_profit_type = action.payload.work_profit_type);
                action.payload.hasOwnProperty("work_position_type") && (draft.dictionary.work_position_type = action.payload.work_position_type);
                action.payload.hasOwnProperty("work_employer_type") && (draft.dictionary.work_employer_type = action.payload.work_employer_type);
                action.payload.hasOwnProperty("item_types") && (draft.item_types = action.payload.item_types);
                action.payload.hasOwnProperty("offer_terms") && (draft.offer_terms = action.payload.offer_terms);
                action.payload.hasOwnProperty("request_items") && (draft.request_items = action.payload.request_items.items.length !== 0 ? action.payload.request_items : draft.request_items);
                action.payload.hasOwnProperty("agreementPDF") && (draft.agreementPDF = action.payload.agreementPDF);
                action.payload.hasOwnProperty("all_contracts_pdf") && (draft.all_contracts_pdf = action.payload.all_contracts_pdf);
                action.payload.hasOwnProperty("factoring_date_of_beginning") && (draft.factoring_date_of_beginning = action.payload.factoring_date_of_beginning);
                draft.request_status = "loan_success";
            })
        case LOAN_TERMS_INITIAL_LOAD_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "LoanTermsInitialLoad";
                draft.error_message.message = action.payload;
                draft.loading.type = "loan_terms_loading";
                draft.loading.loading_state = false;
            })
        case CONFIRM_LOAN_TERMS_START:
            return produce(state, draft => {
                // draft.loading = true;
                draft.loading.type = "confirm_loan_loading";
                draft.loading.loading_state = true;
                draft.request_status = "loan_start";
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case CONFIRM_LOAN_TERMS_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "confirm_loan_loading";
                draft.loading.loading_state = false;
                draft.request_status = "loan_confirm";
            })
        case CONFIRM_LOAN_TERMS_FAIL:
            return produce(state, draft => {
                draft.loading.type = "confirm_loan_loading";
                draft.loading.loading_state = false;
                draft.request_status = "loan_fail";
                draft.error_message.type = "SaveLoanContract";
                draft.error_message.message = action.payload.error_message;
            })
        case DOWNLOAD_ALL_CONTRACTS_START:
            return produce(state, draft => {
                draft.loading.type = "download_all_contracts_start";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case DOWNLOAD_ALL_CONTRACTS_SUCCESS:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.all_contracts_pdf = action.payload.all_contracts_pdf;
                draft.acba_status = action.payload.acba_status;
            })
        case DOWNLOAD_ALL_CONTRACTS_FAIL:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.error_message.type = "DownloadAllContracts";
                draft.error_message.message = action.payload;
            })
        case GET_PRIZE_OFFER_START:
            return produce(state, draft => {
                draft.loading.type = "get_prize_offer_start";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
            });
        case GET_PRIZE_OFFER_SUCCESS:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.prizeWinAmount = action.payload.amount;
            });
        case GET_PRIZE_OFFER_FAIL:
            return produce(state, draft => {
                draft.loading.loading_state = false;
                draft.error_message.type = "GetPrizeOffer";
                draft.error_message.message = action.payload;
            });
        case LOAD_ACRA_AGREEMENT_START:
            return produce(state, draft => {
                draft.loading.type = "acra_loading";
                draft.loading.loading_state = true;
                draft.agreementPDF = "";
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case LOAD_ACRA_AGREEMENT_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "acra_loading";
                draft.loading.loading_state = false;
                switch (action.payload.type) {
                    case "person":
                        draft.agreementPDF = action.payload.agreementPDF;
                        break;
                    case "guarantor":
                        draft.guarantorPDF = action.payload.agreementPDF
                        break;
                }
            })
        case LOAD_ACRA_AGREEMENT_FAIL:
            return produce(state, draft => {
                draft.loading.type = "acra_loading";
                draft.loading.loading_state = false;
                draft.error_message.type = "LoadAcraAgreement";
                draft.error_message.message = action.payload;
            })
        case GET_REFUSE_REASON_TYPES_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
                draft.loading.type = "refuse_loading";
                draft.loading.loading_state = true;
            })
        case GET_REFUSE_REASON_TYPES_SUCCESS:
            return produce(state, draft => {
                draft.refuse_reason_types = action.payload.refuse_reason_types;
                draft.loading.type = "refuse_loading";
                draft.loading.loading_state = false;
            })
        case GET_REFUSE_REASON_TYPES_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "GetRefuseReasonTypes";
                draft.error_message.message = action.payload.error_message;
                draft.loading.type = "refuse_loading";
                draft.loading.loading_state = false;
            })
        case CANCEL_REQUEST_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case CANCEL_REQUEST_SUCCESS:
            return produce(state, draft => {
                draft.acba_status = action.payload.acba_status;
            })
        case CANCEL_REQUEST_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "CancelRequest";
                draft.error_message.message = action.payload.error_message;
            })
        case CUSTOMER_SUM_NEED_ON_CHANGE:
            return produce(state, draft => {
                draft.customer_info_for_save.request.startCapital = action.payload.sum_need;
            })
        case CHANGE_CUSTOMER_LOAN_ACTION:
            return produce(state, draft => {
                draft.customer_info_for_save.request.action = action.payload.loan_action;
            })
        case SAVE_REQUEST_ITEMS_START:
            return produce(state, draft => {
                draft.loading.type = "confirm_request_loading";
                draft.loading.loading_state = true;
                draft.error_message.type = "";
                draft.error_message.message = "";
                if (Object.entries(draft.request_conditions).length !== 0) {
                    draft.request_conditions = {};
                }
            })
        case SAVE_REQUEST_ITEMS_SUCCESS:
            return produce(state, draft => {
                draft.loading.type = "confirm_request_loading";
                draft.loading.loading_state = false;
                draft.request_status = "confirm_success";
                draft.acba_status = action.payload.acba_status;
                draft.request_items = action.payload.request_items;
            })
        case SAVE_REQUEST_ITEMS_FAIL:
            return produce(state, draft => {
                draft.error_message.type = "SaveRequestItems";
                draft.error_message.message = action.payload.confirm_offer_fail_message;
                draft.loading.type = "confirm_request_loading";
                draft.loading.loading_state = false;
            })
        case GET_EMPLOYER_TYPE_SUCCESS:
            return produce (state, draft => {
                draft.dictionary.employer_type_by_group = action.payload.employer_type_by_group;
            })
        case GET_EMPLOYER_TYPE_FAIL:
            return produce (state, draft => {
                draft.error_message.type = "GetEmployerType";
                draft.error_message.message = action.payload.error_message;
            })
        case CHANGE_CUSTOMER_PERIOD:
            return produce(state, draft => {
                draft.period = action.payload.period;
                draft.customer_info_for_save.request.periodRequested = action.payload.period;
            })
        case CHANGE_EDIT_STATUS:
            return produce(state, draft => {
                draft.request_status = (draft.request_status === "view" || draft.request_status === "calc_analyse_in_bank" || draft.request_status === "view_from_edit") ? "edit" : "view_from_edit";
            })
        case GET_ARMENIAN_PLACES_BY_REGION_ID_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case GET_ARMENIAN_PLACES_BY_REGION_ID_SUCCESS:
            return produce(state, draft => {
                if (action.payload.region_type === "region") { // check if user selected residenceRegion or livingRegion
                    draft.dictionary.cities = action.payload.cities;
                } else {
                    draft.dictionary.residence_cities = action.payload.cities;
                }
            })
        case GET_ARMENIAN_PLACES_BY_REGION_ID_FAIL:
            return produce(state, draft => {

            })
        case GET_MULTIPLE_WORK_REFERENCE_TYPES_START:
            return produce(state, draft => {
                draft.error_message.type = "";
                draft.error_message.message = "";
            })
        case GET_MULTIPLE_WORK_REFERENCE_TYPES_SUCCESS:
            return produce(state, draft => {
                draft.income_temp_dictionary.work_reference_type = action.payload.reference_types;
            })
        case GET_MULTIPLE_WORK_REFERENCE_TYPES_FAIL:
            return produce(state, draft => {

            })
        case GET_ALL_STREETS_START:
          return produce(state, draft => {

          })
        case GET_ALL_STREETS_SUCCESS:
          return produce(state, draft => {
            if (action.payload.type === 'user') {
              if (action.payload.id == 1) {
                draft.searching_streets = action.payload.searching_streets;
              }
              else {
                draft.residence_searching_streets = action.payload.searching_streets;
              }
            } else {
              draft.searching_streets = action.payload.searching_streets;
            }
          })
        case GET_ALL_STREETS_FAIL:
          return produce(state, draft => {

          })
        case RESTORE_CANCEL_REQUEST_START:
          return produce(state, draft => {
            draft.loading.type = "restore_cancel_request_loading";
            draft.loading.loading_state = true;
            draft.error_message.type = "";
            draft.error_message.message = "";
          })
        case RESTORE_CANCEL_REQUEST_SUCCESS:
          return produce(state, draft => {
            draft.loading.loading_state = false;
            draft.acba_status = action.payload.acba_status;
          })
        case RESTORE_CANCEL_REQUEST_FAIL:
          return produce(state, draft => {
            draft.error_message.type = "RestoreCancelRequest";
            draft.error_message.message = action.payload;
            draft.loading.loading_state = false;
          })
      default:
            return state;
    }
  };
