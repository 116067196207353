import React, { Component } from 'react';
import Plate from "../../components/Plate/Plate";
import PlateCard from "../../components/PlateCard/PlateCard";
import Icon from "../../components/Icon/Icon";

export default class OfferTerms extends Component {
  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    let {terms} = this.props;
    let amount = terms.amount !== undefined ? this.formatNumber(terms.amount) : "";
    let monthlyRepayment = terms.monthlyRepayment !== undefined ? this.formatNumber(terms.monthlyRepayment).split('.')[0] : "";
    let requiredPrepayment = terms.requiredPrepayment !== undefined ? this.formatNumber(terms.requiredPrepayment) : "";
    let monthlyFee = terms.monthlyFee !== undefined ? this.formatNumber(terms.monthlyFee) : "";
    let rate = !isNaN(terms.rate * 100) ? parseFloat(terms.rate * 100).toFixed(1) : "";

    return (
      <>
      <div className="row mb_30">
        <div className="column small-12 medium-4">
          <Plate label="Վարկի գումար" value={amount} type={<Icon type="dram" size={15} />} theme="green" />
        </div>
        <div className="column small-12 medium-4">
          <Plate label="Տևողություն" value={terms.duration} type="ամիս" theme="grey" />
        </div>
        <div className="column small-12 medium-4">
          <Plate label="Ամսական ընդհանուր վճար" value={monthlyRepayment} type={<Icon type="dram" size={15} />} theme="grey" />
        </div>
      </div>

      <div className="plate_data mb_25">
        <div className="row">
          <PlateCard className="column small-12 medium-4" label="Կանխավճար" value={requiredPrepayment} suffix={<Icon type="dram" size={17} />} />
          <PlateCard className="column small-12 medium-4" label="Տոկոսադրույք" value={rate} suffix="%" />
          <PlateCard className="column small-12 medium-4" label="Ամսական սպասարկման վճար" value={monthlyFee} suffix={<Icon type="dram" size={17} />} />
          {terms.requiredGuarantorCount > 0 &&
            <PlateCard className="column small-12 medium-4" label="Երաշխավորների քանակ" value={terms.requiredGuarantorCount} suffix="անձ" />
          }
          {terms.onlyCapitalMaturePeriod > 0 &&
            <PlateCard className="column small-12 medium-4" label="Մայր գումարի մարման արտոնյալ ժամանակահատված" value={terms.onlyCapitalMaturePeriod} suffix="ամիս" />
          }
          {terms.onlyInterestMaturePeriod > 0 &&
            <PlateCard className="column small-12 medium-4" label="Տոկոսների մարման արտոնյալ ժամանակահատված" value={terms.onlyInterestMaturePeriod} suffix="ամիս" />
          }
        </div>
      </div>
      </>
    )
  }
}
