import React, {Component} from "react";
import {InputNumber} from "antd";

import "./_numberic-input.scss";

class NumericInput extends Component{
  render() {
    const {suffix} = this.props;

    if (suffix) {
      return (
        <div className="input-number-wrapper" >
          <InputNumber
            {...this.props}
          />
          <div className="input-number-suffix-wrapper">
            {suffix}
          </div>
        </div>
      )
    } else {
      return (
        <InputNumber {...this.props} />
      )
    }
  }
}

export default NumericInput;