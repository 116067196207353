import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, message } from 'antd';

import Menu from '../../layout/Menu/Menu';
import Content from '../../layout/Content/Content';
import Title from '../../components/Title/Title';
import Icon from '../../components/Icon/Icon';
import ErrorMessageContent from '../../components/ErrorMessageContent/ErrorMessageContent';
import { checkRestoreTokenTime, restoreUserPassword } from '../../reducers/initReducer';
import { NavLink } from 'react-router-dom';
import './RestorePassword.scss';

class RestorePassword extends React.Component {
  getQueryParams = () => {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username, token } = this.getQueryParams();
        const params = {
          UserName: username,
          Token: token,
          NewPassword: values.newPassword,
        };
        this.props.dispatch(restoreUserPassword(params));
      }
    });
  };

  passwordOnChange = () => {
    const repeatPasswordValue = this.props.form.getFieldValue('repeatNewPassword');
    if (repeatPasswordValue) {
      this.props.form.setFieldsValue({
        repeatNewPassword: '',
      });
    }
  };

  newPasswordValidator = (rule, value) => {
    const hasSpecialSymbols = /\W|_/;
    const hasNumber = /[0-9]+/;
    const hasUpperChar = /[A-Z]+/;
    const hasMinimum8Chars = /.{8,}/;
    if (value) {
      return hasSpecialSymbols.test(value) && hasNumber.test(value) && hasUpperChar.test(value) && hasMinimum8Chars.test(value);
    }
    return true;
  };

  repeatPasswordValidator = (rule, value) => {
    const newPasswordValue = this.props.form.getFieldValue('newPassword');
    if (newPasswordValue && value) {
      return newPasswordValue === value;
    }
    return true;
  };

  componentDidMount() {
    const { username, token } = this.getQueryParams();
    const params = {
      UserName: username,
      Token: token,
    };
    this.props.dispatch(checkRestoreTokenTime(params));
  }

  componentDidUpdate(prevProps, prevState) {
    const { error_message } = this.props;

    if ((error_message.type === "RestoreUserPassword" || error_message.type === "CheckRestoreTokenTime") &&
      (error_message.message !== prevProps.error_message.message) && (error_message.message !== "")) {
      message.error(<ErrorMessageContent text={error_message.message} />, 5);
    }
  }

  render() {
    const { isRestoreLinkValid } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className='medium-large-flex-container row-wrap fluid-y' >
        <Menu/>
        <Content>
          <div className='row'>
            <div className='column small-12 medium-large-6' >
              {
                !isRestoreLinkValid
                  ? (
                    <>
                      <Title
                        size='xs'
                        weight='medium'
                        className='capitalize-first-letter'
                        text='Հղումն ակտիվ չէ,'
                      />
                      <Title
                        size='xs'
                        weight='medium'
                        className='mb_35 lowercase-letter'
                        text='խնդրում ենք նորից անցնել «Մոռացել եմ գաղտնաբառը» հղումով'
                      />
                      <div className='mt_10' >
                        <NavLink to="/forgot-password" className='forgot-password-url' >
                          Մոռացել եմ գաղտնաբառը
                        </NavLink>
                      </div>
                    </>
                  ) : (
                    <>
                      <Title text='Գաղտանաբառի փոփոխություն' size='md' weight='black' className='mb_35' />
                      <Form onSubmit={this.onSubmit} >
                        <Form.Item label='Նոր գաղտնաբառ' >
                          {getFieldDecorator('newPassword', {
                            validateTrigger: 'onBlur',
                            rules: [
                              {required: true, message: 'դաշտը լրացված չէ'},
                              { validator: this.newPasswordValidator, message: 'Գաղտնաբառը պետք է պարունակի լատինական մեծատառեր, թվեր և հատուկ նշաններ' }
                            ],
                          })(
                            <Input
                              type='password'
                              onChange={this.passwordOnChange}
                            />
                          )}
                        </Form.Item>
  
                        <Form.Item label='Կրկնել նոր գաղտնաբառը' >
                          {getFieldDecorator('repeatNewPassword', {
                            validateTrigger: 'onBlur',
                            rules: [
                              { required: true, message: 'դաշտը լրացված չէ' },
                              { validator: this.repeatPasswordValidator, message: 'Գաղտնաբառերը չեն համընկնում' }
                            ],
                          })(
                            <Input type='password' />
                          )}
                        </Form.Item>
  
                        <Button
                          loading={this.props.loading}
                          htmlType="submit"
                          className="btn btn-green"
                        >
                          <span>ՓՈԽԵԼ</span>
  
                          <span className="btn__icon">
                            <Icon type="ident" size={22}/>
                          </span>
                        </Button>
                      </Form>
                    </>
                  )
              }
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.initReducer.loading,
    error_message: state.initReducer.error_message,
    isRestoreLinkValid: state.initReducer.isRestoreLinkValid,
  };
}

const RestorePasswordContainer = Form.create()(RestorePassword);

export default connect(mapStateToProps)(RestorePasswordContainer);
