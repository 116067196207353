import React, { Component } from 'react';
import "./TitleUserData.scss";
import Icon from "../Icon/Icon";

export default class TitleUserData extends Component {
  render() {
    let classNames = [
      "h-title",
      this.props.weight ? this.props.weight : "",
      this.props.size ? this.props.size : "",
      this.props.className
    ];

    return (
      <div className={classNames.join(" ").trim()} style={this.props.style}>
        {
          this.props.icon
            ?
            <span className={classNames[0] + "__icon"}>
              <Icon type={this.props.icon} size={this.props.iconSize} />
            </span>
            : null
        }
        { this.props.text }
      </div>
    )
  }
}
