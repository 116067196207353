import React, { Component } from 'react';
import './GuarantorCard.scss';
import Icon from "../Icon/Icon";

export default class  GuarantorCard extends Component {
  getGuarantorCardText = () => {
    const {guarantorStatus} = this.props;

    if (guarantorStatus == 2) {
      return "Հաստատված երաշխավոր";
    }
    else if (guarantorStatus == 3) {
      return "Չհաստատված երաշխավոր";
    }

    return "Ուղարկվել է Բանկ դիտարկման";
  };

  getGuarantorCardIconTypeAndColor = () => {
    const {guarantorStatus} = this.props;

    if (guarantorStatus == 2) {
      return {
        type: "confirm",
        className: "color-green"
      };
    }
    else if (guarantorStatus == 3) {
      return {
        type: "reject",
        className: "color-red"
      };
    }

    return {
      type: "question",
      className: "color-green"
    }
  };

  render() {
    const guarantorIdentifiedText = this.getGuarantorCardText();
    const {type, className} = this.getGuarantorCardIconTypeAndColor();

    let classNames = [
      "guarantor",
      "flex-container align-middle mb_30",
      this.props.className
    ];

    return (
      <div className={classNames.join(" ").trim()}>
        <div className={classNames[0] + "__icon"}>
           <Icon
            type={type}
            size={46}
            className={className}
           />
        </div>
        <div className={classNames[0] + "__caption"}>
          <div className={classNames[0] + "_label"}>
            {guarantorIdentifiedText}
          </div>
          <div className={classNames[0] + "_name"}>
            {this.props.name}
          </div>
        </div>
      </div>
    )
  }
}
