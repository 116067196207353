import React, { Component } from 'react';
import Icon from "../../components/Icon/Icon";
import PersonAvatar from "../../components/PersonAvatar/PersonAvatar";
import {Form, Input, Button, Checkbox, Select, DatePicker, Spin, message} from 'antd';
import moment from 'moment';
import {connect} from "react-redux";
import {
  fetchStreet,
  identificationStart,
  searchSelect,
  saveGuarantorInfo,
  loadAcraAgreement,
  getArmenianPlacesByRegionId, getAllStreets, getRegions
} from "../../reducers/applicationReducer";
import Title from "../../components/Title/Title";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";
import ActionInfo from "../../components/ActionInfo/ActionInfo";

class Guarantor extends Component {
  constructor(props) {
    super(props);
    const identifiedAddressList = this.props.guarantor_info.addressList !== undefined && this.props.guarantor_info.addressList != null ? this.props.guarantor_info.addressList : [];
    const isIdentifiedAddressListEmpty = identifiedAddressList.length === 0;

    let registrationCountry;
    if (!isIdentifiedAddressListEmpty) {
      const {addressList} = this.props.user_info;
      registrationCountry = addressList[0] ? addressList[0].country : 51; // by default Armenia is needed
    }

    this.identification_result_message = {message1:'',message2:''};
    this.state = {
      checked: false,
      apartment_required: !isIdentifiedAddressListEmpty ? identifiedAddressList[0].building == 36 : true,
      phoneNumbers: [{}],
      emails: [{}],
      isCountrySelectedArmenia: registrationCountry == 51,
      isAddressStreetInputTag: registrationCountry != 51,
      selectedDocumentType: null
    }
  }

  componentDidUpdate(previousProps, previousState) {
    // Clear guarantor identification result input values
    if (previousProps.loading.loading_state !== this.props.loading.loading_state && this.props.loading.type === "guarantor_identification_loading") {
      this.props.form.resetFields();
      this.setState({
        checked: false,
      })
    }
  }

  searchStreet = (value) => {
    const cityID = this.props.form.getFieldValue('city').key;
    
    this.props.dispatch(fetchStreet(value, cityID, "registrationAddress"))
  };

  handleStreetChange = (value, id, type) => {
    const {addressList} = this.props.guarantor_info;
    const found = this.props.searching_streets.find(element =>
        element.street == value.key
    );

    if (found["country"] == null) {
      found["country"] = "51";
    }

    if (!found["country"]) {
      found["country"] = "51";
    }

    if (!found["building"] && addressList[id]) {
      found["building"] = addressList[id].building
    }

    if (!found["appartment"] && addressList[id]) {
      found["appartment"] = addressList[id].appartment
    }

    if (!found["house"] && addressList[id]) {
      found["house"] = addressList[id].house
    }

    this.props.dispatch(searchSelect(found, id, type));

    this.props.form.resetFields(['city', 'region']);
  };

  isAcraChecked = (event)  => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (event.target.checked === true) {
          let doc_number = this.props.form.getFieldValue('documentNumber');
          let guarantorFullName = {
            name: values.name,
            lastName: values.lastName,
            fatherName: values.fatherName
          };
          this.props.dispatch(loadAcraAgreement("guarantor", doc_number, guarantorFullName))
        }
        this.setState({
          checked: event.target.checked,
        })
      }
    })

  };

  isAddressListEmpty = (addressListIndex, addressListValue) => {
    const user_address_list = this.props.guarantor_info.addressList;
    if (user_address_list == null || user_address_list[addressListIndex] === undefined || user_address_list[addressListIndex][addressListValue] == null || user_address_list[addressListIndex][addressListValue] === "") {
      return true;
    }
    else {
      return false;
    }
  };

  handleBuildingTypeChange = (e) =>{
    const apartmentValue = this.props.form.getFieldValue("apartment") ? this.props.form.getFieldValue("apartment") : "";

    if (e == 36 && !apartmentValue) {
      this.props.form.setFields({
        "apartment": {
          value: apartmentValue,
          errors: [new Error("դաշտը լրացված չէ")]
        }
      });
    }
    else {
      this.props.form.setFields({
        "apartment": {
          value: apartmentValue
        }
      });
    }
    this.setState({
      apartment_required: e == 36
    });
  };

  getGuarantorAddressInfo = (street, region, city) => {
    let returningObject = {
      street: {
        key: null,
        label: ""
      },
      region: {
        key: null,
        label: ""
      },
      city: {
        key: null,
        label: ""
      }
    };

    if (street) {
      if (typeof street === "string") {
        returningObject.street = {
          key: 0,
          label: street
        }
      }
      else {
        returningObject.street = {
          key: street.key,
          label: street.label.split('-')[0]
        }
      }
    }

    if (region) {
      if (typeof region === "string") {
        returningObject.region = {
          key: 0,
          label: region
        }
      }
      else {
        returningObject.region = {
          key: region.key,
          label: region.label
        }
      }
    }

    if (city) {
      if (typeof city === "string") {
        returningObject.city = {
          key: 0,
          label: city
        }
      }
      else {
        returningObject.city = {
          key: city.key,
          label: city.label
        }
      }
    }

    return returningObject;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let guarantor_info = this.props.guarantor_info;
        let guarantor_address_list = [];
        const registrationAddressInfo = this.getGuarantorAddressInfo(values.street1, values.region, values.city);
        guarantor_address_list.push({
          "addressType": 1,
          "appartment": values.apartment !== undefined && values.apartment,
          "building": values.buildingType !== undefined && values.buildingType,
          "country": values.country !== undefined && values.country,
          "house": values.house !== undefined && values.house,
          "postCode": guarantor_info.addressList[0] !== undefined && guarantor_info.addressList[0].postCode,
          "priority": 1,
          "region": registrationAddressInfo.region.key,
          "regionDescription": registrationAddressInfo.region.label,
          "street": registrationAddressInfo.street.key ? registrationAddressInfo.street.key : 0,
          "streetDescription": registrationAddressInfo.street.label,
          "townVillage": registrationAddressInfo.city.key,
          "townVillageDescription": registrationAddressInfo.city.label,
        });

        let guarantor_details_info = {
          "address": guarantor_info.address,
          "addressList": guarantor_address_list,
          "applicationID": guarantor_info.applicationID,
          "applicationProcessingStatus": guarantor_info.applicationProcessingStatus,
          "automatScoreRejectionReason": guarantor_info.automatScoreRejectionReason,
          "automatScoreStep": guarantor_info.automatScoreStep,
          "citizenship": guarantor_info.citizenship,
          "customerIdentificationGroup": guarantor_info.customerIdentificationGroup,
          "customerNumber": guarantor_info.customerNumber,
          "customerPhoto": guarantor_info.customerPhoto,
          "customerQuality": 1,
          "dateOfBirth": values.dateOfBirth.format("YYYY-MM-DD"),
          "documentDate": values.documentDate.format("YYYY-MM-DD"),
          "documentInf": values.documentInf,
          "documentNumber": values.documentNumber,
          "documentType": values.documentType,
          "documentValidDate": values.documentValidDate.format("YYYY-MM-DD"),
          "familyCount": guarantor_info.familyCount,
          "familyWorkingCount": guarantor_info.familyWorkingCount,
          "fatherName": values.fatherName,
          "filialCode": guarantor_info.filialCode,
          "guarantStatusQuality": guarantor_info.guarantStatusQuality,
          "homePhone": guarantor_info.homePhone,
          "lastName": values.lastName,
          "maritalStatus": guarantor_info.maritalStatus,
          "marketPhone": guarantor_info.marketPhone,
          "mobilePhone": guarantor_info.mobilePhone,
          "name": values.name,
          "notDocSalary": guarantor_info.notDocSalary,
          "notDocSalarySource": guarantor_info.notDocSalarySource,
          "offeredCustomerNumber": guarantor_info.offeredCustomerNumber,
          "otherDocSalary": guarantor_info.otherDocSalary,
          "otherPhone": guarantor_info.otherPhone,
          "quality": guarantor_info.quality,
          "realAddress": guarantor_info.realAddress,
          "requestQualityDescr": guarantor_info.requestQualityDescr,
          "resident": guarantor_info.resident,
          "scoreResult": guarantor_info.scoreResult,
          "socialNumber": values.socialNumber !== undefined ? values.socialNumber : guarantor_info.socialNumber,
        };

        let guarantor_contacts = {
          phone: [],
          email: [],
        };

        this.state.phoneNumbers.map((element) => {
          guarantor_contacts.phone.push({
            areaCode: element.telCode,
            number: element.telNumber,
          })
        });

        this.state.emails.map((element) => {
          element.email && guarantor_contacts.email.push(element.email);
        });

        this.props.dispatch(saveGuarantorInfo(guarantor_details_info, guarantor_contacts));
      }
    })
  } ;

  guarantorAvatarText = () => {
    const {guarantor_info} = this.props;
    const lastName = guarantor_info.lastName != null && guarantor_info.lastName !== "" ? guarantor_info.lastName : "";
    const name = guarantor_info.name != null && guarantor_info.name !== "" ? guarantor_info.name : "";
    const fatherName = guarantor_info.fatherName != null && guarantor_info.fatherName !== "" ? guarantor_info.fatherName : "";
    return lastName + " " + name + " " + fatherName;
  };

  handleRegionOnChange = (region, type) => {
    this.props.dispatch(getArmenianPlacesByRegionId(region.key, type));
    this.props.form.setFieldsValue({
      city: "",
      street1: "",
    });
  };

  handleCityOnChange = (e, id) => {
    const arm_place_id = e.key ? e.key : e;
    const region = this.props.form.getFieldValue('region');

    this.props.form.setFieldsValue({
      'street1': '',
    });
    this.props.dispatch(getAllStreets(arm_place_id, id, 'guarantor', region));
  };

  handleAdd = (state) => {
    let arr = [...this.state[state], {}];

    this.setState({[state]: arr});
  };

  handleRemove = (index, state) => {
    let arr = this.state[state];

    arr.splice(index, 1);

    this.setState({[state]: arr});
  };

  handleGetData = (item, index, name, type, state) => {
    let arr = this.state[state];

    switch (type) {
      case "input":
        arr[index][name] = item.target.value;
        if (name === 'telNumber') {
          let are_the_same = false;
          if (arr.length >= 2) {
            /*let phoneTelCodes = [], phoneTelNumbers = [];
            arr.map((element) => {
              phoneTelCodes.push(element.telCode);
              phoneTelNumbers.push(element.telNumber)
            })
            are_the_same = this.hasDuplicates(phoneTelCodes) && this.hasDuplicates(phoneTelNumbers);*/

            are_the_same = arr.every((value) => value.telNumber === arr[0].telNumber && value.telCode === arr[0].telCode);
          }
          if (are_the_same) {
            message.error("Միևնույն հետախոսահամարը մուտքագրվել է 2 անգամ");
          }
        }
        break;
      case "select":
        arr[index][name] = item;

        if (name === 'telCode') {
          let are_the_same = false;
          if (arr.length >= 2) {
            /*let phoneTelCodes = [], phoneTelNumbers = [];
            arr.map((element) => {
              phoneTelCodes.push(element.telCode);
              phoneTelNumbers.push(element.telNumber)
            })
            are_the_same = this.hasDuplicates(phoneTelCodes) && this.hasDuplicates(phoneTelNumbers);*/
            are_the_same = arr.every( (value) => value.telNumber === arr[0].telNumber && value.telCode === arr[0].telCode);
          }
          if (are_the_same) {
            message.error("Միևնույն հետախոսահամարը մուտքագրվել է 2 անգամ");
          }
        }
        break;
    }

    this.setState({[state]: arr})
  };

  handleCountryOnChange = (value) => {
    if (value != 51 && value != 999) { // 51 = ARMENIA, 999 = KARABAKH
      this.setState({
        isCountrySelectedArmenia: false,
        isAddressStreetInputTag: true
      })
    }
    else {
      let objectForSettingState = {
        isCountrySelectedArmenia: true
      };

      if (value == 51) {
        objectForSettingState.isAddressStreetInputTag = false
      } else {
        objectForSettingState.isAddressStreetInputTag = true
      }

      this.setState(objectForSettingState)
    }

    this.props.dispatch(getRegions(value, 1));
    
    this.props.form.setFieldsValue({
      street1: "",
      city: "",
      region: "",
    })
  };

  handleEmailOnBlur = (e, index) => {
    const {value} = e.target;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value && !emailRegex.test(String(value).toLowerCase())) {
      this.props.form.setFields({
        [`email_${index}`]: {
          value,
          errors: [new Error('Էլեկտրոնային հասցեն պետք է պարունակի @ սիմվոլը')]
        }
      });
    }
  };

  handleDocumentTypeOnChange = (value) => {
    this.setState({
      selectedDocumentType: value
    })
  };

  printIdentificationResultMessage = () => {
    const {guarantor_info} = this.props;
    const input_values = {
      lastName: guarantor_info.lastName,
      name: guarantor_info.name,
      fatherName: guarantor_info.fatherName,
      dateOfBirth: guarantor_info.dateOfBirth,
      documentType: guarantor_info.documentType,
      documentNumber: guarantor_info.documentNumber,
      documentDate: guarantor_info.documentDate,
      documentInf: guarantor_info.documentInf,
      documentValidDate: guarantor_info.documentValidDate,
      region: guarantor_info.addressList[0] ? guarantor_info.addressList[0].region : "",
      regionDescription: guarantor_info.addressList[0] ? guarantor_info.addressList[0].regionDescription : "",
      townVillage: guarantor_info.addressList[0] ? guarantor_info.addressList[0].townVillage : "",
      townVillageDescription: guarantor_info.addressList[0] ? guarantor_info.addressList[0].townVillageDescription : "",
      street: guarantor_info.addressList[0] ? guarantor_info.addressList[0].street : "",
      streetDescription: guarantor_info.addressList[0] ? guarantor_info.addressList[0].streetDescription : "",
      building: guarantor_info.addressList[0] ? guarantor_info.addressList[0].building : "",
      house: guarantor_info.addressList[0] ? guarantor_info.addressList[0].house : "",
      appartment: guarantor_info.addressList[0] ? guarantor_info.addressList[0].appartment : "",
    };

    if (input_values.building != 36) {
      delete input_values.appartment;
    }

    const input_values_keys = Object.keys(input_values);

    let emptyInputsCount = 0;
    for (let i = 0; i < input_values_keys.length; i++) {
      const currentInputValue = input_values[input_values_keys[i]];
      if (currentInputValue == null || currentInputValue === "") {
        emptyInputsCount++;
      }
    }

    switch (emptyInputsCount) {
      case 0:
        this.identification_result_message = {
          message1: "Հաջող նույնականացում.",
          message2: "խնդրում ենք լրացնել հաճախորդի բնակության հասցեն, եթե այն տարբեր է գրանցման հասցեից",
          identificationStatus: "success",
        };
        break;
      case input_values_keys.length:
        this.identification_result_message = {
          message1: "Նույնականացումը հնարավոր չէ․",
          message2: "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը",
          identificationStatus: "fail",
        };
        break;
      default:
        // this.props.form.validateFields();
        this.identification_result_message = {
          message1: "Հաճախորդի անձնական տվյալներն ամբողջությամբ լրացված չեն.",
          message2: "խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը",
          identificationStatus: "partly",
        };
        break;
    }
  };

  render() {
    this.printIdentificationResultMessage();
    const { getFieldDecorator } = this.props.form;
    const dateFormat = 'DD/MM/YYYY';
    const default_country = this.props.dictionaries.countries.find((element) => element.label.toUpperCase() === "ARMENIA" );

    return (
      this.props.hide_guarantor_result === false ?
          <Form
            onSubmit={this.onSubmit}
            className="mb_60"
          >

            <ActionInfo message={this.identification_result_message}/>

            <div className="flex-container row-wrap align-justify align-middle mb_30">
              <PersonAvatar
                  name={this.guarantorAvatarText()}
                  avatar={this.props.guarantor_info.customerPhoto ? 'data:image/jpeg;base64,' + this.props.guarantor_info.customerPhoto : require("../../assets/img/no-user.gif")}
              />
              {/*<Button disabled={this.props.request_status === 'save' ? true : false} type="link" className="link_btn" onClick={() => {this.props.dispatch(identificationStart())}}>*/}
              {/*  <Icon type="proccess" size={13} />*/}
              {/*  Նոր նույնականացում*/}
              {/*</Button>*/}
            </div>

            <div className="row mb_30 person_main">
              <Form.Item label="Ազգանուն" className="column small-12 large-4">
                {getFieldDecorator('lastName', {
                  initialValue: this.props.guarantor_info.lastName,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Input type="text"
                           name="lastName"
                           autoComplete="off"
                    />
                )}
              </Form.Item>

              <Form.Item label="Անուն" className="column small-12 large-4">
                {getFieldDecorator('name', {
                  initialValue: this.props.guarantor_info.name,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Input type="text"
                           name="name"
                           autoComplete="off"
                    />
                )}
              </Form.Item>

              <Form.Item label="Հայրանուն" className="column small-12 large-4">
                {getFieldDecorator('fatherName', {
                  initialValue: this.props.guarantor_info.fatherName,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Input type="text"
                           name="fatherName"
                           autoComplete="off"
                    />
                )}
              </Form.Item>

              <Form.Item label="Ծննդյան ամսաթիվ" className="column small-12 large-4">
                {getFieldDecorator('dateOfBirth', {
                  initialValue: this.props.guarantor_info.dateOfBirth != null && this.props.guarantor_info.dateOfBirth !== "" ? moment(new Date(this.props.guarantor_info.dateOfBirth), dateFormat) : null,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <DatePicker placeholder="" allowClear={false} format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]} />
                )}
              </Form.Item>

              <Form.Item label="Փաստաթղթի տեսակ" className="column small-12 large-4">
                {getFieldDecorator('documentType', {
                  initialValue: this.props.guarantor_info.documentType,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Select onChange={this.handleDocumentTypeOnChange} >
                      <Select.Option style={{paddingBottom: 25}} value="">

                      </Select.Option>
                      {
                        this.props.dictionaries.document_types.length
                            ?
                            this.props.dictionaries.document_types.map((key)=>{
                              return (
                                  <Select.Option key={key} value={key.value}>
                                    {key.label}
                                  </Select.Option>
                              )
                            })
                            :
                            null
                      }
                    </Select>
                )}
              </Form.Item>

              <Form.Item label="Փաստաթղթի համար" className="column small-12 large-4">
                {getFieldDecorator('documentNumber', {
                  initialValue: this.props.guarantor_info.documentNumber,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Input type="text"
                           name="documentNumber"
                           autoComplete="off"
                    />
                )}
              </Form.Item>

              <Form.Item label="Տրվել է" className="column small-12 large-4">
                {getFieldDecorator('documentDate', {
                  initialValue: this.props.guarantor_info.documentDate != null && this.props.guarantor_info.documentDate !== "" ? moment(new Date(this.props.guarantor_info.documentDate), dateFormat) : "",
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <DatePicker placeholder="" allowClear={false} format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]} />
                )}
              </Form.Item>

              <Form.Item label="Ում կողմից" className="column small-12 large-4">
                {getFieldDecorator('documentInf', {
                  initialValue: this.props.guarantor_info.documentInf,
                  rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                })(
                    <Input type="text"
                           name="documentInf"
                           autoComplete="off"
                    />
                )}
              </Form.Item>

              <Form.Item label="Վավերական է" className="column small-12 large-4">
                {getFieldDecorator('documentValidDate', {
                  initialValue: this.props.guarantor_info.documentValidDate != null && this.props.guarantor_info.documentValidDate !== "" ? moment(new Date(this.props.guarantor_info.documentValidDate), dateFormat) : "",
                  rules: [
                    {required: true, message: 'դաշտը լրացված չէ'},
                    {
                      validator: (rule, value) => (moment(value).isAfter()),
                      message: `Մուտքագրված անձը հաստատող փաստաթուղթը վավեր չէ`,
                    }],
                })(
                    <DatePicker placeholder="" allowClear={false} format={[dateFormat, "DD.MM.YYYY", "DDMMYYYY"]} />
                )}
              </Form.Item>

              {(this.state.selectedDocumentType == 2 || this.props.guarantor_info.documentType == 2) &&
                <Form.Item label="Հանրային ծառայությունների համարանիշ" className="column small-12 large-4">
                  {getFieldDecorator('socialNumber', {
                    initialValue: this.props.guarantor_info.customerNumber,
                    rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                  })(
                      <Input type="number"
                             name="customerNumber"
                             autoComplete="off"
                      />
                  )}
                </Form.Item>
              }
            </div>

            <div className="mb_30 person_addresses">
              <div id="register_address">
                <Title text="ԳՐԱՆՑՄԱՆ ՀԱՍՑԵ" size="lg" weight="bold" icon="location" className="mb_30" />
                <div className="row">
                  <Form.Item label="Երկիր" className="column small-12 large-4">
                    {getFieldDecorator('country', {
                      initialValue: (this.props.guarantor_info.addressList !== null && this.props.guarantor_info.addressList[0] !== undefined && this.props.guarantor_info.addressList[0].country !== null) ? this.props.guarantor_info.addressList[0].country : default_country.value,
                      rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.handleCountryOnChange}
                      >
                        <Select.Option style={{paddingBottom: 25}} value="">

                        </Select.Option>
                        {
                          this.props.dictionaries.countries.length
                            ?
                            this.props.dictionaries.countries.map((key)=>{
                              return (
                                <Select.Option key={key} value={key.value}>
                                  {key.label}
                                </Select.Option>
                              )
                            })
                            :
                            null
                        }
                      </Select>
                    )}
                  </Form.Item>

                  {
                    this.state.isCountrySelectedArmenia ?
                      <Form.Item label="Մարզ" className="column small-12 large-4">
                        {getFieldDecorator('region', {
                          initialValue: !this.isAddressListEmpty(0, "region") && !this.isAddressListEmpty(0, "regionDescription") ?
                            {
                              key: this.props.guarantor_info.addressList[0].region,
                              label: this.props.guarantor_info.addressList[0].regionDescription
                            } : "",
                          rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                        })(
                          <Select
                            labelInValue
                            showSearch
                            onChange={(e) => this.handleRegionOnChange(e, "region")}
                            filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Select.Option style={{paddingBottom: 25}} value="">

                            </Select.Option>
                            {
                              this.props.dictionaries.regions.length
                                ?
                                this.props.dictionaries.regions.map((key)=>{
                                  return (
                                    <Select.Option key={key} value={key.value}>
                                      {key.label}
                                    </Select.Option>
                                  )
                                })
                                :
                                null
                            }
                          </Select>
                        )}
                      </Form.Item>
                      :
                      <Form.Item label="Մարզ" className="column small-12 large-4">
                        {getFieldDecorator("region", {
                          initialValue: this.props.guarantor_info.addressList[0] && this.props.guarantor_info.addressList[0].regionDescription ? this.props.guarantor_info.addressList[0].regionDescription : null,
                        })(
                          <Input type="text" autoComplete="off" />
                        )}
                      </Form.Item>
                  }

                  {
                    this.state.isCountrySelectedArmenia ?
                      <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                        {getFieldDecorator('city', {
                          initialValue: !this.isAddressListEmpty(0, "townVillage") && !this.isAddressListEmpty(0, "townVillageDescription") ?
                            {
                              key: this.props.guarantor_info.addressList[0].townVillage,
                              label: this.props.guarantor_info.addressList[0].townVillageDescription
                            } : "",
                          rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                        })(
                          <Select
                            labelInValue
                            showSearch
                            filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={(e) => this.handleCityOnChange(e, 1)}
                          >
                            <Select.Option style={{paddingBottom: 25}} value="">

                            </Select.Option>
                            {
                              this.props.dictionaries.cities.length
                                ?
                                this.props.dictionaries.cities.map((key)=>{
                                  return (
                                    <Select.Option key={key.number} value={key.number}>
                                      {key.description}
                                    </Select.Option>
                                  )
                                })
                                :
                                null
                            }
                          </Select>
                        )}
                      </Form.Item>
                      :
                      <Form.Item label="Քաղաք / Գյուղ / Համայնք" className="column small-12 large-4">
                        {getFieldDecorator("city", {
                          initialValue: this.props.guarantor_info.addressList[0] && this.props.guarantor_info.addressList[0].townVillageDescription ? this.props.guarantor_info.addressList[0].townVillageDescription : null,
                        })(
                          <Input type="text" autoComplete="off" />
                        )}
                      </Form.Item>
                  }

                  {
                    !this.state.isAddressStreetInputTag ?
                      <Form.Item label="Փողոց" className="column small-12 large-4">
                        {getFieldDecorator('street1', {
                          rules: [{ required: this.props.searching_streets.length ? true : false, message: 'դաշտը լրացված չէ' }],
                          initialValue: !this.isAddressListEmpty(0, "street") && !this.isAddressListEmpty(0, "streetDescription") ?
                            {
                              key: this.props.guarantor_info.addressList[0].street,
                              label: this.props.guarantor_info.addressList[0].streetDescription
                            } : ""
                        })(
                          <Select
                            labelInValue
                            showSearch
                            mode="default"
                            // setFieldsValue={this.state.value}
                            placeholder="Մուտքագրեք փողոցը"
                            filterOption={false}
                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                            onSearch={this.searchStreet}
                            onChange={(e) => this.handleStreetChange(e,'0', 'guarantor')}
                            style={{ width: '100%' }}
                            dropdownStyle={{ minWidth: "35%" }}
                          >
                            {this.props.searching_streets.length !== 0 ?
                              this.props.searching_streets.map(element => (
                                <Select.Option key={element.street} value={element.street}>{element.streetDescription !== undefined && `${element.streetDescription} - ${element.townVillageDescription}`}</Select.Option>
                              )) : null}
                          </Select>
                        )}
                      </Form.Item>
                    :
                      <Form.Item label="Փողոց" className="column small-12 large-4">
                        {getFieldDecorator("street1", {
                          initialValue: this.props.guarantor_info.addressList[0] && this.props.guarantor_info.addressList[0].streetDescription ? this.props.guarantor_info.addressList[0].streetDescription : null,
                        })(
                          <Input type="text" autoComplete="off" />
                        )}
                      </Form.Item>
                  }

                  <Form.Item label="Շինության տեսակ" className="column small-12 large-4">
                    {getFieldDecorator('buildingType', {
                      initialValue: (this.props.guarantor_info.addressList != null && this.props.guarantor_info.addressList[0] !== undefined && this.props.guarantor_info.addressList[0].building != null) ? this.props.guarantor_info.addressList[0].building.toString() : null,
                      rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                    })(
                        <Select
                          showSearch
                          filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={this.handleBuildingTypeChange}
                        >
                          <Select.Option style={{paddingBottom: 25}} value="">

                          </Select.Option>
                          {
                            this.props.dictionaries.building_types.length
                                ?
                                this.props.dictionaries.building_types.map((key)=>{
                                  return (
                                      <Select.Option key={key} value={key.value}>
                                        {key.label}
                                      </Select.Option>
                                  )
                                })
                                :
                                null
                          }
                        </Select>
                    )}
                  </Form.Item>

                  <Form.Item label="Տուն / Շենք" className="column small-12 large-2">
                    {getFieldDecorator('house', {
                      initialValue: (this.props.guarantor_info.addressList !== null && this.props.guarantor_info.addressList[0] !== undefined && this.props.guarantor_info.addressList[0].house !== null) ? this.props.guarantor_info.addressList[0].house : null,
                      rules: [{ required: true, message: 'դաշտը լրացված չէ' }],
                    })(
                        <Input type="text"
                               name="house"
                               autoComplete="off"
                        />
                    )}
                  </Form.Item>

                  <Form.Item label="Բնակարան" className="column small-12 large-2">
                    {getFieldDecorator('apartment', {
                      initialValue: (this.props.guarantor_info.addressList !== null && this.props.guarantor_info.addressList[0] !== undefined && this.props.guarantor_info.addressList[0].appartment !== null) ? this.props.guarantor_info.addressList[0].appartment : null,
                      rules: [{ required: this.state.apartment_required, message: 'դաշտը լրացված չէ' }],
                    })(
                        <Input type="text"
                               name="apartment"
                               autoComplete="off"
                        />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className='person_contacts' >
              <div className="medium-large-flex-container row-expanded align-start">
                <div className="column small-12 large-6">
                  {
                    this.state.phoneNumbers.map((item, index) => {
                      return (
                        <CloneField
                          className="clone_panel"
                          key={index}
                          index={index}
                          value={item}
                        >
                          <div className="medium-large-flex-container align-middle">
                            <div className="small-12">
                              <div className="row">
                                <Form.Item label="Կոդ" className="column small-12 large-4 mb_20">
                                  {getFieldDecorator(`telCode_${index}`, {
                                    // initialValue: item.telCode,
                                    rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                                  })(
                                    <Select
                                      id={`telCode_${index}`}
                                      className="phone-code-selectbox"
                                      onChange={(value) => {
                                        this.handleGetData(value, index, 'telCode', 'select', 'phoneNumbers');
                                      }}
                                      showSearch
                                      filterOption={(input, option) => option.props.children !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                      <Select.Option style={{paddingBottom: 25}} value="">

                                      </Select.Option>
                                      {
                                        this.props.mobile_phone_codes.length
                                          ?
                                          this.props.mobile_phone_codes.map((key) => {
                                            return (
                                              <Select.Option key={key} value={key}>
                                                {key}
                                              </Select.Option>
                                            )
                                          })
                                          :
                                          null
                                      }
                                    </Select>
                                  )}
                                </Form.Item>

                                <Form.Item label="Հեռախոսահամար" className="column small-12 large-8 mb_20">
                                  {getFieldDecorator(`telNumber_${index}`, {
                                    // initialValue: item.telNumber,
                                    rules: [
                                      {required: true, message: 'դաշտը լրացված չէ'},
                                      {
                                        max: 6,
                                        message: 'Հեռախոսահամարը պետք է պարունակի 6 թիվ. խնդրում ենք ուղղել'
                                      },
                                      {
                                        min: 6,
                                        message: 'Հեռախոսահամարը պետք է պարունակի 6 թիվ. խնդրում ենք ուղղել'
                                      }
                                    ],
                                    validateTrigger: "onBlur",
                                  })(
                                    <Input
                                      type="number"
                                      name="telNumber"
                                      onChange={(event) => {
                                        this.handleGetData(event, index, 'telNumber', 'input', 'phoneNumbers')
                                      }}
                                      autoComplete="off"
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </div>

                            <div className="column">
                              {index > 0 ?
                                <button
                                  type="button"
                                  className="cust_btn mb_10 color-green"
                                  onClick={() => {
                                    this.handleRemove(index, 'phoneNumbers')
                                  }}
                                >
                                  <Icon type="minus" size={32}/>
                                </button>
                                : null
                              }
                            </div>
                          </div>
                        </CloneField>
                      )
                    })
                  }
                </div>

                <div
                  className="column pt_24 fluid-y"
                  style={{paddingLeft: "0"}}
                >
                  <button
                    type="button"
                    className="cust_btn mt_10 color-green"
                    onClick={() => {
                      this.handleAdd('phoneNumbers')
                    }}
                  >
                    <Icon type="plus" size={31}/>
                  </button>
                </div>
              </div>

              <div className="medium-large-flex-container row-expanded align-start">
                <div className="column small-12 large-4">
                  {
                    this.state.emails.map((item, index) => {
                      return (
                        <CloneField
                          className="clone_panel"
                          key={index}
                          index={index}
                          value={item}
                        >
                          <div className="medium-large-flex-container align-middle">
                            <div className="small-12 lareg-4">
                              <Form.Item label="Էլ փոստի հասցե">
                                {getFieldDecorator(`email_${index}`, {
                                  // initialValue: item.email,
                                  rules: [{required: false, message: ''}],
                                })(
                                  <Input
                                    name={`email_${index}`}
                                    id={`email_${index}`}
                                    onChange={(event) => {
                                      this.handleGetData(event, index, 'email', 'input', 'emails')
                                    }}
                                    onBlur={(e) => this.handleEmailOnBlur(e, index)}
                                    autoComplete="off"
                                  />
                                )}
                              </Form.Item>
                            </div>

                            <div className="column">
                              {index > 0 ?
                                <button
                                  type="button"
                                  className="cust_btn mb_10 color-green"
                                  onClick={() => {
                                    this.handleRemove(index, 'emails')
                                  }}
                                >
                                  <Icon type="minus" size={32}/>
                                </button>
                                : null
                              }
                            </div>
                          </div>
                        </CloneField>
                      )
                    })
                  }
                </div>

                <div
                  className="column pt_24 fluid-y"
                  style={{paddingLeft: "0"}}
                >
                  <button
                    type="button"
                    className="cust_btn mt_10 color-green"
                    onClick={() => {
                      this.handleAdd('emails')
                    }}
                  >
                    <Icon type="plus" size={31}/>
                  </button>
                </div>
              </div>
            </div>
            <Spin size="small" spinning={this.props.loading.type === "acra_loading" && this.props.loading.loading_state} >
              <div className="mb_60">
                {this.props.guarantorPDF === "" &&
                  <Form.Item>
                    <Checkbox
                        className="mb_30"
                        onChange={this.isAcraChecked}
                        checked={this.state.checked}
                    >
                      Խնդրում ենք արտատպել ԷԿԵՆԳ/ՆՈՐՔ/ԱՔՌԱ հարցումներ կատարելու
                      թույլտվության համաձայնագիրը և ապահովել հաճախորդի ստորագրությունը:
                    </Checkbox>
                  </Form.Item>
                }

                {(this.props.guarantorPDF !== "" && this.state.checked) &&
                (<a
                    href={`data:application/octet-stream;charset=utf-8;base64,${this.props.guarantorPDF}`}       download="ACRA_Agreement.pdf"
                    className="base_link"
                >
                  <Icon type="print" size={20} />
                  ԷԿԵՆԳ/ՆՈՐՔ/ԱՔՌԱ հարցումներ կատարելու թույլտվության համաձայնագիր {<span style={{textDecoration: "underline"}} >(Տպել)</span>}
                </a>)
                }
              </div>
            </Spin>
            <div className="row">
              {(this.props.guarantorPDF !== "" && this.state.checked) &&
                <div className="column small-12">
                    <Button
                        className="btn btn-green btn_group"
                        htmlType="submit"
                        disabled={(this.props.acba_status === 5) ? false : true}
                    >
                      <span>ՀԱՍՏԱՏԵԼ</span>
                      <span className="btn__icon">
                      <Icon type="done" size={20} />
                    </span>
                    </Button>
                </div>
              }

            </div>
          </Form>
          : null
    )
  }
}

class CloneField extends Component {
  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.applicationReducer.dictionary,
    acba_status: state.applicationReducer.acba_status,
    error_message: state.applicationReducer.error_message,
    user_info: state.applicationReducer.customer_info_for_save.request.customerDetails,
    request_info: state.applicationReducer.request_info,
    searching_streets: state.applicationReducer.searching_streets,
    region: state.applicationReducer.region,
    townVillage: state.applicationReducer.townVillage,
    user_address_list: state.applicationReducer.user_address_list,
    agreementPDF: state.applicationReducer.agreementPDF,
    guarantorPDF: state.applicationReducer.guarantorPDF,
    loading: state.applicationReducer.loading,
    request_status: state.applicationReducer.request_status,
    calculate_request: state.applicationReducer.calculate_request,
    clear_money: state.applicationReducer.clear_money,
    guarantor_info: state.applicationReducer.guarantor_info,
    guarantor_address_list: state.applicationReducer.guarantor_address_list,
    hide_guarantor_result: state.applicationReducer.hide_guarantor_result,
    mobile_phone_codes: state.applicationReducer.mobile_phone_codes,
  };
}

const GuarantorContainer = Form.create()(Guarantor);

export default connect(mapStateToProps)(GuarantorContainer);