import produce from 'immer';
import history from "../history";
import commonUtils from "../utils/CommonUtils";
import {unsetToken} from "./initReducer";

//=================== ACTION TYPES ======================
const NOT_AUTHORIZED = "NOT_AUTHORIZED";

const INIT_USERS_LOAD_START = "INIT_USERS_LOAD_START";
const INIT_USERS_LOAD_SUCCESS = "INIT_USERS_LOAD_SUCCESS";
const INIT_USERS_LOAD_FAIL = "INIT_USERS_LOAD_FAIL";
const REGISTER_USER_START = "REGISTER_USER_START";
const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
const GET_LOAN_USERS_LIST_START = "GET_LOAN_USERS_LIST_START";
const GET_LOAN_USERS_LIST_SUCCESS = "GET_LOAN_USERS_LIST_SUCCESS";
const GET_LOAN_USERS_LIST_FAIL = "GET_LOAN_USERS_LIST_FAIL";
const USER_REQUEST_ACTIONS_START = "USER_REQUEST_ACTIONS_START";
const USER_REQUEST_ACTIONS_SUCCESS = "USER_REQUEST_ACTIONS_SUCCESS";
const USER_REQUEST_ACTIONS_FAIL = "USER_REQUEST_ACTIONS_FAIL";
const GET_PARTNERS_LIST_START = "GET_PARTNERS_LIST_START";
const GET_PARTNERS_LIST_SUCCESS = "GET_PARTNERS_LIST_SUCCESS";
const GET_PARTNERS_LIST_FAIL = "GET_PARTNERS_LIST_FAIL";
const GET_SHOP_BY_PARTNER_ID_START = "GET_SHOP_BY_PARTNER_ID_START";
const GET_SHOP_BY_PARTNER_ID_SUCCESS = "GET_SHOP_BY_PARTNER_ID_SUCCESS";
const GET_SHOP_BY_PARTNER_ID_FAIL = "GET_SHOP_BY_PARTNER_ID_FAIL";
const CLEAR_SHOPS_LIST = "CLEAR_SHOPS_LIST";

//=================== ACTION CREATORS ======================
export function notAuthorized() {
  return {
    type: NOT_AUTHORIZED
  }
}

export function initUsersLoadStart() {
  return {
    type: INIT_USERS_LOAD_START,
  }
}

export function initUsersLoadSuccess(payload) {
  return {
    type: INIT_USERS_LOAD_SUCCESS,
    payload
  }
}

export function initUsersLoadFail(payload) {
  return {
    type: INIT_USERS_LOAD_FAIL,
    payload
  }
}

export function registerUserStart() {
  return {
    type: REGISTER_USER_START,
  }
}

export function registerUserSuccess(payload) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload
  }
}

export function registerUserFail(payload) {
  return {
    type: REGISTER_USER_FAIL,
    payload,
  }
}

export function getLoanUsersListStart() {
  return {
    type: GET_LOAN_USERS_LIST_START,
  }
}

export function getLoanUsersListSuccess(payload) {
  return {
    type: GET_LOAN_USERS_LIST_SUCCESS,
    payload,
  }
}

export function getLoanUsersListFail(payload) {
  return {
    type: GET_LOAN_USERS_LIST_FAIL,
    payload,
  }
}

export function userRequestActionsStart() {
  return {
    type: USER_REQUEST_ACTIONS_START,
  }
}

export function userRequestActionsSuccess(payload) {
  return {
    type: USER_REQUEST_ACTIONS_SUCCESS,
    payload
  }
}

export function userRequestActionsFail(payload) {
  return {
    type: USER_REQUEST_ACTIONS_FAIL,
    payload,
  }
}

export function getPartnersListStart() {
  return {
    type: GET_PARTNERS_LIST_START,
  }
}

export function getPartnersListSuccess(payload) {
  return {
    type: GET_PARTNERS_LIST_SUCCESS,
    payload,
  }
}

export function getPartnersListFail(payload) {
  return {
    type: GET_PARTNERS_LIST_FAIL,
    payload,
  }
}

export function getShopByPartnerIdStart() {
  return {
    type: GET_SHOP_BY_PARTNER_ID_START,
  }
}

export function getShopByPartnerIdSuccess(payload) {
  return {
    type: GET_SHOP_BY_PARTNER_ID_SUCCESS,
    payload,
  }
}

export function getShopByPartnerIdFail(payload) {
  return {
    type: GET_SHOP_BY_PARTNER_ID_FAIL,
    payload,
  }
}

export function clearShopsList() {
  return {
    type: CLEAR_SHOPS_LIST
  }
}

//=================== thunks ======================
export function initUsersLoad() {
  return (dispatch, getState) => {
    dispatch(initUsersLoadStart());

    // Check if user session_token es not expired then make initialLoad calls
    commonUtils.apiCall('post', 'Authorization/CheckToken', {})
        .then((authorizationTokenResponse) => {
          if (authorizationTokenResponse.data.result) {
            let api_calls = [];
            const shops_list = getState().usersReducer.shops_list;

            let get_loan_users_param = {
              filter: {
                firstName: "",
                lastName: "",
                userName: "",
                quality: true,
                pageNumber: 1,
                IsBlocked: "",
              }
            };

            api_calls.push(
              commonUtils.apiCall("post", "Info/GetLoanUsersList", get_loan_users_param),
            );

            if (getState().initReducer.user_type != 4 && !Object.entries(shops_list).length ) {
              const contractId = getState().initReducer.contract_id;
              api_calls.push(
                commonUtils.apiCall("post", "Info/GetShops", { ContractID: contractId })
              )
            }

            let payload = {};
            if (api_calls.length > 0) {
              Promise.all(api_calls)
                .then((responses) => {
                  for (let i = 0; i < responses.length; i++) {
                    if (responses[i].data.resultCode === 1) {
                      let call_method = responses[i].config.url.split('/').slice(-1)[0];
                      switch (call_method) {
                        case "GetLoanUsersList":
                          payload.users_list = responses[i].data.result;
                          payload.filter = get_loan_users_param.filter; //setting filter in initLoad, for avoiding of dispatching resetFilter action creator in willUnMount()
                          break;
                        case "GetShops":
                          payload.shops_list = responses[i].data.result;
                          break;
                        case "GetPartnersList":
                          payload.partners_list = responses[i].data.result;
                          break;
                      }
                    }
                    if (responses[i].data.resultCode === 3) {
                      history.push('/signin');
                      dispatch(unsetToken());
                      dispatch(notAuthorized());
                      break;
                    }
                    if (responses[i].data.resultCode === 4) {
                      dispatch(initUsersLoadFail(responses[i].data.result[0].validationMessage));
                    }
                  }
                  dispatch(initUsersLoadSuccess(payload));
                })
                .catch((error) => {

                })
            }
            else {
              dispatch(initUsersLoadSuccess(payload));
            }

          }
          else {
            history.push('/signin');
            dispatch(unsetToken());
            dispatch(notAuthorized());
          }
        })
        .catch((error) => {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
          initUsersLoadFail("Կապի խնդիր. խնդրում ենք կրկին փորձել")
        })
  }
}

export function registerUser(input_values) {
  return(dispatch, getState) => {
    dispatch(registerUserStart());

    const contract_id = getState().initReducer.contract_id;
    const ipAddress = getState().initReducer.ip_address;

    let user_type = input_values.userType === "User" ? 1 : input_values.userType === "Manager" ? 2 : input_values.userType;

    let param = {
      registrationDetails: {
        contractID: input_values.contractID !== undefined ? input_values.contractID : contract_id ? contract_id : 0,
        email: input_values.email,
        firstName: input_values.firstName,
        lastName: input_values.lastName,
        marketID: input_values.marketID != null ? input_values.marketID : 0,
        userType: user_type
      },
      ipAddress
    }

    commonUtils.apiCall("post", "Authorization/RegisterUser", param)
      .then((response) => {
        if (response.data.resultCode === 1) {

          let get_loan_users_param = {
            "filter": {
              "firstName": "",
              "lastName": "",
              "userName": "",
              "quality": true,
              "pageNumber": 1
            }
          }

          commonUtils.apiCall("post", "Info/GetLoanUsersList", get_loan_users_param)
            .then((getUsersResponse) => {
              if (getUsersResponse.data.resultCode === 1) {
                const user_type = getState().initReducer.user_type;
                dispatch(registerUserSuccess({users_list: getUsersResponse.data.result, user_type}));
              }
              if (getUsersResponse.data.resultCode === 3) {
                history.push('/signin');
                dispatch(unsetToken());
                dispatch(notAuthorized());
              }
              if (getUsersResponse.data.resultCode === 4) {
                dispatch(registerUserFail(response.data.result[0].validationMessage));
              }
            })
            .catch((getUsersError) => {
              dispatch(registerUserFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
            })
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(registerUserFail(response.data.result[0].validationMessage));
        }
      })
      .catch((error) => {
        dispatch(registerUserFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

export function getLoanUsersList(page_number, filters) {
  return (dispatch, getState) => {
    dispatch(getLoanUsersListStart());

    const {
      firstName,
      lastName,
      userName,
      quality,
      userType,
      IsBlocked,
    } = filters;

    let  param = {};

    if (userType && userType != 0) { // if userType exists and is selected something from options
      param = {
        filter: {
          firstName: firstName || '', // if firstName exists -> firstName; else -> ''
          lastName: lastName || '',
          userName: userName || '',
          quality: quality,
          IsBlocked: IsBlocked,
          userType: userType,
          pageNumber: page_number
        }
      };
    } else {
      param = {
        filter: {
          firstName: firstName || '', // if firstName exists -> firstName; else -> ''
          lastName: lastName || '',
          userName: userName || '',
          quality: quality,
          IsBlocked: IsBlocked,
          pageNumber: page_number
        }
      };
    }

    commonUtils.apiCall("post", "Info/GetLoanUsersList", param)
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getLoanUsersListSuccess({users_list: response.data.result, filter: param.filter}));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(getLoanUsersListFail(response.data.result[0].validationMessage))
        }
      })
      .catch((error) => {
        dispatch(getLoanUsersListFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"))
      })
  }
}

export function userRequestActions(input_values) {
  return (dispatch, getState) => {
    dispatch(userRequestActionsStart());
    const ipAddress = getState().initReducer.ip_address;

    let param = {
      "requestType": input_values.action_type,
      "userDetails": {
        "email": input_values.email,
        "firstName": input_values.firstName,
        "lastName": input_values.lastName,
        "userName": input_values.login
      },
      ipAddress
    };

    if (input_values.marketID) {
      param.userDetails.marketID = input_values.marketID;
    }

    commonUtils.apiCall("post", "Authorization/UserRequestActions", param)
      .then((response) => {
        if (response.data.resultCode === 1) {

          const param = {
            filter: getState().usersReducer.filter,
          };

          commonUtils.apiCall("post", "Info/GetLoanUsersList", param)
            .then((response) => {
              if (response.data.resultCode === 1) {
                dispatch(userRequestActionsSuccess({users_list: response.data.result}));
              }
            })
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(userRequestActionsFail(response.data.result[0].validationMessage));
        }
      })
      .catch((error) => {
        dispatch(userRequestActionsFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"))
      })
  }
}

export function getPartnersList(search_text) {
  return (dispatch, getState) => {
    dispatch(getPartnersListStart());

    commonUtils.apiCall("get", `Info/GetPartnersList?searchPartner=${search_text}`, {})
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getPartnersListSuccess({partners_list: response.data.result}));
        }
      })
      .catch((error) => {
        dispatch(getPartnersListFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })

  }
}

export function getShopByPartnerId(contractID) {
  return (dispatch, getState) => {
    dispatch(getShopByPartnerIdStart());

    commonUtils.apiCall("post", "Info/GetShops", {contractID})
      .then((response) => {
        if (response.data.resultCode === 1) {
          dispatch(getShopByPartnerIdSuccess({shops_list: response.data.result}));
        }
        if (response.data.resultCode === 3) {
          history.push('/signin');
          dispatch(unsetToken());
          dispatch(notAuthorized());
        }
        if (response.data.resultCode === 4) {
          dispatch(getShopByPartnerIdFail(response.data.result[0].validationMessage));
        }
      })
      .catch((error) => {
        dispatch(getShopByPartnerIdFail("Կապի խնդիր. խնդրում ենք կրկին փորձել"));
      })
  }
}

//=================== State ======================
const initial_state = {
  loading: {
    type: "",
    loading_state: false,
  },
  error_message: {
    type: '',
    message: '',
  },
  users_list: {},
  shops_list: {},
  partners_list: {},
  user_saved: false,
  user_edited: false,
  filter: {
    firstName: "",
    lastName: "",
    userName: "",
    quality: true,
    pageNumber: 1,
    IsBlocked: "",
  }
}

export default function usersReducer(state = initial_state, action) {
  switch (action.type) {
    case NOT_AUTHORIZED:
      return produce(state, draft => {
        draft.loading.type = "";
        draft.loading.loading_state = false;
        draft.error_message.type = "NotAuthorized";
        draft.error_message.message = "Խնդրում ենք կրկին մուտք գործել համակարգ";
      })
    case INIT_USERS_LOAD_START:
      return produce(state, draft => {
        draft.loading.type = "init_reducer_load";
        draft.loading.loading_state = true;
      })
    case INIT_USERS_LOAD_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        action.payload.hasOwnProperty("users_list") && (draft.users_list = action.payload.users_list);
        action.payload.hasOwnProperty("filter") && (draft.filter = action.payload.filter);
        action.payload.hasOwnProperty("shops_list") && (draft.shops_list = action.payload.shops_list);
        action.payload.hasOwnProperty("partners_list") && (draft.partners_list = action.payload.partners_list);
      })
    case INIT_USERS_LOAD_FAIL:
      return produce(state, draft => {
        draft.error_message.type = "init_reducer_fail";
        draft.error_message.message = action.payload;
        draft.loading.loading_state = false;
      })
    case REGISTER_USER_START:
      return produce(state, draft => {
        draft.loading.type = "register_user";
        draft.loading.loading_state = true;
        draft.error_message.type = "";
        draft.error_message.message = "";
        draft.user_saved = false;
      })
    case REGISTER_USER_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.user_saved = true;
        draft.users_list = action.payload.users_list;
        if (action.payload.user_type == 4) {
          draft.shops_list = {};
          draft.partners_list = {};
        }
      })
    case REGISTER_USER_FAIL:
      return produce(state, draft => {
        draft.error_message.type = "register_user_error";
        draft.error_message.message = action.payload;
        draft.loading.loading_state = false;
      })
    case GET_LOAN_USERS_LIST_START:
      return produce(state, draft => {
        draft.loading.type = "get_loan_user";
        draft.loading.loading_state = true;
        draft.error_message.type = "";
        draft.error_message.message = "";
      })
    case GET_LOAN_USERS_LIST_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.users_list = action.payload.users_list;
        draft.filter = action.payload.filter;
      })
    case GET_LOAN_USERS_LIST_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "get_loan_user_error";
        draft.error_message.message = action.payload;
      })
    case USER_REQUEST_ACTIONS_START:
      return produce(state, draft => {
        draft.loading.type = "user_request_actions_load";
        draft.loading.loading_state = true;
        if (state.user_edited) {
          draft.user_edited = false;
        }
      })
    case USER_REQUEST_ACTIONS_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.user_edited = true;
        draft.users_list = action.payload.users_list;
      })
    case USER_REQUEST_ACTIONS_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "user_request_actions_fail";
        draft.error_message.message = action.payload;
      })
    case GET_PARTNERS_LIST_START:
      return produce(state, draft => {

      })
    case GET_PARTNERS_LIST_SUCCESS:
      return produce(state, draft => {
        draft.partners_list = action.payload.partners_list;
      })
    case GET_PARTNERS_LIST_FAIL:
      return produce(state, draft => {

      })
    case GET_SHOP_BY_PARTNER_ID_START:
      return produce(state, draft => {
        draft.loading.type = "get_shop_by_partner_id";
        draft.loading.loading_state = true;
        draft.error_message.type = "";
        draft.error_message.message = "";
      })
    case GET_SHOP_BY_PARTNER_ID_SUCCESS:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.shops_list = action.payload.shops_list;
      })
    case GET_SHOP_BY_PARTNER_ID_FAIL:
      return produce(state, draft => {
        draft.loading.loading_state = false;
        draft.error_message.type = "get_shop_by_partner_id";
        draft.error_message.message = action.payload;
      })
    case CLEAR_SHOPS_LIST:
      return produce(state, draft => {
        draft.shops_list = {};
      })
    default:
      return state;
  }
}