import React, { Component } from 'react';
import {connect} from "react-redux";
import {downloadAllContracts, loadAcraAgreement} from "../../reducers/applicationReducer";
import moment from 'moment';
import TitleUserData from "../TitleUserData/TitleUserData";
import PlateCardUser from "../PlateCardUser/PlateCardUser";
import Icon from "../Icon/Icon";
import {Button, Spin} from "antd";

class UserData extends Component {
  getUserData() {
    const {user_info} = this.props;
    const dateFormat = 'DD/MM/YYYY';

    let payload = {
      dateOfBirth: user_info.dateOfBirth ? moment(user_info.dateOfBirth).format(dateFormat) : "-",
      documentNumber: user_info.documentNumber ? user_info.documentNumber : "-",
      socialNumber: user_info.socialNumber ? user_info.socialNumber : "-",
      documentDate: user_info.documentDate ? moment(user_info.documentDate).format(dateFormat) : "-",
      documentInf: user_info.documentInf ? user_info.documentInf : "-",
      documentValidDate: user_info.documentValidDate ? moment(user_info.documentValidDate).format(dateFormat) : "-",
    };
    
    return payload;
  }

  getAddressListNames(index) {
    const { dictionary } = this.props;
    let addressList = this.props.user_info.addressList[index];

    if (!addressList) { // if addressList is undefined
      addressList = this.props.user_info.addressList[0];
    }

    let payload = {
      street: "-",
      building: "-",
      house: "-",
      appartment: "-",
      townVillage: "-",
      region: "-",
      country: "-",
    };

    if (addressList.streetDescription) {
      payload.street = addressList.streetDescription;
    }

    if (addressList.building) {  // if is not null, undefined, "", and 0
      payload.building = dictionary.building_types.find(element => element.value == addressList.building).label;
    }

    if (addressList.house) {
      payload.house = addressList.house;
    }

    if (addressList.appartment) {
      payload.appartment = addressList.appartment;
    }

    if (addressList.townVillageDescription) {
      payload.townVillage = addressList.townVillageDescription;
    }

    if (addressList.regionDescription) {
      payload.region = addressList.regionDescription;
    } else if (addressList.region) {
      payload.region = dictionary.regions.find(element => element.value == addressList.region).label;
    }

    if (addressList.country) {
      payload.country = dictionary.countries.find(element => element.value == addressList.country).label;
    }

    return payload;
  }

  getDocumentType() {
    const { dictionary } = this.props;
    const user_info = this.props.user_info;

    if (user_info.documentType) {
      return dictionary.document_types.find(element => element.value == user_info.documentType).label;
    }

    return "-";
  }

  downloadAllContracts = () => {
    this.props.dispatch(downloadAllContracts());
  };

  downloadAcraAgreement = () => {
    const userData = this.getUserData();
    const { name, lastName, fatherName } = this.props.user_info;
    const {documentNumber} = userData;

    const userInfo = {
      name,
      lastName,
      fatherName
    };
    this.props.dispatch(loadAcraAgreement("person", documentNumber, userInfo));
  };

  downloadAcraAgreementToASoft = () => {
    const contract = `data:application/octet-stream;charset=utf-8;base64,${this.props.agreementPDF}`;
    let link = document.createElement('a');
    link.download = "ACRA_Agreement.pdf";
    link.href = contract;
    link.dispatchEvent(new MouseEvent('click'));
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.all_contracts_pdf != prevProps.all_contracts_pdf) {
      const contracts = this.props.all_contracts_pdf;

      if (Array.isArray(contracts)) {
        contracts.forEach((contract, index) => {
          contract = `data:application/octet-stream;charset=utf-8;base64,${contract}`;
          setTimeout(() => {
            let link = document.createElement('a');
            link.download = `Պայմանագիր_${index + 1}.pdf`;
            link.href = contract;
            link.dispatchEvent(new MouseEvent('click'))
          }, 50)
        })
      }
    }

    if (this.props.agreementPDF && this.props.agreementPDF !== prevProps.agreementPDF) {
      this.downloadAcraAgreementToASoft();
    }
  }

  render() {
    const {user_info, agreementPDF, acba_status} = this.props;

    const userData = this.getUserData();
    const documentType = this.getDocumentType();
    const registrationAddressList = this.getAddressListNames(0);
    const habitationAddressList = this.getAddressListNames(1);

    return (
        <div>
            {/*<Title text={`${user_info.lastName} ${user_info.name} ${user_info.fatherName}`} size="md" weight="bold" className="mb_45" />*/}

            <TitleUserData text="Անձնագրային տվյալներ" size="xs" weight="bold" icon="info" iconSize="xs" className="mb_20"/>
            <div className="plate_data mb_25">
                <div className="row">
                    <PlateCardUser className="column small-12 medium-3" label="Փաստաթղթի տեսակ" value={documentType}  />
                    <PlateCardUser className="column small-12 medium-2" label="Փաստաթղթի համար" value={userData.documentNumber}  />
                    <PlateCardUser className="column small-12 medium-3" label="Ծննդյան ամսաթիվ" value={userData.dateOfBirth} />
                    {
                      user_info.documentType == 2 &&
                        <PlateCardUser className="column small-12 medium-4" label="Հանրային ծառայությունների համարանիշ" value={userData.socialNumber}  />
                    }
                </div>
                <div className="row">
                  <PlateCardUser className="column small-12 medium-3" label="Տրվել է" value={userData.documentDate}  />
                  <PlateCardUser className="column small-12 medium-3" label="Ում կողմից" value={userData.documentInf}  />
                  <PlateCardUser className="column small-12 medium-4" label="Վավերական է" value={userData.documentValidDate}  />
                </div>
            </div>
            <TitleUserData text="ԳՐԱՆՑՄԱՆ ՀԱՍՑԵ" size="sm" weight="bold" icon="home" iconSize="sm" className="mb_20"/>
            <div className="plate_data mb_25">
                <div className="row">
                    <PlateCardUser className="column small-12 medium-3" label="Փողոց" value={registrationAddressList.street} />
                    <PlateCardUser className="column small-12 medium-3" label="Շինության տեսակ" value={registrationAddressList.building}  />
                    <PlateCardUser className="column small-12 medium-3" label="Տուն / Շենք" value={registrationAddressList.house}  />
                    <PlateCardUser className="column small-12 medium-3" label="Բնակարան" value={registrationAddressList.appartment}  />
                    <PlateCardUser className="column small-12 medium-4" label="Քաղաք / Գյուղ / Համայնք" value={registrationAddressList.townVillage}  />
                    <PlateCardUser className="column small-12 medium-4" label="Մարզ" value={registrationAddressList.region}  />
                    <PlateCardUser className="column small-12 medium-4" label="Երկիր" value={registrationAddressList.country}  />
                </div>
            </div>
            {user_info.addressList[1] !== undefined &&
              <>
                <TitleUserData text="ԲՆԱԿՈՒԹՅԱՆ ՀԱՍՑԵ" size="sm" weight="bold" icon="home" iconSize="sm" className="mb_20"/>
                <div className="plate_data mb_25">
                  <div className="row">
                    <PlateCardUser className="column small-12 medium-3" label="Փողոց" value={habitationAddressList.street} />
                    <PlateCardUser className="column small-12 medium-3" label="Շինության տեսակ" value={habitationAddressList.building}  />
                    <PlateCardUser className="column small-12 medium-3" label="Տուն / Շենք" value={habitationAddressList.house}  />
                    <PlateCardUser className="column small-12 medium-3" label="Բնակարան" value={habitationAddressList.appartment}  />
                    <PlateCardUser className="column small-12 medium-4" label="Քաղաք / Գյուղ / Համայնք" value={habitationAddressList.townVillage}  />
                    <PlateCardUser className="column small-12 medium-4" label="Մարզ" value={habitationAddressList.region}  />
                    <PlateCardUser className="column small-12 medium-4" label="Երկիր" value={habitationAddressList.country}  />
                  </div>
                </div>
              </>
            }

            <Spin spinning={this.props.loading.type === "acra_loading" && this.props.loading.loading_state} >
              <a
                onClick={this.downloadAcraAgreement}
                className="base_link mb_45"
              >
                <Icon type="print" size={20} />
                ԷԿԵՆԳ/ՆՈՐՔ/ԱՔՌԱ հարցումներ կատարելու թույլտվության համաձայնագիր {<span style={{textDecoration: "underline"}} >(Տպել)</span>}
              </a>
            </Spin>

            {
              acba_status == 6 || acba_status == 7 ?
                <Spin spinning={this.props.loading.type === "download_all_contracts_start" && this.props.loading.loading_state} >
                  <p
                    className="base_link mb_45"
                    onClick={this.downloadAllContracts}
                    style={{cursor: "pointer"}}
                  >
                    <Icon type="print" size={20} />
                    Արտատպել պայմանագրերը
                  </p>
                </Spin>
              : null
            }

        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.applicationReducer.loading,
    dictionary: state.applicationReducer.dictionary,
    acba_status: state.applicationReducer.acba_status,
    agreementPDF: state.applicationReducer.agreementPDF,
    all_contracts_pdf: state.applicationReducer.all_contracts_pdf
  };
}

export default connect(mapStateToProps)(UserData);