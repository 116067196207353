import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Button} from 'antd';

import Title from '../../components/Title/Title';


class Settings extends Component {

  render() {
    return (
      <div className='column small-7' >
        <Title text='Կարգավորումներ' size='md' weight='black' className='mb_35'/>

        <div className='settings-actions-wrapper' >
          <NavLink to='/passwordreset' >
            <Button className='mb_25 btn btn-green' >
              <span>Փոխել գաղտնաբառը</span>
            </Button>
          </NavLink>

          <NavLink to='/applicationstatus' >
            <Button className='btn btn-green' >
              <span>ՆՈՐ ՀԱՅՏԻ ԿԱՐԳԱՎԻՃԱԿԻ ՓՈՓՈԽՈՒԹՅՈՒՆ</span>
            </Button>
          </NavLink>
        </div>
      </div>
    )
  }
}

export default Settings;