export default function toBoolean(boolean) {
  if (typeof boolean !== "boolean") {
    if (boolean === "true") {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return boolean;
  }
}