import React, {Component} from 'react';
import history from "../../history";
import './Offer.scss';
import Icon from "../../components/Icon/Icon";
import Title from "../../components/Title/Title";
import TopSection from "../../layout/TopSection/TopSection";
import {Form, Input, Button, Select, Spin, message} from 'antd';
import {connect} from "react-redux";
import {
  offerPageInitLoad,
  saveRequestItems,
  clearRequestStatus,
  clearErrorMessage,
  editRequestTerms
} from "../../reducers/applicationReducer";
import OfferTerms from "../OfferTerms/OfferTerms";
import {Redirect} from 'react-router-dom';
import NumericInput from "../../components/NumericInput/NumericInput";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

const {Option, OptGroup} = Select;

class Offer extends Component {
  constructor(props) {
    super(props);

    if (this.props.match.params.id === 'undefined') {
      window.location.replace("/");
    }
    let total = this.props.request_items.items.reduce((total, currentValue) => parseInt(total) + parseInt(currentValue.itemPrice), 0);
    this.state = {
      products: this.props.request_items.items,
      prepayment: parseInt(this.props.request_items.prepaymentAmount),
      addition: 0,
      total: !isNaN(total) ? total : 0,
    };
  }

  additionBlur = (event) => {
    let sum = 0;
    this.state.products.map(element => {
      if (isNaN(element.itemPrice) || !element.itemPrice) {
        sum += 0;
      } else {
        sum += parseInt(element.itemPrice);
      }
    });

    let prepayment = 0;
    if (sum > this.props.offer_terms.amount) {
      prepayment = sum - this.props.offer_terms.amount;
    }

    this.props.form.setFieldsValue({
      "prepay": prepayment,
    })
    this.setState((state, props) => ({
      total: sum,
      prepayment: prepayment,
    }));
  };

  handleRemove = (index) => {
    let arr = this.state.products;

    if (Object.entries(arr[index]).length !== 0) {
      let prepayment = this.state.prepayment - parseInt(arr[index].itemPrice) > 0 ? this.state.prepayment - parseInt(arr[index].itemPrice) : 0;
      this.props.form.resetFields([`itemPrice_${index}`, `itemModel_${index}`, `productType_${index}`, "prepay"]);
      this.setState({
        total: (arr[index].itemPrice !== "" && arr[index].itemPrice != null) ? this.state.total - parseInt(arr[index].itemPrice) : this.state.total,
        prepayment: prepayment,
        products: arr
      });
    } else {
      this.setState({
        products: arr
      });
    }

    arr.splice(index, 1);
  };

  handleAdd = (state) => {
    let arr = [...this.state[state], {}];

    // if (this.state.prepayment === this.state.total - this.props.offer_terms.amount) {
    this.setState({[state]: arr});
    // }
  };

  handleGetData = (item, index, name, type, state) => {
    let arr = this.state[state];

    switch (type) {
      case "input":
        if (name === "itemPrice") { //erb vor NumericInput-ica galis, miangamic value-n a galis, el petq chi target.value-ov stanal
          arr[index][name] = item;
        } else {
          arr[index][name] = item.target.value;
        }
        break;
      case "select":
        arr[index]['itemType'] = item.label;
        arr[index]['itemTypeID'] = item.key;
        break;
      case "checkbox":
        arr[index][name] = item.target.checked;
        break;
      default:
        // useless
    }

    this.props.form.resetFields([name + "_" + index]);

    this.setState({[state]: arr})
  };

  componentDidUpdate(previousProps, previousState) {

    if (previousProps.request_items !== this.props.request_items) {
      let sum = this.props.request_items.items.reduce((total, currentValue) => Number(total) + Number(currentValue.itemPrice), 0);
      this.setState({
        products: this.props.request_items.items,
        total: sum,
        prepayment: this.props.request_items.prepaymentAmount
      })
    }

    /*if (previousState.products.length !== this.state.products.length) {
      let total = this.props.request_items.items.reduce((total, currentValue) => parseInt(total) + parseInt(currentValue.itemPrice), 0);
      this.setState({
        total: !isNaN(total) ? total : 0,
      })
    }*/

    if (this.props.request_status === 'sent_to_bank_success') {
      message.warning("Ուղարկված", 10);
      this.props.dispatch(clearRequestStatus());
    }

    switch (this.props.error_message.type) {
      case "SaveRequestItems":
      case "CalculateRequestOffer":
      case "GetRequestOffer":
        if (previousProps.error_message.message !== this.props.error_message.message) {
          message.warning(<ErrorMessageContent text={this.props.error_message.message} />, 5);
          this.props.dispatch(clearErrorMessage());
        }
        break;
      default:
    }

    if (isNaN(this.state.prepayment)) {
      this.setState({
        prepayment: 0,
      })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let request_id = this.props.match.params.id === undefined ? '' : this.props.match.params.id;
    this.props.dispatch(offerPageInitLoad(request_id));
  }

  prepaymentOnBlur = (event) => {
    if (this.state.total > this.props.offer_terms.amount && parseInt(event.target.value) >= this.state.total - this.props.offer_terms.amount) {
      this.setState({
        prepayment: this.state.total - this.props.offer_terms.amount,
      })
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (parseInt(this.state.total) < 26000) {
          message.error("Ապրանքների հանրագումարը պետք է մեծ լինի 26,000 դրամից")
        } else {
          this.props.dispatch(saveRequestItems(this.state.products, this.state.prepayment));
        }
      }
    })
  };

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  editRequestTerms = () => {
    history.push(`/application/${this.props.request_id}`);
    this.props.dispatch(editRequestTerms());
  };

  formOnKeyDown = (e) => {
    return e.keyCode == 13 ? e.preventDefault(): '';
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    let counter = 0;
    return (
        this.props.request_status === 'confirm_success' ?
            <Redirect to={`/loan/${this.props.request_id}`}/>
            :
                <>
                  <TopSection step={2}/>
                  <Spin tip="Loading..."
                        spinning={(this.props.loading.type === "offer_init_load_loading" || this.props.loading.type === "confirm_request_loading") && this.props.loading.loading_state}>
                    <Form
                        onSubmit={this.onSubmit}
                        onKeyDown={this.formOnKeyDown}
                    >

                      <div className="row">
                        <div className="column small-12 medium-large-9">
                          <div
                            className="flex-container row-wrap align-end align-middle mb_30"
                            style={{justifyContent: "flex-end"}}
                          >
                            <Button
                              type="link" className="link_btn"
                              onClick={this.editRequestTerms}
                              disabled={this.props.acba_status === 9 ? false : true}
                            >
                              <Icon type="pen" size={13}/>
                              ԽՄԲԱԳՐԵԼ ՏՎՅԱԼՆԵՐԸ
                            </Button>
                          </div>

                          <OfferTerms terms={this.props.offer_terms}/>

                          <div className="mb_30">
                            <div className="flex-container align-center mb_30" >
                              <Title text="ԱՊՐԱՆՔ" size="lg" weight="bold" icon="product"/>
                              <div className="column fluid-y">
                                <button type="button" className="cust_btn spaced-md color-green"
                                        onClick={() => {
                                          this.handleAdd('products')
                                        }}
                                >
                                  <Icon type="plus" size={31}/>
                                </button>
                              </div>
                            </div>

                            <div className="medium-large-flex-container align-start">
                              <div className="small-12">
                                {
                                  this.state.products.map((item, index) => {
                                    return (
                                        <Incomes className="clone_panel"
                                                 key={index}
                                                 index={index}
                                                 value={item}>

                                          <div className="medium-large-flex-container align-middle">
                                            <div className="small-12">
                                              <div className="row">
                                                <Form.Item label="Ապրանքի տեսակ / Ծառայություն" className="column small-12 large-4">
                                                  {getFieldDecorator(`productType_${index}`, {
                                                    // initialValue: "", // from selected option
                                                    initialValue: item.itemTypeID !== undefined && item.itemType !== undefined ?{
                                                      key: item.itemTypeID,
                                                      label: item.itemType,
                                                    } : "",
                                                    rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                                                  })(
                                                      <Select
                                                        labelInValue
                                                        showSearch
                                                        onChange={(event) => {
                                                          this.handleGetData(event, index, 'productType', 'select', 'products')
                                                        }}
                                                        filterOption={(input, option) => option.props.children.toLowerCase !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} /*option.props.children.toLowerCase() !== undefined && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/
                                                      >
                                                        {this.props.item_types.map((element, index) => {
                                                          const nextElement = this.props.item_types[index + 1];
                                                          counter++;
                                                          if (nextElement !== undefined) {
                                                            if (index === 0 || element.itemGroupID !== nextElement.itemGroupID) {
                                                              return (
                                                                <OptGroup
                                                                  key={index === 0 ? element.id : nextElement.id}
                                                                  label={
                                                                    <b>
                                                                      <p style={{color: "rgb(140, 161, 9)"}} >{index === 0 ? element.itemGroupDescription : nextElement.itemGroupDescription}</p>
                                                                    </b>
                                                                  }
                                                                >
                                                                  <Option
                                                                    key={index === 0 ? element.id : nextElement.id}
                                                                    label={index === 0 ? element.itemDescription : nextElement.itemDescription}
                                                                  >
                                                                    {index === 0 ? element.itemDescription : nextElement.itemDescription}
                                                                  </Option>
                                                                </OptGroup>
                                                              )
                                                            }
                                                            else {
                                                              if (index === 1 || index === 2 || index === 3) {
                                                                return (
                                                                  <Option
                                                                    key={element.id}
                                                                    label={element.itemDescription}
                                                                  >
                                                                    {element.itemDescription}
                                                                  </Option>
                                                                )
                                                              }
                                                              return (
                                                                <Option
                                                                  key={nextElement.id}
                                                                  label={nextElement.itemDescription}
                                                                >
                                                                  {nextElement.itemDescription}
                                                                </Option>
                                                              )
                                                            }
                                                          }
                                                        })}
                                                      </Select>
                                                  )}
                                                </Form.Item>

                                                <Form.Item label="Մակնիշ/Տեսակ"
                                                           className="column small-12 large-4 mb_20">
                                                  {getFieldDecorator(`itemModel_${index}`, {
                                                    initialValue: item.itemModel,
                                                    rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                                                  })(
                                                      <Input type="text"
                                                             name={`itemModel_${index}`}
                                                             id={`itemModel_${index}`}
                                                             onChange={(event) => {
                                                               this.handleGetData(event, index, 'itemModel', 'input', 'products')
                                                             }}
                                                             autoComplete="off"
                                                      />
                                                  )}
                                                </Form.Item>

                                                <Form.Item label="Գին" className="column small-12 large-4 mb_20">
                                                  {getFieldDecorator(`itemPrice_${index}`, {
                                                    initialValue: item.itemPrice,
                                                    rules: [{required: true, message: 'դաշտը լրացված չէ'}],
                                                    trigger: "blur",
                                                  })(
                                                      <NumericInput
                                                        formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        name={`itemPrice_${index}`}
                                                        id={`itemPrice_${index}`}
                                                        suffix={<Icon type="dram" size={13}/>}
                                                        onChange={(value) => {
                                                          this.handleGetData(value, index, 'itemPrice', 'input', 'products')
                                                        }}
                                                        onBlur={this.additionBlur}
                                                      />
                                                  )}
                                                </Form.Item>
                                              </div>
                                            </div>

                                            <div className="column"/>
                                            <div className="column pt_5">
                                              {this.state.products.length > 1 ? (
                                                  <button type="button" className="cust_btn mb_10 color-green"
                                                          onClick={() => {this.handleRemove(index)}}>
                                                    <Icon type="minus" size={32}/>
                                                  </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </Incomes>
                                    )
                                  })
                                }
                              </div>

                              <div className="column">
                              </div>

                            </div>

                            <div className="row">
                              <div className="column small-12 large-4">
                              </div>
                              <Form.Item label="Կանխավճար" className="column small-12 large-4">
                                {getFieldDecorator('prepay', {
                                  type: 'number',
                                  initialValue: this.state.prepayment,
                                  /*rules: [{
                                    message: `Պետք է լինի ավել քան՝ ${this.state.prepayment}`,
                                    type: "number",
                                    min: this.state.total > this.props.offer_terms.amount ? this.state.total - this.props.offer_terms.amount : null
                                  }],*/
                                  rules: [{
                                    // a little bit is not works as logic, but still works :)
                                    validator: (rule, value) => (parseInt(value) >= this.state.total - this.props.offer_terms.amount),
                                    message: `Պետք է լինի ավել քան՝ ${this.formatNumber(this.state.total - this.props.offer_terms.amount)}`,
                                  }],
                                  validateTrigger: 'blur',
                                })(
                                    <NumericInput
                                      formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        name="prepay"
                                        suffix={<Icon type="dram" size={13}/>}
                                        onBlur={this.prepaymentOnBlur}
                                        onChange={(value) => {
                                          this.setState({
                                            prepayment: parseInt(value),
                                          })
                                        }}
                                    />
                                )}
                              </Form.Item>

                              <div className="column small-12 large-4">
                                <div className="form_field">
                                  <label>
                                    Հանրագումար
                                  </label>
                                  <div className="fluid-x small-order-2 text-black text-lg">
                                    {this.formatNumber(this.state.total)} <Icon type="dram" size={28}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="column small-12">
                          <div className="flex-container row-wrap align-justify md-align-center align-middle" style={{justifyContent: "flex-end"}} >
                            <Button
                                className="btn btn-green btn_group"
                                htmlType="submit"
                                disabled={(this.props.acba_status === 9 || this.props.acba_status === 5 && (this.props.user_type == 1 || this.props.user_type == 2)) ? false : true}
                            >
                              <span>ՇԱՐՈՒՆԱԿԵԼ</span>
                              <span className="btn__icon">
                                <Icon type="next" size={20}/>
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Spin>
                </>
    )
  }

}

class Incomes extends Component {
  render() {
    return (
        <div className={this.props.className} style={this.props.style}>
          {this.props.children}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_type: state.initReducer.user_type,
    acba_status: state.applicationReducer.acba_status,
    request_status: state.applicationReducer.request_status,
    // init_offer_load: state.applicationReducer.init_offer_load,
    item_types: state.applicationReducer.item_types,
    offer_terms: state.applicationReducer.offer_terms,
    error_message: state.applicationReducer.error_message,
    loading: state.applicationReducer.loading,
    request_id: state.applicationReducer.customer_info_for_save.request.requestID,
    request_items: state.applicationReducer.request_items,
  };
}

const OfferContainer = Form.create()(Offer);

export default connect(mapStateToProps)(OfferContainer);
