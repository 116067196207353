import React, { Component } from 'react';
import './Materials.scss';
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/Button/Button";
import NotificationsPopover from "../NotificationsPopover/NotificationsPopover";
import { Tabs, Pagination } from 'antd';

export default class Materials extends Component {
  render() {
    const { TabPane } = Tabs;

    return (
      <div className="row">
        <div className="column small-12">
          <div className="flex-container row-wrap align-justify align-middle mb_45">
            <Title text="ՏԵՂԵԿԱՏՎԱԿԱՆ ՆՅՈՒԹԵՐ" size="md" weight="black" />

            <NotificationsPopover />
          </div>
        </div>

        <div className="column small-12">
          <Tabs animated={false}>
            <TabPane tab={[<Icon type="all" size={15} className="tab_icon" />, 'Բոլորը']} key="tab-1">
              <Title text="Վերջին նյութեր" size="md" weight="medium" className="base-case mb_30" />

              <div className="row">
                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 3.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      Հաճախ հանդիպող սխալներ
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 6.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      <div className="clamp-3">
                        Սահմանափակ բյուջեի դեպքում՝ փոքր բիզնեսի վաճառքը խթանող 7 ban man
                      </div>
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 6.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      <div className="clamp-3">
                        Սահմանափակ բյուջեի դեպքում՝ փոքր բիզնեսի վաճառքը խթանող 7 ban man
                      </div>
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 3.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      Հաճախ հանդիպող սխալներ, որոնք թույլ են տալիս Ստարտափները
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 3.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      Հաճախ հանդիպող սխալներ, որոնք թույլ են տալիս Ստարտափները
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 6.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      <div className="clamp-3">
                        Սահմանափակ բյուջեի դեպքում՝ փոքր բիզնեսի վաճառքը խթանող 7 ban man
                      </div>
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 6.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      <div className="clamp-3">
                        Սահմանափակ բյուջեի դեպքում՝ փոքր բիզնեսի վաճառքը խթանող 7 ban man
                      </div>
                    </div>
                  </a>
                </div>

                <div className="column small-12 medium-4 large-3">
                  <a href="" className="list_card">
                    <div className="list_card__img">
                      <img src={require("../../assets/img/image 3.jpg")} alt=""/>

                      <div className="list_card_info">
                        <i>02 սեպտեմբերի, 2019</i>
                      </div>
                    </div>
                    <div className="list_card__title">
                      <Icon type="badge" size={24} className="list_card_badge"/>
                      Հաճախ հանդիպող սխալներ, որոնք թույլ են տալիս Ստարտափները
                    </div>
                  </a>
                </div>
              </div>

              <div className="row pt_10">
                <div className="column small-12">
                  <Pagination defaultCurrent={1} total={50} />
                </div>
              </div>
            </TabPane>

            <TabPane tab={[<Icon type="info" size={16} className="tab_icon" />, 'Տեղեկատվական']} key="tab-2">
              <p>Content of Tab Pane 3</p>
              <p>Content of Tab Pane 3</p>
            </TabPane>

            <TabPane tab={[<Icon type="product" size={14} className="tab_icon" />,
              'Նոր պրոդուկտ/արշավ',
              <span className="notification_btn__count" style={{position: 'relative', top: -15}}>2</span>]} key="tab-3">
              <p>Content of Tab Pane 3</p>
            </TabPane>

            <TabPane tab={[<Icon type="power" size={14} className="tab_icon" />, 'Ինչպես օգտվել ծրագրից']} key="tab-4">
              <p>Content of Tab Pane 3</p>
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
