import React from "react";
import createMarkup from "../../utils/createMarkup";

const NewsContainer = (props) => {
  const {text} = props;

  return (
    <div
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  )
};

export default NewsContainer;