import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.scss';
import {initReportsLoad, getReportList, printRequestReports} from '../../reducers/reportsReducer'
import Title from "../../components/Title/Title";
import Icon from "../../components/Icon/Icon";
import {Spin, Form, Table, message, Tooltip, Typography, Button} from 'antd';
import moment from 'moment';
import NotificationsPopover from "../NotificationsPopover/NotificationsPopover";
import Filter from "./Filter";
import ReportsInfo from "./ReportsInfo";
import ErrorMessageContent from "../../components/ErrorMessageContent/ErrorMessageContent";

class Reports extends Component {
  constructor(props){
    super(props);
    this.columns = [
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Հայտի համար</Typography.Text>,
        dataIndex: 'appNumber',
        render: text => <a target="_blank" href={`/application/${text}`} className="base-text underlined" style={{fontSize: 14}}>{text}</a>,
        sorter: (a, b) => parseInt(a.appNumber) - parseInt(b.appNumber)
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Անուն Ազգանուն</Typography.Text>,
        dataIndex: 'fullname',
        render: text => (<><b style={{fontSize: 14}}>{text[0]}</b><div style={{color: '#565656', fontSize: 14}}>{text[1]}</div></>),
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Կարգավիճակ</Typography.Text>,
        dataIndex: 'status',
        render: text => (
            <>
              <b className="base-text block_elem" style={{color: text[1]}}>{text[0]}</b>
              {text[2] ? <div className="base-text" style={{color: '#D60000'}}><Icon type="info" size={18} /> {text[2]} </div> : null}
            </>
        ),
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} ><Tooltip title='Նախատեսվող գումար' >Նախատեսվող ֏</Tooltip></Typography.Text>,
        dataIndex: 'limit',
        render: text => <b>{text} ֏</b>,
        sorter: (a, b) => parseInt(a.limit) - parseInt(b.limit),
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} ><Tooltip title='Հաստատված գումար'>Հաստատված ֏</Tooltip></Typography.Text>,
        dataIndex: 'loanSumma',
        render: text => <b>{text} ֏</b>,
        sorter: (a, b) => parseInt(a.loanSumma) - parseInt(b.loanSumma),
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} ><Tooltip title='Մուտքագրման ամսաթիվ' >Մուտք. ա/թ.</Tooltip></Typography.Text>,
        dataIndex: 'registrationDate',
        render: text => <><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[0]}</div><div style={{fontSize: 14, color: '#565656'}}>{text.forRender[1]}</div></>,
        sorter: (a,b) =>  moment(a.registrationDate.date).unix() - moment(b.registrationDate.date).unix()
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Օգտատեր</Typography.Text>,
        dataIndex: 'user',
        render: text => <div style={{fontSize: 17}}>{text}</div>
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Խանութ</Typography.Text>,
        dataIndex: 'shop',
        render: text => {
          return (
            <div style={{fontSize: 14}}>{text}</div>
          )
        }
      },
      {
        title: <Typography.Text style={{fontSize: 13, color: "white"}} >Ապրանք</Typography.Text>,
        dataIndex: 'product',
        render: text => {
          /*let firstItemName;

          if (text) {
            firstItemName = text.split(',')[0]; // taking first word from API object's itemNames value (e.g Nokia, Sony, Apple);
          }

          return (
            <div style={{fontSize: 14}}>
              <Tooltip title={text} >{firstItemName}</Tooltip>
            </div>
          )*/
          return (
            <div style={{fontSize: 14}}>{text}</div>
          )
        }
      }
    ];
  }

  componentDidMount() {
    this.props.dispatch(initReportsLoad());
  }

  componentDidUpdate(prevProps, prevState) {
    let {error} = this.props;
    if ((error.type === "init_reports_fail" || error.type === "get_reports_fail") && (prevProps.error.message !== error.message && error.message !== "")) {
      message.error(<ErrorMessageContent text={error.message} />, 5);
    }

    if (this.props.reportsExcelFile !== prevProps.reportsExcelFile) {
      const {startDate, endDate} = this.props;

      const file = `data:application/octet-stream;charset=utf-8;base64,${this.props.reportsExcelFile}`;
      let link = document.createElement("a");
      link.download = `Հաշվետվություն_${startDate}--${endDate}.xls`;
      link.href = file;
      link.dispatchEvent(new MouseEvent("click"));
    }
  }

  handlePageOnChange = (value) => {
    let param = this.props.reports_list_filter;
    this.props.dispatch(getReportList(param, value));
  }

  onExpand = (record) => {
    return <p>{record.shop[1]}</p>;
  }

  setStatusColorByQuality = (quality) => {
    let color = "";
    switch (quality) {
      case 1:
        color = "#245D7D";
        break;
      case 2:
        color = "#00A789";
        break;
      case 3:
        color = "#00A789";
        break;
      case 4:
        color = "#E48900";
        break;
      case 5:
        color = "#00A789";
        break;
      case 6:
        color = "#00A789";
        break;
      case 7:
        color = "#00A789";
        break;
      case 8:
        color = "#696969";
        break;
      case 9:
        color = "#8CA109";
        break;
      case 10:
        color = "#323232";
        break;
      case 99:
        color = "#840000";
        break;
      case 100:
        color = "#D60000";
        break;
    }
    return color;
  }

  formatDateWithMoment = (date) => {
    return moment(date).format("DD/MM/YYYY");
  }

  formatNumber = (number) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  downloadExcelFile = () => {
    this.props.dispatch(printRequestReports());
  };

  render() {
    const {loading} = this.props;
    let data = [];
    if (Object.entries(this.props.reports_list).length !== 0 && this.props.reports_list.RequestList !== undefined) {
      this.props.reports_list.RequestList.map((element, index) => {
        let registrationDate = element.registrationDate != null ? this.formatDateWithMoment(element.registrationDate.split('T')[0]) : ""; // get Date before T character
        let registrationDateHour = element.registrationDate != null ? element.registrationDate.split('T')[1] : ""; // get Hour after T character

        data.push({
          key: index,
          appNumber: element.requestID,
          fullname: [element.fullName, element.documentNumber],
          status: [element.qualityDescription, this.setStatusColorByQuality(element.quality)],
          limit: this.formatNumber(element.startCapital),
          loanSumma: this.formatNumber(element.approvedAmount),
          registrationDate: {
            date: element.registrationDate,
            forRender: [registrationDate, registrationDateHour]
          },
          user: [element.userName],
          shop: element.marketName,
          product: element.itemNames,
          // expandable: element.itemNames ? true : false,
        })
      })
    }

    const paginationParams = {
      hideOnSinglePage: true,
      pageSize: data.length,
      total: data.length * this.props.reports_list.PageCount,
      onChange: this.handlePageOnChange,
      current: this.props.reports_list_filter.pageNumber,
    };

    return (
      <Spin
        spinning={(this.props.loading.type === "init_reports_load" || this.props.loading.type === "get_reports_load") && this.props.loading.loading_state}
      >
        <div className="row">
          <div className="column small-12">
            <div className="flex-container row-wrap align-justify align-middle mb_40">
              <Title text="ՀԱՇՎԵՏՎՈՒԹՅՈՒՆՆԵՐ" size="md" weight="black" />

              <NotificationsPopover count="2" />
            </div>
          </div>

          <div className="column small-12">
            <Filter />

            <ReportsInfo />

            <Button
              type="link"
              className="link_btn"
              onClick={this.downloadExcelFile}
              loading={loading.type === "print_request_reports" && loading.loading_state}
            >
              <img
                style={{width: 13, marginRight: 5, marginBottom: 2}}
                src={require("../../assets/img/exfile.svg")}
                alt="excel-icon"
              />
              Հաշվետվություն
            </Button>

            <Table
              columns={this.columns}
              // expandedRowRender={record => record.expandable && <p>{record.product}</p>}
              // rowClassName={record => (record.expandable ? "show" : "hidden")}
              dataSource={data}
              pagination={paginationParams}
            />
          </div>
        </div>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.reportsReducer.error_message,
    loading: state.reportsReducer.loading,
    reports_list: state.reportsReducer.reports_list,
    reports_list_filter: state.reportsReducer.filter,
    reportsExcelFile: state.reportsReducer.reportsExcelFile,
    startDate: state.reportsReducer.filter.startDate,
    endDate: state.reportsReducer.filter.endDate
  };
}

const ReportsContainer = Form.create()(Reports);

export default connect(mapStateToProps)(ReportsContainer);